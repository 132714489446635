import { UntypedFormControl, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Agrupador, Operador } from 'src/app/model';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { EUF } from 'src/app/constantes/app.euf.const';
import { Equipamento } from 'src/app/model/app.equipamentos.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
    selector: 'app-campo',
    templateUrl: './campo.component.html',
    styleUrls: ['./campo.component.scss']
})
export class CampoComponent implements OnInit {

    public form: FormGroup;
    public ufs: ListItem[] = EUF.toList();

    @Input() campo: UntypedFormControl;
    @Input() index: number;
    private _ehEdicao: boolean = false;
    @Input() set ehEdicao(_ehEdicao: string) {
        if (_ehEdicao == "")
            this._ehEdicao = true;
    };
    @Input() set ultimo(indexUltimo: number) {
        this.campo.value.ehUltimo = indexUltimo == this.index;
    };
    @Input() operadores: Operador[];
    @Input() agrupadores: Agrupador[];
    @Input() equipamentos: Equipamento[];

    @Output() deleteItem = new EventEmitter();

    @ViewChild('campo') input: { nativeElement: { value: any; }; };

    public configuracoesPadrao: IDropdownSettings = {
        singleSelection: true,
        allowSearchFilter: true,
        textField: 'nome',
        itemsShowLimit: 1,
        closeDropDownOnSelection: true
    };

    public configCampo: IDropdownSettings = {
        ...this.configuracoesPadrao,
        idField: 'identificadorDoEquipamento',
    }

    public configLocal: IDropdownSettings = {
        ...this.configuracoesPadrao,
        idField: 'localId',
    }

    constructor(
        private formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            operador: new UntypedFormControl('', Validators.required),
            agrupador: new UntypedFormControl('', Validators.required),
            campo: new FormControl([]),
            localId: new UntypedFormControl([]),
            sentidoDaLeitura: new UntypedFormControl('')
        }, { updateOn: 'change' });
    }

    get item(): any { return this.campo.value?.item || {}; }
    get ehUltimo(): any { return this.campo.value.ehUltimo || false; }
    get transform(): any { return this.campo.value.transform || ((valor: any) => valor); }
    get ehObrigatorio(): any { return !!this.item.naoRemove; }

    get ehCpf(): boolean { return this.item.tipoDadoNome === 'CPF'; }
    get ehTexo(): boolean { return this.item.tipoDadoNome === 'TEXTO'; }
    get ehInteiro(): boolean { return this.item.tipoDadoNome === 'INTEIRO'; }
    get ehDecimal(): boolean { return this.item.tipoDadoNome === 'DECIMAL'; }
    get ehDatahora(): boolean { return this.item.tipoDadoNome === 'DATAHORA'; }
    get ehCnpj(): boolean { return this.item.tipoDadoNome === 'CNPJ'; }
    get ehIncricaoEstadual(): boolean { return this.item.tipoDadoNome === 'INSCRIÇÃO ESTADUAL'; }
    get ehPlaca(): boolean { return this.item.tipoDadoNome === 'PLACA'; }
    get ehUF(): boolean { return this.item.tipoDadoNome === 'UF'; }
    get ehEquipamentos(): boolean { return this.item.tipoDadoNome === 'EQUIPAMENTOS'; }

    get localId(): number {
        if (typeof this.form.controls.localId.value == "number")
            return this.form.controls.localId.value;
        return this.form.controls.localId.value?.at(0)?.localId;
    }
    get sentidoDaLeitura(): string { return this.form.controls.sentidoDaLeitura.value; }

    get localIdSelecionado(): boolean { return !!this.localId; }
    get sentidoDaLeituraSelecionado(): any { return !!this.sentidoDaLeitura; }

    get locais(): ListItem[] {
        return this.equipamentos?.reduce((final, { localId, local }) => {
            if (!final.some(local => local.localId === localId))
                final.push({ localId, nome: local?.nome });
            return final;
        }, []);
    }

    get sentidosDaLeitura(): ListItem[] {
        return this.equipamentos
            ?.filter(({ localId }) => localId == this.localId)
            .reduce((final, { sentidoDaLeitura }) => {
                if (!final.some(sentido => sentido.nome === sentidoDaLeitura))
                    final.push({ nome: sentidoDaLeitura });
                return final;
            }, []);
    }

    get equipamentosFiltrados(): Equipamento[] {
        return this.equipamentos?.filter(equipamento => (!this.localId || equipamento.localId == this.localId) && (!this.sentidoDaLeitura || equipamento.sentidoDaLeitura == this.sentidoDaLeitura)) || [];
    }

    ngOnInit(): void {
        this.form.controls.campo.addValidators(this.campo.validator);
        if (this.transform)
            this.form.controls.campo
                .valueChanges
                .subscribe(value => {
                    const ehTexto = typeof value === "string";
                    const ehObjeto = typeof value === "object";
                    let valorCampoTela = value;
                    if (this.ehEquipamentos) {
                        if (ehTexto)
                            valorCampoTela = this.equipamentos.filter(({ identificadorDoEquipamento }) => identificadorDoEquipamento == value);
                    } else
                        valorCampoTela = value = this.transform(value);
                    if (this._ehEdicao)
                        this.form.patchValue({ campo: valorCampoTela }, { emitEvent: false });

                    if (this.ehEquipamentos && typeof valorCampoTela === "object")
                        valorCampoTela = valorCampoTela.at(0)?.identificadorDoEquipamento;

                    if (this.ehCnpj || this.ehCpf)
                        valorCampoTela = this.somenteNumeros(valorCampoTela)

                    if (this.ehPlaca || this.ehIncricaoEstadual)
                        valorCampoTela = this.letrasENumerosSemCaracteresEspeciaisSemAcentuacao(valorCampoTela);

                    this.campo.patchValue({
                        ...this.campo.value,
                        valor: valorCampoTela,
                        ehValido: !this.form.controls.campo.invalid
                    }, { emitEvent: false });
                });
        this.form.controls.operador.valueChanges
            .subscribe(_value => this.campo.patchValue({ ...this.campo.value, operador: _value, ehValido: !this.form.controls.campo.invalid }, { emitEvent: false }));
        this.form.controls.agrupador.valueChanges
            .subscribe(_value => this.campo.patchValue({ ...this.campo.value, agrupador: _value, ehValido: !this.form.controls.campo.invalid }, { emitEvent: false }));

        let operador = this.operadores.find(({ operadorId }) => operadorId == this.campo.value.operador?.operadorId);
        if (!operador)
            operador = this.operadores.find(({ operadorId }) => operadorId == this.campo.value.operadorId);
        let agrupador = this.agrupadores.find(({ agrupadorId }) => agrupadorId == this.campo.value.agrupador?.agrupadorId);
        if (!agrupador)
            agrupador = this.agrupadores.find(({ agrupadorId }) => agrupadorId == this.campo.value.agrupadorId);
        let equipamento = this.equipamentos.find(({ identificadorDoEquipamento }) => identificadorDoEquipamento == this.campo.value?.valor);

        let localId = null;
        if (this.ehEquipamentos)
            localId = this.locais.filter(({ localId }) => localId == equipamento?.localId ?? null);

        this.form.patchValue({
            operador: operador || this.operadores.at(0),
            agrupador: agrupador || this.agrupadores.at(0),
            campo: this.campo.value?.valor,
            localId,
            sentidoDaLeitura: equipamento?.sentidoDaLeitura ?? ''
        });
    }

    onChangeLocal() {
        this.form.patchValue({ sentidoDaLeitura: '' });
        this.form.patchValue({ campo: '' });
    }

    onChangeSentido() {
        this.form.patchValue({ campo: '' });
    }

    onRemoveFieldInForm() {
        this.deleteItem.emit({ value: this.index });
    }

    formatarValor() {
        if (!this.form.controls.campo.value)
            return;

        this.form.patchValue({
            campo: this.tratarValor(this.form.controls.campo.value.toUpperCase())
        });
    }

    private tratarValor(valor: any): any {
        switch (true) {
            case this.ehCpf:
                return this.mascaraParaCPF(valor);
            case this.ehTexo:
                return this.letrasENumerosSemCaracteresEspeciais(valor);
            case this.ehInteiro:
            case this.ehDecimal:
                return this.somenteNumeros(valor);
            case this.ehCnpj:
                return this.mascaraParaCNPJ(valor);
            case this.ehPlaca:
            case this.ehIncricaoEstadual:
                return this.letrasENumerosSemCaracteresEspeciaisSemAcentuacao(valor);
            default:
                return valor;
        }
    }

    private somenteNumeros(valor: any): any {
        if (!valor)
            return null;

        return valor.replace(/[^0-9]/g, '');
    }

    private somenteLetras(valor: any): any {
        return valor.replace(/[^0-9]/g, '');
    }

    private letrasENumerosSemCaracteresEspeciaisSemAcentuacao(valor: any): any {
        if (!valor)
            return null;

        return valor.replace(/[^a-zA-Z0-9]/g, '');
    }

    private letrasENumerosSemCaracteresEspeciais(valor: any): any {
        if (!valor)
            return null;

        return valor.replace(/[^a-zA-ZÀ-úçÇ 0-9]/g, '');
    }

    private mascaraParaCPF(cpf: any): any {
        if (!cpf)
            return null;

        cpf = cpf.replace(/\D/g, "");
        cpf = cpf.replace(/^(\d{3})/g, "$1.");
        cpf = cpf.replace(/(\d{3})(\d{3})/g, "$1.$2-");
        return cpf;
    }

    private mascaraParaCNPJ(cnpj: any): any {
        if (!cnpj)
            return null;

        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})/g, "$1.");
        cnpj = cnpj.replace(/(\d{3})(\d{3})(\d{4})/g, "$1.$2/$3-");
        return cnpj;
    }
}
