import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { ToastUtil } from 'src/app/helper/app.toast.utils';

@Component({
  selector: 'app-passo5justificativa',
  templateUrl: './passo5justificativa.component.html',
  styleUrls: ['./passo5justificativa.component.scss'],
})
export class Passo5JustificativaComponent implements OnInit {
  public form: FormGroup;

  constructor (private rootFormGroup: FormGroupDirective, private toast: ToastUtil) {}

  ngOnInit (): void {
    this.form = this.rootFormGroup.control as UntypedFormGroup;

    this.form.controls.justificativa.valueChanges.subscribe(valor => {
      if (valor) {
        this.form.controls.justificativa.setValue(valor, { emitEvent: false });
        if (this.form.controls.justificativa.dirty) {
          if (valor.length >= 300) {
            this.toast.showWarning('A quantidade máxima de caracteres foi atingida', 'Justificativa');
          }
        }
      }
    });
  }
}
