import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ParmissoesPagina } from '../permissoes.pages';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/app.login.service';
import { Observable, switchMap, tap } from 'rxjs';
import { LocalizacaoUsuarioDto } from './dto/localizacaousuario.dto';
import { NgbdSortableHeader } from 'src/app/directives/app.sortable.directive';
import { LocalizacaoUsuarioService } from 'src/app/services/app.localizacaousuario.service';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { ComunService } from 'src/app/services/app.comum.service';
import { SubUnidade, Unidade, UnidadeFiscal } from 'src/app/model/app.unidadefiscal.model';
import { ExcelService } from 'src/app/services/app.excel.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MapaComponent } from './mapa/mapa.component';
import { MasterComponent } from 'src/app/components/master/master.component';
import * as moment from "moment";
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { BlockUtil } from 'src/app/helper/app.block.utils';


@Component({
    selector: 'app-usuarioconectado',
    templateUrl: './usuarioconectado.component.html',
    styleUrls: ['./usuarioconectado.component.scss'],
    providers: [
        LocalizacaoUsuarioService,
        MasterComponent
    ]
})
export class UsuarioconectadoComponent extends ParmissoesPagina implements OnInit {

    public form: FormGroup;
    public isMobile: boolean = false;
    public usuariosConectados$: Observable<LocalizacaoUsuarioDto[]>;
    public total$: Observable<number>;
    public unidadesFiscais: UnidadeFiscal[];
    public usuarios: Array<any> = [];
    public nomesDosServidoresDaUnidade: any[];
    public habilitarDropNomeServidores = true;
    public selectedUnidades = [];
    public activeButton = false;
    public nomeUsuarios = "";

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<LocalizacaoUsuarioDto>>;

    public configuracoesNomeUsuario: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: false,
        textField: 'nome',
        idField: 'usuarioId'
    };

    public configuracoesPadrao: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: true,
        textField: 'descricao',
        idField: 'unidadeFiscalId'
    };
    constructor(
        private router: Router,
        private accountService: AccountService,
        private formBuilder: FormBuilder,
        public service: LocalizacaoUsuarioService,
        private dialogModal: DialogUtil,
        private comunService: ComunService,
        private excelService: ExcelService,
        private modalService: NgbModal,
        private toast: ToastUtil,
        private blockUtil: BlockUtil
    ) {
        super(router.url, accountService);
        this.usuariosConectados$ = service.usuariosConectados$;
        this.total$ = service.total$;

        this.form = this.formBuilder.group({
            nomeUsuario: new FormControl(''),
            unidadeDoUsuario: new FormControl(),
            dataInicio: new FormControl(''),
            dataInicioAux: new FormControl(''),
            horaInicio: new FormControl(),
            dataFim: new FormControl(''),
            dataFimAux: new FormControl(''),
            horaFim: new FormControl('')
        });
    }

    ngOnInit(): void {
        this.service.get({});
        this.obterUnidades();

        this.form.get('dataFimAux').valueChanges.subscribe(e => { this.changeDataAux(e, 'dataFim'); })
        this.form.get('dataInicioAux').valueChanges.subscribe(e => { this.changeDataAux(e, 'dataInicio'); })
    }

    changeDataAux(event, seletor) {
        if (!event)
            return;

        let formatedDate = `${event?.day}/${event?.month}/${event?.year}`;
        this.form.get(seletor).setValue(formatedDate, { emitEvent: false });
    }

    obterUnidades() {
        this.comunService.ObterTodasUnidadesFiscaisPorUsuario()
            .subscribe(unidadesFiscais => {
                this.unidadesFiscais = unidadesFiscais.filter((unidadeFiscal: UnidadeFiscal) => unidadeFiscal.usuarios.length > 0);

            });
    }

    search(validarPreenchimentoDoFiltro: boolean = true) {
        this.service.bloqueioTela = "localizacaoUsuario";
        let body = this.form.getRawValue();

        if (validarPreenchimentoDoFiltro && !this.validarFiltro(body)) {
            return;
        }

        if (body.unidadeDoUsuario) {
            var unidadesIdAux = this.form.get('unidadeDoUsuario').value.map(u => u.unidadeFiscalId);
            body.unidadeDoUsuario = unidadesIdAux;
        }

        if (body.nomeUsuario)
            this.nomesDosServidoresDaUnidade = this.form.get('nomeUsuario').value.map(u => u.nome).join(",");
        else
            this.nomesDosServidoresDaUnidade = null;

        let dataHoraInicio = this.DataHoraFormat(body.dataInicioAux, body.horaInicio);
        let dataHoraFim = this.DataHoraFormat(body.dataFimAux, body.horaFim);

        this.service.get(
            {
                ...body,
                dataHoraInicio: dataHoraInicio,
                dataHoraFim: dataHoraFim,
                nomesDosServidoresDaUnidade: this.nomesDosServidoresDaUnidade
            });
    }

    validarFiltro(filtro: any): boolean {
        if (!filtro.unidadeDoUsuario || String.isNullOrEmpty(filtro.unidadeDoUsuario.length == 0)) {
            this.toast.showWarning("Unidade Fiscal é obrigatória.");
            return false;
        }

        if (String.isNullOrEmpty(filtro.dataInicio)) {
            this.toast.showWarning("Data Inicial é obrigatória.");
            return false;
        }

        if (String.isNullOrEmpty(filtro.horaInicio)) {
            this.toast.showWarning("Hora Inicial é obrigatória.");
            return false;
        }

        if (String.isNullOrEmpty(filtro.dataFim)) {
            this.toast.showWarning("Data Final é obrigatória.");
            return false;
        }

        if (String.isNullOrEmpty(filtro.horaFim)) {
            this.toast.showWarning("Hora Final é obrigatória.");
            return false;
        }

        return true;
    }

    DataHoraFormat(data: any, hora: any = null) {
        if (data && hora) {
            return (moment(`${data.year}-${data.month}-${data.day} ${hora.hour}:${hora.minute}:00`)).format('YYYY-MM-DD HH:mm:ss');
        } else if (data) {
            return (moment(`${data.year}-${data.month}-${data.day} 00:00:00`)).format('YYYY-MM-DD HH:mm:ss');
        }
    }

    limpar() {
        this.form.controls.dataFim.patchValue("");
        this.form.controls.dataFimAux.patchValue("");
        this.form.controls.dataInicio.patchValue("");
        this.form.controls.dataInicioAux.patchValue("");
        this.form.controls.horaFim.patchValue("");
        this.form.controls.horaInicio.patchValue(null);
        this.form.controls.nomeUsuario.patchValue("");
        this.form.controls.unidadeDoUsuario.patchValue(null);

        this.service.get(this.form.getRawValue())
    }

    onVisualizarLocalizacaoUsuario({ data }: any) {
        this.dialogModal.open({
            title: 'Fiscalizar',
            text: 'Ação não implementada',
            btns: [],
        });
    }

    exportExcel(): void {
        const jsonData = this.service.usuariosConectados.map(({
            NomeDoUsuario,
            LoginUsuario,
            UnidadeDoUsuario,
            dataHoraInclusao,
            Coordenadas
        }) => ({
            "Nome do Usuário": NomeDoUsuario,
            "Login do Usuário": LoginUsuario,
            "Unidade do Usuário": UnidadeDoUsuario,
            "Data e Hora Conexão": dataHoraInclusao,
            "Coordenadas": Coordenadas
        }));
        this.excelService.exportExcel(jsonData, "LocalizacaoUsuario");
    }


    selecionarUnidade(event: any) {
        if (event) {
            this.selectedUnidades = event;
        } else {
            this.selectedUnidades = this.form.controls.unidadeDoUsuario.getRawValue();
        }

        let unidadeSelecionada = this.unidadesFiscais.reduce((acumulator: any, elemento: any) => {
            this.selectedUnidades.forEach((u: any) => {
                if (u.unidadeFiscalId == elemento.unidadeFiscalId) {
                    acumulator.push(elemento)
                }
            })
            return acumulator;

        }, [])

        this.usuarios = unidadeSelecionada.reduce((acumulator: any, elemento) => {
            elemento.usuarios.forEach(e => acumulator.push(e))
            return acumulator;
        }, [])
    }

    removerUnidadeFiscal(_unidade: Unidade) {
        const unidades = this.form.controls.codigoUnidade.value?.filter(unidade => _unidade.codigoLocal != unidade.codigoLocal);
        this.form.patchValue({ unidades });
        this.form.removeControl(_unidade.campoFiscais);
    }

    abrirMapa(data: LocalizacaoUsuarioDto) {
        const modalRef: NgbModalRef = this.modalService.open(MapaComponent, {
            backdrop: true,
            centered: true,
            modalDialogClass: 'modal-dialog',
        });

        modalRef.componentInstance.nomeUsuario = data.NomeDoUsuario;
        modalRef.componentInstance.login = data.LoginUsuario;
        modalRef.componentInstance.unidadeDeLotacao = data.UnidadeDoUsuario;
        modalRef.componentInstance.dataHoraInclusao = data.dataHoraInclusao;
        modalRef.componentInstance.coordenadas = data.Coordenadas;
    }
}
