<div class="d-flex col-md-12 mr-3 justify-content-between p-0">
    <div class="d-flex flex-wrap" style="width: 100%; gap:10px;">
        <form id="pesquisaAvancadaForm" class="d-flex flex-wrap" style="width: 100%; gap:10px;">

        <div class="input-group mb-3" style="width: 200px;height: 40px;">
            <select class="form-control form-control-sm" style="width: auto" name="attributeToFilter"
                (change)="selectChange($event)"  selected="selecione">
                <option value='selecione'>Selecione</option>
                <option *ngFor="let c of colsGrid" [value]="c.colKey">{{c.colName}}</option>
            </select>
        </div>

        <div *ngFor="let input of inputs; let i = index">
                <app-inputs [body]="body" (delete)="delete(i)" [inputProp]="input" ></app-inputs>
        </div>

        <div class="input-group-append d-flex gap-3" style="margin-bottom: 10px;">
            <button type="button" class="btn btn-primary my-0 d-flex align-items-center"
                style="gap: 0.5rem; height: 40px;" (click)="search()">
                Pesquisar<i class="fa-solid fa-magnifying-glass"></i>
            </button>
            <button type="reset" class="btn btn-default btn-sm my-0 d-flex align-items-center"
                style="gap:0.5rem; margin-left: 10px; height: 40px;" (click)="limpar()">Limpar<i
                    class="fa-solid fa-times"></i>
            </button>
            <button type="reset" class="btn btn-default btn-sm my-0 d-flex align-items-center"
                style="gap:0.5rem; margin-left: 10px; height: 40px;" (click)="closer()">Fechar pesquisa avançada<i
                    class="fa-solid fa-times"></i>
            </button>
        </div>
    </form>

    </div>
</div>