import { ModuloService } from 'src/app/services/app.modulo.service';
import { ModuloValidator } from 'src/app/validators/modulo.validator';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Observable } from 'rxjs';
import { catchError, debounceTime, map, pluck, startWith, switchMap, tap } from 'rxjs/operators';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { ComunService } from 'src/app/services/app.comum.service';
import { Icone } from 'src/app/model';

@Component({
    selector: 'app-entrada-editar',
    templateUrl: './modulo.editar.component.html',
    styleUrls: ['./modulo.editar.component.scss']
})
export class ModuloEditarComponent implements OnInit {

    public form: FormGroup;
    public perfils: Observable<ListItem[]>;
    public grupos: Observable<ListItem[]>;
    public _icones: Observable<Icone[]>;
    public icones: Icone[];
    public filtro: string;
    public icone: Icone;
    public selecionar: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private validator: ModuloValidator,
        private blockUtil: BlockUtil,
        public service: ModuloService,
        private toast: ToastUtil,
        private comum: ComunService
    ) {

        this.form = this.formBuilder.group({
            moduloId: new FormControl(null, [Validators.required]),
            grupo: new FormControl('', [Validators.required]),
            titulo: new FormControl(null, [Validators.required]),
            caminho: new FormControl(null, [Validators.required]),
            icone: new FormControl(null),
            ordem: new FormControl(null),
            filtro: new FormControl(null),
        });

        const moduloId = +this.route.snapshot.queryParamMap.get("moduloId");
        this.service.getById(moduloId)
            .subscribe((data: any) => {
                if (!data)
                    return;
                const { moduloId, titulo, caminho, ordem } = data;
                this.grupos = this.comum.ObterGrupos()
                    .pipe(
                        tap((grupos: any) => {
                            if (!grupos)
                                return;
                            const grupo = grupos.find(({ grupoID }) => grupoID == data.grupoId);

                            this._icones = this.comum.ObterIcones()
                            this._icones
                                .subscribe(icones => {
                                    this.icones = icones;
                                    this.icone = icones.find(({ classe }) => classe == data.icone);
                                    this.form.patchValue({
                                        moduloId,
                                        grupo,
                                        titulo,
                                        caminho: caminho?.replace(/\//g, ""),
                                        icone: this.icone,
                                        ordem
                                    });
                                });
                        })
                    );
            });
    }

    get iconeSelecionado(): string {
        return this.form.controls.icone.value;
    }

    ngOnInit(): void {
        const input = fromEvent(document.querySelector("#filtro"), "input");

        input
            .pipe(
                debounceTime(300),
                pluck("target", "value"),
                map((evento: string) => evento.trim()),
                switchMap(termo => {
                    if (!termo || termo.length < 2)
                        return this._icones;

                    return this._icones
                        .pipe(
                            map(icones => icones.filter(({ nome }) => nome.includes(termo)))
                        )
                }),
                catchError((error, source) => source.pipe(startWith([])))
            )
            .subscribe(icones => this.icones = icones);
    }

    onSubmit() {
        this.blockUtil.bloquear();

        this.validator.set = this.form;

        if (!this.validator.isValid) {
            this.blockUtil.desbloquear();
            this.validator.showMessages();
        } else {
            this.service.put(this.validator.modulo)
                .subscribe({
                    next: (data: string) => {
                        this.toast.showSuccess(data);
                        this.blockUtil.desbloquear();
                        this.router.navigate(['menu']);
                    },
                    error: (erro: string) => {
                        this.toast.showDanger(erro);
                        this.blockUtil.desbloquear();
                    }
                })
        }
    }

    selectIcon(icone: String) {
        if (this.iconeSelecionado == icone)
            this.removeIcon();
        else
            this.form.patchValue({ icone });
    }

    removeIcon() {
        this.form.patchValue({ icone: null });
    }

    selectOrigin() {
        this.form.patchValue({ icone: this.icone });
        this.confirmIcon();
    }

    confirmIcon() {
        this.selecionar = false;
        this.clearFilter();
    }

    toogleSelectIcon() {
        this.selecionar = !this.selecionar;
    }

    clearFilter() {
        this.form.patchValue({ filtro: null });
        this._icones.subscribe(icones => this.icones = icones);
    }

    cancel() {
        this.router.navigate(['menu']);
    }

}
