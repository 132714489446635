import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CondicionanteService } from 'src/app/services/app.condicionante.service';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { ComunService } from 'src/app/services/app.comum.service';
import { Dashboard } from 'src/app/model/app.dashboard.model';

import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexTitleSubtitle,
    ApexStroke,
    ApexGrid
} from "ng-apexcharts";


export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    title: ApexTitleSubtitle;
};

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [
        CondicionanteService,
        DialogUtil,
        BlockUtil
    ]
})
export class DashboardComponent extends ParmissoesPagina implements OnInit {

    public isMobile: boolean = false;
    public qtdesAlertas: any = [];
    public horas: number = 24;
    public motorOnline: boolean = true;
    public ultimaAtualizacao: Date = new Date();
    public regras: Dashboard = null;
    public chartOptions: Partial<ChartOptions>;

    constructor(
        private router: Router,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private formBuilder: FormBuilder,
        public service: CondicionanteService,
        private toast: ToastUtil,
        private comum: ComunService,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService
    ) {
        super(router.url, accountService);
        this.onGetDashboard();
        const horasHoje = (new Date()).getHours() + 1;
        this.comum.ObterDashboard(horasHoje)
            .subscribe(dashboard => this.regras = dashboard);

        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });

        this.chartOptions = {
            series: [
                {
                    name: "Pendentes",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            ],
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "straight"
            },
            // title: {
            //     text: "Alertas Pendentes",
            //     align: "left"
            // },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                }
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Mai",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez",
                ]
            }
        };
    }

    ngOnInit(): void {
    }

    onGetDashboard() {
        this.comum.ObterDashboard(this.horas)
            .subscribe(({
                qtdeAlertasPendentes,
                qtdeAlertasEmAtendimento,
                qtdeAlertasFinalizados,
                qtdeAlertasVinculados
            }) => {
                this.qtdesAlertas = [];
                this.qtdesAlertas.push({ icone: "fas fa-exclamation-triangle text-danger", situacaoNome: "Alertas Pendentes", qtde: qtdeAlertasPendentes })
                this.qtdesAlertas.push({ icone: "fa-solid fa-link text-info", situacaoNome: "Alertas Vinculados", qtde: qtdeAlertasVinculados })
                this.qtdesAlertas.push({ icone: "fas fa-tasks text-warning", situacaoNome: "Alertas em Atendimento", qtde: qtdeAlertasEmAtendimento })
                this.qtdesAlertas.push({ icone: "fas fa-check text-success", situacaoNome: "Alertas Finalizados", qtde: qtdeAlertasFinalizados })                
            });
    }
}
