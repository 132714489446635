import { UtilService } from './app.utils.service';
import { EOrderBy } from '../constantes/app.eorderby.const';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SearchResult, State } from '../interfaces/app.utilservices.interface';
import { ComunService } from './app.comum.service';
import { SortColumn, SortDirection } from '../directives/app.sortable.directive';
import { Placa } from '../model/app.placas.dto';
import { ToastUtil } from '../helper/app.toast.utils';
import { BlockUtil } from '../helper/app.block.utils';

@Injectable({
    providedIn: 'root'
})

export class PlacaService {
    private _search$ = new Subject<void>();
    private _placas$ = new BehaviorSubject<Placa[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    private _situacao$ = new BehaviorSubject<boolean>(null);
    public blockUtil: BlockUtil = null;
    public consultaRetornouResultado = false;

    private readonly URL_CONTROLLER = 'Placa';

    private _state: State<Placa> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: EOrderBy.ASC,
        placa: '',
        dataInicio: '',
        dataFim: ''
    };

    constructor(private util: UtilService, private comunService: ComunService, private toast: ToastUtil,) {
        this._search$.pipe(     
            tap(() => this.blockUtil.bloquear()),       
            switchMap(() => this._pagination())
            ).subscribe(({ placas, total }) => {
                this._placas$.next(placas);
                this._total$.next(total);
                this.consultaRetornouResultado = total > 0;
                this.blockUtil.desbloquear();
            });
    }

    get placas$() { return this._placas$.asObservable(); }
    get placas() { return this._placas$.value }

    get total$() { return this._total$.asObservable(); }
    get page() { return this._state.page; }
    get pages() { return Math.ceil(this._total$.value / this._state.pageSize); }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get situacao$() { return this._situacao$.asObservable(); }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize, page: 1 }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn<Placa>) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    public get({ placa = null, dataInicio = null, dataFim = null }) {
        this._set({ placa, dataInicio, dataFim });
    }

    public getOrderByDirection(sortColumn: SortColumn<Placa>, sortDirection: SortDirection) {
        this._set({ sortColumn, sortDirection });
    }

    private _set(patch: any) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _pagination(): Observable<SearchResult<Placa>> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}`;

        return this.util.Post(url, {
            Page: this._state.page,
            Size: this._state.pageSize,
            OrderBy: this._state.sortDirection || EOrderBy.ASC,
            FieldAtOrder: this._state.sortColumn?.toCaptalize(),
            placa: this._state.placa,
            dataInicio: this._state.dataInicio,
            dataFim: this._state.dataFim
        }).pipe(
            map((data) => ({ placas: data.results.map(dto => new Placa(dto)), total: data.total })),
            catchError(errorRequest => {
                const { error, message } = errorRequest;
                console.log("ERROR...", error?.message || message);
                this.toast.showWarning(error?.message || message);
                return of({ placas: [], total: 0 });
            })
        );
    }
}
