import { NgModule, ElementRef } from '@angular/core';
import { PlaceholderComponent } from './master.placeholder.component';
import { ContentComponent } from './master.content.component';
import { ContentService } from './master.content.service';

export interface ContentDescriptor {
    placeholder: string;
    elementRef: ElementRef;
}

@NgModule({
    declarations: [PlaceholderComponent, ContentComponent],
    exports: [PlaceholderComponent, ContentComponent],
    providers: [ContentService],
})
export class MasterModule {

}
