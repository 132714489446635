import { ListItem } from "../interfaces/app.utilservices.interface";

export enum ETipoDado {
    TEXTO = 1,
    INTEIRO = 2,
    DECIMAL = 3,
    DATAHORA = 4,
    CNPJ = 5,
    INSCRICAO_ESTADUAL = 6,
    PLACA = 7,
    UF = 8,
    CPF = 9,
    KM = 9,
    EQUIPAMENTOS = 10,
    OUTROS = 13,
}

export namespace ETipoDado {
    export function toString(situacao: ETipoDado | number): string {
        switch (situacao) {
            case ETipoDado.TEXTO: return 'TEXTO';
            case ETipoDado.INTEIRO: return 'INTEIRO';
            case ETipoDado.DECIMAL: return 'DECIMAL';
            case ETipoDado.DATAHORA: return 'DATAHORA';
            case ETipoDado.CNPJ: return 'CNPJ';
            case ETipoDado.INSCRICAO_ESTADUAL: return 'INSCRICAO_ESTADUAL';
            case ETipoDado.PLACA: return 'PLACA';
            case ETipoDado.UF: return 'UF';
            case ETipoDado.CPF: return 'CPF';
            case ETipoDado.KM: return 'KM';
            case ETipoDado.EQUIPAMENTOS: return 'EQUIPAMENTOS';
            case ETipoDado.OUTROS: return 'OUTROS';

        }
    }

    export function toListAll(): ListItem[] {
        return Object.keys(ETipoDado)
            .filter((value) => isNaN(Number(value)) === false)
            .map<ListItem>((key) => ({ id: +key, nome: ETipoDado.toString(+key) }))
            // .filter(({ tipoDadoId }) => tipoDadoId != ETipoDado.INTEIRO && tipoDadoId != ETipoDado.DECIMAL && tipoDadoId != ETipoDado.TEXTO );
            
    }
}
