import { ActivatedRoute, Router } from '@angular/router';
import { Component, Pipe } from '@angular/core';
import { ModuloService } from 'src/app/services/app.modulo.service';
import { AlertaService } from 'src/app/services/app.alerta.service';
import { Alerta } from 'src/app/model/app.alerta.model';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { Regra, RegraFiltroEntrada, RegraParametroEntrada } from 'src/app/model';
import { BehaviorSubject, combineLatest, filter, from, of, reduce, switchMap, take } from 'rxjs';
import { ComunService } from 'src/app/services/app.comum.service';
import { Equipamento } from 'src/app/model/app.equipamentos.model';
import { UnidadeFiscal } from 'src/app/model/app.unidadefiscal.model';
import { RegraService } from 'src/app/services/app.regra.service';
import { map } from 'jquery';
import { AlertaImagem } from 'src/app/model/app.alertaimagematendimento.model';
import { BlockUtil } from 'src/app/helper/app.block.utils';

@Component({
    selector: 'app-alerta-visualizar',
    templateUrl: './alerta.visualizar.component.html',
    styleUrls: ['./alerta.visualizar.component.scss'],
    providers: [
        ModuloService,
        BlockUtil
    ]
})
export class AlertaVisualizarComponent {

    public data: Alerta;
    public equipamentos: Equipamento[] = [];
    public unidadesFiscais: UnidadeFiscal[] = [];
    public showImage: string = null;
    public imagens: AlertaImagem[] = [];


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: AlertaService,
        private regraService: RegraService,
        private blockUtil: BlockUtil,
        private comum: ComunService
    ) {
        this.blockUtil.blockName = "alerta";

        this.blockUtil.bloquear();
        const alertaId = +this.route.snapshot.queryParamMap.get("alertaId");
        combineLatest({
            alerta: this.service.getById(alertaId),
            equipamentos: this.comum.ObterEquipamentos()
                .pipe(filter(equipamentos => !!equipamentos)),
            unidadesFiscais: this.comum.ObterTodasUnidadesFiscaisPorUsuario()
                .pipe(filter(unidades => !!unidades))
        })
            .pipe(
                take(1)
            )
            .subscribe({
                next: ({ unidadesFiscais, alerta, equipamentos }) => {
                    this.data = alerta;
                    this.equipamentos = equipamentos;
                    this.unidadesFiscais = unidadesFiscais;

                    this.blockUtil.desbloquear();
                    from(alerta?.alertaImagemAtendimentoIds || [])
                        .pipe(
                            switchMap((alertaImagemAtendimentoId: number) => this.comum.ObterAlertaImagemPorAlertaImagemAtendimento(alertaImagemAtendimentoId))
                        )
                        .subscribe({
                            next: (alertaImagem => this.imagens.push(alertaImagem))
                        })

                },
                error: () => this.data = null
            });
    }

    get unidades(): UnidadeFiscal[] {

        const selecionadas = this.regra?.regrasUnidadeFiscal.filter(({ unidadeFiscalId, usuarioId, situacaoId }) => ({ unidadeFiscalId, usuarioId }) && situacaoId == ESituacao.ATIVO);

        return this.unidadesFiscais
            .filter(({ unidadeFiscalId }) => selecionadas.some(unidadeFiscal => unidadeFiscal.unidadeFiscalId == unidadeFiscalId))
            .map(unidade => {
                unidade.usuarios = unidade.usuarios.filter(({ usuarioId }) => selecionadas.some(unidadeFiscal => unidadeFiscal.usuarioId == usuarioId && unidadeFiscal.unidadeFiscalId == unidade.unidadeFiscalId));
                return unidade;
            });
    }

    get pathImagens() {
        return this.imagens.map(({ base64, tipo }) => {
            if (tipo.toLowerCase().trim() == 'png')
                return `data:image/png;base64,${base64}`;
            if (tipo.toLowerCase().trim() == 'jpg')
                return `data:image/png;base64,${base64}`;
            if (tipo.toLowerCase().trim() == 'jpeg')
                return `data:image/jpeg;base64,${base64}`;
        }) || [];
    }

    get EmAtendimento() { return this.data?.alertaHistoricos.find(atendimentoHistorico => atendimentoHistorico.situacaoId == ESituacao.EM_ATENDIMENTO) }
    get Finalizado() { return this.data?.alertaHistoricos.find(atendimentoHistorico => atendimentoHistorico.situacaoId == ESituacao.FINALIZADO) }
    get Devolvido() { return this.data?.alertaHistoricos.find(atendimentoHistorico => atendimentoHistorico.situacaoId == ESituacao.DEVOLVER_ATENDIMENTO) }
    get Desativado() { return this.data?.alertaHistoricos.find(atendimentoHistorico => atendimentoHistorico.situacaoId == ESituacao.DESATIVADO) }

    get estaFinalizado() { return this.data?.situacaoId == ESituacao.FINALIZADO; }
    get estaEmAtendimento() { return this.data?.situacaoId == ESituacao.EM_ATENDIMENTO; }
    get estaDevolvido() { return this.data?.situacaoId == ESituacao.DEVOLVER_ATENDIMENTO; }
    get estaDesativado() { return this.data?.situacaoId == ESituacao.DESATIVADO; }
    get estaPendente() { return this.data?.situacaoId == ESituacao.PENDENTE; }

    get placa(): string { return this.data.alertasParametroSaida.find(({ nome }) => nome == "Placa")?.valor; }

    get regra(): Regra {
        return this.data?.regra;
    }

    get unidadeGeracaoRegra() {
        return this.unidadesFiscais
            .find(({ usuarios }) => usuarios.some(u => u.usuarioId == this.regra.usuarioIDInclusao));
    }

    get filtrosEntrada(): any {
        return (this.data?.regra.regrasFiltroEntrada || [])
            .map(filtroEntrada => {
                const { valor } = this.data.regra
                    ?.regrasFiltroEntrada
                    ?.find(({ filtroEntradaNome }) => filtroEntradaNome == filtroEntrada.filtroEntradaNome) || {};
                filtroEntrada.valor = valor;
                const { tipoDadoNome } = filtroEntrada;
                if (tipoDadoNome == 'EQUIPAMENTOS') {
                    const { local, sentidoDaLeitura, nome } = this.equipamentos.find(({ identificadorDoEquipamento }) => identificadorDoEquipamento == filtroEntrada.valor) || {};
                    filtroEntrada.localNome = local?.nome;
                    filtroEntrada.sentidoDaLeitura = sentidoDaLeitura;
                }

                return filtroEntrada;
            });
    }

    get parametrosEntrada(): any {
        return (this.data?.regra.regrasParametroEntrada || [])
            .filter(parametroEntrada => {
                const isValid = this.data.regra
                    ?.modeloRegra?.parametrosEntrada
                    ?.some(({ nome }) => nome == parametroEntrada.parametroEntradaNome) || {};
                const { tipoDadoNome } = parametroEntrada;
                if (tipoDadoNome == 'EQUIPAMENTOS') {
                    const { local, sentidoDaLeitura, nome } = this.equipamentos.find(({ identificadorDoEquipamento }) => identificadorDoEquipamento == parametroEntrada.valor) || {};
                    parametroEntrada.localNome = local?.nome;
                    parametroEntrada.sentidoDaLeitura = sentidoDaLeitura;
                }

                return parametroEntrada && isValid;
            });
    }

    get showParametroEntrada(): boolean {
        return this.parametrosEntrada.some(p => !String.isNullOrEmpty(p.valor))
    }

    get showFiltroEntrada(): boolean {
        return this.filtrosEntrada.some(f => !String.isNullOrEmpty(f.valor))
    }

    verImagem(e: Event) {
        const image = (e.target as HTMLElement).closest("img");
        if (!image) return;
        this.showImage = image.src;
    }

    fecharImagem(e: Event) {
        if (e.target !== e.currentTarget) return;
        this.showImage = null;
    }

    voltar() {
        this.router.navigate(['alerta']);
    }

    formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada: RegraParametroEntrada, valorFormatado: string): string[] {
        let retorno = new Array();
        retorno.push(parametroEntrada?.operadorNome);
        retorno.push(valorFormatado);
        if (parametroEntrada.agrupadorNome)
            retorno.push(parametroEntrada.agrupadorNome);

        return retorno;
    }

    formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada: RegraFiltroEntrada, valorFormatado: string): string[] {
        let retorno = new Array();
        retorno.push(filtroEntrada?.operadorNome);
        retorno.push(valorFormatado);
        if (filtroEntrada.agrupadorNome)
            retorno.push(filtroEntrada.agrupadorNome);

        return retorno;
    }
}
