import { Component, Input, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContentService } from "./master.content.service";
import { ContentDescriptor } from './master.module';

@Component({
    selector: 'my-placeholder',
    template: '<ng-content></ng-content>'
})
export class PlaceholderComponent {
    @Input() name: string;

    private subscription: Subscription;

    constructor(
        private containerRef: ViewContainerRef,
        private contentService: ContentService
    ) {
        this.subscription = contentService.contentInit().subscribe((content: ContentDescriptor) => {
            if (content.placeholder == this.name) {
                this.containerRef.clear();
                this.containerRef.element.nativeElement.appendChild(content.elementRef.nativeElement);
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
