import { Routes } from '@angular/router';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { MasterComponent } from '../../components/master/master.component';
import { UsuarioEditarComponent } from './editar/usuario.editar.component';
import { UsuarioComponent } from './usuario.component';
import { UsuarioVisualizarComponent } from './visualizar/usuario.visualizar.component';

export const UsuarioComponentRoutes: Routes = [
    {
        path: 'usuario',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: UsuarioComponent
            }
        ]
    },
    {
        path: 'usuario/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: UsuarioVisualizarComponent
            }
        ]
    },
    {
        path: 'usuario/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: UsuarioEditarComponent
            }
        ]
    }
];
