import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MasterComponent } from './master/master.component';
import { MasterModule } from './master/master.module';
import { GrupoComponent } from './sidebar/sidebar.grupo.component';
import { DateTimePickerComponent } from './datimepicker/app.component.datimepicker';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { CustomSearchComponent, InputsComponent } from './customSearch/customsearch.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MasterModule,
        NgbModule,
        FormsModule,
        FontAwesomeModule,
        NgxMaskModule.forRoot(),
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        MasterComponent,
        GrupoComponent,
        DateTimePickerComponent,
        CustomSearchComponent,
        InputsComponent
        
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        DateTimePickerComponent,
        CustomSearchComponent,
        InputsComponent

    ],
    providers: [
        MasterComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
