import { ETipoPlaca } from "../constantes/app.etipoplaca.const";

export class Placa {
    tipoPlacaId: ETipoPlaca;
    tipoPlacaNome: string;
    numero: number;
    unidadeFiscalId: number;
    unidadeFiscalNome: string;
    dataHora: string;
    cpfMotorista: string;
    chaveDeAcesso: string;
    ufCarregamento: string;
    ufDescarregamento: string;
    ufsPercurso: string[];
    tipoMovimento: string;
    nomeEquipamento: string;
    ufEquipamento: string;
    cnpjOperador: string;
    foto: string;

    constructor(dto: any) {
        this.tipoPlacaId = dto?.tipoPlacaID;
        this.tipoPlacaNome = dto?.tipoPlacaNome;
        this.numero = dto?.numero;
        this.unidadeFiscalId = dto?.unidadeFiscalID;
        this.unidadeFiscalNome = dto?.unidadeFiscalNome;
        this.dataHora = dto?.dataHora;
        this.cpfMotorista = dto?.cpfMotorista;
        this.chaveDeAcesso = dto?.chaveDeAcesso;
        this.ufCarregamento = dto?.ufCarregamento;
        this.ufDescarregamento = dto?.ufDescarregamento;
        this.ufsPercurso = dto?.ufsPercurso;
        this.tipoMovimento = dto?.tipoMovimento;
        this.nomeEquipamento = dto?.nomeEquipamento;
        this.ufEquipamento = dto?.ufEquipamento;
        this.cnpjOperador = dto?.cnpjOperador;
        this.foto = dto?.foto;
    };
};
