<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body " *blockUI="'atualizarPerfil'">
                        <form class="form-horizontal " [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-body ">
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Nome</label>
                                    <div class="col-md-12 main-card">
                                        <input type="text " class="form-control input-lg " name="caminho"
                                            placeholder="Digite aqui o Nome do Perfil... " formControlName="nome">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row d-flex">
                                        <div class="col-md-5">
                                            <div class="form-group ">
                                                <label class="col-md-5 control-label ">Menu</label>
                                                <div class="col-md-12 main-card">
                                                    <ng-multiselect-dropdown [placeholder]="'Selecione o menu'"
                                                        [settings]="moduloConfiguracao" [data]="modulos"
                                                        formControlName="modulosSelecionados">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="form-group ">
                                                <label class="col-md-5 control-label ">Permissão</label>
                                                <div class="col-md-12 main-card">
                                                    <!-- <ng-multiselect-dropdown [placeholder]="'Selecione a permissão'" *ngIf="possuiModulosSelecionados; else notModule"
                                                      (onSelect)="onItemSelectAcao($event)" (onSelectAll)="onSelectAllAcao($event)"
                                                      (onDeSelect)="onDeSelectAcao($event)" (onDeSelectAll)="onDeSelectAcaoAll($event)"
                                                      [settings]="acaoConfiguracao" [data]="acoes" formControlName="acoesSelecionadas" >
                                                  </ng-multiselect-dropdown> -->
                                                    <ng-multiselect-dropdown [placeholder]="'Selecione a permissão'"
                                                        *ngIf="possuiModulosSelecionados; else notModule"
                                                        [settings]="acaoConfiguracao" [data]="acoes"
                                                        formControlName="acoesSelecionadas">
                                                    </ng-multiselect-dropdown>
                                                    <ng-template #notModule>
                                                        <select class="form-control " disabled="true">
                                                            <option value>Selecione a permissão</option>
                                                        </select>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group pr-3">
                                                <label class="col-md-2 control-label "></label>
                                                <button type="button" class="btn btn-primary btn-block my-0"
                                                    [disabled]="!possuiModulosEAcoesSelecionadas"
                                                    (click)="adicionarModulos() ">Adicionar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope class="col-md-3">Menu</th>
                                            <th scope class="col-md-5">Permissões</th>
                                            <th scope class="col-md-4">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let moduloPerfil of modulosPerfilAdicionados">
                                            <td class="col-md-3">
                                                <span>{{ moduloPerfil.moduloTitulo }}</span>
                                            </td>
                                            <td class="col-md-5">
                                                <span>{{ retornaNomeAcao(moduloPerfil.acoes) }}</span>
                                            </td>
                                            <td class="col-md-4 ">
                                                <div class="d-flex align-items-center">
                                                    <button type="button" class="btn btn-sm default btn-outline "
                                                        (click)="onEdit(moduloPerfil)" style="gap:0.5rem">
                                                        Editar<i class="fa-sharp fa-solid fa-pen"></i>
                                                    </button>
                                                    <button type="button" class=" btn btn-sm default btn-outline mx-3"
                                                        (click)="onDelete(moduloPerfil)" style="gap:0.5rem">
                                                        Excluir<i class="fa-solid fa-trash-can"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!possuiModulosAdicionados">
                                        <tr>
                                            <td colspan="4" class="text-center text-danger ">
                                                <ngb-alert type="primary" [dismissible]="false" class="text-center">
                                                    Você ainda não possui nenhum menu adicionado.
                                                </ngb-alert>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn default" (click)="cancel()">Cancelar</button>
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!possuiModulosAdicionados">Inserir</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
