export class AlertaEventoAtualizacaoDfe {
    alertaEventoAtualizacaoDfeId: number;
    alertaId: number;
    situacaoId: number;
    hashNIFI: string;
    numeroEventoDfe: string;
    descricaoEventoDfe: string;
    dataHoraInclusao: string;
    dataHoraEvento: string;
    chaveAcessoDfe: string;
    fusoHorario: string;
    dataHoraPassagem: string;
    dataHoraPassagemFusoHorario: string;
    sentido: string;
    local: string;

    constructor(dto: any) {
        this.alertaEventoAtualizacaoDfeId = dto?.alertaEventoAtualizacaoDfeID;
        this.alertaId = dto?.alertaID;
        this.situacaoId = dto?.situacaoID;
        this.hashNIFI = dto?.hashNIFI;
        this.numeroEventoDfe = dto?.numeroEventoDfe;
        this.descricaoEventoDfe = dto?.descricaoEventoDfe;
        this.dataHoraInclusao = dto?.dataHoraInclusao;
        this.dataHoraEvento = dto?.dataHoraEvento;
        this.chaveAcessoDfe = dto?.chaveAcessoDfe;
        this.fusoHorario = dto?.fusoHorario;
        this.dataHoraPassagem = dto?.dataHoraPassagem;
        this.dataHoraPassagemFusoHorario = dto?.dataHoraPassagemFusoHorario;
        this.sentido = dto?.sentido;
        this.local = dto?.local;
    }
}
