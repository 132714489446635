<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card" *blockUI="'usuario'">
                    <div class="card-body">
                        <form class="form-horizontal" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-body">
                                <div class="form-group">
                                    <label class="col-md-5 control-label">Nome</label>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control input-lg" placeholder="Nome Completo"
                                            formControlName="nome" required disabled>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-5 control-label">Login</label>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control input-lg" maxlength="100"
                                            placeholder="login" formControlName="login" required disabled>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="temUnidadeFiscal">
                                    <label class="col-md-5 control-label">Unidade Fiscal</label>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control input-lg" maxlength="100"
                                            placeholder="Unidade Fiscal" formControlName="unidadeFiscalNome" required
                                            disabled>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-5 control-label">Perfil</label>
                                    <div class="col-md-12 main-card">
                                        <select class="form-control form-control-sm" formControlName="perfil">
                                            <option value>Selecione...</option>
                                            <option *ngFor="let perfil of perfils" [ngValue]="perfil">
                                                {{perfil.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group d-flex justify-content-center" *ngIf="ehChefe">
                                    <div class="col-md-12 main-card d-flex justify-content-left flex-column">
                                        <label class="control-label text-left">Unidades Fiscais qual(ais) é
                                            chefe</label>
                                        <ng-multiselect-dropdown placeholder="Selecione Chefe Unidade Fiscal"
                                            [settings]="configuracoesPadrao" [data]="unidadesFiscais"
                                            formControlName="unidades" >
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="unidadesFiscaisSelecionadas.length">
                                    <div class="col">
                                        <div class="d-flex flex-column">
                                            <ng-contaioner *ngFor="let unidade of unidadesFiscaisSelecionadas">
                                                <div class="input-group">
                                                    <small class="input-group-text fw-bold flex-grow-1 py-1 font-12">{{
                                                        unidade.nome
                                                        }}</small>
                                                    <button class="btn btn-danger btn-sm my-0" type="button"
                                                        ngbTooltip="Remover" (click)="removerUnidadeFiscal(unidade)">
                                                        <i class="fa-solid fa-times"></i>
                                                    </button>
                                                </div>
                                            </ng-contaioner>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-sm default" (click)="cancel()">Cancelar</button>
                                <button *ngIf="!modalConfirm || !ehChefe" type="submit" class="btn btn-sm btn-primary">Atualizar</button>
                                <button *ngIf="modalConfirm && ehChefe" type="button" class="btn btn-sm btn-primary" (click)="openModalConfirm()">Atualizar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
