import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { ModeloRegraService } from 'src/app/services/app.modeloregra.service';
import { FiltroEntrada, ModeloRegra, ParametroEntrada, ParametroSaida } from 'src/app/model';

@Component({
    selector: 'app-modeloregra-visualizar',
    templateUrl: './modeloregra.visualizar.component.html',
    styleUrls: ['./modeloregra.visualizar.component.scss'],
    providers: [
        ModeloRegraService
    ]
})
export class ModeloRegraVisualizarComponent {

    public data: ModeloRegra;
    public parametrosEntrada: ParametroEntrada[];
    public filtrosEntrada: FiltroEntrada[];
    public parametrosSaida: ParametroSaida[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: ModeloRegraService
    ) {
        const modeloRegraId = +this.route.snapshot.queryParamMap.get("modeloRegraId");
        this.service.getById(modeloRegraId)
            .subscribe({
                next: (data: any) => {
                    this.data = data;
                    const { parametrosEntrada, filtrosEntrada, parametrosSaida } = data;
                    this.parametrosEntrada = parametrosEntrada;
                    this.filtrosEntrada = filtrosEntrada;
                    this.parametrosSaida = parametrosSaida;
                },
                error: () => this.data = null
            });
    }

    voltar() {
        this.router.navigate(['modeloregra']);
    }
}
