import { Component, ElementRef, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { RegraService } from 'src/app/services/app.regra.service';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">Desativar Regra #{{ regraId }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="regraId" class="col-form-label">Regra:</label>
          <input type="text" class="form-control" id="regraId" value="{{ regraDescricao }}" disabled readonly />
        </div>
        <div class="form-group">
          <label for="message-text" class="col-form-label">Motivo do Cancelamento:</label>
          <textarea
            class="form-control"
            id="justificativa"
            name="justificativa"
            minlength="10"
            maxlength="150"
            rows="4"
            placeholder="Mín. 10 caracteres, Máx. 150 caracteres"
            #bindingInput
          ></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onConfirm()">DESATIVAR</button>
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cancel Click')">Voltar</button>
    </div>
  `,
})
export class DesativarRegraComponent implements OnInit {
  @Input() regraId: string;
  @Input() regraDescricao: string;
  @ViewChild('bindingInput') bindingInput!: ElementRef;

  constructor (protected activeModal: NgbActiveModal, private toast: ToastUtil, private regraService: RegraService) {}

  ngOnInit (): void {}

  onConfirm () {
    const justificativa = this.bindingInput.nativeElement.value?.toString() || '';

    if (justificativa.length > 10) {
      this.regraService.cancelarRegra(Number(this.regraId), justificativa).subscribe({
        next: result => {
          this.toast.showSuccess(result, 'Desativar Regra');
          this.activeModal.close(result);
        },
        error: err => {
          this.toast.showDanger(err);
        },
      });
    } else {
      this.toast.showDanger('A justificativa para a desativação deve conter entre 10 e 150 caracteres!');
    }

    return false;
  }
}
