import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CondicionantesComponent } from './condicionante.component'
import { CondicionanteCadastroComponent } from './cadastro/condicionante.cadastro.component'
import { CondicionanteVisualizarComponent } from './visualizar/condicionante.visualizar.component';
import { CondicionanteEditarComponent } from "./editar/condicionante.editar.component";
import { DirectivesModule } from "src/app/directives/app.directives.module";
import { ComponentsModule } from "src/app/components/components.module";


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule
    ],
    declarations: [
        CondicionantesComponent,
        CondicionanteCadastroComponent,
        CondicionanteEditarComponent,
        CondicionanteVisualizarComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CondicionanteModule { }
