import { ListItem } from '../interfaces/app.utilservices.interface';

export enum ESituacao {
    ATIVO = 1,
    DESATIVADO = 2,
    EXCLUIDO = 3,
    PENDENTE = 4,
    ATRIBUIDO = 5,
    CANCELADO = 6,
    EM_ATENDIMENTO = 7,
    FINALIZADO = 8,
    DEVOLVER_ATENDIMENTO = 9,
    VINCULADO = 10
}

export namespace ESituacao {
    export function toString(situacao: ESituacao | number): string {
        switch (situacao) {
            case ESituacao.ATIVO: return 'ATIVO';
            case ESituacao.DESATIVADO: return 'DESATIVADO';
            case ESituacao.EXCLUIDO: return 'EXCLUÍDO';
            case ESituacao.PENDENTE: return 'PENDENTE';
            case ESituacao.ATRIBUIDO: return 'ATRIBUÍDO';
            case ESituacao.CANCELADO: return 'CANCELADO';
            case ESituacao.EM_ATENDIMENTO: return 'EM ATENDIMENTO';
            case ESituacao.FINALIZADO: return 'FINALIZADO';
            case ESituacao.DEVOLVER_ATENDIMENTO: return 'DEVOLVER ATENDIMENTO';
            case ESituacao.VINCULADO: return 'VINCULADO';
        }
    }

    export function toList(): ListItem[] {
        return Object.keys(ESituacao)
            .filter((value) => isNaN(Number(value)) === false)
            .map<ListItem>((key) => ({ situacaoId: +key, nome: ESituacao.toString(+key) }))
            .filter(({ situacaoId }) => situacaoId != ESituacao.EXCLUIDO);
    }

    export function toListAll(): ListItem[] {
        return Object.keys(ESituacao)
            .filter((value) => isNaN(Number(value)) === false)
            .map<ListItem>((key) => ({ id: +key, nome: ESituacao.toString(+key) }))
    }
}
