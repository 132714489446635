import { HttpClient } from '@angular/common/http';
import { UtilService } from './app.utils.service';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

import { map, switchMap, filter, catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortColumn, SortDirection } from '../directives/app.sortable.directive';
import { SearchResult, State } from '../interfaces/app.utilservices.interface';
import { EOrderBy } from '../constantes/app.eorderby.const';
import { Alerta } from '../model/app.alerta.model';
import { ComunService } from './app.comum.service';
import { ESituacao } from '../constantes/app.esituacao.const';
import { AccountService } from './app.login.service';
import { BlockUtil } from '../helper/app.block.utils';

@Injectable({
    providedIn: 'root',
})
export class AlertaService {
    private _search$ = new Subject<void>();
    private _alertas$ = new BehaviorSubject<Alerta[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    private _situacao$ = new BehaviorSubject<boolean>(null);
    public blockUtil: BlockUtil = null;

    private readonly URL_CONTROLLER = 'Alerta';

    private _state: State<Alerta> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: EOrderBy.ASC,
        alerta: '',
        modeloRegraId: '',
        dataGeracao: '',
        dataHoraInclusao: '',
        situacaoId: '',
        unidadeFiscalNome: '',
        usuarioAtendimentoNome: '',
        alertaTipoLiberacaoId: '',
        filtroGenerico: '',
        alertaID: '',
        regraID: '',
        periodo: '',
        situacao: '',
        motivo: '',
        serie: '',
        condicionante: '',
        usuarioAtendimento: '',
        tipoAlerta: ''

    };

    constructor(private util: UtilService, private comunService: ComunService, private accountService: AccountService) {
        this._search$
            .pipe(
                tap(() => this.blockUtil.bloquear()),
                switchMap(() => this._pagination())
            )
            .subscribe(({ alertas, total }) => {
                this._alertas$.next(alertas);
                this._total$.next(total);
                this.blockUtil.desbloquear();
            });
    }

    get alertas$() {
        return this._alertas$.asObservable();
    }
    get alertas() {
        return this._alertas$.value;
    }
    get total$() {
        return this._total$.asObservable();
    }
    get page() {
        return this._state.page;
    }
    get pages() {
        return Math.ceil(this._total$.value / this._state.pageSize);
    }
    get pageSize() {
        return this._state.pageSize;
    }
    get searchTerm() {
        return this._state.searchTerm;
    }
    get situacao$() {
        return this._situacao$.asObservable();
    }

    set page(page: number) {
        this._set({ page });
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize, page: 1 });
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }
    set sortColumn(sortColumn: SortColumn<Alerta>) {
        this._set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    public get(data) {
        this._set(data);
    }

    public getById(id: number): Observable<Alerta> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPorID/${id}`;
        return this.util.Get<Alerta>(url)
            .pipe(
                filter(item => !!item),
                map(alerta => new Alerta(alerta))
            );
    }

    public getOrderByDirection(sortColumn: SortColumn<Alerta>, sortDirection: SortDirection) {
        this._set({ sortColumn, sortDirection });
    }

    private _set(patch: any) {

        Object.assign(this._state, patch);

        this._search$.next();
    }

    public onFinalize({
        alertaId = null,
        numeroDocumento = null,
        tipoLiberacaoId = null,
        motivo = null,
        serie = null,
        unidade = null,
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/Alterar`;
        return this.util.Put<Alerta>(url, {
            AlertaID: alertaId,
            NumeroDocumento: numeroDocumento,
            AlertaTipoLiberacaoID: tipoLiberacaoId,
            Motivo: motivo,
            Serie: serie,
            Unidade: unidade,
            SituacaoID: ESituacao.FINALIZADO,
            UsuarioID: this.accountService.userValue?.usuarioId,
        });
    }

    public onFinalizarAlerta({
        alertaId = null,
        numeroDocumento = null,
        tipoLiberacaoId = null,
        motivo = null,
        serie = null,
        unidade = null,
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/FinalizarAlerta`;
        return this.util.Put<Alerta>(url, {
            AlertaID: alertaId,
            NumeroDocumento: numeroDocumento,
            AlertaTipoLiberacaoID: tipoLiberacaoId,
            Motivo: motivo,
            Serie: serie,
            Unidade: unidade,
            SituacaoID: ESituacao.FINALIZADO,
            UsuarioID: this.accountService.userValue?.usuarioId,
        });
    }

    public onFinalizarAlertaERegra({
        alertaId = null,
        numeroDocumento = null,
        tipoLiberacaoId = null,
        motivo = null,
        serie = null,
        unidade = null,
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/FinalizarAlertaERegra`;
        return this.util.Put<Alerta>(url, {
            AlertaID: alertaId,
            NumeroDocumento: numeroDocumento,
            AlertaTipoLiberacaoID: tipoLiberacaoId,
            Motivo: motivo,
            Serie: serie,
            Unidade: unidade,
            SituacaoID: ESituacao.FINALIZADO,
            UsuarioID: this.accountService.userValue?.usuarioId,
        });
    }

    public onConfirm({
        alertaId = null,
        numeroDocumento = null,
        tipoLiberacaoId = null,
        motivo = null,
        serie = null,
        unidade = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/Alterar`;
        return this.util.Put<Alerta>(url, {
            AlertaID: alertaId,
            NumeroDocumento: numeroDocumento,
            AlertaTipoLiberacaoID: tipoLiberacaoId,
            Motivo: motivo,
            Serie: serie,
            Unidade: unidade,
            SituacaoID: ESituacao.EM_ATENDIMENTO,
            UsuarioID: this.accountService.userValue?.usuarioId,
        });
    }

    public atender({
        alertaId = null,
        numeroDocumento = null,
        tipoLiberacaoId = null,
        motivo = null,
        serie = null,
        unidade = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/Atender`;
        return this.util.Put<Alerta>(url, {
            AlertaID: alertaId,
            NumeroDocumento: numeroDocumento,
            AlertaTipoLiberacaoID: tipoLiberacaoId,
            Motivo: motivo,
            Serie: serie,
            Unidade: unidade,
            SituacaoID: ESituacao.EM_ATENDIMENTO,
            UsuarioID: this.accountService.userValue?.usuarioId,
        });
    }

    public onToMeet({
        alertaId = null,
        numeroDocumento = null,
        tipoLiberacaoId = null,
        motivo = null,
        serie = null,
        unidade = null,
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/Alterar`;
        return this.util.Put<Alerta>(url, {
            AlertaID: alertaId,
            NumeroDocumento: numeroDocumento,
            AlertaTipoLiberacaoID: tipoLiberacaoId,
            Motivo: motivo,
            Serie: serie,
            Unidade: unidade,
            SituacaoID: ESituacao.PENDENTE,
            UsuarioID: this.accountService.userValue?.usuarioId,
        });
    }

    public putSituacao({
        alertaId = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizarSituacao`;
        return this.util.Put<Alerta>(url, {
            ID: alertaId,
            UsuarioID: this.accountService.userValue?.usuarioId,
            SituacaoID: situacaoId
        })
            .pipe(
                tap(() => this._search$.next())
            )
    }

    public devolverParaFilaDePendentes({
        alertaId = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/DevolverParaFilaDePendentes`;
        return this.util.Put<Alerta>(url, {
            ID: alertaId,
            UsuarioID: this.accountService.userValue?.usuarioId,
            SituacaoID: situacaoId
        })
            .pipe(
                tap(() => this._search$.next())
            )
    }

    private _pagination(): Observable<SearchResult<Alerta>> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPaginado`;
        return this.util.Post(url, {
            Page: this._state.page,
            Size: this._state.pageSize,
            OrderBy: this._state.sortDirection || EOrderBy.ASC,
            FieldAtOrder: this._state.sortColumn?.toCaptalize(),
            DataGeracao: this._state.dataGeracao,
            FiltroGenerico: this._state.filtroGenerico,
            UsuarioAtendimentoNome: this._state.usuarioAtendimentoNome,
            UsuarioID: this.accountService.userValue?.usuarioId,
            AlertaID: this._state.alertaID,
            RegraID: this._state.regraID,
            DataInicial: this._state.periodo.dataInicial,
            DataFinal: this._state.periodo.dataFinal,
            Situacao: this._state.situacao,
            Motivo: this._state.motivo,
            Serie: this._state.serie,
            Condicionante: this._state.condicionante,
            UsuarioAtendimento: this._state.usuarioAtendimento,
            TipoAlerta: this._state.tipoAlerta,
            UsuarioIDLogado: this.accountService.userValue?.usuarioId
        })
            .pipe(
                map((data) => ({ alertas: data.results.map(dto => new Alerta(dto)), total: data.total })),
                catchError(errorRequest => {
                    const { error, message } = errorRequest;
                    console.log("ERROR", error?.message || message);
                    return of({ alertas: [], total: 0 });
                })
            );
    }
}
