<div class="wrapper">
    <div class="sidebar" data-color="white">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <header>
            <div class="panel-header panel-header-sm">
            </div>
            <my-placeholder name="navigation"></my-placeholder>
        </header>
        <section>
            <my-placeholder name="main"></my-placeholder>
        </section>
        <router-outlet name="app-master"></router-outlet>
        <app-footer></app-footer>
    </div>
</div>