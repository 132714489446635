import { EAcao } from './../../constantes/app.eacao.const';
import { map, tap } from 'rxjs/operators';
import { ESituacao } from '../../constantes/app.esituacao.const';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/app.sortable.directive';
import { Condicionante } from 'src/app/model/app.condicionante.model';
import { CondicionanteService } from 'src/app/services/app.condicionante.service';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { EOrderBy } from 'src/app/constantes/app.eorderby.const';

@Component({
    selector: 'app-condicionantes',
    templateUrl: './condicionante.component.html',
    styleUrls: ['./condicionante.component.scss'],
    providers: [
        CondicionanteService,
        DialogUtil,
        BlockUtil
    ]
})
export class CondicionantesComponent extends ParmissoesPagina implements OnInit {

    public isMobile: boolean = false;
    public condicionantes$: Observable<Condicionante[]>;
    public condicionanteEditar = new BehaviorSubject<Condicionante>(null);
    public total$: Observable<number>;
    public form: FormGroup;
    public ATRIBUIDO: ESituacao = ESituacao.ATRIBUIDO;
    public pesquisaAvancadaAberta: boolean = false;

    public colsGrid: any = [
        {
            colKey: "descricao",
            colName: "Descrição"
        },
        {
            colKey: "situacaoID",
            colName: "Situação",
            type: 'string',
            options: ESituacao.toListAll()
        },


    ]

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<Condicionante>>;

    constructor(
        private router: Router,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private formBuilder: FormBuilder,
        public service: CondicionanteService,
        private toast: ToastUtil,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService
    ) {
        super(router.url, accountService);
        this.condicionantes$ = service.condicionantes$;
        this.total$ = service.total$;

        this.form = this.formBuilder.group({
            filtroGenerico: new FormControl('')
        });

        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        });
    }

    ngOnInit(): void {
        this.service.get({});
    }

    onSort({ column, direction }: SortEvent<Condicionante>) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.getOrderByDirection(column, direction);
    }

    search() {
        const filtroGenerico = this.form.controls.filtroGenerico.value?.trim();
        this.service.get({
            page: 1,
            pageSize: 10,
            searchTerm: '',
            sortColumn: '',
            sortDirection: EOrderBy.ASC,
            filtroGenerico: filtroGenerico?filtroGenerico:'',
            descricao: '',
            situacaoID: '',
        });
    }

    // clean() {
    //     this.form.setValue({
    //         descricao: '',
    //         situacao: ''
    //     });
    //     this.service.get({});
    // }

    onView({ condicionanteId: condicionanteId }: any) {
        this.router.navigate(['condicionante/visualizar'], { queryParams: { condicionanteId } });
    }

    onEdit({ condicionanteId: condicionanteId }: any) {
        this.router.navigate(['condicionante/editar'], { queryParams: { condicionanteId } });
    }

    onDelete(data: Condicionante) {
        this.dialogModal.open({
            title: data.descricao,
            text: `Você está prestes a remover a condicionante!<br/><strong>Deseja continuar?</strong>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        data.situacaoId = ESituacao.EXCLUIDO;
                        this.service.putSituacao(data)
                            .subscribe({
                                next: () => {
                                    this.toast.showSuccess("Condicionante Excluida com sucesso!");
                                    this.blockUtil.desbloquear();
                                },
                                error: (erro) => {
                                    this.toast.showDanger(erro);
                                    this.blockUtil.desbloquear();
                                }
                            });
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    toInsert() {
        this.router.navigate(['condicionante/cadastro']);
    }

    pesquisaAvancada() {
        this.form.reset();

        this.pesquisaAvancadaAberta = true;
    }

    close() {
        this.pesquisaAvancadaAberta = false;
        this.search();


    }
    cleanSearch() {
        this.form.controls.filtroGenerico.setValue('');

        this.search();
    }

}
