import { Passo2DestinatarioComponent } from './passo2destinatario/passo2destinatario.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from "src/app/directives/app.directives.module";
import { RegraEditarComponent } from "./regra.editar.component";
import { Passo1ModeloRegraComponent } from "./passo1modeloregra/passo1modeloregra.component";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Passo3ComportamentoComponent } from './passo3comportamento/passo3comportamento.component';
import { Passo4PeriodoComponent } from './passo4periodo/passo4periodo.component';
import { Passo5JustificativaComponent } from './passo5justificativa/passo5justificativa.component';
import { ComumModule } from '../comum/comum.module';
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
        NgMultiSelectDropDownModule.forRoot(),
        ComumModule,
        BlockUIModule.forRoot(),
    ],
    declarations: [
        RegraEditarComponent,
        Passo1ModeloRegraComponent,
        Passo2DestinatarioComponent,
        Passo3ComportamentoComponent,
        Passo4PeriodoComponent,
        Passo5JustificativaComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RegraEditarModule { }
