import { UsuarioService } from 'src/app/services/app.usuario.service';
import { UsuarioValidator } from 'src/app/validators/usuario.validator';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { ComunService } from 'src/app/services/app.comum.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UnidadeFiscal } from 'src/app/model/app.unidadefiscal.model';
import { Perfil } from 'src/app/model';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';

@Component({
    selector: 'app-entrada-editar',
    templateUrl: './usuario.editar.component.html',
    styleUrls: ['./usuario.editar.component.scss']
})
export class UsuarioEditarComponent implements OnInit {

    public form: FormGroup;
    public perfils: Perfil[];
    public unidadesFiscais: UnidadeFiscal[] = [];

    public configuracoesPadrao: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: true,
        itemsShowLimit: 0,
        textField: 'descricao',
        idField: 'unidadeFiscalId'
    };
    modalConfirm: boolean = false;
    modalOpen: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private dialogModal: DialogUtil,
        private validator: UsuarioValidator,
        private blockUtil: BlockUtil,
        public service: UsuarioService,
        private toast: ToastUtil,
        private comum: ComunService
    ) {
        this.blockUtil.blockName = "usuario";
        this.blockUtil.bloquear();
        this.form = this.formBuilder.group({
            usuarioId: new FormControl(null),
            nome: new FormControl(null, [Validators.required]),
            login: new FormControl(null, [Validators.required]),
            unidadeFiscalNome: new FormControl(null, [Validators.required]),
            perfil: new FormControl('', [Validators.required]),
            unidades: new FormControl([], [Validators.required])
        });

        const usuarioId = +this.route.snapshot.queryParamMap.get("usuarioId");
        combineLatest({
            usuario: this.service.getById(usuarioId),
            unidadesFiscais: this.comum.ObterUnidadesESubunidadesFiscaisAtivas(false)
                .pipe(filter(unidadesFiscais => !!unidadesFiscais)),
            perfils: this.comum.ObterPerfilsAtivos()
                .pipe(filter(perfils => !!perfils))
        })
            .pipe(
                take(1),
            )
            .subscribe(({ usuario, unidadesFiscais, perfils }) => {
                this.unidadesFiscais = unidadesFiscais;
                this.perfils = perfils;
                if (!usuario)
                    return;
                const { usuarioId, nome, login, unidadeFiscalNome, chefeUnidadesFiscais } = usuario;
                const perfil = perfils.find(({ perfilId }) => perfilId == usuario.perfil?.perfilId);
                const unidadesSelecionadas = chefeUnidadesFiscais.map((unidadeFiscal) => unidadeFiscal.SituacaoId == ESituacao.ATIVO?unidadeFiscal.unidadeFiscalId:null);
                const unidades = unidadesFiscais.filter(({ unidadeFiscalId }) => unidadesSelecionadas.includes(unidadeFiscalId));

                this.form.patchValue({
                    usuarioId,
                    nome,
                    login,
                    perfil,
                    unidadeFiscalNome,
                    unidades
                });
                this.blockUtil.desbloquear();
            });
    }

    get ehChefe(): boolean { return this.form.controls.perfil?.value.nome?.toUpperCase().includes("CHEFE"); }
    get temUnidadeFiscal(): boolean { return !!this.form.controls.unidadeFiscalNome?.value; }

    get unidadesFiscaisSelecionadas(): UnidadeFiscal[] {
        const selecionadas = (this.form?.controls.unidades.value || []).map(({ unidadeFiscalId }) => unidadeFiscalId);
        return this.unidadesFiscais.filter(({ unidadeFiscalId }) => selecionadas.includes(unidadeFiscalId));
    }

    ngOnInit(): void {
        this.form.controls.unidades.valueChanges.subscribe(value => value.length > 0? this.modalConfirm = false : this.modalConfirm = true)
        this.form.controls.perfil.valueChanges.subscribe(value => !value.nome?.includes("CHEFE")? this.inabilitarChefiaUnidades():null);
    }

    onSubmit() {
        this.blockUtil.bloquear();

        this.validator.set = this.form;

        if (!this.validator.isValid) {
            this.blockUtil.desbloquear();
            this.validator.showMessages();
        } else {
            this.service.put(this.validator.usuario)
                .subscribe({
                    next: (data: string) => {
                        this.toast.showSuccess(data);
                        this.blockUtil.desbloquear();
                        this.router.navigate(['usuario']);
                    },
                    error: (erro: string) => {
                        this.toast.showDanger(erro);
                        this.blockUtil.desbloquear();
                    }
                })
        }
    }

    inabilitarChefiaUnidades(){
        if(this.form.controls.unidades.value.length > 0){
            this.form.controls.unidades.setValue([]);
        }
    }
    openModalConfirm(){
        this.dialogModal.open({

            title: "",
            text:  `<p align="justify"><strong>Caso não selecione nenhuma unidade fiscal para destinar o usuário como chefe, será assumido pelo sistema que a unidade que ele chefia será a unidade fiscal atual que ele pertence. Confirma?</strong></p>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();

                        this.validator.set = this.form;
                
                        if (!this.validator.isValid) {
                            this.blockUtil.desbloquear();
                            this.validator.showMessages();
                        } else {
                            this.service.put(this.validator.usuario)
                                .subscribe({
                                    next: (data: string) => {
                                        this.toast.showSuccess(data);
                                        this.blockUtil.desbloquear();
                                        this.router.navigate(['usuario']);
                                    },
                                    error: (erro: string) => {
                                        this.toast.showDanger(erro);
                                        this.blockUtil.desbloquear();
                                    }
                                })
                        }
                        
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }


    

    removerUnidadeFiscal({ unidadeFiscalId }: UnidadeFiscal) {
        const unidades = this.form.controls.unidades.value?.filter(unidade => unidade.unidadeFiscalId != unidadeFiscalId);
        this.form.patchValue({ unidades });
    }

    cancel() {
        this.router.navigate(['usuario']);
    }

}
