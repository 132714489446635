import { ESituacao } from "../constantes/app.esituacao.const";
import { Usuario } from "./app.usuario.model";

export class SubUnidade {
    nomeLocal: string;
    codigoLocal: number;
    codigoUnidade: number;
    ehSubUnidade: boolean = true;

    constructor(subUnidadeDto: any) {
        this.nomeLocal = subUnidadeDto?.nomeLocal;
        this.codigoLocal = subUnidadeDto?.codigoLocal;
        this.codigoUnidade = subUnidadeDto?.codigoUnidade;
    }
}

export class Unidade {
    nomeLocal: string;
    codigoLocal: number;
    subUnidades: SubUnidade[];
    ehUnidade: boolean = true;
    campoFiscais: string;

    constructor(unidadeDto: any) {
        this.campoFiscais = `subUnidades${unidadeDto?.codigoLocal}`;
        this.nomeLocal = unidadeDto?.nomeLocal;
        this.codigoLocal = unidadeDto?.codigoLocal;
        this.subUnidades = unidadeDto?.subUnidades?.map(subUnidade => new SubUnidade(subUnidade));
    }
}

export class UnidadeFiscal {
    unidadeFiscalId: number;
    nome: string;
    unidadeFiscalIdEfazenda: number;
    unidadeFiscalIdPaiEfazenda?: number;
    dataHoraInclusao?: string;
    UsuarioNomeInclusao: string;
    UsuarioIdInclusao: number;
    dataHoraAlteracao?: string;
    UsuarioNomeAlteracao?: string;
    UsuarioIdAlteracao?: number;
    coordenadas: string;
    situacaoId: ESituacao;
    situacaoNome: string;
    sigla: string;
    usuarios: Usuario[];
    isDisabled: boolean;    

    ehUnidade: boolean = false;
    campoFiscais: string;
    subUnidades: UnidadeFiscal[];
    descricao?: string;

    constructor(unidadeFiscalDto: any, desabilitarUnidadesQueNaoPossuiUsuario: boolean = true) {
        this.unidadeFiscalId = unidadeFiscalDto?.unidadeFiscalID;
        this.nome = unidadeFiscalDto?.nome;
        this.unidadeFiscalIdEfazenda = unidadeFiscalDto?.unidadeFiscalIDEfazenda;
        this.unidadeFiscalIdPaiEfazenda = unidadeFiscalDto?.unidadeFiscalIDPaiEfazenda;
        this.dataHoraInclusao = unidadeFiscalDto?.dataHoraInclusao;
        this.UsuarioNomeInclusao = unidadeFiscalDto?.usuarioNomeInclusao;
        this.UsuarioIdInclusao = unidadeFiscalDto?.usuarioIDInclusao;
        this.dataHoraAlteracao = unidadeFiscalDto?.dataHoraAlteracao;
        this.UsuarioNomeAlteracao = unidadeFiscalDto?.usuarioNomeAlteracao;
        this.UsuarioIdAlteracao = unidadeFiscalDto?.usuarioIDAlteracao;
        this.coordenadas = unidadeFiscalDto?.coordenadas;
        this.situacaoId = unidadeFiscalDto?.situacaoId;
        this.situacaoNome = unidadeFiscalDto?.situacaoNome;
        this.sigla = unidadeFiscalDto?.sigla;
        this.usuarios = unidadeFiscalDto?.usuarios?.map(usuario => new Usuario(usuario)) || [];
        this.isDisabled = desabilitarUnidadesQueNaoPossuiUsuario ? this.usuarios.length == 0 : false;
        this.ehUnidade = unidadeFiscalDto?.unidadeFiscalIDPaiEfazenda == 23;
        this.campoFiscais = `fiscalUnidade${unidadeFiscalDto?.unidadeFiscalID}`;
        this.subUnidades = [];
        this.descricao = unidadeFiscalDto.descricao;
    }
}
