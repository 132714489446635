import { Routes } from '@angular/router';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { MasterComponent } from '../../components/master/master.component';
import { ModuloCadastroComponent } from './cadastro/modulo.cadastro.component';
import { ModuloEditarComponent } from './editar/modulo.editar.component';
import { ModuloComponent } from './modulo.component';
import { ModuloVisualizarComponent } from './visualizar/modulo.visualizar.component';

export const ModuloComponentRoutes: Routes = [
    {
        path: 'menu',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ModuloComponent
            }
        ]
    },
    {
        path: 'menu/cadastro',
        component: MasterComponent,
        data: { permissao: EAcao.CADASTRAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ModuloCadastroComponent
            }
        ]
    },
    {
        path: 'menu/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ModuloVisualizarComponent
            }
        ]
    },
    {
        path: 'menu/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ModuloEditarComponent
            }
        ]
    }
];
