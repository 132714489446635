import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { Usuario } from "../model/app.usuario.model";

@Injectable({
    providedIn: 'root'
})
export class UsuarioValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _usuario: Usuario;

    constructor(private toast: ToastUtil) {
        this._messages = [];
    }

    get isValid() { return this._messages.length == 0; }
    get usuario() { return this._usuario; }

    set set(form: UntypedFormGroup) {
        this._form = form;
        this._usuario = {
            usuarioId: this._form.controls.usuarioId?.value || 0,
            nome: this._form.controls.nome.value?.trim(),
            login: this._form.controls.login.value?.trim(),
            perfilId: this._form.controls.perfil.value?.perfilId,
            perfilNome: this._form.controls.perfil.value?.nome,
            chefeUnidadesFiscais: this._form.controls.unidades.value,
        };
        this._validate();
    }

    private _validate() {
        const {
            usuarioId,
            nome,
            login,
            perfilId,
            perfilNome,
            chefeUnidadesFiscais
        } = this._usuario;

        this._messages = [];
        if (!usuarioId)
            this._messages.push({ type: ToastType.WARNING, message: "Usuário é obrigatório!" });
        if (!nome)
            this._messages.push({ type: ToastType.WARNING, message: "Nome é obrigatório!" });
        if (!login)
            this._messages.push({ type: ToastType.WARNING, message: "Login é obrigatório!" });
        if (!perfilId)
            this._messages.push({ type: ToastType.WARNING, message: "Perfil é obrigatório!" });
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }
}
