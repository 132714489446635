import { ComunService } from '../../services/app.comum.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/app.sortable.directive';
import { ParametroEntrada } from 'src/app/model/app.parametroentrada.model';
import { ParametrosEntradaService as ParametroEntradaService } from 'src/app/services/app.parametrosentrada.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { ETipoDado } from 'src/app/constantes/app.etipodado.const';
import { EOrderBy } from 'src/app/constantes/app.eorderby.const';

@Component({
    selector: 'app-parametro-entrada',
    templateUrl: './parametroentrada.component.html',
    styleUrls: ['./parametroentrada.component.scss'],
    providers: [ParametroEntradaService]
})
export class ParametroEntradaConsultaComponent extends ParmissoesPagina implements OnInit {
    public isMobile: boolean = false;
    public parametros$: Observable<ParametroEntrada[]>;
    public total$: Observable<number>;
    public form: FormGroup;
    public eSituacao: ListItem[] = ESituacao.toList()
    public tipoDados: Observable<ListItem[]>;
    public pesquisaAvancadaAberta: boolean = false;


    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<ParametroEntrada>>;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public service: ParametroEntradaService,
        private toast: ToastUtil,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private comum: ComunService,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService
    ) {
        super(router.url, accountService);
        this.parametros$ = service.parametros$;
        this.total$ = service.total$;
        this.tipoDados = this.comum.ObterTipoDado();

        this.accountService.PermissionOnModel(router.url)
            .subscribe({
                next: permissoes => this.permissoes = permissoes
            });

        this.form = this.formBuilder.group({
            filtroGenerico: new FormControl(null),

        });

        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        });
    }

    public colsGrid: any = [
        {
            colKey: "nome",
            colName: "Nome"
        },
        {
            colKey: "tipoDadoID",
            colName: "Tipo Dado",
            type: "string",
            options: ETipoDado.toListAll()

        },
        {
            colKey: "situacaoID",
            colName: "Situação",
            type: 'string',
            options: ESituacao.toListAll()
        },


    ]

    ngOnInit(): void {
        this.service.get({});
    }

    onSort({ column, direction }: SortEvent<ParametroEntrada>) {
        this.headers.forEach(header => {
            if (header.sortable !== column)
                header.direction = '';
        });

        this.service.getOrderByDirection(column, direction);
    }

    search() {
        const filtroGenerico = this.form.controls.filtroGenerico.value?.trim();

        this.service.get(
            {
                page: 1,
                pageSize: 10,
                searchTerm: '',
                sortColumn: '',
                sortDirection: EOrderBy.ASC,
                nome: null,
                situacaoID: null,
                tipoDadoID: null,
                dataDeTratamento: null,
                filtroGenerico: filtroGenerico ? filtroGenerico : ''

            });
    }

    clean() {
        this.form.setValue({
            nome: null,
            situacao: '',
            tipoDado: ''
        });
        this.service.get({});
    }

    onView({ parametroEntradaID: parametroEntradaId }: any) {
        this.router.navigate(['parametroentrada/visualizar'], {
            queryParams: { parametroEntradaId }
        });
    }

    onEdit({ parametroEntradaID: parametroEntradaId }: any) {
        this.router.navigate(['parametroentrada/editar'], {
            queryParams: { parametroEntradaId }
        });
    }

    onDelete(data: ParametroEntrada) {
        this.dialogModal.open({
            title: data.nome,
            text: `Você está prestes a remover este Parametro de Entrada!<br/><strong>Deseja continuar?</strong>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        data.situacaoId = ESituacao.EXCLUIDO;
                        this.service.putSituacao(data)
                            .subscribe({
                                next: () => {
                                    this.toast.showSuccess("Parâmetro de Entrada Excluido com sucesso!");
                                    this.blockUtil.desbloquear();
                                },
                                error: (erro: string) => {
                                    let exibirMensagemDeAtencao = erro.toString().includes('Atenção!');
                                    if (exibirMensagemDeAtencao)
                                        this.toast.showWarning(erro.toString().replace('Error:', ''), '');
                                    else
                                        this.toast.showDanger(erro);

                                    this.blockUtil.desbloquear();
                                }
                            });
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    toInsert() {
        this.router.navigate(['parametroentrada/cadastro']);
    }

    pesquisaAvancada() {
        this.form.reset();

        this.pesquisaAvancadaAberta = true;
    }

    close() {
        this.pesquisaAvancadaAberta = false;
        this.search();


    }
    cleanSearch() {
        this.form.controls.filtroGenerico.setValue('');

        this.search();

    }
}
