import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { Modulo } from '../model/app.modulo.model';

@Injectable({
    providedIn: 'root'
})
export class ModuloValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _modulo: Modulo;

    constructor(private toast: ToastUtil) {
        this._messages = [];
    }

    get isValid() { return this._messages.length == 0; }
    get modulo() { return this._modulo; }

    set set(form: UntypedFormGroup) {
        this._form = form;
        this._modulo = {
            moduloId: this._form.controls.moduloId?.value || 0,
            grupoId: this._form.controls.grupo?.value?.grupoID,
            titulo: this._form.controls.titulo.value,
            caminho: this._form.controls.caminho.value,
            icone: this._form.controls.icone.value?.classe,
            ordem: this._form.controls.ordem.value
        };
        this._validate();
    }

    private _validate() {
        const {
            moduloId,
            grupoId,
            titulo,
            caminho,
            icone,
            ordem,
        } = this._modulo;

        this._messages = [];
        if (!grupoId)
            this._messages.push({ type: ToastType.WARNING, message: "Grupo é obrigatório!" });
        if (String.isNullOrEmpty(titulo))
            this._messages.push({ type: ToastType.WARNING, message: "Título é obrigatório!" });
        if (String.isNullOrEmpty(caminho))
            this._messages.push({ type: ToastType.WARNING, message: "Caminho é obrigatório!" });
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }
}
