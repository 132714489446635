import { ModeloRegra } from "./app.modeloregra.model";
import { RegraFiltroEntrada } from "./app.regrafiltroentrada.model";
import { RegraParametroEntrada } from "./app.regraparametroentrada.model";
import { RegraUnidadeFiscal } from "./app.regraunidadefiscal.model";
import { UnidadeFiscal } from "./app.unidadefiscal.model";
import { Usuario } from "./app.usuario.model";

export class Regra {
    regraId?: number;
    modeloRegra?: ModeloRegra;
    tipoDestinatarioId?: number;
    tipoDestinatarioNome?: string;
    comportamentoRegraId?: number;
    comportamentoRegraNome?: string;
    dataHoraInicioVigencia?: Date;
    dataHoraFimVigencia?: Date;
    justificativa?: string;
    dataHoraInclusao?: Date;
    dataHoraAlteracao?: Date;
    usuarioIDInclusao?: number;
    usuarioNomeInclusao?: string;
    usuarioIDAlteracao?: number;
    usuarioNomeAlteracao?: string;
    situacaoId?: number;
    situacaoNome?: string;
    regrasUnidadeFiscal?: RegraUnidadeFiscal[];
    regrasParametroEntrada?: RegraParametroEntrada[];
    regrasFiltroEntrada?: RegraFiltroEntrada[];
    unidades?: UnidadeFiscal[];
    usuarios?: Usuario[];
    exibirIconePararAtualizacao?: boolean;
    regraDoTipoCustomizada?: boolean;
    tipoRegraID?: number;
    tipoRegraDescricao?: string;
    dataHoraInicioVigenciaFormatado?: string;
    dataHoraFimVigenciaFormatado?: string;

    constructor(dtoRegra: any) {
        this.regraId = dtoRegra?.regraID;
        this.modeloRegra = new ModeloRegra(dtoRegra?.modeloRegra);
        this.tipoDestinatarioId = dtoRegra?.tipoDestinatarioID;
        this.tipoDestinatarioNome = dtoRegra?.tipoDestinatarioNome;
        this.comportamentoRegraId = dtoRegra?.comportamentoRegraID;
        this.comportamentoRegraNome = dtoRegra?.comportamentoRegraNome;
        this.dataHoraInicioVigencia = dtoRegra?.dataHoraInicioVigencia;
        this.dataHoraFimVigencia = dtoRegra?.dataHoraFimVigencia;
        this.justificativa = dtoRegra?.justificativa;
        this.dataHoraInclusao = dtoRegra?.dataHoraInclusao;
        this.dataHoraAlteracao = dtoRegra?.dataHoraAlteracao;
        this.usuarioIDInclusao = dtoRegra?.usuarioIDInclusao;
        this.usuarioNomeInclusao = dtoRegra?.usuarioNomeInclusao;
        this.usuarioIDAlteracao = dtoRegra?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = dtoRegra?.usuarioNomeAlteracao;
        this.situacaoId = dtoRegra?.situacaoID;
        this.situacaoNome = dtoRegra?.situacaoNome;
        this.regrasUnidadeFiscal = dtoRegra?.regrasUnidadeFiscal?.map((regra: any) => new RegraUnidadeFiscal(regra)) as RegraUnidadeFiscal[];
        this.regrasParametroEntrada = dtoRegra?.regrasParametroEntrada?.map((regra: any) => new RegraParametroEntrada(regra)) as RegraParametroEntrada[];
        this.regrasFiltroEntrada = dtoRegra?.regrasFiltroEntrada?.map((regra: any) => new RegraFiltroEntrada(regra)) as RegraFiltroEntrada[];
        this.exibirIconePararAtualizacao = dtoRegra?.exibirIconePararAtualizacao;
        this.regraDoTipoCustomizada = dtoRegra?.regraDoTipoCustomizada;
        this.tipoRegraID = dtoRegra?.tipoRegraID;
        this.tipoRegraDescricao = dtoRegra?.tipoRegraDescricao;
        this.dataHoraInicioVigenciaFormatado = dtoRegra?.dataHoraInicioVigenciaFormatado;
        this.dataHoraFimVigenciaFormatado = dtoRegra?.dataHoraFimVigenciaFormatado;
    }
}
