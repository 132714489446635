import { switchMap, tap } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CondicionanteService } from 'src/app/services/app.condicionante.service';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { Observable } from 'rxjs';
import { Condicionante, FiltroEntrada, ParametroEntrada, ParametroSaida } from 'src/app/model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ModeloRegraValidator } from 'src/app/validators/modeloregra.validator';
import { ModeloRegraService } from 'src/app/services/app.modeloregra.service';
import { ComunService } from 'src/app/services/app.comum.service';

@Component({
    selector: 'app-modeloregra-editar',
    templateUrl: './modeloregra.editar.component.html',
    styleUrls: ['./modeloregra.editar.component.scss'],
    providers: [
        ModeloRegraService,
        BlockUtil
    ]
})
export class ModeloRegraEditarComponent implements OnInit {

    public form: FormGroup;
    public condicionantes$: Observable<Condicionante[]>;
    public parametrosEntrada: ParametroEntrada[];
    public filtrosEntrada: FiltroEntrada[];
    public parametrosSaida: ParametroSaida[];
    public selectedItems: ParametroEntrada[] = [];

    public configuracoesPadrao: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: true,
        itemsShowLimit: 0,
        textField: 'nome',
    };

    public parametrosEntradaSettings: IDropdownSettings = {
        ...this.configuracoesPadrao,
        idField: 'parametroEntradaId',
    };

    public filtrosEntradaSettings: IDropdownSettings = {
        ...this.configuracoesPadrao,
        idField: 'filtroEntradaId',
    };

    public parametrosSaidaSettings: IDropdownSettings = {
        ...this.configuracoesPadrao,
        idField: 'parametroSaidaId',
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private validator: ModeloRegraValidator,
        private blockUtil: BlockUtil,
        public service: ModeloRegraService,
        private toast: ToastUtil,
        private comum: ComunService
    ) {
        this.blockUtil.blockName = "atualizarModelo";
        this.form = this.formBuilder.group({
            modeloRegraId: new FormControl(null),
            nome: new FormControl(null),
            condicionante: new FormControl(''),
            parametrosEntrada: new FormControl([]),
            filtrosEntrada: new FormControl([]),
            parametrosSaida: new FormControl([])
        });

        const modeloRegraId = +this.route.snapshot.queryParamMap.get("modeloRegraId");
        this.service.getById(modeloRegraId)
            .subscribe((data: any) => {
                if (!data)
                    return;

                const { condicionanteId, nome, parametrosEntrada, filtrosEntrada, parametrosSaida } = data;

                this.condicionantes$ = this.comum.ObterCondicionantesAtivosEAtribuido(condicionanteId)
                    .pipe(
                        tap(condicionantes => {
                            const condicionante = condicionantes.find(condicionante => condicionante.condicionanteId == condicionanteId);
                            if (condicionante)
                                this.form.patchValue({ condicionante });
                        })
                    );
                this.comum.ObterParametrosEntradaAtivos()
                    .pipe(
                        tap(listaParametrosEntrada => {
                            this.parametrosEntrada = listaParametrosEntrada;
                            const parametros = this.parametrosEntrada.filter(({ parametroEntradaId }) => parametrosEntrada.find(({ parametroEntradaId: id }) => id === parametroEntradaId))
                                .map(parametroEntrada => Object.assign(parametroEntrada, parametrosEntrada.find(({ parametroEntradaId: id }) => id === parametroEntrada.parametroEntradaId)));
                            if (!!parametros.length)
                                this.form.patchValue({ parametrosEntrada: parametros });
                        })
                    ).subscribe();
                this.comum.ObterFiltrosEntradaAtivos()
                    .pipe(
                        tap(listaFiltrosEntrada => {
                            this.filtrosEntrada = listaFiltrosEntrada;
                            const filtros = this.filtrosEntrada.filter(({ filtroEntradaId }) => filtrosEntrada.find(({ filtroEntradaId: id }) => id === filtroEntradaId))
                                .map(filtroEntrada => Object.assign(filtroEntrada, filtrosEntrada.find(({ filtroEntradaId: id }) => id === filtroEntrada.filtroEntradaId)));
                            if (!!filtros.length)
                                this.form.patchValue({ filtrosEntrada: filtros });
                        })
                    ).subscribe();
                this.comum.ObterParametrosSaidaAtivos()
                    .pipe(
                        tap(listaParametrosSaida => {
                            this.parametrosSaida = listaParametrosSaida;
                            const parametros = this.parametrosSaida.filter(({ parametroSaidaId }) => parametrosSaida.find(({ parametroSaidaId: id }) => id === parametroSaidaId))
                                .map(parametroSaida => Object.assign(parametroSaida, parametrosSaida.find(({ parametroSaidaId: id }) => id === parametroSaida.parametroSaidaId)));
                            if (!!parametros.length)
                                this.form.patchValue({ parametrosSaida: parametros });
                        })
                    ).subscribe();

                this.form.patchValue({
                    modeloRegraId,
                    nome
                });
            });
    }

    get parametrosEntradaSelecionados(): ParametroEntrada[] {
        return this.form?.value?.parametrosEntrada || [];
    }

    get parametrosSaidaSelecionados(): ParametroSaida[] {
        return this.form?.value?.parametrosSaida || [];
    }

    get filtrosEntradaSelecionados(): FiltroEntrada[] {
        return this.form?.value?.filtrosEntrada || [];
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.blockUtil.bloquear();
        this.validator.set = this.form;

        if (!this.validator.isValid) {
            this.blockUtil.desbloquear();
            this.validator.showMessages();
        }
        else {
            this.service.put(this.validator.modeloRegra)
                .subscribe({
                    next: () => {
                        this.toast.showSuccess("Modelo de Regra atualizado com sucesso!");

                        this.blockUtil.desbloquear();
                        this.clean();
                        this.router.navigate(['modeloregra']);
                    },
                    error: (erro) => {
                        this.toast.showDanger(erro);
                        this.blockUtil.desbloquear();
                        return;
                    }
                });
        }
    }

    removerParametroEntrada(_parametroEntrada: ParametroEntrada) {
        const parametrosEntrada = this.form.controls.parametrosEntrada.value?.filter(parametroEntrada => _parametroEntrada != parametroEntrada);
        this.form.patchValue({ parametrosEntrada })
    }

    removerParametroSaida(_parametroSaida: ParametroSaida) {
        const parametrosSaida = this.form.controls.parametrosSaida.value?.filter(parametroSaida => _parametroSaida != parametroSaida);
        this.form.patchValue({ parametrosSaida })
    }

    removerFiltroEntrada(_filtroEntrada: FiltroEntrada) {
        const filtrosEntrada = this.form.controls.filtrosEntrada.value?.filter(filtroEntrada => _filtroEntrada != filtroEntrada);
        this.form.patchValue({ filtrosEntrada })
    }


    cancel() {
        this.clean();
        this.router.navigate(['modeloregra']);
    }

    clean() {
        this.form.patchValue({
            nome: null,
            condicionante: '',
            parametrosEntrada: [],
            filtrosEntrada: [],
            parametrosSaida: []
        })
    }
}
