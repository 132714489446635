import { ParametroSaida } from '../model/app.parametrosaida.model';
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ToastUtil, ToastType } from '../helper/app.toast.utils';

@Injectable({
    providedIn: 'root'
})
export class ParametroSaidaValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _parametroSaida: ParametroSaida;

    constructor(private toast: ToastUtil) {
        this._messages = [];
    }

    get isValid() { return this._messages.length == 0; }
    get parametroSaida() { return this._parametroSaida; }

    set set(form: UntypedFormGroup) {
        this._form = form;
        this._parametroSaida = {
            parametroSaidaId: this._form.controls.parametroSaidaId?.value || 0,
            nome: this._form.controls.nome.value?.trim(),
            dataHoraInclusao: new Date(),
            dataHoraAlteracao: null,
            tipoDadoId: this._form.controls.tipoDado.value?.tipoDadoID,
            caminhoMapeado: this._form.controls.caminhoMapeado.value?.trim()
        } as ParametroSaida;
        this._validate();
    }

    private _validate() {
        const {
            nome, tipoDadoId
        } = this._parametroSaida;

        this._messages = [];
        if (!nome)
            this._messages.push({ type: ToastType.WARNING, message: "Nome é obrigatório!" });
        if (!tipoDadoId)
            this._messages.push({ type: ToastType.WARNING, message: "Tipo de Dado é obrigatório!" });
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }
}
