import { switchMap } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { CondicionanteService } from 'src/app/services/app.condicionante.service';
import { CondicionanteValidator } from 'src/app/validators/condicionante.validator';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';

@Component({
    selector: 'app-condicionante-editar',
    templateUrl: './condicionante.editar.component.html',
    styleUrls: ['./condicionante.editar.component.scss'],
    providers: [
        CondicionanteService,
        BlockUtil
    ]
})
export class CondicionanteEditarComponent implements OnInit {

    public form: FormGroup;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private condicionanteValidator: CondicionanteValidator,
        private blockUtil: BlockUtil,
        public service: CondicionanteService,
        private toast: ToastUtil
    ) {
        this.form = this.formBuilder.group({
            condicionanteId: new FormControl(0),
            descricao: new FormControl(null)
        });

        const condicionanteId = +this.route.snapshot.queryParamMap.get("condicionanteId");
        this.service.getById(condicionanteId)
            .subscribe((data: any) => {
                if (!data)
                    return;

                const {condicionanteID: condicionanteId, descricao} = data;
                this.form.patchValue({
                    condicionanteId,
                    descricao
                });

            });
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.blockUtil.bloquear();
        this.condicionanteValidator.set = this.form;

        if(!this.condicionanteValidator.isValid){
            this.blockUtil.desbloquear();
			this.condicionanteValidator.showMessages();
        }
        else{
            this.service.put(this.condicionanteValidator.condicionante)
            .subscribe({
                next: () =>{
                    this._sucesso("Condicionante atualizada com sucesso!");

                    this.blockUtil.desbloquear();
                    this.clean();
                    this.router.navigate(['condicionante']);
                },
                error: (erro) =>{
                    this._erro(erro);
                    this.blockUtil.desbloquear();
                    return;
                }
            });
        }
    }

    cancel() {
        this.clean();
        this.router.navigate(['condicionante']);
    }

    clean(){
        this.form.setValue({
            condicionanteId: null,
            descricao: null
        });
    }

    private _erro(message: string) {
		this.toast.showDanger(message);
	}

	private _sucesso(message: string) {
		this.toast.showSuccess(message);
	}

}
