import { FormDinamico } from "./app.formdinamico.model";
import { Operador } from "./app.operador.model";

export class RegraParametroEntrada extends FormDinamico {
    regraParametroEntradaId?: number;
    parametroEntradaId?: number;
    parametroEntradaNome?: string;
    parametroEntradaTipoDadoId?: number;
    parametroEntradaTipoDadoNome?: string;
    operadorId?: number;
    operadorNome?: string;
    agrupadorId?: number;
    agrupadorNome?: string;
    valor?: string;
    localNome?: string;
    sentidoDaLeitura?: string;

    constructor(dtoRegraParametroEntrada: any) {
        super(dtoRegraParametroEntrada?.parametroEntrada, dtoRegraParametroEntrada?.regraParametroEntradaID);
        this.regraParametroEntradaId = dtoRegraParametroEntrada?.regraParametroEntradaID;
        this.parametroEntradaId = dtoRegraParametroEntrada?.parametroEntrada?.parametroEntradaID;
        this.parametroEntradaNome = dtoRegraParametroEntrada?.parametroEntrada?.nome;
        this.parametroEntradaTipoDadoId = dtoRegraParametroEntrada?.parametroEntrada?.tipoDadoID;
        this.parametroEntradaTipoDadoNome = dtoRegraParametroEntrada?.parametroEntrada?.tipoDadoNome;
        this.operadorId = dtoRegraParametroEntrada?.operador?.operadorID;
        this.operadorNome = dtoRegraParametroEntrada?.operador?.nome;
        this.agrupadorId = dtoRegraParametroEntrada?.agrupador?.agrupadorID;
        this.agrupadorNome = dtoRegraParametroEntrada?.agrupador?.nome;
        this.valor = dtoRegraParametroEntrada?.valor;
    }
}
