import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
export enum EAcao {
    PESQUISAR = 1,
    LIMPAR = 2,
    CADASTRAR = 3,
    ALTERAR = 4,
    VISUALIZAR = 5,
    EXCLUIR = 6
}
export namespace EAcao {
    export function toString(acao: EAcao | number): string {
        switch (acao) {
            case EAcao.PESQUISAR: return 'PESQUISAR';
            case EAcao.LIMPAR: return 'LIMPAR';
            case EAcao.CADASTRAR: return 'CADASTRAR';
            case EAcao.ALTERAR: return 'ALTERAR';
            case EAcao.VISUALIZAR: return 'VISUALIZAR';
            case EAcao.EXCLUIR: return 'EXCLUIR';
        }
    }

    export function toList(): ListItem[] {
        return Object.keys(EAcao)
            .filter(value => isNaN(Number(value)) === false)
            .map<ListItem>(key => ({ acaoId: +key, nome: EAcao.toString(+key) }));
    }

    export function toObject(): any {
        return Object.keys(EAcao)
            .filter(value => isNaN(Number(value)) === false)
            .reduce((final, chave) => ({
                ...final,
                [EAcao.toString(+chave)]: false
            }), {});
    }
}

