<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body ">
                        <form class="form-horizontal " [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-body ">
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Descrição da Condicionante</label>
                                    <div class="col-md-12 main-card">
                                        <input type="text " class="form-control input-lg " name="descricao"
                                            placeholder="Digite aqui o Nome da Condicionante... "
                                            formControlName="descricao">
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn default" (click)="cancel() ">Cancelar</button>
                                <button type="submit " class="btn btn-primary ">Inserir</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
