<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body" *blockUI="'atualizarModelo'">
                        <form class="form-horizontal " [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-body">
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Nome do Modelo de Regra</label>
                                    <div class="col-md-12 main-card">
                                        <input type="text " class="form-control input-lg" name="nome"
                                            placeholder="Digite aqui o Nome do Modelo..." formControlName="nome">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-5 control-label">Condicionante</label>
                                    <div class="col-md-12 main-card">
                                        <select class="form-control form-control-sm" formControlName="condicionante">
                                            <option value>Selecione...</option>
                                            <option *ngFor="let condicionante of condicionantes$ | async"
                                                [ngValue]="condicionante">
                                                {{condicionante.descricao}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-5 control-label">Parâmetro de Entrada</label>
                                    <div class="col-md-12 main-card">
                                        <ng-multiselect-dropdown [placeholder]="'Selecione Parâmetro de Entrada'"
                                            [settings]="parametrosEntradaSettings" [data]="parametrosEntrada"
                                            formControlName="parametrosEntrada">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="parametrosEntradaSelecionados.length">
                                    <div class="col">
                                        <label class="control-label">Parâmetro de Entrada - Selecionados</label>
                                        <hr class="mt-0 mb-1" />
                                        <div class="d-flex flex-column">
                                            <div class="input-group"
                                                *ngFor="let parametro of parametrosEntradaSelecionados">
                                                <span class="input-group-text fw-bold flex-grow-1 py-1">{{
                                                    parametro.nome
                                                    }}</span>
                                                <button class="btn btn-sm my-0" type="button"
                                                    [ngbTooltip]="!parametro.obrigatorio ? 'Clique para tornar obrigatório' : 'Clique para tornar opcional'"
                                                    [ngClass]="{ 'btn-outline-primary': !parametro.obrigatorio, 'btn-primary': parametro.obrigatorio }"
                                                    (click)="parametro.obrigatorio=!parametro.obrigatorio">
                                                    {{ parametro.obrigatorio ? 'Obrigatório' : 'Opcional' }}
                                                </button>
                                                <button class="btn btn-danger btn-sm my-0" type="button"
                                                    ngbTooltip="Remover" (click)="removerParametroEntrada(parametro)">
                                                    <i class="fa-solid fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-5 control-label">Filtro de Entrada</label>
                                    <div class="col-md-12 main-card">
                                        <ng-multiselect-dropdown [placeholder]="'Selecione Filtro de Entrada'"
                                            [settings]="filtrosEntradaSettings" [data]="filtrosEntrada"
                                            formControlName="filtrosEntrada">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="filtrosEntradaSelecionados.length">
                                    <div class="col">
                                        <label class="control-label">Filtro de Entrada - Selecionados</label>
                                        <hr class="mt-0 mb-1" />
                                        <div class="d-flex flex-column">
                                            <div class="input-group" *ngFor="let filtro of filtrosEntradaSelecionados">
                                                <span class="input-group-text fw-bold flex-grow-1 py-1">{{
                                                    filtro.nome
                                                    }}</span>
                                                <button class="btn btn-sm my-0" type="button"
                                                    [ngbTooltip]="!filtro.obrigatorio ? 'Clique para tornar obrigatório' : 'Clique para tornar opcional'"
                                                    [ngClass]="{ 'btn-outline-primary': !filtro.obrigatorio, 'btn-primary': filtro.obrigatorio }"
                                                    (click)="filtro.obrigatorio=!filtro.obrigatorio">
                                                    {{ filtro.obrigatorio ? 'Obrigatório' : 'Opcional' }}
                                                </button>
                                                <button class="btn btn-danger btn-sm my-0" type="button"
                                                    ngbTooltip="Remover" (click)="removerFiltroEntrada(filtro)">
                                                    <i class="fa-solid fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-5 control-label">Parâmetro de Saída</label>
                                    <div class="col-md-12 main-card">
                                        <ng-multiselect-dropdown [placeholder]="'Selecione Parâmetro de Saída'"
                                            [settings]="parametrosSaidaSettings" [data]="parametrosSaida"
                                            formControlName="parametrosSaida">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="parametrosSaidaSelecionados.length">
                                    <div class="col">
                                        <label class="control-label">Parâmetro de Saída - Selecionados</label>
                                        <hr class="mt-0 mb-1" />
                                        <div class="d-flex flex-column">
                                            <div class="input-group"
                                                *ngFor="let parametro of parametrosSaidaSelecionados">
                                                <span class="input-group-text fw-bold flex-grow-1 py-1">{{
                                                    parametro.nome
                                                    }}</span>
                                                <button class="btn btn-sm my-0" type="button"
                                                    [ngbTooltip]="!parametro.compoeVisualizacao ? 'Clique para liberar para visualização' : 'Clique para remover da visualização'"
                                                    [ngClass]="{ 'btn-outline-primary': !parametro.compoeVisualizacao, 'btn-primary': parametro.compoeVisualizacao }"
                                                    (click)="parametro.compoeVisualizacao=!parametro.compoeVisualizacao">
                                                    {{ parametro.compoeVisualizacao ? 'Visualização' : 'Liberar
                                                    Visualização' }}
                                                </button>
                                                <button class="btn btn-sm my-0" type="button"
                                                    [ngbTooltip]="!parametro.compoeNotificacao ? 'Clique para liberar para notificação' : 'Clique para remover da notificação'"
                                                    [ngClass]="{ 'btn-outline-primary': !parametro.compoeNotificacao, 'btn-primary': parametro.compoeNotificacao }"
                                                    (click)="parametro.compoeNotificacao=!parametro.compoeNotificacao">
                                                    {{ parametro.compoeNotificacao ? 'Notificação' : 'Liberar
                                                    Notificação' }}
                                                </button>
                                                <button class="btn btn-danger btn-sm my-0" type="button"
                                                    ngbTooltip="Remover" (click)="removerParametroSaida(parametro)">
                                                    <i class="fa-solid fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn default" (click)="cancel() ">Cancelar</button>
                                <button type="submit " class="btn btn-primary ">Atualizar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>