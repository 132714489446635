import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Component, ViewChildren, QueryList } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-master',
    templateUrl: './master.component.html',
    providers: [DecimalPipe]
})
export class MasterComponent {
    closeResult = '';
    advancedSearch = false;

    constructor(private modalService: NgbModal) {
    }

    setAdvancedSearch() {
        this.advancedSearch = true
    }

    closeAdvancedSearch() {
        this.advancedSearch = false
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
