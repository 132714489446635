import { ListItem } from "../interfaces/app.utilservices.interface";

export enum EGrupo {
    ADMINISTRATIVO = 1,
    REGRAS = 2,
    CONSULTA = 3,
    ALERTA = 4,
    DASHBOARD = 5
}

export namespace EGrupo {
    export function toString(situacao: EGrupo | number): string {
        switch (situacao) {
            case EGrupo.ADMINISTRATIVO: return 'ADMINISTRATIVO';
            case EGrupo.REGRAS: return 'REGRAS';
            case EGrupo.CONSULTA: return 'CONSULTA';
            case EGrupo.ALERTA: return 'ALERTA';
            case EGrupo.DASHBOARD: return 'DASHBOARD';
           

        }
    }

    export function toListAll(): ListItem[] {
        return Object.keys(EGrupo)
            .filter((value) => isNaN(Number(value)) === false)
            .map<ListItem>((key) => ({ id: +key, nome: EGrupo.toString(+key) }))
            
    }
}