import { ModuloVisualizarComponent } from './visualizar/modulo.visualizar.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from "src/app/directives/app.directives.module";
import { ModuloComponent } from "./modulo.component";
import { ModuloCadastroComponent } from './cadastro/modulo.cadastro.component';
import { ModuloEditarComponent } from './editar/modulo.editar.component';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        DirectivesModule,
        ComponentsModule
    ],
    declarations: [
        ModuloComponent,
        ModuloCadastroComponent,
        ModuloVisualizarComponent,
        ModuloEditarComponent,

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModuloModule { }
