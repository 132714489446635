<!-- <my-content placeholder="navigation">
    <p>entrada works!</p>
</my-content> -->
<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card" *ngIf="!isMobile">
                    <div class="card-body">
                        <form [formGroup]="form" (ngSubmit)="search()">
                            <h5 class="mb-0">Seção de Filtros de Entrada</h5>
                            <hr class="mt-2" />
                            <div class="d-flex">
                                <div class="d-flex flex-wrap col-md-12 mr-3 justify-content-between p-0">

                                    <div [ngClass]="pesquisaAvancadaAberta?'d-flex col-md-12':'d-flex col-md-8'">
                                        <div class="input-group mb-3" *ngIf="!pesquisaAvancadaAberta">
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Digite e clique em Pesquisar..." formControlName="filtroGenerico"
                                                maxlength="75" />
                                            <div class="input-group-append">
                                                <button type="submit"
                                                    class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                                    style="gap: 0.5rem; height: 40px; border-radius:0.1875rem;">
                                                    Pesquisar<i class="fa-solid fa-magnifying-glass"></i>
                                                </button>
                                            </div>
                                            <div class="input-group-append">
                                                <button type="button" (click)="pesquisaAvancada()"
                                                    class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                                    style="gap: 0.5rem; border-radius:0.1875rem;">
                                                    Pesquisa Avançada<i class="fa-solid fa-magnifying-glass"></i>
                                                </button>
                                            </div>
                                            <div class="input-group-append">
                                                <button type="reset"
                                                    class="btn btn-default btn-sm my-0 d-flex align-items-center"
                                                    style="gap:0.5rem;border-radius:0.1875rem; height: 40px;"
                                                    (click)="cleanSearch()">Limpar<i class="fa-solid fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <app-inputsearch *ngIf="pesquisaAvancadaAberta" [colsGrid]="colsGrid" [service]="service" (closeSearch)="close()" (cleanSearch)="cleanSearch()" style="width: 100%;"></app-inputsearch>
                                    </div>
                                    <div class="d-flex col-md-4" [ngClass]="pesquisaAvancadaAberta?'justify-content-start':'justify-content-end'">
                                        <button type="button"
                                            class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                            style="gap:0.5rem;height: 40px; justify-content: center;" (click)="toInsert()" *ngIf="PodeCadastrar">Cadastrar
                                            Filtro de Entrada <i class="fa-solid fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive px-3" style="padding-left: 1.8rem!important; padding-right: 1.8rem!important;">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="nome" (sort)="onSort($event)">Nome
                                    </th>
                                    <th scope="col" sortable="tipoDado.Nome" (sort)="onSort($event)">Tipo Dado
                                    </th>
                                    <th scope="col" sortable="situacao.Nome" (sort)="onSort($event)">Situação
                                    </th>
                                    <th scope="col" class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of filtroEntrada$ | async">
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.nome" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.tipoDadoNome" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.situacaoNome" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1" style="width: 30%;">
                                        <div class="d-flex justify-content-center" style="gap: 1rem;">
                                            <button
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onView(data)" style="gap:0.5rem" *ngIf="PodeVisualizar">
                                                Visualizar<i class="fa-solid fa-eye"></i>
                                                <!-- <i class="now-ui-icons travel_info hover-icon"></i> -->
                                            </button>
                                            <button
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onEdit(data)" style="gap:0.5rem" *ngIf="PodeAlterar">
                                                Editar<i class="fa-sharp fa-solid fa-pen"></i>
                                                <!-- <i class="now-ui-icons shopping_tag-content hover-icon"></i> -->
                                            </button>
                                            <button
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onDelete(data)" style="gap:0.5rem" *ngIf="PodeExcluir">
                                                Excluir<i class="fa-solid fa-trash-can"></i>
                                                <!-- <i class="now-ui-icons ui-1_simple-remove hover-icon"></i> -->
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize" [maxSize]="10">
                            </ngb-pagination>

                            <select class="form-select form-control-sm" style="width: auto" name="pageSize"
                                [(ngModel)]="service.pageSize">
                                <option [ngValue]="10">10 Itens</option>
                                <option [ngValue]="15">15 Itens</option>
                                <option [ngValue]="20">20 Itens</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="isMobile">
                    <div class="card-body">
                        <form [formGroup]="form" (ngSubmit)="search()">
                            <h5 class="mb-0">Seção de Filtros de Entrada</h5>
                            <hr class="mt-2" />
                            <div class="d-flex">
                                <div class="d-flex flex-column col-md-12 justify-content-center align-items-center p-0">
                                    <div class="input-group mb-3 col-sm-12 col-md-3 p-0 justify-content-end">
                                        <button type="button"
                                            class="btn btn-primary btn-sm my-0 d-flex align-items-center w-50"
                                            style="gap:0.5rem; font-size: 14px;" (click)="toInsert()">Cadastrar Filtro
                                            de Entrada <i class="fa-solid fa-plus"></i></button>
                                    </div>
                                    <div class="input-group mb-3 col-sm-12 p-0">
                                        <!-- <div class="input-group-append" *ngIf="form.value.nome">
                                            <button type="button" class="btn btn-limpar btn-sm my-0"
                                                *ngIf="form.value.nome" (click)="clean()">Limpar</button>
                                        </div> -->
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Pesquisar..." formControlName="nome" maxlength="75" />
                                        <div class="input-group-append">
                                            <button type="submit"
                                                class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                                style="gap:0.5rem"><i class="fa-solid fa-magnifying-glass"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- <hr /> -->
                    <div class="table-responsive px-3">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>

                                </tr>
                            </thead>
                            <tbody class="d-flex flex-column">
                                <tr class="d-initial mb-2 border" *ngFor="let data of filtroEntrada$ | async">
                                    <td class="d-flex justify-content-between align-items-center ">
                                        <div>Nome</div>
                                        <ngb-highlight [result]="data.nome " [term]="service.searchTerm ">
                                        </ngb-highlight>
                                    </td>
                                    <td class="d-flex justify-content-between align-items-center ">
                                        <div>Condicionante</div>
                                        <ngb-highlight [result]="data.tipoDadoNome " [term]="service.searchTerm ">
                                        </ngb-highlight>
                                    </td>
                                    <td class="d-flex justify-content-between align-items-center ">
                                        <div>Nome da Situação</div>
                                        <ngb-highlight [result]="data.situacaoNome " [term]="service.searchTerm ">
                                        </ngb-highlight>
                                    </td>
                                    <td class="d-flex justify-content-between align-items-center">
                                        <div>
                                            Ações
                                        </div>
                                        <div class="d-flex justify-content-around" style="gap: 1rem;">
                                            <button
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onView(data)" style="gap:0.5rem">
                                                <i class="fa-solid fa-eye"></i>
                                                <!-- <i class="now-ui-icons travel_info hover-icon"></i> -->
                                            </button>
                                            <button
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onEdit(data)" style="gap:0.5rem">
                                                <i class="fa-sharp fa-solid fa-pen"></i>
                                                <!-- <i class="now-ui-icons shopping_tag-content hover-icon"></i> -->
                                            </button>
                                            <button
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onDelete(data)" style="gap:0.5rem">
                                                <i class="fa-solid fa-trash-can"></i>
                                                <!-- <i class="now-ui-icons ui-1_simple-remove hover-icon"></i> -->
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex flex-column justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize" [maxSize]="10">
                            </ngb-pagination>

                            <select class="form-select form-control-sm" style="width: auto" name="pageSize"
                                [(ngModel)]="service.pageSize">
                                <option [ngValue]="10">10 Itens</option>
                                <option [ngValue]="15">15 Itens</option>
                                <option [ngValue]="20">20 Itens</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>