<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12" *blockUI="'alertasCadastrados'">
                <div class="card" *ngIf="!isMobile">
                    <div class="card-body">
                        <form [formGroup]="form" (ngSubmit)="search()">
                            <h5 class="mb-0">Seção de Alertas</h5>
                            <hr class="mt-2" />
                            <div class="d-flex flex-column">
                                <div class="d-flex flex-wrap col-md-12 mr-3 justify-content-between p-0">
                                    <div [ngClass]="pesquisaAvancadaAberta?'d-flex col-md-12':'d-flex col-md-8'">
                                        <div class="input-group mb-3" *ngIf="!pesquisaAvancadaAberta">
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Digite e clique em Pesquisar..."
                                                formControlName="filtroGenerico" maxlength="75" />
                                            <div class="input-group-append">
                                                <button type="submit"
                                                    class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                                    style="gap: 0.5rem; height: 40px;border-radius:0.1875rem;">
                                                    Pesquisar<i class="fa-solid fa-magnifying-glass"></i>
                                                </button>
                                            </div>
                                            <div class="input-group-append">
                                                <button type="button" (click)="pesquisaAvancada()"
                                                    class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                                    style="gap: 0.5rem; border-radius:0.1875rem;">
                                                    Pesquisa Avançada<i class="fa-solid fa-magnifying-glass"></i>
                                                </button>
                                            </div>
                                            <div class="input-group-append">
                                                <button type="reset"
                                                    class="btn btn-default btn-sm my-0 d-flex align-items-center"
                                                    style="gap:0.5rem;border-radius:0.1875rem; height: 40px;"
                                                    (click)="cleanSearch()">Limpar<i class="fa-solid fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <app-inputsearch *ngIf="pesquisaAvancadaAberta" [colsGrid]="colsGrid"
                                            [service]="service" (closeSearch)="closeSearch()"
                                            (cleanSearch)="cleanSearch()" style="width: 100%;"></app-inputsearch>
                                    </div>

                                    <div class="d-flex col-md-4"
                                        [ngClass]="pesquisaAvancadaAberta?'justify-content-start':'justify-content-end'">
                                        <button type="button"
                                            class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                            style="gap: 0.5rem;height: 40px;justify-content: center;"
                                            (click)="exportExcel()">
                                            Excel <i class="fas fa-file-csv"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- <hr /> -->
                    <div class="table-responsive px-3">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th class="align-bottom" scope="col" sortable="alertaId" (sort)="onSort($event)">
                                        Alerta</th>
                                    <th class="align-bottom" scope="col" sortable="regra.regraID"
                                        (sort)="onSort($event)">
                                        Regra</th>
                                    <th class="align-bottom" scope="col" sortable="dataHoraInclusao"
                                        (sort)="onSort($event)">
                                        Data de Geração
                                    </th>
                                    <th class="align-bottom" scope="col" sortable="situacao.Nome"
                                        (sort)="onSort($event)">
                                        Situação</th>

                                    <th class="align-bottom" scope="col" sortable="numeroDocumento"
                                        (sort)="onSort($event)">
                                        Condicionante
                                    </th>
                                    <th class="align-bottom" scope="col" sortable="usuarioAlteracao.Nome"
                                        (sort)="onSort($event)">
                                        Usuário Atendimento
                                    </th>
                                    <th class="align-bottom" scope="col" sortable="descricaoTipoAlerta"
                                        (sort)="onSort($event)">
                                        Tipo Alerta</th>
                                    <th class="align-bottom text-center" scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of alertas$ | async">
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.alertaId" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.regra.regraId" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.dataHoraInclusao | date: 'dd/MM/yyyy HH:mm:ss'"
                                            [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.situacaoNome" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.regra.modeloRegra.condicionanteDescricao"
                                            [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.usuarioNomeAlteracao" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.descricaoTipoAlerta" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <div class="d-flex justify-content-left" style="gap: 1rem">
                                            <button ngbTooltip="Visualizar"
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onView(data)" style="gap: 0.5rem" *ngIf="PodeVisualizar">
                                                <i class="fa-solid fa-eye"></i>
                                            </button>
                                            <button ngbTooltip="Vincular"
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onLink(data)" style="gap: 0.5rem"
                                                *ngIf="PodeVisualizar && data.situacaoId == eSitucaoPendente">
                                                <i class="fa-solid fa-link"></i>
                                            </button>
                                            <button ngbTooltip="Atender"
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onFinalize(data)" style="gap: 0.5rem"
                                                *ngIf="PodeVisualizar && data.situacaoId != eSitucaoFinalizado">
                                                <i class="fa-solid fa-magnifying-glass"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize" [maxSize]="10">
                            </ngb-pagination>

                            <select class="form-select form-control-sm" style="width: auto" name="pageSize"
                                [(ngModel)]="service.pageSize">
                                <option [ngValue]="10">10 Itens</option>
                                <option [ngValue]="15">15 Itens</option>
                                <option [ngValue]="20">20 Itens</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>