import { UtilService } from './app.utils.service';
import { EOrderBy } from '../constantes/app.eorderby.const';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, of, from, AsyncSubject } from 'rxjs';

import { switchMap, tap, map, catchError, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SearchResult, State } from '../interfaces/app.utilservices.interface';
import { ComunService } from './app.comum.service';
import { SortColumn, SortDirection } from '../directives/app.sortable.directive';
import { LocalizacaoUsuarioDto } from '../pages/usuarioconectado/dto/localizacaousuario.dto';
import { data } from 'jquery';
import { BlockUtil } from '../helper/app.block.utils';

@Injectable({
    providedIn: 'root'
})

export class LocalizacaoUsuarioService {
    private _search$ = new Subject<void>();
    private _usuariosConectados$ = new BehaviorSubject<LocalizacaoUsuarioDto[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    private _situacao$ = new BehaviorSubject<boolean>(null);

    private readonly URL_CONTROLLER = 'LocalizacaoUsuario';

    private _state: State<LocalizacaoUsuarioDto> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: EOrderBy.ASC,
     
    };

    constructor(private util: UtilService, private comunService: ComunService, private blockUtil: BlockUtil) {
        this._search$.pipe(
            switchMap(() => this._pagination())
        )
            .subscribe(({ usuariosConectados, total }) => {
                this._usuariosConectados$.next(usuariosConectados);
                this._total$.next(total);
            });
    }

    get usuariosConectados$() { return this._usuariosConectados$.asObservable(); }
    get usuariosConectados() { return this._usuariosConectados$.value }

    get total$() { return this._total$.asObservable(); }
    get page() { return this._state.page; }
    get pages() { return Math.ceil(this._total$.value / this._state.pageSize); }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get situacao$() { return this._situacao$.asObservable(); }

    set bloqueioTela(nome: string) { this.blockUtil.blockName = nome; }
    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize, page: 1 }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn<LocalizacaoUsuarioDto>) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    public get(data:any) {
        this._set(data);
    }

    public getOrderByDirection(sortColumn: SortColumn<LocalizacaoUsuarioDto>, sortDirection: SortDirection) {
        this._set({ sortColumn, sortDirection });
    }

    private _set(patch: any) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _pagination(): Observable<SearchResult<LocalizacaoUsuarioDto>> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPaginado`;
        this.blockUtil.bloquear();
        return this.util.Post(url, 
            this._state
        ).pipe(                
                map((data) => ({usuariosConectados: data.results.map(dto => new LocalizacaoUsuarioDto(dto)), total: data.total })),
                tap(() => this.blockUtil.desbloquear()),
                catchError(errorRequest => {
                    const { error, message } = errorRequest;
                    console.log("ERROR", error?.message || message);
                    return of({ usuariosConectados: [], total: 0 });
                })
            );
    }
}