import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { UsuarioconectadoComponent } from "./usuarioconectado.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DirectivesModule } from "src/app/directives/app.directives.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MapaComponent } from './mapa/mapa.component';
import {MatFormField}  from '@angular/material/form-field';
import { ComponentsModule } from "src/app/components/components.module";
import { DateTimePickerComponent } from "src/app/components/datimepicker/app.component.datimepicker";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
        NgMultiSelectDropDownModule.forRoot(),

    ],
    declarations: [
        UsuarioconectadoComponent,
        MapaComponent,
        
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsuarioConectadoModule { }