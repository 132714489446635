import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ParametroEntradaCadastroComponent } from './cadastro/parametroentrada.cadastro.component';
import { ParametroEntradaConsultaComponent } from './parametroentrada.component';
import { ParametroEntradaVisualizarComponent } from './visualizar/parametroentrada.visualizar.component';
import { ParametroEntradaEditarComponent } from './editar/parametroentrada.editar.component';
import { DirectivesModule } from "src/app/directives/app.directives.module";
import { ComponentsModule } from "src/app/components/components.module";


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        DirectivesModule,
        ComponentsModule
    ],
    declarations: [
        ParametroEntradaConsultaComponent,
        ParametroEntradaCadastroComponent,
        ParametroEntradaVisualizarComponent,
        ParametroEntradaEditarComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ParametroEntradaModule { }
