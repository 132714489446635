import { ListItem } from './../../../../interfaces/app.utilservices.interface';
import { filter, map, take } from 'rxjs/operators';
import { FiltroEntrada } from 'src/app/model/app.filtroentrada.model';
import { ParametroEntrada } from 'src/app/model/app.parametroentrada.model';
import { combineLatest } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ComunService } from 'src/app/services/app.comum.service';
import { ModeloRegraService } from 'src/app/services/app.modeloregra.service';
import { Agrupador, Condicionante, ModeloRegra, Operador } from 'src/app/model';
import { RegraService } from 'src/app/services/app.regra.service';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { CpfPipe } from 'src/app/pipes/app.cpf.pipe';
import { CnpjPipe } from 'src/app/pipes/app.cnpj.pipe';
import { InscricaoEstadualPipe } from 'src/app/pipes/app.inscricaoestadual.pipe';
import { EUF } from 'src/app/constantes/app.euf.const';
import { PlacaPipe } from 'src/app/pipes/app.placa.pipe';
import { FormControlUtil } from 'src/app/helper/app.tipocampo.utils';
import { Equipamento } from 'src/app/model/app.equipamentos.model';

@Component({
    selector: 'app-passo1modeloregra',
    templateUrl: './passo1modeloregra.component.html',
    styleUrls: ['./passo1modeloregra.component.scss'],
    providers: [
        ModeloRegraService,
        RegraService,
        BlockUtil,
        CpfPipe,
        CnpjPipe,
        InscricaoEstadualPipe,
        PlacaPipe,
        FormControlUtil
    ]
})
export class Passo1ModeloRegraComponent implements OnInit {

    public form: FormGroup;
    public formParametro: FormArray = new FormArray([]);
    public formFiltro: FormArray = new FormArray([]);
    public modelosRegra: ModeloRegra[];
    public condicionantes: Condicionante[] = [];
    public parametrosEntrada: ParametroEntrada[];
    public filtrosEntrada: FiltroEntrada[];
    public operadores: Operador[] = [];
    public agrupadores: Agrupador[] = [];
    public equipamentos: Equipamento[] = [];
    public ieDoMs: boolean = null;
    public ufs: ListItem[] = EUF.toList();

    constructor(
        private rootFormGroup: FormGroupDirective,
        private comum: ComunService,
        private blockUtil: BlockUtil,
        private util: FormControlUtil,
    ) {
        this.blockUtil.blockName = "modeloRegra";
    }

    get modeloDeRegraSelecionado(): boolean { return !!this.form.controls.modeloRegra?.value; }    
    get possuiParametrosEntrada(): boolean { return !!this.formParametro.length; }
    get possuiFiltrosEntrada(): boolean { return !!this.formFiltro.length; }
    get modeloRegra(): ModeloRegra { return this.form.controls.modeloRegra?.value; }
    get condicionanteDomodeloDeRegraSelecionado(): string { return this.modelosRegra.filter(m => m.modeloRegraId == this.form.controls.modeloRegra?.value?.modeloRegraId)[0]?.condicionanteDescricao; }

    onAddParametroEntrada() {
        const parametro = this.form.controls.parametroSelecionado.value;
        if (!parametro)
            return;
        this.onAddFieldInForm(this.formParametro, parametro);
        this.form.patchValue({ parametroSelecionado: '' }, { emitEvent: false });
    }

    onAddFiltroEntrada() {
        const filtro = this.form.controls.filtroSelecionado.value;
        if (!filtro)
            return;
        this.onAddFieldInForm(this.formFiltro, filtro);
        this.form.patchValue({ filtroSelecionado: '' }, { emitEvent: false });
    }

    onAddFieldInForm(form: FormArray, item: any) {
        const { formControl, transform } = this.util.tipo[item.tipoDadoId];
        const campo = formControl({ item: { ...item }, transform, valor: '' });
        form.push(campo);
    }

    ngOnInit(): void {
        this.blockUtil.bloquear();
        this.form = this.rootFormGroup.control as UntypedFormGroup;
        
        const form = this.form.values();
        combineLatest({
            operadores: this.comum.ObterOperadoresAtivos()
                .pipe(filter(operadores => !!operadores)),
            agrupadores: this.comum.ObterAgrupadoresAtivos()
                .pipe(filter(agrupadores => !!agrupadores)),
            modeloRegra: form
                .pipe(
                    map(({ modeloRegra }) => modeloRegra),
                    filter(modeloRegra => !!modeloRegra)
                ),
            equipamentos: this.comum.ObterEquipamentos()
                .pipe(filter(equipamentos => !!equipamentos))
        })
            .pipe(
                take(1),
            )
            .subscribe(({ operadores, agrupadores, modeloRegra, equipamentos }) => {
                const { condicionanteId, condicionanteDescricao: descricao } = modeloRegra;
                const condicionante = new Condicionante({ condicionanteID: condicionanteId, descricao });
                this.form.patchValue({ condicionante }, { emitEvent: false });
                this.form.patchValue({ modeloRegra }, { emitEvent: false });
                this.condicionantes = [condicionante];
                this.modelosRegra = [modeloRegra];
                this.parametrosEntrada = modeloRegra?.parametrosEntrada;
                this.filtrosEntrada = modeloRegra?.filtrosEntrada;

                operadores.forEach(operador => this.operadores.push(operador));
                agrupadores.forEach(agrupador => this.agrupadores.push(agrupador));
                equipamentos.forEach(equipamento => this.equipamentos.push(equipamento));
                this.formParametro = this.rootFormGroup.control.get('formParametro') as UntypedFormArray;
                this.formFiltro = this.rootFormGroup.control.get('formFiltro') as UntypedFormArray;
                this.blockUtil.desbloquear();
            });
    }

    onRemoveFieldInFormParametro({ value }) {
        this.formParametro.removeAt(value);
    }

    onRemoveFieldInFormFiltro({ value }) {
        this.formFiltro.removeAt(value);
    }
}
