import { UtilService } from './app.utils.service';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortColumn, SortDirection } from '../directives/app.sortable.directive';
import { SearchResult, State } from '../interfaces/app.utilservices.interface';
import { EOrderBy } from '../constantes/app.eorderby.const';
import { ComunService } from './app.comum.service';
import { LocalizacaoEvento, LocalizacaoEventoSearch } from '../model/app.localizacaoevento.model';
import { BlockUtil } from '../helper/app.block.utils';

@Injectable({
    providedIn: 'root'
})
export class LocalizacaoEventoService {
    private _search$ = new Subject<void>();
    private _localizacaoEventos$ = new BehaviorSubject<LocalizacaoEvento[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    private _situacao$ = new BehaviorSubject<boolean>(null);

    private readonly URL_CONTROLLER = 'LocalizacaoEvento';

    private _state: State<LocalizacaoEventoSearch> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: EOrderBy.ASC,
        usuarioId: null,
        unidade: null,
        acaoId: null,
        dataHoraInicio: null,
        dataHoraFim: null
    };

    constructor(private util: UtilService, private blockUtil: BlockUtil) {
        this._search$.pipe(
            switchMap(() => this._pagination())
        )
            .subscribe(({ localizacaoEventos, total }) => {
                this._localizacaoEventos$.next(localizacaoEventos);
                this._total$.next(total);
            });
    }

    get localizacaoEventos() { return this._localizacaoEventos$.value; }
    get localizacaoEventos$() { return this._localizacaoEventos$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get page() { return this._state.page; }
    get pages() { return Math.ceil(this._total$.value / this._state.pageSize); }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get situacao$() { return this._situacao$.asObservable(); }


    set bloqueioTela(nome: string) { this.blockUtil.blockName = nome; }
    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize, page: 1 }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn<LocalizacaoEvento>) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    public get({
        usuarioId = null,
        unidade = null,
        acaoID = null,
        dataHoraInicio = null,
        dataHoraFim = null,
        page = 1
    }) {
        this._set({ usuarioId, unidade, acaoID, dataHoraInicio, dataHoraFim, page });
    }

    public getById(id: number) {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPorID/${id}`;
        return this.util.Get<any>(url)
            .pipe(
                filter(localizacaoEventos => !!localizacaoEventos),
                map(localizacaoEvento => new LocalizacaoEvento(localizacaoEvento))
            );
    }

    public getOrderByDirection(sortColumn: SortColumn<LocalizacaoEvento>, sortDirection: SortDirection) {
        this._set({ sortColumn, sortDirection });
    }

    private _set(patch: any) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _pagination(): Observable<SearchResult<LocalizacaoEvento>> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPaginado`;
        this.blockUtil.bloquear();
        return this.util.Post(url, {
            Page: this._state.page,
            Size: this._state.pageSize,
            OrderBy: this._state.sortDirection || EOrderBy.DESC,
            FieldAtOrder: this._state.sortColumn?.toCaptalize(),
            UsuarioID: this._state.usuarioId,
            Unidade: this._state.unidade,
            acaoID: this._state.acaoID,
            DataHoraInicio: this._state.dataHoraInicio,
            DataHoraFim: this._state.dataHoraFim
        })
            .pipe(
                map(({ results, total }) => ({ localizacaoEventos: results.map(dto => new LocalizacaoEvento(dto)), total })),
                tap(() => this.blockUtil.desbloquear()),
                catchError(errorRequest => {
                    const { error, message } = errorRequest;
                    console.log("ERROR", error?.message || message);
                    return of({ localizacaoEventos: [], total: 0 });
                })
            );
    }
}
