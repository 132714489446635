// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    local: false,
    tokenUrl: "http://hom.id.ms.gov.br/auth/realms/ms/protocol/openid-connect/token",
    apiUrl: "https://hom.gw.sgi.ms.gov.br/k0468/sistemaalerta/v1",
    apiUrlFiscalizacaoVirutalTransito: "https://hom.gw.sgi.ms.gov.br/k0787/fvt-api/v1",
    apiUrlUnidades: "https://hom.gw.sgi.ms.gov.br/k0951/efron-escala-plantao-api/v1",
    apiUrlUnidadesProducao: "https://gw.sgi.ms.gov.br/k0951/efron-escala-plantao-api/v1",
    clientSecret: "c0e26f3a-2db7-4791-b371-451ebe42df6a",
    clientId: "sistemaalerta",
    auth: true,
    efazendaUrl: "https://hom.eservicos.sefaz.ms.gov.br/"
};
