import { CampoComponent } from './campo/campo.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from "src/app/directives/app.directives.module";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
        NgMultiSelectDropDownModule.forRoot(),
        ComponentsModule,
    ],
    declarations: [
        CampoComponent
    ],
    exports: [
        CampoComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComumModule { }
