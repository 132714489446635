import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Usuario } from '../model/app.usuario.model';
import { UtilService } from './app.utils.service';

const TEMPO_MINUTOS: number = 10;
@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private userSubject: BehaviorSubject<Usuario>;
    private useKeyLocalStorage: string = 'CotinAlerta@User';
    private authUrl: string;

    public user: Observable<Usuario>;
    public userLocal: Usuario;

    constructor(
        private router: Router,
        private util: UtilService
    ) {
        this.userLocal = this.getWithExpiry(this.useKeyLocalStorage);
        this.userSubject = new BehaviorSubject<Usuario>(this.userLocal);
        this.user = this.userSubject.asObservable();

        if (environment.local) {
            const usuario = new Usuario({ "usuarioID": 1, "nome": "System", "login": "sys", "perfil": { "nome": "ADMINISTRADOR" } });
            this.setWithExpiry(this.useKeyLocalStorage, usuario, TEMPO_MINUTOS * 10);
            this.userSubject.next(usuario);
        }
    }

    public get $user(): Observable<Usuario> {
        return this.userSubject.asObservable();
    }

    public get userValue(): Usuario {
        return this.userSubject.value;
    }

    public get userLogged(): boolean {
        return !!this.getWithExpiry(this.useKeyLocalStorage);
    }

    public Login(chave: string): Observable<Usuario> {
        this.authUrl = `${environment.apiUrl}/Usuario/login/${chave}`;
        return this.util.Get<Usuario>(this.authUrl)
            .pipe(
                filter(response => !!response),
                map(({ usuario: _usuario }: any) => {
                    const usuario = new Usuario(_usuario);
                    this.setWithExpiry(this.useKeyLocalStorage, usuario, TEMPO_MINUTOS);
                    this.userSubject.next(usuario);
                    return usuario;
                }));
    }

    public Logout() {
        localStorage.removeItem(this.useKeyLocalStorage);
        this.userSubject.next(null);
        // if (!this.router.url.endsWith('login'))
        //     this.router.navigate(['/login']);
        //window.open('','_parent','');
        window.close();
    }

    private setWithExpiry(key: string, usuarioLocal: Usuario, minutes: number) {
        const now = new Date();
        const item = {
            value: usuarioLocal,
            expiry: now.getTime() + (minutes * 1000 * 60),
        };
        localStorage.setItem(key, JSON.stringify(item));
    }

    private getWithExpiry(key: string) {
        const itemStr = localStorage.getItem(key);
        if (!itemStr)
            return null;

        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }

    public GetPermissionbyUser(caminho: string): Observable<ListItem[]> {
        const dados: Subject<ListItem[]> = new BehaviorSubject<ListItem[]>(null);
        const usuario = this.getWithExpiry(this.useKeyLocalStorage);

        if (!usuario)
            return of();

        this.authUrl = `${environment.apiUrl}/Usuario/${usuario.usuarioId}/validar/${caminho}/permissoes`;
        this.util.Get<ListItem[]>(this.authUrl)
            .pipe(
                filter(acoes => !!acoes),
                tap((acoes: any[]) => acoes.orderBy("Nome"))
            )
            .subscribe((acoes: ListItem[]) => dados.next(acoes.map(({ acaoID, nome }) => ({ nome, acaoId: acaoID }))));
        return dados.asObservable();
    }

    public CheckPermission(caminho: string, permissao: number): Observable<boolean> {
        return this.GetPermissionbyUser(caminho)
            .pipe(
                filter(acoes => !!acoes),
                map((acoes: any[]) => acoes.some(({ acaoId }) => acaoId == permissao))
            );
    }

    public PermissionOnModel(url: string) {
        const permissoes = EAcao.toObject();
        var regex = new RegExp('[?&]id(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        const caminho = url.slice(0, results?.index ?? url.length).replace('/', '');
        return this.GetPermissionbyUser(caminho)
            .pipe(
                filter(acoes => acoes != undefined),
                map((acoes: any[]) => {
                    for (const { nome } of acoes)
                        permissoes[nome.toUpperCase()] = true;

                    return permissoes;
                })
            );
    }
}
