<div class="{{class}}">
    <input [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'" class="form-control form-control-sm"
        (blur)="inputBlur($event)" [ngModel]="dateString | date:inputDatetimeFormat" (change)="onInputChange($event)"
        [disabled]="true" [mask]="mask" />

    <div class="input-group-append">
        <button class="btn btn-outline-secondary m-0 py-1 px-3" [ngbPopover]="calendarContent" [disabled]="disabled"
            type="button">
            <fa-icon [icon]="['far', 'calendar']"></fa-icon>
        </button>
    </div>
</div>

<ng-template #calendarContent>
    <div>
        <div *ngIf="!showTimePickerToggle">
            <ngb-datepicker id="dp" #dp name="datepicker" [ngModel]="datetime"
                (ngModelChange)="onDateChange($event, dp)"></ngb-datepicker>
            <button class="btn btn-block btn-outline-secondary" [disabled]="!datetime?.day"
                [ngbPopover]="timePickerContent" type="button" (click)="toggleDateTimeState($event)" *ngIf="selectTime">
                <fa-icon [icon]="['far', 'clock']"></fa-icon>
            </button>
        </div>
        <div *ngIf="showTimePickerToggle">
            <button class="btn btn-block btn-outline-secondary" [ngbPopover]="calendarContent" type="button"
                (click)="toggleDateTimeState($event)">
                <fa-icon [icon]="['far', 'calendar']"></fa-icon>
            </button>
            <div class="mt-auto">
                <ngb-timepicker #tp name="timepicker" [ngModel]="datetime" (ngModelChange)="onTimeChange($event)"
                    [seconds]="seconds" [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep">
                </ngb-timepicker>
            </div>
        </div>
    </div>
</ng-template>