import { ComunService } from 'src/app/services/app.comum.service';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { ExcelService } from 'src/app/services/app.excel.service';
import { PlacaService } from 'src/app/services/app.placa.service';
import { Equipamento } from 'src/app/model/app.equipamentos.model';
import { EUF } from 'src/app/constantes/app.euf.const';
import { Placa } from '../../model/app.placas.dto';
import { ETipoPlaca } from 'src/app/constantes/app.etipoplaca.const';

@Component({
    selector: 'app-consultarplacas',
    templateUrl: './consultarplacas.component.html',
    styleUrls: ['./consultarplacas.component.scss'],
    providers: [
        BlockUtil,
        PlacaService,
        ExcelService
    ]
})
export class ConsultarplacasComponent extends ParmissoesPagina implements OnInit {


    public form: FormGroup;
    public placas$: Observable<Placa[]>;
    public total$: Observable<number>;
    public placa: Placa;
    public buscaFoiRealizada = false;

    constructor(
        private router: Router,
        private toast: ToastUtil,
        private accountService: AccountService,
        private comunService: ComunService,
        private excelService: ExcelService,
        private formBuilder: FormBuilder,
        private blockUtil: BlockUtil,
        public service: PlacaService
    ) {

        super(router.url, accountService);

        this.blockUtil.blockName = "placa";
        this.service.blockUtil = this.blockUtil;


        this.total$ = service.total$;
        this.placas$ = service.placas$
            .pipe(tap(placas => {
                if (placas.length)
                    this.blockUtil.desbloquear();
            }));

        this.form = this.formBuilder.group({
            placa: new FormControl(''),
            dataInicio: new FormControl(null, { validators: [DateTimeValidator] }),
            dataFim: new FormControl(null, { validators: [DateTimeValidator] }),
        }, { updateOn: 'change' });
    }

    get visualizar(): boolean { return !!this.placa; }
    get ehMDFE(): boolean { return this.placa?.tipoPlacaId == ETipoPlaca.MDFE; }
    get ehRPE(): boolean { return this.placa?.tipoPlacaId == ETipoPlaca.RPE; }
    get ehTA(): boolean { return this.placa?.tipoPlacaId == ETipoPlaca.TA; }
    get ehTVF(): boolean { return this.placa?.tipoPlacaId == ETipoPlaca.TVF; }
    get ehRPA(): boolean { return this.placa?.tipoPlacaId == ETipoPlaca.RPA; }
    get consultaRetornouResultado(): boolean {return this.service.consultaRetornouResultado}

    ngOnInit(): void {
    }

    search() {
        this.buscaFoiRealizada = true;
        const placa = this.form.controls.placa.value?.trim();
        const dataInicio = this.form.controls.dataInicio.value?.trim();
        const dataFim = this.form.controls.dataFim.value?.trim();

        this.service.get({ placa, dataInicio, dataFim });
    }

    limpar() {
        this.buscaFoiRealizada = false;
        this.form.controls.placa.patchValue("");
        this.form.controls.dataInicio.patchValue("");
        this.form.controls.dataFim.patchValue("");

        this.placas$ = new Observable<Placa[]>;
    }

    onView(placa: Placa) {
        this.placa = placa;
    }

    exportExcel(): void {
        const jsonData = this.service.placas.map(({
            tipoPlacaNome,
            numero,
            unidadeFiscalNome,
            dataHora
        }) => {
            const novaDataHora = new Date(dataHora);
            return {
                "Tipo": tipoPlacaNome,
                "Número": numero,
                "Unidade Fiscal": unidadeFiscalNome,
                "Data e Hora": novaDataHora.toLocaleString('pt-BR', { timeZone: 'UTC' })
            };
        });
        this.excelService.exportExcel(jsonData, "Placas");
    }

    voltar() {
        this.placa = null;
    }

}

export const DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());

    return isValid ? null : {
        isValid: {
            valid: false
        }
    };
};
