import { Routes } from '@angular/router';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { MasterComponent } from '../../components/master/master.component';
import { ModeloRegraCadastroComponent } from './cadastro/modeloregra.cadastro.component';
import { ModeloRegraEditarComponent } from './editar/modeloregra.editar.component';
import { ModeloRegraComponent } from './modeloregra.component';
import { ModeloRegraVisualizarComponent } from './visualizar/modeloregra.visualizar.component';

export const ModelosRegraComponentRoutes: Routes = [
    {
        path: 'modeloregra',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ModeloRegraComponent
            }
        ]
    },
    {
        path: 'modeloregra/cadastro',
        component: MasterComponent,
        data: { permissao: EAcao.CADASTRAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ModeloRegraCadastroComponent
            }
        ]
    },
    {
        path: 'modeloregra/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ModeloRegraVisualizarComponent
            }
        ]
    },
    {
        path: 'modeloregra/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ModeloRegraEditarComponent
            }
        ]
    }
];
