import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ParametroSaidaCadastroComponent } from './cadastro/parametrosaida.cadastro.component';
import { ParametroSaidaConsultaComponent } from './parametrosaida.component';
import { ParametroSaidaVisualizarComponent } from './visualizar/parametrosaida.visualizar.component';
import { ParametroSaidaEditarComponent } from './editar/parametrosaida.editar.component';
import { DirectivesModule } from "src/app/directives/app.directives.module";
import { ComponentsModule } from "src/app/components/components.module";


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        DirectivesModule,
        ComponentsModule
    ],
    declarations: [
        ParametroSaidaConsultaComponent,
        ParametroSaidaCadastroComponent,
        ParametroSaidaVisualizarComponent,
        ParametroSaidaEditarComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ParametroSaidaModule { }
