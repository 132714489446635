import { Modulo } from 'src/app/model/app.modulo.model';
import { Perfil } from 'src/app/model/app.perfil.model';
import { UntypedFormGroup } from '@angular/forms';
import { Injectable } from "@angular/core";
import { ToastType, ToastUtil } from '../helper/app.toast.utils';
import { ModuloPerfil } from '../model/app.moduloperfil.model';

@Injectable({
  providedIn:'root'
})
export class PerfilValidator{

  private _form: UntypedFormGroup;
  private _messages: any[];
  private _perfil: Perfil;

  constructor(private toast: ToastUtil) {
    this._messages = [];
  }

  get isValid(){return this._messages.length == 0};
  get perfil(){return this._perfil};
  get messages(){return this.messages};

  set set(form:UntypedFormGroup) {
    this._form = form;

    let perfilId = this._form.controls.perfilId?.value;
    let nome = this._form.controls.nome?.value;
    let modulosPerfil = this._form.controls.modulosPerfil?.value;

    this._perfil = {
      perfilId: perfilId,
      nome: nome,
      modulosPerfil: this._configuraModulos(JSON.parse(JSON.stringify(modulosPerfil)))
    };
    this._validate();
  }

  set adicionar(modulos: Modulo[]){
    this._perfil = {
      //modulos: this._configuraModulos(modulos)
    };
    this._messages = [];
    this._validateModulos();
  }

  private _configuraModulos(modulosPerfil: ModuloPerfil[]): ModuloPerfil[]{
    // const teste = modulosPerfil.map(item =>{
    //     const acoes = [...item.acoes];
    //     item.acoes = acoes.map(({acaoId})=> acaoId)
    // })
    // return teste;
    modulosPerfil.forEach((item)=> {
        const acoes = [...item.acoes];
        item.acoes=acoes.map(({acaoId})=>acaoId)
    });
    return modulosPerfil;
  }

  private _validate() {
    const {
      nome
    } = this._perfil;

    this._messages = [];
    if (String.isNullOrEmpty(nome))
        this._messages.push({ type: ToastType.WARNING, message: "Nome é obrigatória!" });
    else if (nome.length > 30)
        this._messages.push({ type: ToastType.WARNING, message: "Nome é muito longa!" });

    this._validateModulos();
  }

  public showMessages() {
    this.toast.showMultiple(this._messages);
  }

  private _validateModulos(){
    // const {
    //   //modulos
    // } = this._perfil;

    // if(!modulos.length)
    //     this._messages.push({ type: ToastType.WARNING, message: "Modulo(s) é obrigatório!" });
    // else if(!modulos.filter(({acoes}) => !!acoes.length).length)
    //     this._messages.push({ type: ToastType.WARNING, message: "Ação(ões) para o modulo(s) é obrigatório!" });
  }

}
