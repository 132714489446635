import { ParametroEntrada, FiltroEntrada } from 'src/app/model';
import { Agrupador } from './app.agrupador.model';
import { Operador } from './app.operador.model';
export class FormDinamico {
    tipoDadoId?: number;
    tipoDadoNome?: string;
    ehValido?: boolean;
    item?: ParametroEntrada | FiltroEntrada;
    entradaId?: number;
    operador?: Operador;
    agrupador?: Agrupador;
    ehUltimo?: boolean;

    constructor(dto: any, entradaId: number = null) {
        this.entradaId = entradaId;
        this.tipoDadoId = dto?.tipoDadoID;
        this.tipoDadoNome = dto?.tipoDadoNome;
        this.item = dto?.item;
        this.operador = dto?.operador;
        this.agrupador = dto?.agrupador;
        this.ehValido = false;
        this.ehUltimo = false;
    }
}
