import { UsuarioVisualizarComponent } from './visualizar/usuario.visualizar.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from "src/app/directives/app.directives.module";
import { UsuarioComponent } from "./usuario.component";
import { UsuarioEditarComponent } from './editar/usuario.editar.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BlockUIModule } from 'ng-block-ui';
import { ComponentsModule } from 'src/app/components/components.module';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        DirectivesModule,
        NgMultiSelectDropDownModule.forRoot(),
        BlockUIModule.forRoot(),
        ComponentsModule
    ],
    declarations: [
        UsuarioComponent,
        UsuarioVisualizarComponent,
        UsuarioEditarComponent

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsuarioModule { }
