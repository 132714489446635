<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body ">
                        <div class="form-horizontal mx-3 ">
                            <form class="form-horizontal " [formGroup]="form " (ngSubmit)="onSubmit() ">
                                <div class="form-body " *ngIf="data ">
                                    <h3 class="mb-0">Alerta</h3>
                                    <div class="bg-secondary py-1 pl-2 rounded-3 mb-1">
                                        <span class="text-white text-uppercase">Dados do Alerta</span>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex flex-row col align-items-center">
                                            <label class="control-label mr-2 mb-0">Alerta Nº: </label>
                                            <span class="mr-5">{{data.alertaId}}</span>
                                            <span class="badge badge-success opacity-50"
                                                *ngIf="estaFinalizado">Finalizado</span>
                                            <span class="badge badge-warning opacity-50" *ngIf="estaEmAtendimento">Em
                                                Atendimento</span>
                                            <span class="badge badge-dark opacity-50"
                                                *ngIf="estaDevolvido">Devolvido</span>
                                            <span class="badge badge-secondary opacity-50"
                                                *ngIf="estaDesativado">Desativado</span>
                                            <span class="badge badge-danger opacity-50"
                                                *ngIf="estaPendente">Pendente</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex flex-row col">
                                            <label class="control-label mr-2 mb-0">Alerta Gerado Em: </label>
                                            <span>{{data.dataHoraInclusao | date: 'dd/MM/yyyy'}} às
                                                {{data.dataHoraInclusao
                                                | date: 'hh:mm'}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex flex-row col">
                                            <label class="control-label mr-2 mb-0">Tipo Alerta: </label>
                                            <span>{{data.descricaoTipoAlerta}}</span>
                                        </div>
                                    </div>
                                    <div class="bg-secondary py-1 pl-2 rounded-3 mb-1 mt-2">
                                        <span class="text-white text-uppercase">INFORMAÇÕES DE SAÍDA</span>
                                    </div>
                                    <div *ngFor="let parametroSaida of data.alertasParametroSaida">
                                        <ng-container class="d-flex flex-column" *ngIf="parametroSaida.compoeVisualizacao">
                                            <hr class="linha-novo-bloco-de-informacoes"
                                                *ngIf="parametroSaida.edadosDoNovoHash" />
                                            <div class="d-flex flex-row row col" *ngIf="parametroSaida.hashNIFI">
                                                <label class="control-label mr-2 mb-0">Data da Atualização: </label>
                                                <span>{{parametroSaida.dataHoraInclusao | date: 'dd/MM/yyyy'}} às
                                                    {{parametroSaida.dataHoraInclusao
                                                    | date: 'hh:mm'}}</span>
                                            </div>
                                            <br *ngIf="parametroSaida.hashNIFI"/>
                                            <div class="d-flex flex-row row col">
                                                <label class="control-label mr-2 mb-0">Descrição: </label>
                                                <span>{{parametroSaida.nome}}</span>
                                            </div>
                                            <div class="d-flex flex-row row col pb-1">
                                                <label class="control-label mr-2 mb-0">Informação: </label>
                                                <span class="">{{parametroSaida.valor}}</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="bg-secondary py-1 pl-2 rounded-3 mb-1 mt-2">
                                        <span class="text-white text-uppercase">Dados do Evento</span>
                                    </div>
                                    <div class="d-flex flex-column row mb-2"
                                        *ngFor="let evento of data.alertasEventoAtualizacaoDfe">
                                        <div class="d-flex flex-row row col">
                                            <div class="d-flex flex-row col">
                                                <label class="control-label mr-2 mb-0">Evento: </label>
                                                <span>{{evento.numeroEventoDfe}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row row col">
                                            <div class="d-flex flex-row col">
                                                <label class="control-label mr-2 mb-0">Data do Evento: </label>
                                                <span>{{evento.dataHoraInclusao | date: 'dd/MM/yyyy'}} às
                                                    {{evento.dataHoraInclusao
                                                    | date: 'hh:mm' }}</span>
                                                <span *ngIf="evento.fusoHorario" style="margin-left: 4px;">no fuso horário: {{evento.fusoHorario}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row row col">
                                            <div class="d-flex flex-row col">
                                                <label class="control-label mr-2 mb-0">Informações: </label>
                                                <span>{{evento.descricaoEventoDfe}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row row col">
                                            <div class="d-flex flex-row col">
                                                <label class="control-label mr-2 mb-0">Data de Passagem: </label>
                                                <span>{{evento.dataHoraPassagem | date: 'dd/MM/yyyy'}}</span>
                                                <span *ngIf="evento.dataHoraPassagem">às</span>
                                                <span>{{evento.dataHoraPassagem | date: 'hh:mm' }} </span>
                                                <span *ngIf="evento.dataHoraPassagemFusoHorario" style="margin-left: 4px;">no fuso horário: {{evento.dataHoraPassagemFusoHorario}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row row col">
                                            <div class="d-flex flex-row col">
                                                <label class="control-label mr-2 mb-0">Sentido: </label>
                                                <span>{{evento.sentido}}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row row col">
                                            <div class="d-flex flex-row col">
                                                <label class="control-label mr-2 mb-0">Local: </label>
                                                <span>{{evento.local}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bg-secondary py-1 pl-2 rounded-3 mb-1 mt-2">
                                        <span class="text-white text-uppercase">Dados da Regra</span>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex flex-row col">
                                            <label class="control-label mr-2 mb-0">Regra Nº </label>
                                            <span class="mr-2">{{regra.regraId}}</span>
                                            <span class="mr-2"> de {{regra.dataHoraInicioVigencia | date: 'dd/MM/yyyy'}}
                                                às
                                                {{
                                                regra.dataHoraInicioVigencia | date: 'hh:mm' }}</span>
                                            <span class=""> até {{regra.dataHoraFimVigencia | date: 'dd/MM/yyyy'}} às {{
                                                regra.dataHoraFimVigencia | date: 'hh:mm' }}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex flex-row col">
                                            <label class="control-label mr-2 mb-0">Inserida por </label>
                                            <span class="mr-2">{{regra.usuarioNomeInclusao}}</span>
                                            <span class="mr-2"> em {{regra.dataHoraInclusao | date: 'dd/MM/yyyy'}} às {{
                                                regra.dataHoraInclusao | date: 'hh:mm' }}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex flex-row col">
                                            <label class="control-label mr-2 mb-0">Condicionante: </label>
                                            <span class="mr-2">{{regra.modeloRegra.condicionanteDescricao}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex flex-row col">
                                            <label class="control-label mr-2 mb-0">Unidade de Geração Regra: </label>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="d-flex flex-column col">
                                            <label class="control-label mr-2 mb-0">justificativa de inserção da Regra:
                                            </label>
                                            <span>{{regra.justificativa}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group ">
                                                <label class="control-label">Parâmetros de Entrada</label>
                                                <ul>
                                                    <li *ngFor="let parametroEntrada of parametrosEntrada">
                                                        <div class="row" *ngIf="parametroEntrada.valor">
                                                            <div class="col-12">
                                                                {{ parametroEntrada.parametroEntradaNome }}
                                                            </div>
                                                            <div class="col-12"
                                                                [ngSwitch]="parametroEntrada.tipoDadoNome">
                                                                <div *ngSwitchCase="'CPF'">
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor | cpf)">
                                                                            <span>{{item}}</span>                                                                        
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchCase="'CNPJ'">
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor | cnpj)">
                                                                            <span>{{item}}</span>                                                                        
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchCase="'INSCRIÇÃO ESTADUAL'">
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor | inscricaoEstadual)">
                                                                            <span>{{item}}</span>                                                                        
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchCase="'DATAHORA'">
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor | date: 'dd/MM/yyyy')">
                                                                            <span>{{item}}</span>                                                                        
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchCase="'EQUIPAMENTOS'">
                                                                    <ul>
                                                                        <li>{{parametroEntrada?.operadorNome}}</li>
                                                                        <li><strong>Local:</strong>{{parametroEntrada.localNome}}</li>
                                                                        <li><strong>Sentido:</strong>{{parametroEntrada.sentidoDaLeitura}}</li>
                                                                        <li><strong>Equipamento:</strong>{{parametroEntrada.valor}}</li>
                                                                        <li *ngIf="parametroEntrada.agrupadorNome">{{parametroEntrada.agrupadorNome}}</li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchDefault>
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor)">
                                                                            <span>{{item}}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group ">
                                                <label class="control-label">Filtros de Entrada</label>
                                                <ul>
                                                    <li *ngFor="let filtroEntrada of filtrosEntrada">
                                                        <div class="row" *ngIf="filtroEntrada.valor">
                                                            <div class="col-12">
                                                                {{ filtroEntrada.filtroEntradaNome }}
                                                            </div>
                                                            <div class="col-12" [ngSwitch]="filtroEntrada.tipoDadoNome">
                                                                <div *ngSwitchCase="'CPF'">
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor | cpf)">
                                                                            <span>{{item}}</span>                                                                        
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchCase="'CNPJ'">
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor | cnpj)">
                                                                            <span>{{item}}</span>                                                                        
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchCase="'INSCRIÇÃO ESTADUAL'">
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor | inscricaoEstadual)">
                                                                            <span>{{item}}</span>                                                                        
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchCase="'DATAHORA'">
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor | date: 'dd/MM/yyyy')">
                                                                            <span>{{item}}</span>                                                                        
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchCase="'EQUIPAMENTOS'">
                                                                    <ul>
                                                                        <li>{{filtroEntrada?.operadorNome}}</li>
                                                                        <li><strong>Local:</strong>{{filtroEntrada.localNome}}</li>
                                                                        <li><strong>Sentido:</strong>{{filtroEntrada.sentidoDaLeitura}}</li>
                                                                        <li><strong>Equipamento:</strong>{{filtroEntrada.valor}}</li>
                                                                        <li *ngIf="filtroEntrada.agrupadorNome">{{filtroEntrada.agrupadorNome}}</li>
                                                                    </ul>
                                                                </div>
                                                                <div *ngSwitchDefault>
                                                                    <ul>
                                                                        <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor)">
                                                                            <span>{{item}}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="estaEmAtendimento || estaVinculado || estaPendente">
                                        <div class="form-group ">
                                            <label class="col-md-5 control-label ">Ação Fiscal</label>
                                            <div class="col-md-12 main-card">
                                                <select class="form-control form-control-sm"
                                                    formControlName="tipoLiberacao" (change)="onClear()">
                                                    <option value>Selecione...</option>
                                                    <option *ngFor="let tipoLiberacao of eTipoLiberacao"
                                                        [ngValue]="tipoLiberacao">
                                                        {{tipoLiberacao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="mostrarNumeroDocumento">
                                            <label class="col-md-5 control-label">Número do Documento</label>
                                            <div class="col-md-12">
                                                <input type="text " class="form-control input-lg "
                                                    placeholder="Digite aqui o Número do Documento... "
                                                    formControlName="numeroDocumento">
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="mostrarMotivo">
                                            <label class="col-md-5 control-label">Motivo</label>
                                            <div class="col-md-12">
                                                <textarea minlength="20" maxlength="10000"
                                                    placeholder="Digite aqui o Motivo..." class="form-control" rows="3"
                                                    formControlName="motivo"></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="mostrarSerie">
                                            <label class="col-md-5 control-label">Série</label>
                                            <div class="col-md-12">
                                                <input type="text " class="form-control input-lg "
                                                    placeholder="Digite aqui a Série... " formControlName="serie">
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="mostrarUnidade">
                                            <label class="col-md-5 control-label">Unidade</label>
                                            <div class="col-md-12">
                                                <input type="text " class="form-control input-lg"
                                                    placeholder="Digite aqui a Unidade... " formControlName="unidade">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer ">
                                    <button *ngIf="estaEmAtendimento || estaVinculado || estaPendente" type="button"
                                        class="btn btn-sm btn-primary" (click)="onConfirm()">Confirmar</button>
                                    <button *ngIf="estaEmAtendimento || estaVinculado || estaPendente" type="submit"
                                        class="btn btn-sm btn-primary">Finalizar</button>
                                    <button *ngIf="estaEmAtendimento || estaVinculado || estaPendente" type="button"
                                        class="btn btn-sm btn-primary" (click)="onFinalizeAndRules()">Finalizar Alerta e
                                        Regra</button>
                                    <button *ngIf="estaEmAtendimento || estaVinculado || estaPendente" type="button"
                                        class="btn btn-sm btn-primary" (click)="onGiveBack()">Devolver para a Fila de
                                        Pendentes</button>
                                    <button type="button" class="btn btn-sm default " (click)="voltar()">Voltar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>