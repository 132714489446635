import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, retry, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Token } from '../model/app.token.model';


@Injectable()
export class AuthenticationProvider {
    private token: Token;

    constructor(private http: HttpClient) { }

    GetToken(): Observable<Token> {
        if (this.token)
            return of(this.token);

        const clientSecret = `client_secret=${environment.clientSecret}`;
        const clientId = `client_id=${environment.clientId}`;
        const grantType = 'grant_type=client_credentials';
        const data = `${grantType}&${clientId}&${clientSecret}`;
        const headers = new HttpHeaders({
            'content-type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json, text/plain, */*'
        });

        return this.http.post(environment.tokenUrl, data, { headers, responseType: "json" })
            .pipe(
                retry(2),
                map((token: Token) => {
                    this.token = token;
                    setTimeout(() => { this.token = null }, this.token.expires_in * 1000);
                    return token;
                }),
                catchError((erro, souce) => {
                    console.log(erro);
                    return of(null);
                })
            );

    }
}
