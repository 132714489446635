import { ModuloPerfil } from './app.moduloperfil.model';
export class Perfil {

    perfilId?: number;
    nome?: string;
    usuarioIdInclusao?: number;
    usuarioNomeInclusao?: string;
    usuarioIdAlteracao?: number;
    usuarioNomeAlteracao?: string;
    dataHoraInclusao?: Date;
    situacaoId?: number;
    situacaoNome?: string;
    dataHoraAlteracao?: Date;
    modulosPerfil?: ModuloPerfil[];

    constructor(dto: any) {
        this.perfilId = dto?.perfilID;
        this.nome = dto?.nome;
        this.usuarioIdInclusao = dto?.usuarioIDInclusao;
        this.usuarioNomeInclusao = dto?.usuarioNomeInclusao;
        this.usuarioIdAlteracao = dto?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = dto?.usuarioNomeAlteracao;
        this.dataHoraInclusao = dto?.dataHoraInclusao;
        this.dataHoraAlteracao = dto?.dataHoraAlteracao;
        this.situacaoId = dto?.situacaoID;
        this.situacaoNome = dto?.situacaoNome;
        this.modulosPerfil = dto?.modulosPerfil?.map(mod => new ModuloPerfil(mod));
    }
}
