<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card" *blockUI="'localizacaoEventos'">
                    <div class="card-body">
                        <form [formGroup]="form" (ngSubmit)="search()">
                            <h5 class="mb-0">Seção de Localização de Eventos</h5>
                            <hr class="mt-2" />
                            <div class="d-flex flex-column">
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-column col">
                                        <label>Usuário</label>
                                        <ng-multiselect-dropdown class="form-control form-control-sm p-0 font-14"
                                            placeholder="Selecione Usuário" [settings]="configuracoesUsuario"
                                            [data]="usuarios" formControlName="usuario">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="d-flex flex-column col">
                                        <label>Evento Localização</label>
                                        <ng-multiselect-dropdown class="form-control form-control-sm p-0 font-14"
                                            placeholder="Selecione Evento Localização" [settings]="configuracoesAcao"
                                            [data]="acoes" formControlName="acao">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-column col">
                                        <label>Data Inicial</label>
                                        <app-date-time-picker formControlName="dataHoraInicio"
                                            inputDatetimeFormat="dd/MM/yyyy" noSelectTime></app-date-time-picker>
                                    </div>
                                    <div class="d-flex flex-column col">
                                        <label>Data Final</label>
                                        <app-date-time-picker inputDatetimeFormat="dd/MM/yyyy" noSelectTime
                                            formControlName="dataHoraFim"></app-date-time-picker>
                                    </div>
                                </div>
                                <div class="d-flex flex-row btn-pesquisar" style="padding-left: 14px;">
                                    <div class="input-group mb-3 justify-content-start">
                                        <button type="submit"
                                            class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                            style="gap:0.5rem">Pesquisar<i class="fa-solid fa-magnifying-glass"></i>
                                        </button>
                                        <button type="reset"
                                            class="btn btn-default btn-sm my-0 d-flex align-items-center"
                                            style="gap:0.5rem" (click)="clear()">Limpar<i class="fa-solid fa-times"></i>
                                        </button>
                                    </div>
                                    <div class="input-group mb-3 justify-content-end" style="padding-right: 14px;">
                                        <button type="button"
                                            class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                            style="gap: 0.5rem" (click)="exportExcel()">
                                            Excel <i class="fas fa-file-csv"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive px-3">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="id" (sort)="onSort($event)">ID</th>
                                    <th scope="col" sortable="usuario.Nome" (sort)="onSort($event)">Usuário</th>
                                    <th scope="col" sortable="acao" (sort)="onSort($event)">Evento Localização</th>
                                    <th scope="col">Regra</th>
                                    <th scope="col">Alerta</th>
                                    <th scope="col" sortable="dataHora" (sort)="onSort($event)">Data e Hora Evento</th>
                                    <th scope="col" class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of localizacaoEventos$ | async">
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.id" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.usuario.nome" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.acaoNome" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.regra?.regraId" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.alerta?.alertaId" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.dataHora"
                                            [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <div class="d-flex justify-content-center" style="gap: 1rem;">
                                            <button
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onView(data)" title="Visualizar" style="gap:0.5rem" *ngIf="PodeVisualizar">
                                                <i class="fa-solid fa-eye"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize" [maxSize]="10">
                            </ngb-pagination>

                            <select class="form-select form-control-sm" style="width: auto" name="pageSize"
                                [(ngModel)]="service.pageSize">
                                <option [ngValue]="10">10 Itens</option>
                                <option [ngValue]="15">15 Itens</option>
                                <option [ngValue]="20">20 Itens</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>