import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { LocalizacaomapaComponent } from "./localizacaomapa.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DirectivesModule } from "src/app/directives/app.directives.module";
import { LocalizacaoMapaService } from "./localizacaomapa.service";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
    ],
    declarations:[LocalizacaomapaComponent],
    schemas:[CUSTOM_ELEMENTS_SCHEMA],
    providers:[LocalizacaoMapaService]
})
export class LocalizacaoMapaModule {}