import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'inscricaoEstadual' })
export class InscricaoEstadualPipe implements PipeTransform {
    transform(value: string, args?: any): any {
        const INICIO_IE_MS = '28';

        value = value.replace(/\D/g, '');
        if (value.substring(0, 2) != INICIO_IE_MS)
            return value;

        if (value.length > 9)
            return value.substring(0, 14);
        else {
            value = value.substring(0, 9);
            switch (value.length) {
                case 3:
                case 4:
                case 5:
                    value = value.replace(/(\d{2})(\d+)/, '$1.$2')
                    break;
                case 6:
                case 7:
                case 8:
                    value = value.replace(/(\d{2})(\d{3})(\d+)/, '$1.$2.$3')
                    break;
                case 9:
                case 10:
                case 11:
                case 12:
                    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d+)/, '$1.$2.$3-$4')
                    break;
                default:
                    return value;
            }
            return value;
        }
    }
}
