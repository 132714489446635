<form [formGroup]="form">
  <h2 class="fs-title text-center">Descreva sua Justificativa</h2>
  <div class="d-flex justify-content-center flex-column my-5">
    <div class="form-group d-flex justify-content-center">
      <div class="col-md-12 main-card d-flex justify-content-left flex-column">
        <label for="exampleFormControlTextarea1">Justificativa</label>
        <div class="textarea-wrapper">
          <textarea
            maxlength="300"
            placeholder="Digite sua justificativa..."
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            formControlName="justificativa"
          ></textarea>
          <!-- <span class="remaning">{{ this.form.controls.justificativa?.value?.length }}</span> -->
        </div>
      </div>
    </div>
  </div>
</form>
