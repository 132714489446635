export class Dashboard {
    qtdeAlertasPendentes: number;
    qtdeAlertasEmAtendimento: number;
    qtdeAlertasFinalizados: number;
    qtdeAlertasVinculados: number;
    totalQtdeRegras: number;
    totalQtdeAlertas: number;
    regrasSemAlertas: number;

    constructor(dashboardDto: any) {
        this.qtdeAlertasPendentes = dashboardDto?.qtdeAlertasPendentes;
        this.qtdeAlertasEmAtendimento = dashboardDto?.qtdeAlertasEmAtendimento;
        this.qtdeAlertasFinalizados = dashboardDto?.qtdeAlertasFinalizados;
        this.qtdeAlertasVinculados = dashboardDto?.qtdeAlertasVinculados;
        this.totalQtdeRegras = dashboardDto?.totalQtdeRegras;
        this.totalQtdeAlertas = dashboardDto?.totalQtdeAlertas;
        this.regrasSemAlertas = dashboardDto?.regrasSemAlertas;
    }
}
