import { FiltroEntradaService } from 'src/app/services/app.filtroentrada.service';
import { FiltroEntradaValidator } from 'src/app/validators/filtroentrada.validator';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { ComunService } from 'src/app/services/app.comum.service';

@Component({
    selector: 'app-entrada-editar',
    templateUrl: './filtroentrada.editar.component.html',
    styleUrls: ['./filtroentrada.editar.component.scss']
})
export class FiltroEntradaEditarComponent implements OnInit {

    public form: FormGroup;
    public tipoDados: Observable<ListItem[]>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private validator: FiltroEntradaValidator,
        private blockUtil: BlockUtil,
        public service: FiltroEntradaService,
        private toast: ToastUtil,
        private comum: ComunService
    ) {
        this.form = this.formBuilder.group({
            filtroEntradaId: new FormControl(null),
            nome: new FormControl(null),
            tipoDado: new FormControl(''),
            caminhoMapeado: new FormControl(null),
        });

        const filtroEntradaId = +this.route.snapshot.queryParamMap.get("filtroEntradaId");
        this.service.getById(filtroEntradaId)
            .subscribe((data: any) => {
                if (!data)
                    return;
                const { filtroEntradaID: filtroEntradaId, nome, caminhoMapeado } = data;
                this.tipoDados = this.comum.ObterTipoDado()
                    .pipe(
                        tap((tiposDado: any) => {

                            if (!tiposDado)
                                return;
                            const tipoDado = tiposDado.find(({ tipoDadoID }) => tipoDadoID == data.tipoDadoID);
                            this.form.patchValue({
                                filtroEntradaId,
                                nome,
                                tipoDado,
                                caminhoMapeado
                            });
                        })
                    );
            });
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.blockUtil.bloquear();

        this.validator.set = this.form;

        if (!this.validator.isValid) {
            this.blockUtil.desbloquear();
            this.validator.showMessages();
        } else {
            this.service.put(this.validator.filtroEntrada)
                .subscribe({
                    next: (data: string) => {
                        this.toast.showSuccess(data);
                        this.blockUtil.desbloquear();
                        this.router.navigate(['filtroentrada']);
                    },
                    error: (erro: string) => {
                        this.toast.showDanger(erro);
                        this.blockUtil.desbloquear();
                    }
                })
        }
    }

    cancel() {
        this.router.navigate(['filtroentrada']);
    }

}
