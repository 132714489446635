import { ComunService } from 'src/app/services/app.comum.service';
import { Router } from '@angular/router';
import { Observable, combineLatest, filter, take } from 'rxjs';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/app.sortable.directive';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { Usuario } from 'src/app/model/app.usuario.model';
import { UsuarioService } from 'src/app/services/app.usuario.service';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { LocalizacaoEventoService } from 'src/app/services/app.localizacaoevento.service';
import { LocalizacaoEvento, LocalizacaoEventoSearch } from 'src/app/model/app.localizacaoevento.model';
import { Acao } from 'src/app/model/app.acao.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ExcelService } from 'src/app/services/app.excel.service';

@Component({
    selector: 'app-localizacao-evento',
    templateUrl: './localizacaoevento.component.html',
    styleUrls: ['./localizacaoevento.component.scss'],
    providers: [
        BlockUtil,
        ExcelService
    ]
})
export class LocalizacaoEventoComponent extends ParmissoesPagina implements OnInit {
    public isMobile: boolean = false;
    public localizacaoEventos$: Observable<LocalizacaoEvento[]>;
    public total$: Observable<number>;
    public form: FormGroup;
    public usuarios: Usuario[] = [];
    public acoes: Acao[] = [];

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<LocalizacaoEvento>>;

    public configuracoesPadrao: IDropdownSettings = {
        singleSelection: true,
        allowSearchFilter: true,
    };

    public configuracoesUsuario: IDropdownSettings = {
        ...this.configuracoesPadrao,
        textField: 'nome',
        idField: 'usuarioId'
    };

    public configuracoesAcao: IDropdownSettings = {
        ...this.configuracoesPadrao,
        textField: 'nome',
        idField: 'acaoId'
    };

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public service: LocalizacaoEventoService,
        private toast: ToastUtil,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private comum: ComunService,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService,
        private excelService: ExcelService
    ) {
        super(router.url, accountService);
        this.service.bloqueioTela = "localizacaoEventos";
        this.localizacaoEventos$ = service.localizacaoEventos$;
        this.total$ = service.total$;

        this.form = this.formBuilder.group({
            usuario: new FormControl([]),
            unidade: new FormControl(''),
            acao: new FormControl([]),
            dataHoraInicio: new FormControl(null, { validators: [DateTimeValidator] }),
            dataHoraFim: new FormControl(null, { validators: [DateTimeValidator] })
        }, { updateOn: 'change' });

        combineLatest({
            usuarios: this.comum.ObterUsuariosAtivos()
                .pipe(filter(usuarios => !!usuarios)),
            acoes: this.comum.ObterAcoes()
                .pipe(filter(acoes => !!acoes)),
            permissoes: this.accountService.PermissionOnModel(router.url)
                .pipe(filter(permissoes => !!permissoes))
        })
            .pipe(
                take(1),
            )
            .subscribe(({ usuarios, acoes, permissoes }) => {
                this.usuarios = usuarios;
                this.acoes = acoes;
                this.permissoes = permissoes;
            });

        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        });
    }

    ngOnInit(): void {
        this.service.get({});
    }

    onSort({ column, direction }: SortEvent<LocalizacaoEventoSearch>) {
        this.headers.forEach(header => {
            if (header.sortable !== column)
                header.direction = '';
        });

        this.service.getOrderByDirection(column, direction);
    }

    search() {
        const usuarioId = this.form.controls.usuario.value?.at(0)?.usuarioId;
        const unidade = this.form.controls.unidade.value?.trim();
        const acaoID = this.form.controls.acao.value?.at(0)?.acaoId;
        const dataHoraInicio = this.form.controls.dataHoraInicio.value;
        const dataHoraFim = this.form.controls.dataHoraFim.value;
        this.service.get({ usuarioId, unidade, acaoID, dataHoraInicio, dataHoraFim });
    }

    clear() {
        this.service.get({});
    }

    onView({ id }: any) {
        this.router.navigate(['localizacaoevento/visualizar'], { queryParams: { id } });
    }

    exportExcel(): void {
        const jsonData = this.service.localizacaoEventos.map(({
            id,
            acaoNome,
            usuario,
            dataHora,
            regra,
            alerta
        }) => {
            return {
                "ID": id,
                "Usuário": usuario?.nome,
                "Evento Localização": acaoNome,
                "Regra": regra?.regraId,
                "Alerta": alerta?.alertaId,
                "Data e Hora Evento": dataHora
            }
        });

        this.excelService.exportExcel(jsonData, "LocalizacaoEventos");
    }
}

export const DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());

    return isValid ? null : {
        isValid: {
            valid: false
        }
    };
};
