import { PlacaPipe } from './app.placa.pipe';
import { NgModule } from '@angular/core';
import { CnpjPipe } from './app.cnpj.pipe';
import { CpfPipe } from './app.cpf.pipe';
import { CpfCnpjPipe } from './app.cpfcnpj.pipe';
import { DateTimePipe } from './app.datetime.pipe';
import { InscricaoEstadualPipe } from './app.inscricaoestadual.pipe';
import { TruncateWithLineBreaksPipe } from './app.stringlimit.pipe';

@NgModule({
    imports: [],
    declarations: [CpfCnpjPipe, DateTimePipe, CpfPipe, CnpjPipe, PlacaPipe, InscricaoEstadualPipe, TruncateWithLineBreaksPipe],
    exports: [CpfCnpjPipe, DateTimePipe, CpfPipe, CnpjPipe, PlacaPipe, InscricaoEstadualPipe, TruncateWithLineBreaksPipe]
})
export class PipesModule { }
