import { Routes } from '@angular/router';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { MasterComponent } from '../../components/master/master.component';
import { LocalizacaoEventoComponent } from './localizacaoevento.component';
import { LocalizacaoEventoVisualizarComponent } from './visualizar/localizacaoevento.visualizar.component';

export const LocalizacaoEventoRoutes: Routes = [
    {
        path: 'localizacaoevento',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: LocalizacaoEventoComponent
            }
        ]
    },
    {
        path: 'localizacaoevento/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: LocalizacaoEventoVisualizarComponent
            }
        ]
    }
];
