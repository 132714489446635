<form [formGroup]="form">
    <div class="main-card">
        <div class="input-group">
            <ng-container *ngIf="!ehEquipamentos">
                <span class="input-group-text" id="basic-addon1">{{ item.nome }}</span>
                <select class="form-control form-control-sm col-md-1 px-2 text-center" formControlName="operador">
                    <option *ngFor="let operador of operadores" [ngValue]="operador">
                        {{ operador.nome }}
                    </option>
                </select>
            </ng-container>
            <input class="form-control input-lg" *ngIf="ehCpf" formControlName="campo" [onkeypress]="formatarValor()" 
                maxlength="14" placeholder="000.000.000-00" />
            <input class="form-control input-lg" *ngIf="ehTexo" formControlName="campo" type="text" [onkeypress]="formatarValor()"
                placeholder="Valor" />
            <input class="form-control input-lg" *ngIf="ehInteiro" formControlName="campo" type="text"
                [onkeypress]="formatarValor()" placeholder="Valor" />
            <input class="form-control input-lg" *ngIf="ehDecimal" formControlName="campo" type="text"
                [onkeypress]="formatarValor()" placeholder="Valor" />
            <app-date-time-picker internalClass="d-flex flex-row h-100" *ngIf="ehDatahora"
                inputDatetimeFormat="dd/MM/yyyy" noSelectTime formControlName="campo"></app-date-time-picker>
            <!-- <input class="form-control input-lg" formControlName="campo" type="text"
                placeholder="Valor" /> -->
            <input class="form-control input-lg" *ngIf="ehCnpj" formControlName="campo" type="text" maxlength="18"
                [onkeypress]="formatarValor()" placeholder="00.000.000/0000-00" />
            <input class="form-control input-lg" *ngIf="ehIncricaoEstadual" formControlName="campo"
                [onkeypress]="formatarValor()" type="text" />
            <input class="form-control input-lg" *ngIf="ehPlaca" formControlName="campo" maxlength="7" type="text"
                [onkeypress]="formatarValor()"  placeholder="Valor" />
            <select class="form-control form-control-sm" *ngIf="ehUF" formControlName="campo">
                <option value>Selecione...</option>
                <option *ngFor="let uf of ufs" [ngValue]="uf.siglaUf">
                    {{ uf.nome }}
                </option>
            </select>
            <ng-container *ngIf="ehEquipamentos">
                <div class="d-flex flex-row justify-content-between mb-2 w-100">
                    <div class="d-flex flex-row col p-0">
                        <span class="input-group-text">Local</span>
                        <ng-multiselect-dropdown class="form-control form-control-sm p-0"
                            placeholder="Selecione o Local" [settings]="configLocal" [data]="locais"
                            formControlName="localId" (onSelect)="onChangeLocal()" (onDeSelect)="onChangeLocal()">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="d-flex flex-row col p-0">
                        <span class="input-group-text">Sentido</span>
                        <select class="form-control form-control-sm" formControlName="sentidoDaLeitura"
                            [attr.disabled]="!localIdSelecionado ? '' : null" (change)="onChangeSentido()">
                            <option value>Selecione...</option>
                            <option *ngFor="let sentido of sentidosDaLeitura" [ngValue]="sentido.nome">
                                {{ sentido.nome }}
                            </option>
                        </select>
                    </div>
                </div>
                <span class="input-group-text">{{ item.nome }}</span>
                <ng-multiselect-dropdown class="form-control form-control-sm p-0" placeholder="Selecione o Equipamento"
                    [settings]="configCampo" [data]="equipamentosFiltrados" formControlName="campo"
                    [attr.disabled]="!sentidoDaLeituraSelecionado ? '' : null">
                </ng-multiselect-dropdown>
            </ng-container>
            <select class="form-control form-control-sm col-md-1 px-1 text-center" *ngIf="!ehUltimo"
                formControlName="agrupador">
                <option *ngFor="let agrupador of agrupadores" [ngValue]="agrupador">
                    {{ agrupador.nome }}
                </option>
            </select>
            <button class="btn btn-danger my-0" type="button" (click)="onRemoveFieldInForm()" *ngIf="!ehObrigatorio">
                <i class="fa-solid fa-minus"></i>
            </button>
        </div>
    </div>
</form>