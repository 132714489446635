import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PerfilComponent } from '../perfil/perfil.component';
import { DirectivesModule } from "src/app/directives/app.directives.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { PerfilCadastroComponent } from './cadastro/perfil.cadastro.component';
import { BlockUIModule } from 'ng-block-ui';
import { PerfilEditarComponent } from './editar/perfil.editar.component';
import { PerfilVisualizarComponent } from './visualizar/perfil.visualizar.component';
import { ComponentsModule } from "src/app/components/components.module";



@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
        NgMultiSelectDropDownModule.forRoot(),
        BlockUIModule.forRoot(),
        ComponentsModule
    ],
    declarations: [
        PerfilComponent,
        PerfilCadastroComponent,
        PerfilEditarComponent,
        PerfilVisualizarComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PerfilModule { }
