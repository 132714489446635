export class Modulo {

    moduloId?: number;
    grupoId?: number;
    grupoNome?: string;
    titulo?: string;
    caminho?: string;
    icone?: string | any;
    ordem?: number;
    dataHoraInclusao?: Date;
    dataHoraAlteracao?: Date;
    usuarioIDInclusao?: number;
    usuarioNomeInclusao?: string;
    usuarioIDAlteracao?: number;
    usuarioNomeAlteracao?: string;
    situacaoId?: number;
    situacaoNome?: string;
    acoes?: any[];
    estaAtivo?: boolean = false;

    constructor(moduloDto: any) {
        this.moduloId = moduloDto?.moduloID ?? moduloDto?.moduloId;
        this.grupoId = moduloDto?.grupoID;
        this.grupoNome = moduloDto?.grupoNome?.toLowerCase().toCaptalize();
        this.titulo = moduloDto?.titulo;
        this.caminho = `/${moduloDto?.caminho}`;
        this.icone = moduloDto?.icone;
        this.ordem = moduloDto?.ordem;
        this.dataHoraInclusao = moduloDto?.dataHoraInclusao;
        this.dataHoraAlteracao = moduloDto?.dataHoraAlteracao;
        this.usuarioIDInclusao = moduloDto?.usuarioIDInclusao;
        this.usuarioNomeInclusao = moduloDto?.usuarioNomeInclusao;
        this.usuarioIDAlteracao = moduloDto?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = moduloDto?.usuarioNomeAlteracao;
        this.situacaoId = moduloDto?.situacaoID;
        this.situacaoNome = moduloDto?.situacaoNome;
    }
}
