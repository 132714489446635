<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body ">
                        <div class="form-horizontal mx-3 ">
                            <div class="form-body " *ngIf="data ">
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Usuário</label>
                                            <p>{{data.usuario.nome}}</p>
                                        </div>                                        
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group ">
                                            <label class="control-label">Regra</label>
                                            <p>{{data.regra?.regraId}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label class="control-label">Evento Localização</label>
                                            <p>{{data.acaoNome}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group ">
                                            <label class="control-label">Alerta</label>
                                            <p>{{data.alerta?.alertaId}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Data/Hora Evento</label>
                                            <p>{{data.dataHora}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Descrição</label>
                                            <p>{{data.regra?.modeloRegra.condicionante.descricao}}</p>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-sm default " (click)="voltar() ">Voltar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>