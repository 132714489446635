import { UtilService } from './app.utils.service';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

import { map, switchMap, catchError, tap, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortColumn, SortDirection } from '../directives/app.sortable.directive';
import { ParametroEntrada } from '../model/app.parametroentrada.model';
import { SearchResult, State } from '../interfaces/app.utilservices.interface';
import { EOrderBy } from '../constantes/app.eorderby.const';
import { AuthenticationProvider } from '../auth/app.auth.token';
import { HttpClient } from '@angular/common/http';
import { ComunService } from './app.comum.service';
import { AccountService } from './app.login.service';

@Injectable({
    providedIn: 'root'
})
export class ParametrosEntradaService {
    private _search$ = new Subject<void>();
    private _parametros$ = new BehaviorSubject<ParametroEntrada[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    private readonly URL_CONTROLLER = 'ParametroEntrada';

    private _state: State<ParametroEntrada> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: EOrderBy.ASC,
        filtroGenerico:'',
        nome: null,
        situacaoID: null,
        tipoDadoID: null,
        dataDeTratamento: null,
    };

    constructor(private util: UtilService, private comunService: ComunService, private accountService: AccountService) {
        this._search$.pipe(
            switchMap(() => this._pagination())
        )
            .subscribe(({ parametros, total }) => {
                this._parametros$.next(parametros);
                this._total$.next(total);
            });
    }

    get parametros$() { return this._parametros$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get page() { return this._state.page; }
    get pages() { return Math.ceil(this._total$.value / this._state.pageSize); }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize, page: 1 }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn<ParametroEntrada>) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    public get(data) {
        console.log(data);
        
        this._set(data);
    }

    public new({
        nome = null,
        tipoDadoId = null,
        caminhoMapeado = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/inserir`;
        return this.util.Post<ParametroEntrada>(url, {
            Nome: nome,
            tipoDadoID: tipoDadoId,
            UsuarioID: this.accountService.userValue?.usuarioId,
            CaminhoMapeado: caminhoMapeado
        });
    }

    public put({
        parametroEntradaId = null,
        nome = null,
        tipoDadoId = null,
        caminhoMapeado = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizar`;
        return this.util.Put<ParametroEntrada>(url, {
            ParametroEntradaID: parametroEntradaId,
            Nome: nome,
            tipoDadoID: tipoDadoId,
            UsuarioID: this.accountService.userValue?.usuarioId,
            CaminhoMapeado: caminhoMapeado
        })
    }

    public putSituacao({
        parametroEntradaID = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizarSituacao`;
        return this.util.Put<ParametroEntrada>(url, {
            ID: parametroEntradaID,
            UsuarioID: this.accountService.userValue?.usuarioId,
            SituacaoID: situacaoId
        })
            .pipe(
                tap(() => this._search$.next())
            )
    }

    public getById(parametroEntradaId: number) {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPorID/${parametroEntradaId}`;
        return this.util.Get<any>(url);
    }

    public getOrderByDirection(sortColumn: SortColumn<ParametroEntrada>, sortDirection: SortDirection) {
        this._set({ sortColumn, sortDirection });
    }

    private _set(patch: any) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _pagination(): Observable<SearchResult<ParametroEntrada>> {
        console.log(this._state);
        
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPaginado`;
        return this.util.Post(url, {
            Page: this._state.page,
            Size: this._state.pageSize,
            OrderBy: this._state.sortDirection || EOrderBy.ASC,
            FieldAtOrder: this._state.sortColumn?.toCaptalize(),
            Nome: this._state.nome,
            SituacaoID: this._state.situacaoID,
            TipoDadoID: this._state.tipoDadoID,
            UsuarioID: null,
            FiltroGenerico: this._state.filtroGenerico 
        })
            .pipe(
                map((data) => ({ parametros: data.results, total: data.total })),
                catchError(erroRequest => {
                    const { error, message } = erroRequest;
                    console.log("ERROR:", error?.message || message);
                    return of({ parametros: [], total: 0 });
                })
            )
    }
}
