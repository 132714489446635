import { AlertaService } from '../../services/app.alerta.service';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectivesModule } from 'src/app/directives/app.directives.module';
import { AlertaComponent } from './alerta.component';
import { AlertaVisualizarComponent } from './visualizar/alerta.visualizar.component';
import { AlertaAtenderComponent } from './atender/alerta.atender.component';
import { PipesModule } from 'src/app/pipes/app.pipes.module';
import { BlockUIModule } from 'ng-block-ui';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        NgMultiSelectDropDownModule.forRoot(),
        BlockUIModule.forRoot(),
        ComponentsModule
    ],
    declarations: [
        AlertaComponent,
        AlertaVisualizarComponent,
        AlertaAtenderComponent,
       
    ],
    providers: [AlertaService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AlertaModule { }
