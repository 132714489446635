import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RegraComponent } from './regra.component';
import { DirectivesModule } from 'src/app/directives/app.directives.module';
import { RegraVisualizarComponent } from './visualizar/regra.visualizar.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BlockUIModule } from 'ng-block-ui';
import { RegraCadastroModule } from './cadastro/regra.cadastro.module';
import { RegraEditarModule } from './editar/regra.editar.module';
import { PipesModule } from 'src/app/pipes/app.pipes.module';
import { DesativarRegraComponent } from './modal/cancelar-regra.component';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
        NgMultiSelectDropDownModule.forRoot(),
        BlockUIModule.forRoot(),
        RegraCadastroModule,
        RegraEditarModule,
        PipesModule,
        ComponentsModule
    ],
    declarations: [RegraComponent, RegraVisualizarComponent, DesativarRegraComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RegraModule { }
