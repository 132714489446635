import { FormGroup, FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { Component, Injectable, OnInit } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel (value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel (date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse (value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format (date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-passo4periodo',
  templateUrl: './passo4periodo.component.html',
  styleUrls: ['./passo4periodo.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class Passo4PeriodoComponent implements OnInit {
  public form: FormGroup;
  public dataInicial = null;
  public horaInicial = { hour: 0, minute: 0, second: 0 };
  public dataFinal = null;
  public horaFinal = { hour: 0, minute: 0, second: 0 };

  constructor (
    private rootFormGroup: FormGroupDirective,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {}

  get hoje () {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }

  get I () {
    let dataHoraInicioVigencia = null;
    if (!String.isNullOrEmpty(this.dataInicial)) {
      let [dia, mes, ano] = this.dataInicial.split(/[//-]/g);
      let hora = 0,
        minuto = 0,
        segundo = 0;
      if (this.horaInicial) {
        hora = this.horaInicial.hour;
        minuto = this.horaInicial.minute;
        segundo = this.horaInicial.second;
      }

      dataHoraInicioVigencia = `${ano.padStart(4, '0')}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}T${hora
        .toString()
        .padStart(2, '0')}:${minuto.toString().padStart(2, '0')}:${segundo.toString().padStart(2, '0')}`;

      this.form.patchValue({
        dataHoraInicioVigencia,
      });
    }
    return dataHoraInicioVigencia;
  }

  get F () {
    let dataHoraFimVigencia = null;
    if (!String.isNullOrEmpty(this.dataFinal)) {
      let [dia, mes, ano] = this.dataFinal.split(/[//-]/g);
      let hora = 0,
        minuto = 0,
        segundo = 0;
      if (this.horaFinal) {
        hora = this.horaFinal.hour;
        minuto = this.horaFinal.minute;
        segundo = this.horaFinal.second;
      }

      dataHoraFimVigencia = `${ano.padStart(4, '0')}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}T${hora
        .toString()
        .padStart(2, '0')}:${minuto.toString().padStart(2, '0')}:${segundo.toString().padStart(2, '0')}`;

      this.form.patchValue({
        dataHoraFimVigencia,
      });
    }
    return dataHoraFimVigencia;
  }

  ngOnInit (): void {
    this.form = this.rootFormGroup.control as UntypedFormGroup;

    this.form.controls.dataHoraInicioVigencia.valueChanges.subscribe(valor => {
      if (valor && this.dataInicial == null) {
        const [dataInicio, horaInicio] = valor.split(/[//T]/g);
        const [ano, mes, dia] = dataInicio.split(/[//-]/g);
        const [hora, minuto, segundo] = horaInicio.split(/[//:]/g);
        this.dataInicial = `${dia}-${mes}-${ano}`;
        this.horaInicial = { hour: +hora, minute: +minuto, second: +segundo };
      }
    });
    this.form.controls.dataHoraFimVigencia.valueChanges.subscribe(valor => {
      if (valor && this.dataFinal == null) {
        const [dataFim, horaFim] = valor.split(/[//T]/g);
        const [ano, mes, dia] = dataFim.split(/[//-]/g);
        const [hora, minuto, segundo] = horaFim.split(/[//:]/g);
        this.dataFinal = `${dia}-${mes}-${ano}`;
        this.horaFinal = { hour: +hora, minute: +minuto, second: +segundo };
      }
    });
  }
}
