import * as moment from "moment";

export class LocalizacaoUsuarioDto {
    NomeDoUsuario: string;
    LoginUsuario: string;
    UnidadeDoUsuario: string;
    dataHoraInclusao: string;
    Coordenadas: string;

    constructor(dto: any) {
        this.NomeDoUsuario = dto?.nomeDoUsuario;
        this.LoginUsuario = dto?.loginUsuario;
        this.UnidadeDoUsuario = dto?.unidadeDoUsuario;
        this.dataHoraInclusao = (moment(dto?.dataHoraInclusao)).format('DD/MM/YYYY HH:mm:ss');
        this.Coordenadas = dto?.coordenadas;
    };
};