export class RegraUnidadeFiscal {
    regraUnidadeFiscalId: number;
    unidadeFiscalId: number;
    unidadeFiscalNome?: string;
    usuarioId?: number;
    situacaoId?: number;
    situacaoNome?: string;

    constructor(dtoRegraUnidadeFiscal: any) {
        this.regraUnidadeFiscalId = dtoRegraUnidadeFiscal?.regraUnidadeFiscalID;
        this.unidadeFiscalId = dtoRegraUnidadeFiscal?.unidadeFiscalID;
        this.unidadeFiscalNome = dtoRegraUnidadeFiscal?.unidadeFiscalNome;
        this.usuarioId = dtoRegraUnidadeFiscal?.usuarioID;
        this.situacaoId = dtoRegraUnidadeFiscal?.situacaoID;
        this.situacaoNome = dtoRegraUnidadeFiscal?.situacaoNome;
    }
}
