export class AlertaNotificacao {
    alertaNotificacaoId: number;
    alertaId: number;
    usuarioIdInclusao: number;
    regraUnidadeFiscalId: number;
    situacaoId: number;
    hashNIFI: string;

    constructor(dto: any) {
        this.alertaNotificacaoId = dto?.alertaNotificacaoID;
        this.alertaId = dto?.alertaID;
        this.usuarioIdInclusao = dto?.usuarioIDInclusao;
        this.regraUnidadeFiscalId = dto?.regraUnidadeFiscalId;
        this.situacaoId = dto?.situacaoID;
        this.hashNIFI = dto?.hashNIFI;
    }
}
