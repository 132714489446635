import { ModeloRegra } from 'src/app/model/app.modeloregra.model';
export class Condicionante {
    condicionanteId: number;
    descricao: string;
    situacaoId: number;
    situacaoNome: string;
    usuarioIdInclusao: number;
    usuarioNomeInclusao: string;
    usuarioIdAlteracao: number;
    usuarioNomeAlteracao: string;
    dataHoraInclusao: Date;
    dataHoraAlteracao: Date;
    ModeloRegra?: ModeloRegra;
    exibeRegra?: boolean;

    constructor(dtoCondicionante: any) {
        this.condicionanteId = dtoCondicionante.condicionanteID;
        this.descricao = dtoCondicionante.descricao;
        this.situacaoId = dtoCondicionante.situacaoID;
        this.situacaoNome = dtoCondicionante.situacaoNome;
        this.usuarioIdInclusao = dtoCondicionante.usuarioIDInclusao;
        this.usuarioNomeInclusao = dtoCondicionante.usuarioNomeInclusao;
        this.usuarioIdAlteracao = dtoCondicionante?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = dtoCondicionante.usuarioNomeAlteracao;
        this.dataHoraInclusao = dtoCondicionante.dataHoraInclusao;
        this.dataHoraAlteracao = dtoCondicionante?.dataHoraAlteracao;
        this.exibeRegra = dtoCondicionante?.exibeRegra;
    }
}
