export class ChefeUnidadeFiscal {
    chefeUnidadeFiscalId: number;
    usuarioId: string;
    usuarioNome: string;
    unidadeFiscalId: number;
    unidadeFiscalNome: string;
    dataHoraInclusao: string;
    usuarioIdInclusao: number;
    usuarioNomeInclusao: string;
    dataHoraAlteracao: string;
    usuarioIdAlteracao: number;
    usuarioNomeAlteracao: string;
    SituacaoId:number;

    constructor(chefeUnidadeFiscalDto: any) {
        this.chefeUnidadeFiscalId = chefeUnidadeFiscalDto?.ChefeUnidadeFiscalID;
        this.usuarioId = chefeUnidadeFiscalDto?.usuarioID;
        this.usuarioNome = chefeUnidadeFiscalDto?.usuarioNome;
        this.unidadeFiscalId = chefeUnidadeFiscalDto?.unidadeFiscalID;
        this.unidadeFiscalNome = chefeUnidadeFiscalDto?.unidadeFiscalNome;
        this.dataHoraInclusao = chefeUnidadeFiscalDto?.dataHoraInclusao;
        this.usuarioIdInclusao = chefeUnidadeFiscalDto?.usuarioIDInclusao;
        this.usuarioNomeInclusao = chefeUnidadeFiscalDto?.usuarioNomeInclusao;
        this.dataHoraAlteracao = chefeUnidadeFiscalDto?.dataHoraAlteracao;
        this.usuarioIdAlteracao = chefeUnidadeFiscalDto?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = chefeUnidadeFiscalDto?.usuarioNomeAlteracao;
        this.SituacaoId = chefeUnidadeFiscalDto?.situacaoID;
    }
}
