<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <h5 class="mb-0">Localização Mapa</h5>
                <div>
                    <div class="map_box_div">
                        <div class="map" id="map"></div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-footer">
                        <label class="legenda-mapa"><img src="../../../assets/img/equipamento-ms.png" class="legenda-mapa-equipamentos-sefaz"/>Equipamentos SEFAZ/MS</label>
                        <label class="legenda-mapa"><img src="../../../assets/img/unidade-fiscal.png" class="legenda-mapa-unidades-fiscais"/>Unidades Fiscais</label>
                        <label class="legenda-mapa"><img src="../../../assets/img/usuario-conectado.png" class="legenda-mapa-unidades-fiscais"/>Usuários Conectados</label>
                        <br>
                        <div>
                            <fieldset>
                                <div>
                                    <input type="checkbox" id="via_ferrea" name="via_ferrea"
                                        checked="exibirViasFerrea"
                                        (change)="controleExibirViaFerrea($event.target.checked)">
                                    <label class="label-vias via-ferrea" for="via_ferrea"> Via Ferrea</label>

                                    <input type="checkbox" id="rodovias_estaduais" name="rodovias_estaduais"
                                        checked="exibirRodoviasEstaduais"
                                        (change)="controleExibirRodoviasEstaduais($event.target.checked)">
                                    <label class="label-vias rodovia-estadual" for="rodovias_estaduais"> Rodovias Estaduais</label>

                                    <input type="checkbox" id="rodovias_federais" name="rodovias_federais"
                                        checked="exibirRodoviasFederais"
                                        (change)="controleExibirRodoviasFederais($event.target.checked)">
                                    <label class="label-vias rodovia-federal" for="rodovias_federais"> Rodovias Federais</label>
                                </div>                               
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>