<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card " *blockUI="'usuario'">
                    <div class="card-body ">
                        <div class="form-horizontal mx-3 ">
                            <div class="form-body " *ngIf="data ">
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Nome</label>
                                            <p>{{data.nome}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Login</label>
                                            <p>{{data.login}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label class="control-label">Perfil</label>
                                            <p>{{data.perfilNome}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label">Situação</label>
                                            <p>{{data.situacaoNome}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="data.chefeUnidadesFiscais.length">
                                    <div class="col-md-12">
                                        <div class="form-group ">
                                            <label class="control-label">Unidades Fiscais Chefe</label>
                                            <ul class="font-14">
                                                <li *ngFor="let chefeUnidadeFiscal of data.chefeUnidadesFiscais"
                                                    class="mb-1">
                                                    {{ chefeUnidadeFiscal.unidadeFiscalNome }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Data Inclusão</label>
                                            <p>{{data.dataHoraInclusao | date:'dd/MM/yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Data Alteração</label>
                                            <p *ngIf="data.usuarioIdAlteracao ">{{data.dataHoraAlteracao |
                                                date:'dd/MM/yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Usuário Alteração</label>
                                            <p *ngIf="data.usuarioNomeAlteracao ">{{data.usuarioNomeAlteracao}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-sm default " (click)="voltar() ">Voltar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>