import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { Passo1RegraValidator } from 'src/app/validators/passo1regra.validator';
import { Passo2RegraValidator } from 'src/app/validators/passo2regra.validator';
import { Passo3RegraValidator } from 'src/app/validators/passo3regra.validator';
import { Passo4RegraValidator } from 'src/app/validators/passo4regra.validator';
import { RegraValidator } from 'src/app/validators/regra.validator';
import { RegraService } from 'src/app/services/app.regra.service';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { Router } from '@angular/router';

enum EPasso {
    MODELO_REGRA = 0,
    DESTINATARIO = 1,
    COMPORTAMENTO = 2,
    PREIODO_VIGENCIA = 3,
    JUSTIFICATIVA = 4,
}

@Component({
    selector: 'app-wizard-stepform',
    templateUrl: './regra.cadastro.component.html',
    styleUrls: ['./regra.cadastro.component.scss'],
    providers: [BlockUtil, RegraService],
})
export class RegraCadastroComponent implements OnInit {
    public regraForm: UntypedFormGroup;
    public passoAtual: EPasso = EPasso.MODELO_REGRA;
    public model: NgbDateStruct;
    public today: NgbDate;
    public time = null;
    private qtdePassos = Object.keys(EPasso).filter(value => isNaN(Number(value)) === false).length;
    private valorEtapaProgresso = 100 / this.qtdePassos;
    

    constructor(
        private router: Router,
        private calendar: NgbCalendar,
        private formBuilder: FormBuilder,
        private passo1Validator: Passo1RegraValidator,
        private passo2Validator: Passo2RegraValidator,
        private passo3Validator: Passo3RegraValidator,
        private passo4Validator: Passo4RegraValidator,
        private validator: RegraValidator,
        private service: RegraService,
        private blockUtil: BlockUtil,
        private toast: ToastUtil
    ) {
        this.blockUtil.blockName = 'regra';
    }

    get progressValue(): number {
        return (this.passoAtual + 1) * this.valorEtapaProgresso;
    }

    get ehModeloRegra(): boolean {
        return this.passoAtual == EPasso.MODELO_REGRA;
    }
    get ehDestinatario(): boolean {
        return this.passoAtual == EPasso.DESTINATARIO;
    }
    get ehComportamento(): boolean {
        return this.passoAtual == EPasso.COMPORTAMENTO;
    }
    get ehPeriodoVigencia(): boolean {
        return this.passoAtual == EPasso.PREIODO_VIGENCIA;
    }
    get ehJustificativa(): boolean {
        return this.passoAtual == EPasso.JUSTIFICATIVA;
    }
    get jaPassouModeloRegra(): string {
        return this.passoAtual >= EPasso.MODELO_REGRA ? 'active' : 'inactive';
    }
    get jaPassouDestinatario(): string {
        return this.passoAtual >= EPasso.DESTINATARIO ? 'active' : 'inactive';
    }
    get jaPassouComportamento(): string {
        return this.passoAtual >= EPasso.COMPORTAMENTO ? 'active' : 'inactive';
    }
    get jaPassouPeriodoVigencia(): string {
        return this.passoAtual >= EPasso.PREIODO_VIGENCIA ? 'active' : 'inactive';
    }
    get jaPassouJustificativa(): string {
        return this.passoAtual >= EPasso.JUSTIFICATIVA ? 'active' : 'inactive';
    }

    ngOnInit() {
        this.today = this.calendar.getToday();
        this.regraForm = this.formBuilder.group({
            modeloRegra: new UntypedFormControl('', Validators.required),
            condicionante: new UntypedFormControl('', Validators.required),
            parametroSelecionado: new UntypedFormControl(''),
            filtroSelecionado: new UntypedFormControl(''),
            regrasParametroEntrada: new UntypedFormControl([]),
            regrasFiltroEntrada: new UntypedFormControl([]),
            tipoDestinatario: new UntypedFormControl('', Validators.required),
            regrasUnidadeFiscal: new UntypedFormControl([]),
            comportamentoRegra: new UntypedFormControl('', Validators.required),
            dataHoraInicioVigencia: new UntypedFormControl(null, Validators.required),
            dataHoraFimVigencia: new UntypedFormControl(null, [Validators.required]),
            regraDoTipoCustomizada: new UntypedFormControl(false,  [Validators.required]),
            justificativa: new UntypedFormControl(null, {
                updateOn: 'change',
                nonNullable: true,
                validators: [Validators.required, Validators.minLength(20), Validators.maxLength(300)],
            }),

            formParametro: this.formBuilder.array([]),
            formFiltro: this.formBuilder.array([]),
        });
    }

    onNext() {
        this.cleanFormSelectedOnNext();

        if (this.passoAtual == EPasso.MODELO_REGRA) {
            this.passo1Validator.set = this.regraForm;
            if (!this.passo1Validator.isValid) return this.passo1Validator.showMessages();
        } else if (this.passoAtual == EPasso.DESTINATARIO) {
            this.passo2Validator.set = this.regraForm;
            if (!this.passo2Validator.isValid) return this.passo2Validator.showMessages();
        } else if (this.passoAtual == EPasso.COMPORTAMENTO) {
            this.passo3Validator.set = this.regraForm;
            if (!this.passo3Validator.isValid) return this.passo3Validator.showMessages();
        } else if (this.passoAtual == EPasso.PREIODO_VIGENCIA) {
            this.passo4Validator.set = this.regraForm;
            if (!this.passo4Validator.isValid) return this.passo4Validator.showMessages();
        }
        if (this.passoAtual != EPasso.JUSTIFICATIVA) this.passoAtual++;
    }

    private cleanFormSelectedOnNext(){
        this.regraForm.patchValue({ filtroSelecionado: '' }, { emitEvent: false });
    }

    onPrevious() {
        if (this.passoAtual != EPasso.MODELO_REGRA) this.passoAtual--;
    }

    onSubmit() {
        this.blockUtil.bloquear();

        this.validator.set = this.regraForm;

        if (!this.validator.isValid) {
            this.blockUtil.desbloquear();
            this.validator.showMessages();
        } else {
            this.service.new(this.validator.regra).subscribe({
                next: data => {
                    this.toast.showSuccess(data);
                    this.blockUtil.desbloquear();
                    this.router.navigate(['regra']);
                },
                error: erro => {
                    this.toast.showDanger(erro);
                    this.blockUtil.desbloquear();
                },
            });
        }
    }

    onCancel() {
        this.router.navigate(['regra']);
    }
}
