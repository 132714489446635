import { EAcao } from 'src/app/constantes/app.eacao.const';
import { Routes } from '@angular/router';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { MasterComponent } from '../../components/master/master.component';
import { RegraCadastroComponent } from './cadastro/regra.cadastro.component';
import { RegraEditarComponent } from './editar/regra.editar.component';
import { RegraComponent } from './regra.component';
import { RegraVisualizarComponent } from './visualizar/regra.visualizar.component';

export const RegrasComponentRoutes: Routes = [
    {
        path: 'regra',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: RegraComponent
            }
        ]
    },
    {
        path: 'regra/cadastro',
        component: MasterComponent,
        data: { permissao: EAcao.CADASTRAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: RegraCadastroComponent
            }
        ]
    },
    {
        path: 'regra/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: RegraVisualizarComponent
            }
        ]
    },
    {
        path: 'regra/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: RegraEditarComponent
            }
        ]
    }
];
