<form [formGroup]="form" *blockUI="'destinatario'">
    <h2 class="fs-title text-center">Selecione os Destinatários</h2>
    <div class="d-flex justify-content-center flex-column my-5">
        <div class="form-group d-flex justify-content-center">
            <div class="col-md-6 main-card d-flex justify-content-left flex-column form-group">
                <label class="control-label text-left">Tipo Destinatário</label>
                <select class="form-control form-control-sm" formControlName="tipoDestinatario"
                    (change)="tipoDestinatarioSelecionado()">
                    <option value>SELECIONE...</option>
                    <option *ngFor="let tipoDestinatario of tiposDestinatario" [ngValue]="tipoDestinatario">{{
                        tipoDestinatario.nome }}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group d-flex justify-content-center" *ngIf="exibirUnidades()">
            <div class="col-md-12 main-card d-flex justify-content-left flex-column">
                <label class="control-label text-left">Unidades Fiscais</label>
                <ng-multiselect-dropdown placeholder="Selecione Unidade Fiscal" [settings]="configuracoesPadrao"
                    [data]="unidadesFiscais" formControlName="unidades" (onSelect)="selecionarUnidade($event)"
                    (onSelectAll)="selecionarTodasUnidades($event)" (onDeSelect)="onItemDeSelect($event)">
                    <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                        <div style="padding: 1px 10px;
                                    margin-right: 10px;
                                    right: 2px;
                                    top: -2px;">
                            <label style="color: #333; min-width: 130px; font-weight: normal;"
                                [ngStyle]="{ 'font-weight': isSelected? 'bold':' normal','color': getItems[id].isDisabled? 'lightgrey': ''}">{{getItems[id].unidadeFiscalId}}</label>
                        </div>
                    </ng-template>
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div class="form-group" *ngIf="exibirUnidadesSelecionadas()">
            <div class="col">
                <div class="d-flex flex-column">
                    <ng-contaioner *ngFor="let unidade of unidadesFiscaisSelecionadas">
                        <div class="input-group">
                            <small
                                class="d-flex flex-row flex-column flex-grow-1 input-group-text p-0 align-items-start">
                                <div
                                    class="d-flex flex-row font-12 align-items-start text-left justify-content-between align-items-center w-100 p-0">
                                    <span class="px-2 py-1">{{ unidade.nome }}</span>
                                    <ng-multiselect-dropdown class="p-0 font-12 w-50 bg-white" *ngIf="exibirUsuarios()"
                                        placeholder="Selecione Fiscais" [settings]="configuracoesUsuario"
                                        [data]="unidade.usuarios" [formControlName]="unidade.campoFiscais">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div class="font-12 align-items-start text-left">
                                    <div *ngIf="exibirUsuarios()">
                                        <ul class="m-0"
                                            *ngFor="let usuario of form.controls[unidade.campoFiscais].value">
                                            <li>
                                                <strong>{{usuario.nome}}</strong>
                                                <i class="fa-solid fa-times ml-2 text-danger pointer"
                                                    ngbTooltip="Excluir"
                                                    (click)="removerFiscalUnidade(usuario, unidade.campoFiscais)"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </small>
                            <button class="btn btn-danger btn-sm my-0 px-2" type="button" ngbTooltip="Remover"
                                (click)="removerUnidadeFiscal(unidade)">
                                <i class="fa-solid fa-times"></i>
                            </button>
                        </div>
                    </ng-contaioner>
                </div>
            </div>
        </div>
    </div>
</form>
