import { VisualizarFiltroComponent } from "./visualização/filtroentrada.visualizar.component";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FiltroEntradaComponent } from "./filtroentrada.component";
import { FiltroEntradaCadastroComponent } from "./cadastro/filtroentrada.cadastro.component"

import { FiltroEntradaEditarComponent } from "./editar/filtroentrada.editar.component";
import { DirectivesModule } from "src/app/directives/app.directives.module";
import { ComponentsModule } from "src/app/components/components.module";


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule
    ],
    declarations: [
        FiltroEntradaComponent,
        FiltroEntradaCadastroComponent,
        FiltroEntradaEditarComponent,
        VisualizarFiltroComponent

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FiltroEntradaModule { }
