import { Routes } from '@angular/router';
import { MasterComponent } from '../../components/master/master.component';
import { DashboardComponent } from './dashboard.component';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { EAcao } from 'src/app/constantes/app.eacao.const';

export const DashboardRoutes: Routes = [
    {
        path: 'dashboard',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: DashboardComponent
            }
        ]
    }
];
