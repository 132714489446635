import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModeloRegraService } from 'src/app/services/app.modeloregra.service';
import { ComunService } from 'src/app/services/app.comum.service';
import { Observable } from 'rxjs';
import {
    Condicionante,
    FiltroEntrada,
    ParametroEntrada,
    ParametroSaida
} from 'src/app/model';
import { tap } from 'rxjs/operators';
import { ModeloRegraValidator } from 'src/app/validators/modeloregra.validator';

@Component({
    selector: 'app-modeloregra-cadastro',
    templateUrl: './modeloregra.cadastro.component.html',
    styleUrls: ['./modeloregra.cadastro.component.scss'],
    providers: [
        BlockUtil,
        ModeloRegraService,
        BlockUtil
    ]
})
export class ModeloRegraCadastroComponent implements OnInit {

    public form: FormGroup;
    public condicionantes$: Observable<Condicionante[]>;
    public parametrosEntrada: ParametroEntrada[];
    public filtrosEntrada: FiltroEntrada[];
    public parametrosSaida: ParametroSaida[];
    public selectedItems: ParametroEntrada[] = [];

    public configuracoesPadrao: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: true,
        itemsShowLimit: 0,
        textField: 'nome',
    };

    public parametrosEntradaSettings: IDropdownSettings = {
        ...this.configuracoesPadrao,
        idField: 'parametroEntradaId',
    };

    public filtrosEntradaSettings: IDropdownSettings = {
        ...this.configuracoesPadrao,
        idField: 'filtroEntradaId',
    };

    public parametrosSaidaSettings: IDropdownSettings = {
        ...this.configuracoesPadrao,
        idField: 'parametroSaidaId',
    };

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private validator: ModeloRegraValidator,
        private blockUtil: BlockUtil,
        public service: ModeloRegraService,
        private toast: ToastUtil,
        private comum: ComunService
    ) {
        this.blockUtil.blockName = "cadastroModelo";
        this.condicionantes$ = this.comum.ObterCondicionantesByExibeRegra();
        this.comum.ObterParametrosEntradaAtivos()
            .pipe(
                tap(parametrosEntrada => this.parametrosEntrada = parametrosEntrada)
            ).subscribe();
        this.comum.ObterFiltrosEntradaAtivos()
            .pipe(
                tap(filtrosEntrada => this.filtrosEntrada = filtrosEntrada)
            ).subscribe();
        this.comum.ObterParametrosSaidaAtivos()
            .pipe(
                tap(parametrosSaida => this.parametrosSaida = parametrosSaida)
            ).subscribe();

        this.form = this.formBuilder.group({
            nome: new FormControl(null),
            condicionante: new FormControl(''),
            parametrosEntrada: new FormControl([]),
            filtrosEntrada: new FormControl([]),
            parametrosSaida: new FormControl([])
        });
    }

    get parametrosEntradaSelecionados(): ParametroEntrada[] {
        return this.form?.value?.parametrosEntrada || [];
    }

    get parametrosSaidaSelecionados(): ParametroSaida[] {
        return this.form?.value?.parametrosSaida || [];
    }

    get filtrosEntradaSelecionados(): FiltroEntrada[] {
        return this.form?.value?.filtrosEntrada || [];
    }

    ngOnInit(): void {
    }

    onSubmit() {

        this.blockUtil.bloquear();

        this.validator.set = this.form;

        if (!this.validator.isValid) {
            this.blockUtil.desbloquear();
            this.validator.showMessages();
        } else {
            this.service.new(this.validator.modeloRegra)
                .subscribe({
                    next: (data) => {
                        this.toast.showSuccess(data);

                        this.blockUtil.desbloquear();
                        this.clear();
                        this.router.navigate(['modeloregra']);
                    },
                    error: (erro) => {
                        this.toast.showDanger(erro);

                        this.blockUtil.desbloquear();
                    }
                })
        }
    }

    removerParametroEntrada(_parametroEntrada: ParametroEntrada) {
        const parametrosEntrada = this.form.controls.parametrosEntrada.value?.filter(parametroEntrada => _parametroEntrada != parametroEntrada);
        this.form.patchValue({ parametrosEntrada })
    }

    removerParametroSaida(_parametroSaida: ParametroSaida) {
        const parametrosSaida = this.form.controls.parametrosSaida.value?.filter(parametroSaida => _parametroSaida != parametroSaida);
        this.form.patchValue({ parametrosSaida })
    }

    removerFiltroEntrada(_filtroEntrada: FiltroEntrada) {
        const filtrosEntrada = this.form.controls.filtrosEntrada.value?.filter(filtroEntrada => _filtroEntrada != filtroEntrada);
        this.form.patchValue({ filtrosEntrada })
    }

    cancel() {
        this.router.navigate(['modeloregra']);
    }

    clear() {
        this.form.patchValue({
            nome: null,
            condicionante: '',
            parametrosEntrada: [],
            filtrosEntrada: [],
            parametrosSaida: []
        })
    }
}
