import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastType, ToastUtil } from '../helper/app.toast.utils';
import { ETipoLiberacao } from '../constantes/app.etipoliberacao.const';

@Injectable({
    providedIn: 'root',
})
export class AtenderAlertaValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _atenderAlerta: any;

    constructor(
        private toast: ToastUtil,
    ) {
        this._messages = [];
    }

    get isValid() {
        return this._messages.length == 0;
    }
    get atenderAlerta() {
        return this._atenderAlerta;
    }

    set set(form: UntypedFormGroup) {
        this._form = form;

        this._atenderAlerta = {
            atenderAlertaId: 0,
            alertaId: this._form.controls.alertaId.value,
            tipoLiberacaoId: this._form.controls.tipoLiberacao.value?.tipoLiberacaoId,
            motivo: this._form.controls.motivo.value?.trim(),
            serie: this._form.controls.serie.value?.trim(),
            numeroDocumento: this._form.controls.numeroDocumento.value?.trim(),
            unidade: this._form.controls.unidade.value?.trim(),
        };

        this._validate();
    }

    private _validate() {
        const {
            tipoLiberacaoId,
            motivo,
            serie,
            numeroDocumento,
            unidade
        } = this._atenderAlerta;
        this._messages = [];
        if (!tipoLiberacaoId)
            this._messages.push({ type: ToastType.WARNING, message: "Tipo de Liberação é obrigatória!" });
        else {
            const paraNumeroDocumento = [ETipoLiberacao.TERMO_DE_APREENSAO, ETipoLiberacao.TERMO_DE_VERIFICACAO_FISCAL, ETipoLiberacao.ALIM, ETipoLiberacao.REGISTRO_DE_PASSAGEM_ESTADUAL];
            const paraMotivo = [ETipoLiberacao.OUTRAS_LIBERCOES];
            const paraSerie = [ETipoLiberacao.ALIM];
            const paraUnidade = [ETipoLiberacao.TERMO_DE_APREENSAO, ETipoLiberacao.TERMO_DE_VERIFICACAO_FISCAL];

            const numeroDocumentoEhObrigatorio = paraNumeroDocumento.find(tipoLiberacao => tipoLiberacao == tipoLiberacaoId);
            const motivoEhObrigatorio = paraMotivo.find(tipoLiberacao => tipoLiberacao == tipoLiberacaoId);
            const serieEhObrigatoria = paraSerie.find(tipoLiberacao => tipoLiberacao == tipoLiberacaoId);
            const unidadeEhObrigatoria = paraUnidade.find(tipoLiberacao => tipoLiberacao == tipoLiberacaoId);

            if (numeroDocumentoEhObrigatorio && String.isNullOrEmpty(numeroDocumento))
                this._messages.push({ type: ToastType.WARNING, message: "Número de Documento é obrigatório!" });
            if (motivoEhObrigatorio && String.isNullOrEmpty(motivo))
                this._messages.push({ type: ToastType.WARNING, message: "Motivo é obrigatório!" });
            if (serieEhObrigatoria && String.isNullOrEmpty(serie))
                this._messages.push({ type: ToastType.WARNING, message: "Série é obrigatória!" });
            if (unidadeEhObrigatoria && String.isNullOrEmpty(unidade))
                this._messages.push({ type: ToastType.WARNING, message: "Unidade é obrigatória!" });
        }
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }
}
