<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body ">
                        <form class="form-horizontal " [formGroup]="form " (ngSubmit)="onSubmit() ">
                            <div class="form-body ">
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Tipo do Dado</label>
                                    <div class="col-md-12 main-card">
                                        <select class="form-control form-control-sm " formControlName="tipoDado">
                                            <option value>Selecione...</option>
                                            <option *ngFor="let tipoDado of tipoDados | async " [ngValue]="tipoDado">
                                                {{tipoDado.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Nome</label>
                                    <div class="col-md-12">
                                        <input type="text " class="form-control input-lg "
                                            placeholder="Digite aqui o Nome do Filtro de Entrada... "
                                            formControlName="nome" required>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-5 control-label">Caminho Mapeado</label>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control input-lg" maxlength="100"
                                            placeholder="Digite aqui o Caminho Mapeado..."
                                            formControlName="caminhoMapeado" required>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-sm default " (click)="cancel() ">Cancelar</button>
                                <button type="submit " class="btn btn-sm btn-primary ">Inserir</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
