import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { EntradaRoutes } from './pages/parametroentrada/parametroentrada.routing';
import { CondicionantesRoutes } from './pages/condicionante/condicionantes.routing';
import { FiltroEntradaRoutes } from './pages/filtroentrada/filtroentrada.routing';
import { SaidaRoutes } from './pages/parametrosaida/parametrosaida.routing';
import { ModelosRegraComponentRoutes } from './pages/modeloregra/modeloregra.routing';
import { RegrasComponentRoutes } from './pages/regra/regra.routing';
import { PerfilComponentRoutes } from './pages/perfil/perfil.routing';
import { UsuarioComponentRoutes } from './pages/usuario/usuario.routing';
import { ModuloComponentRoutes } from './pages/menu/modulo.routing';
import { AlertasRoutes } from './pages/alerta/alerta.routing';
import { DashboardRoutes } from './pages/dashboard/dashboard.routing';
import { UsuarioConectadoRoutes } from './pages/usuarioconectado/usuarioconectado.routing';
import { LocalizacaoEventoRoutes } from './pages/localizacaoevento/localizacaoevento.routing';
import { ConsultarPlacasRoutes } from './pages/consultarplacas/consultarplacas.routing';
import { LocalizacaoMapaRoutes } from './pages/localizacaomapa/localizacaomapa.routing';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    ...EntradaRoutes,
    ...CondicionantesRoutes,
    ...FiltroEntradaRoutes,
    ...SaidaRoutes,
    ...ModelosRegraComponentRoutes,
    ...RegrasComponentRoutes,
    ...PerfilComponentRoutes,
    ...UsuarioComponentRoutes,
    ...ModuloComponentRoutes,
    ...AlertasRoutes,
    ...DashboardRoutes,
    ...UsuarioConectadoRoutes,
    ...LocalizacaoEventoRoutes,
    ...LocalizacaoMapaRoutes,
    ...ConsultarPlacasRoutes,
    {
        path: '**',
        redirectTo: 'dashboard',
        //resolve: [AuthTokenResolver]
    },
];

@NgModule({
    imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
    exports: [],
})
export class AppRoutingModule { }
