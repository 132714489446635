import { ComunService } from 'src/app/services/app.comum.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/app.sortable.directive';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { Modulo } from 'src/app/model/app.modulo.model';
import { ModuloService } from 'src/app/services/app.modulo.service';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { EGrupo } from 'src/app/constantes/app.egrupo.const';
import { EOrderBy } from 'src/app/constantes/app.eorderby.const';

@Component({
    selector: 'app-modulo',
    templateUrl: './modulo.component.html',
    styleUrls: ['./modulo.component.scss']
})
export class ModuloComponent extends ParmissoesPagina implements OnInit {
    public isMobile: boolean = false;
    public modulo$: Observable<Modulo[]>;
    public total$: Observable<number>;
    public form: FormGroup;
    public eSituacao: ListItem[] = ESituacao.toList();
    public pesquisaAvancadaAberta: boolean = false;
    public colsGrid: any =  [
        {
             colKey: "nome",
             colName: "Nome"
         },
         {
             colKey: "caminho",
             colName: "Caminho",
 
         },
         {
             colKey: "grupoID",
             colName: "Grupo",
             type:'string',
             options:EGrupo.toListAll()
         },
 
     ]


    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<Modulo>>;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public service: ModuloService,
        private toast: ToastUtil,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private comum: ComunService,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService
    ) {
        super(router.url, accountService);
        this.modulo$ = service.modulo$;
        this.total$ = service.total$;

        this.form = this.formBuilder.group({
            filtroGenerico: new FormControl(null),
            
        });

        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        });
    }

    ngOnInit(): void {
        this.service.get({});
    }

    onSort({ column, direction }: SortEvent<Modulo>) {
        this.headers.forEach(header => {
            if (header.sortable !== column)
                header.direction = '';
        });

        this.service.getOrderByDirection(column, direction);
    }

    search() {
        const filtroGenerico = this.form.controls.filtroGenerico.value?.trim();
        this.service.get({
            page: 1,
            pageSize: 10,
            searchTerm: '',
            sortColumn: '',
            sortDirection: EOrderBy.ASC,
            nome: '',
            grupoID:'',
            caminho:'',
            filtroGenerico:filtroGenerico?filtroGenerico:''
        });
    }

    // clean() {
    //     this.form.setValue({
    //         moduloId: null,
    //         nome: null,
    //         situacao: '',
    //     });
    //     this.service.get({});
    // }

    onView({ moduloId }: any) {
        this.router.navigate(['menu/visualizar'], { queryParams: { moduloId } });
    }

    onEdit({ moduloId }: any) {
        this.router.navigate(['menu/editar'], { queryParams: { moduloId } });
    }

    onDelete(data: Modulo) {
        this.dialogModal.open({
            title: data.titulo,
            text: `Você está prestes a remover este Menu !<br/><strong>Deseja continuar?</strong>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        data.situacaoId = ESituacao.EXCLUIDO;
                        this.service.putSituacao(data)
                            .subscribe({
                                next: () => {
                                    this.toast.showSuccess("Menu Excluido com sucesso!");
                                    this.blockUtil.desbloquear();
                                    this.router.navigate(['menu']);
                                },
                                error: (erro: string) => {
                                    this.toast.showDanger(erro);
                                    this.blockUtil.desbloquear();
                                }
                            });
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    toInsert() {
        this.router.navigate(['menu/cadastro']);
    }

    pesquisaAvancada() {
        this.form.reset();

        this.pesquisaAvancadaAberta = true;
    }

    close() {
        this.pesquisaAvancadaAberta = false;
        this.search();


    }
    cleanSearch() {
        this.form.controls.filtroGenerico.setValue('');

        this.search();
    }

}
