import { Routes } from "@angular/router";
import { MasterComponent } from "src/app/components/master/master.component";
import { EAcao } from "src/app/constantes/app.eacao.const";
import { AutenticationGuard } from "src/app/guard/autentication.guard";
import { UsuarioconectadoComponent } from "./usuarioconectado.component";

export const UsuarioConectadoRoutes: Routes = [
    {
        path: 'usuarioconectado',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: UsuarioconectadoComponent
            }
        ]
    }
];