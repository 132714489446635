import { Component, Input, ElementRef } from '@angular/core';
import { ContentService } from "./master.content.service";

@Component({
    selector: 'my-content',
    template: '<ng-content></ng-content>'
})
export class ContentComponent {
    @Input() placeholder: string;
    @Input() dateUpdate: any = {};

    constructor(
        private elementRef: ElementRef,
        private contentService: ContentService
    ) { }

    ngOnInit() {
        this.contentService.registerContent({
            placeholder: this.placeholder,
            elementRef: this.elementRef
        });
    }
}
