import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { Regra } from '../model';

@Injectable({
    providedIn: 'root',
})
export class Passo4RegraValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _regra: Regra;
    //private _dataAtual: Date = new Date();

    constructor(private toast: ToastUtil) {
        this._messages = [];
    }

    get isValid() {
        return this._messages.length == 0;
    }
    get regra() {
        return this._regra;
    }
    get mensagens() {
        return this._messages;
    }

    get isEditing() {
        return this._regra.regraId && this._regra.regraId > 0;
    }

    get isInserting() {
        return !this.isEditing;
    }

    set set(form: UntypedFormGroup) {
        this._form = form;
        this._regra = {
            regraId: this._form.controls.regraId?.value,
            dataHoraInicioVigencia: this._form.controls.dataHoraInicioVigencia?.value,
            dataHoraFimVigencia: this._form.controls.dataHoraFimVigencia?.value,
        };

        this._validate();
    }

    private checkDate(dateOrString: string | Date): Date {
        return typeof dateOrString === 'string' ? new Date(Date.parse(dateOrString)) : dateOrString;
    }

    private getCurrentDate(dateIni: Date): Date {
        const currentDateTime = this.isInserting ? new Date() : dateIni;
        return currentDateTime;
    }

    private _validate() {
        const { dataHoraInicioVigencia, dataHoraFimVigencia } = this._regra;

        this._messages = [];

        if (!dataHoraInicioVigencia) {
            this._messages.push({ type: ToastType.WARNING, message: 'Data/Hora inicial é obrigatório' });
        }

        if (!dataHoraFimVigencia) {
            this._messages.push({ type: ToastType.WARNING, message: 'Data/Hora final é obrigatório' });
        }

        if (!dataHoraInicioVigencia || !dataHoraFimVigencia) return;

        const dateIni: Date = this.checkDate(dataHoraInicioVigencia);
        const currentDateTime: Date = this.getCurrentDate(dateIni);
        const dateEnd = this.checkDate(dataHoraFimVigencia);

        if (dateEnd < dateIni) {
            this._messages.push({ type: ToastType.WARNING, message: 'Data final não pode ser menor que data inicial!' });
            return;
        }

        const diffInHours = this.diffInHours(dateEnd, dateIni);
        if (diffInHours < 1) {
            this._messages.push({
                type: ToastType.WARNING,
                message: 'Data final e hora final deve ser superior a 1 hora de diferença',
            });
        }

        let dataInicialEhMenorQueADataAtual = this.ehMenorQueHoje(currentDateTime, dateIni);
        if (dataInicialEhMenorQueADataAtual) {
            this._messages.push({ type: ToastType.WARNING, message: 'Data Inicial não pode ser menor que data atual!' });
            return;
        }

        let dataFinalEhMenorQueADataAtual = this.ehMenorQueHoje(currentDateTime, dateEnd);
        if (dataFinalEhMenorQueADataAtual) {
            this._messages.push({ type: ToastType.WARNING, message: 'Data Final não pode ser menor que data atual!' });
            return;
        }
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }

    ehMenorQueHoje(dataCorrente: Date, dataComparacao: Date) {
        let _dataCorrente = this.checkDate(dataCorrente);
        let _dataComparacao = this.checkDate(dataComparacao);

        _dataCorrente.setHours(0, 0, 0, 0);
        _dataComparacao.setHours(0, 1, 0, 0);
        return _dataComparacao < _dataCorrente;
    }

    diffInHours(endDate: Date, iniDate: Date) {
        let _endDate = this.checkDate(endDate);
        let _iniDate = this.checkDate(iniDate);

        if (_iniDate >= _endDate)
            return 0;
        
        const diffInMs = _endDate.getTime() - _iniDate.getTime();
        const diffInHours = diffInMs / 1000 / 60 / 60;
        return diffInHours || 0;
    }
}
