import { Routes } from "@angular/router";
import { LocalizacaomapaComponent } from "./localizacaomapa.component";
import { EAcao } from "src/app/constantes/app.eacao.const";
import { AutenticationGuard } from "src/app/guard/autentication.guard";
import { MasterComponent } from "src/app/components/master/master.component";

export const LocalizacaoMapaRoutes: Routes = [
    {
        path: 'localizacaomapa',
        component: MasterComponent,
        data: {permissao: EAcao.PESQUISAR},
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: LocalizacaomapaComponent
            }
        ]
    }
];