import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { Condicionante } from '../model/app.condicionante.model';

@Injectable({
	providedIn: 'root'
})
export class CondicionanteValidator {
    private _form: UntypedFormGroup;
	private _messages: any[];
	private _condicionante: Condicionante;

	constructor(private toast: ToastUtil) {
		this._messages = [];
	}

	get isValid() { return this._messages.length == 0; }
	get condicionante() { return this._condicionante; }

	set set(form: UntypedFormGroup) {
        this._form = form;
        this._condicionante = {
            condicionanteId: this._form.controls.condicionanteId?.value || 0,
            descricao: this._form.controls.descricao.value,
            dataHoraInclusao: new Date(),
            dataHoraAlteracao: null
        } as Condicionante;
		this._validate();
	}

	private _validate() {
		const {
			descricao, situacaoId
		} = this._condicionante;

		this._messages = [];
		if (!descricao || descricao.length === 0 )
			this._messages.push({ type: ToastType.WARNING, message: "Descrição é obrigatória!" });
	}

	public showMessages() {
		this.toast.showMultiple(this._messages);
	}
}
