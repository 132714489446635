import { Injectable } from "@angular/core";

@Injectable()
export class LocalizacaoMapaService {
    constructor() { }

    public obterLinkMalhaViariaMSViaFerrea(): string {
        let parte1 = 'https://www.pinms.ms.gov.br/arcgis/rest/services/AGRAER_SERVICOS/Viario_MS/MapServer/0/query?';
        let parte2 = 'where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inS';
        let parte3 = 'R=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=&returnGeometry=true&ret';
        let parte4 = 'urnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=fals';
        let parte5 = 'e&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&retu';
        let parte6 = 'rnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&param';
        let parte7 = 'eterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson';

        return parte1.concat(parte2, parte3, parte4, parte5, parte6, parte7);
    }

    public obterLinkMalhaViariaMSRodoviasEstaduais(): string {
        let parte1 = 'https://www.pinms.ms.gov.br/arcgis/rest/services/AGESUL/SRE_2024/MapServer/0/query?';
        let parte2 = 'where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inS';
        let parte3 = 'R=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=&returnGeometry=true&ret';
        let parte4 = 'urnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=fals';
        let parte5 = 'e&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&retu';
        let parte6 = 'rnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&param';
        let parte7 = 'eterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson';

        return parte1.concat(parte2, parte3, parte4, parte5, parte6, parte7);
    }

    public obterLinkMalhaViariaMSRodoviasFederais(): string {
        let parte1 = 'https://www.pinms.ms.gov.br/arcgis/rest/services/AGRAER_SERVICOS/Viario_MS/MapServer/2/query?';
        let parte2 = 'where=1%3D1&text=&objectIds=&time=&timeRelation=esriTimeRelationOverlaps&geometry=&geometryType=esriGeometryEnvelope&inS';
        let parte3 = 'R=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=&returnGeometry=true&ret';
        let parte4 = 'urnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=fals';
        let parte5 = 'e&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&retu';
        let parte6 = 'rnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&sqlFormat=none&datumTransformation=&param';
        let parte7 = 'eterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson';

        return parte1.concat(parte2, parte3, parte4, parte5, parte6, parte7);
    }
}