import { pluck } from 'rxjs/operators';
import { UtilService } from './app.utils.service';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, of, from } from 'rxjs';

import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortColumn, SortDirection } from '../directives/app.sortable.directive';
import { SearchResult, State } from '../interfaces/app.utilservices.interface';
import { EOrderBy } from '../constantes/app.eorderby.const';
import { Condicionante } from '../model/app.condicionante.model';
import { ESituacao } from "../constantes/app.esituacao.const";
import { ComunService } from './app.comum.service';
import { AccountService } from './app.login.service';

@Injectable({
    providedIn: 'root'
})
export class CondicionanteService {
    private _search$ = new Subject<void>();
    private _condicionantes$ = new BehaviorSubject<Condicionante[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    private _situacao$ = new BehaviorSubject<boolean>(null);

    private readonly URL_CONTROLLER = 'Condicionante';

    private _state: State<Condicionante> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: EOrderBy.ASC,
        filtroGenerico:'',
        descricao: '',
        situacaoID: '',
    };

    constructor(private util: UtilService, private comunService: ComunService, private accountService: AccountService) {
        this._search$.pipe(
            switchMap(() => this._pagination())
        )
            .subscribe(({ condicionantes, total }) => {
                this._condicionantes$.next(condicionantes);
                this._total$.next(total);
            });
    }

    get condicionantes$() { return this._condicionantes$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get page() { return this._state.page; }
    get pages() { return Math.ceil(this._total$.value / this._state.pageSize); }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get situacao$() { return this._situacao$.asObservable(); }


    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize, page: 1 }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn<Condicionante>) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    public new({
        condicionanteId = null,
        descricao = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/inserir`;
        return this.util.Post<Condicionante>(url, {
            CondicionanteID: condicionanteId,
            Descricao: descricao,
            UsuarioID: this.accountService.userValue?.usuarioId,
            SituacaoID: situacaoId
        });
    }

    public get(data) {
        this._set(data);
    }

    public put({
        condicionanteId = null,
        descricao = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizar`;
        return this.util.Put<Condicionante>(url, {
            CondicionanteID: condicionanteId,
            Descricao: descricao,
            UsuarioID: this.accountService.userValue?.usuarioId,
            SituacaoID: situacaoId
        })
            .pipe(
                tap(() => this._search$.next())
            )
    }

    public putSituacao({
        condicionanteId = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizarSituacao`;
        return this.util.Put<Condicionante>(url, {
            ID: condicionanteId,
            UsuarioID: this.accountService.userValue?.usuarioId,
            SituacaoID: situacaoId
        })
            .pipe(
                tap(() => this._search$.next())
            )
    }

    public getById(condicionanteId: number) {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPorID/${condicionanteId}`;
        return this.util.Get<any>(url);
    }

    public getOrderByDirection(sortColumn: SortColumn<Condicionante>, sortDirection: SortDirection) {
        this._set({ sortColumn, sortDirection });
    }

    private _set(patch: any) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _pagination(): Observable<SearchResult<Condicionante>> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPaginado`;
        return this.util.Post(url, {
            Page: this._state.page,
            Size: this._state.pageSize,
            OrderBy: this._state.sortDirection || EOrderBy.ASC,
            FieldAtOrder: this._state.sortColumn?.toCaptalize(),
            Descricao: this._state.descricao,
            SituacaoID: this._state.situacaoID,
            FiltroGenerico: this._state.filtroGenerico,
            UsuarioID: null,
        })
            .pipe(
                map((data) => ({ condicionantes: data.results.map(dto => new Condicionante(dto)), total: data.total })),
                catchError(errorRequest => {
                    const { error, message } = errorRequest;
                    console.log("ERROR", error?.message || message);
                    return of({ condicionantes: [], total: 0 });
                })
            );
    }
}
