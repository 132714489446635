import { fromEvent, Observable, of } from 'rxjs';
import { ComunService } from 'src/app/services/app.comum.service';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ModuloValidator } from "src/app/validators/modulo.validator";
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModuloService } from 'src/app/services/app.modulo.service';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { Icone } from 'src/app/model';
import { catchError, debounceTime, filter, map, pluck, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-modulo-cadastro',
    templateUrl: './modulo.cadastro.component.html',
    styleUrls: ['./modulo.cadastro.component.scss'],
    providers: [
        BlockUtil,
        ModuloService
    ]
})
export class ModuloCadastroComponent implements OnInit {

    public form: FormGroup;
    public grupos: Observable<ListItem[]>;
    public _icones: Observable<Icone[]>;
    public icones: Icone[];
    public filtro: string;
    public selecionar: boolean = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private validator: ModuloValidator,
        private blockUtil: BlockUtil,
        public service: ModuloService,
        private toast: ToastUtil,
        private comum: ComunService
    ) {
        this.grupos = this.comum.ObterGrupos();
        this._icones = this.comum.ObterIcones()
        this._icones.subscribe(icones => this.icones = icones);

        this.form = this.formBuilder.group({
            grupo: new FormControl('', [Validators.required]),
            titulo: new FormControl(null, [Validators.required]),
            caminho: new FormControl(null, [Validators.required]),
            icone: new FormControl(null),
            ordem: new FormControl(null),
            filtro: new FormControl(null),
        });
    }

    get iconeSelecionado(): string {
        return this.form.controls.icone.value;
    }

    ngOnInit(): void {
        const input = fromEvent(document.querySelector("#filtro"), "input");

        input
            .pipe(
                debounceTime(300),
                pluck("target", "value"),
                map((evento: string) => evento.trim()),
                switchMap((termo: string) => {
                    if (!termo || termo.length < 2)
                        return this._icones;

                    return this._icones
                        .pipe(
                            map((icones: any[]) => icones.filter(({ nome }) => nome.includes(termo)))
                        )
                }),
                catchError((error, source) => source.pipe(startWith([])))
            )
            .subscribe(icones => this.icones = icones);
    }

    onSubmit() {
        this.blockUtil.bloquear();

        this.validator.set = this.form;

        if (!this.validator.isValid) {
            this.blockUtil.desbloquear();
            this.validator.showMessages();
        } else {
            this.service.new(this.validator.modulo)
                .subscribe({
                    next: (data) => {
                        this.toast.showSuccess(data);
                        this.blockUtil.desbloquear();
                        this.clear();
                        this.router.navigate(['menu']);
                    },
                    error: (erro) => {
                        this.toast.showDanger(erro);
                        this.blockUtil.desbloquear();
                        return;
                    }
                })
        }
    }

    selectIcon(icone: String) {
        if (this.iconeSelecionado == icone)
            this.removeIcon();
        else
            this.form.patchValue({ icone });
    }

    removeIcon() {
        this.form.patchValue({ icone: null });
    }

    confirmIcon() {
        this.selecionar = false;
        this.clearFilter();
    }

    toogleSelectIcon() {
        this.selecionar = !this.selecionar;
    }

    cancel() {
        this.router.navigate(['menu']);
    }

    clearFilter() {
        this.form.patchValue({ filtro: null });
        this._icones.subscribe(icones => this.icones = icones);
    }

    clear() {
        this.form.patchValue({
            grupo: '',
            titulo: null,
            caminho: null,
            icone: null,
            ordem: null,
            filtro: null,
        })
    }
}
