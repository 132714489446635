export class FiltroEntrada {
    filtroEntradaId: number;
    nome: string;
    usuarioIdInclusao: number;
    usuarioNomeInclusao: string;
    dataHoraInclusao: Date;
    situacaoId: number;
    situacaoNome: string;
    usuarioIdAlteracao: number;
    usuarioNomeAlteracao: string;
    dataHoraAlteracao: Date;
    tipoDadoId: number;
    tipoDadoNome: string;
    caminhoMapeado: string;
    obrigatorio: boolean;
    valor?: string;
    localNome?: string;
    sentidoDaLeitura?: string;

    constructor(dtoFiltroEntrada: any) {
        this.filtroEntradaId = dtoFiltroEntrada.filtroEntradaID;
        this.nome = dtoFiltroEntrada.nome;
        this.usuarioIdInclusao = dtoFiltroEntrada.usuarioIDInclusao;
        this.usuarioNomeInclusao = dtoFiltroEntrada.usuarioNomeInclusao;
        this.dataHoraInclusao = dtoFiltroEntrada.dataHoraInclusao;
        this.situacaoId = dtoFiltroEntrada.situacaoID;
        this.situacaoNome = dtoFiltroEntrada.situacaoNome;
        this.usuarioIdAlteracao = dtoFiltroEntrada.usuarioIDInclusao;
        this.usuarioNomeAlteracao = dtoFiltroEntrada.usuarioNomeInclusao;
        this.dataHoraAlteracao = dtoFiltroEntrada.dataHoraInclusao;
        this.dataHoraAlteracao = dtoFiltroEntrada?.dataHoraAlteracao;
        this.tipoDadoId = dtoFiltroEntrada?.tipoDadoID;
        this.tipoDadoNome = dtoFiltroEntrada.tipoDadoNome;
        this.caminhoMapeado = dtoFiltroEntrada.caminhoMapeado;
        this.obrigatorio = dtoFiltroEntrada.obrigatorio;
    }
}
