import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component } from '@angular/core';
import { ModuloService } from 'src/app/services/app.modulo.service';
import { Icone, Modulo } from 'src/app/model';
import { ComunService } from 'src/app/services/app.comum.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-modulo-visualizar',
    templateUrl: './modulo.visualizar.component.html',
    styleUrls: ['./modulo.visualizar.component.scss'],
    providers: [
        ModuloService
    ]
})
export class ModuloVisualizarComponent {

    public data: Modulo;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: ModuloService,
        private comum: ComunService
    ) {
        const moduloId = +this.route.snapshot.queryParamMap.get("moduloId");
        this.service.getById(moduloId)
            .subscribe({
                next: (data: Modulo) => {
                    this.comum.ObterIcones()
                        .pipe(filter(icones => !!icones.length))
                        .subscribe(icones => {
                            this.data = data;
                            this.data.icone = icones.find(({ classe }) => classe == data.icone)
                        });
                },
                error: () => this.data = null
            });
    }

    voltar() {
        this.router.navigate(['menu']);
    }
}
