import { FormDinamico } from "./app.formdinamico.model";

export class RegraFiltroEntrada extends FormDinamico {
    regraFiltroEntradaId: number;
    filtroEntradaId: number;
    filtroEntradaNome: string;
    filtroEntradaTipoDadoId: number;
    filtroEntradaTipoDadoNome: string;
    operadorId?: number;
    operadorNome?: string;
    agrupadorId?: number;
    agrupadorNome?: string;
    valor: string;
    localNome?: string;
    sentidoDaLeitura?: string;

    constructor(dtoRegraFiltroEntrada: any) {
        super(dtoRegraFiltroEntrada?.filtroEntrada, dtoRegraFiltroEntrada?.regraFiltroEntradaID);
        this.regraFiltroEntradaId = dtoRegraFiltroEntrada?.regraFiltroEntradaID;
        this.filtroEntradaId = dtoRegraFiltroEntrada?.filtroEntrada?.filtroEntradaID;
        this.filtroEntradaNome = dtoRegraFiltroEntrada?.filtroEntrada?.nome;
        this.filtroEntradaTipoDadoId = dtoRegraFiltroEntrada?.filtroEntrada?.tipoDadoID;
        this.filtroEntradaTipoDadoNome = dtoRegraFiltroEntrada?.filtroEntrada?.tipoDadoNome;
        this.operadorId = dtoRegraFiltroEntrada?.operador?.operadorID;
        this.operadorNome = dtoRegraFiltroEntrada?.operador?.nome;
        this.agrupadorId = dtoRegraFiltroEntrada?.agrupador?.agrupadorID;
        this.agrupadorNome = dtoRegraFiltroEntrada?.agrupador?.nome;
        this.valor = dtoRegraFiltroEntrada?.valor;
    }
}
