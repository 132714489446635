export class Equipamento {
    equipamentoId: number;
    nome: string;
    unidadeNome: string;
    unidadeId: number;
    origem: string;
    destino: string;
    sentidoDaVia: number;
    sentidoDaLeitura: string;
    identificadorDoEquipamento: string;
    cnpjDoOperadorFormatado: string;
    local: Local;
    localId: number;
    tabela: string;
    descricao: string;
    usuarioInsercao: string;
    dataInsercao: string;
    situacaoId: number;
    motivoDaAlteracao: string;
    usuarioAlteracao: string;
    dataAlteracao: string;
    cnpjDoOperador: string;
    situacao: Situacao;

    constructor(equipamentoDto: any) {
        this.equipamentoId = equipamentoDto?.id;
        this.nome = equipamentoDto?.nome;
        this.unidadeNome = equipamentoDto?.unidadeNome;
        this.unidadeId = equipamentoDto?.unidadeId;
        this.origem = equipamentoDto?.origem;
        this.destino = equipamentoDto?.destino;
        this.sentidoDaVia = equipamentoDto?.sentidoDaVia;
        this.sentidoDaLeitura = equipamentoDto?.sentidoDaLeitura;
        this.identificadorDoEquipamento = equipamentoDto?.identificadorDoEquipamento;
        this.cnpjDoOperadorFormatado = equipamentoDto?.cnpjDoOperadorFormatado;
        this.local = this.obterLocalEquipamento(equipamentoDto?.local);
        this.localId = equipamentoDto?.localId;
        this.tabela = equipamentoDto?.tabela;
        this.descricao = equipamentoDto?.descricao;
        this.usuarioInsercao = equipamentoDto?.usuarioInsercao;
        this.dataInsercao = equipamentoDto?.dataInsercao;
        this.situacaoId = equipamentoDto?.situacaoId;
        this.motivoDaAlteracao = equipamentoDto?.motivoDaAlteracao;
        this.usuarioAlteracao = equipamentoDto?.usuarioAlteracao;
        this.dataAlteracao = equipamentoDto?.dataAlteracao;
        this.cnpjDoOperador = equipamentoDto?.cnpjDoOperador;
        this.situacao = equipamentoDto?.situacao ? new Situacao(equipamentoDto?.situacao) : null;
    }

    public obterLocalEquipamento(local: Local): Local {
        if (local)
            return new Local(local);
        else
            return null;
    }

    public equipamentoDeMS(local: Local): boolean {
        if(local)
            return local.uf === 'MS' || local.uf === 'ms'
        else 
            return false;
    }
}

class Local {
    id: number;
    nome: string;
    latitude: string;
    longitude: string;
    observacao: string;
    origem: number;
    uf: string;
    situacaoId: number;
    situacao: Situacao;

    constructor(localDto: any) {
        this.id = localDto?.id;
        this.nome = localDto?.nome;
        this.latitude = localDto?.latitude;
        this.longitude = localDto?.longitude;
        this.observacao = localDto?.observacao;
        this.origem = localDto?.origem;
        this.uf = localDto?.uf;
        this.situacaoId = localDto?.situacaoId;
        this.situacao = localDto?.situacao ? new Situacao(localDto?.situacao) : null;
    }
}

class Situacao {
    id: number;
    nome: string;

    constructor(situacaoDto: any) {
        this.id = situacaoDto?.id;
        this.nome = situacaoDto?.nome;
    }
}
