import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cpf' })
export class CpfPipe implements PipeTransform {
	transform(value: string, args?: any): any {
        if (value) {
            value = value.replace(/\D/g, '');

            if (value.length > 11) {
              value = value.substring(0, 11);
            }

            switch (value.length) {
              case 4:
              case 5:
              case 6:
                value = value.replace(/(\d{3})(\d+)/, '$1.$2');
                break;
              case 7:
              case 8:
              case 9:
                value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
                break;
              case 10:
              case 11:
                value = value.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3-$4');
                break;
              default:
                return value;
            }
          }
          return value;
    }


}
