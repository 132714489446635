import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ParametroEntradaValidator } from 'src/app/validators/parametroentrada.validator';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ParametrosEntradaService } from 'src/app/services/app.parametrosentrada.service';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { Observable } from 'rxjs';
import { ComunService } from 'src/app/services/app.comum.service';

@Component({
    selector: 'app-entrada-cadastro',
    templateUrl: './parametroentrada.cadastro.component.html',
    styleUrls: ['./parametroentrada.cadastro.component.scss']
})
export class ParametroEntradaCadastroComponent implements OnInit {
    public form: FormGroup;
    public tipoDados: Observable<ListItem[]>;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private validator: ParametroEntradaValidator,
        private blockUtil: BlockUtil,
        public service: ParametrosEntradaService,
        private toast: ToastUtil,
        private comum: ComunService
    ) {
        this.tipoDados = this.comum.ObterTipoDado();
        this.form = this.formBuilder.group({
            nome: new FormControl(null),
            caminhoMapeado: new FormControl(null),
            tipoDado: new FormControl('')
        });
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.blockUtil.bloquear();

        this.validator.set = this.form;

        if (!this.validator.isValid) {
            this.blockUtil.desbloquear();
            this.validator.showMessages();
        } else {
            this.service.new(this.validator.parametroEntrada)
                .subscribe({
                    next: (data: string) => {
                        this.toast.showSuccess('Parâmetro de Entrada salvo com sucesso!');
                        this.blockUtil.desbloquear();
                        this.router.navigate(['parametroentrada']);
                    },
                    error: (erro: string) => {
                        this.toast.showDanger(erro);
                        this.blockUtil.desbloquear();
                    }
                })
        }
    }

    cancel() {
        this.router.navigate(['parametroentrada']);
    }

}
