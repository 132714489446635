import { Injectable } from "@angular/core";
import { FormArray, UntypedFormGroup } from "@angular/forms";
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { Regra } from "../model";

@Injectable({
    providedIn: 'root'
})
export class Passo1RegraValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _regra: Regra;

    constructor(private toast: ToastUtil) {
        this._messages = [];
    }

    get isValid() { return this._messages.length == 0; }
    get regra() { return this._regra; }
    get mensagens() { return this._messages; }

    set set(form: UntypedFormGroup) {
        this._form = form;
        this._regra = {
            modeloRegra: this._form.controls.modeloRegra?.value,
            regrasParametroEntrada: this.getItens(this._form.controls.formParametro as FormArray),
            regrasFiltroEntrada: this.getItens(this._form.controls.formFiltro as FormArray)
        };
        this._validate();
    }

    private _validate() {
        const {
            modeloRegra,
            regrasParametroEntrada,
            regrasFiltroEntrada
        } = this._regra;

        this._messages = [];
        if (!modeloRegra)
            this._messages.push({ type: ToastType.WARNING, message: "Modelo de Regra é obrigatório!" });
        else {
            this.validacaoDinamica(regrasParametroEntrada, "parâmetro de entrada", true);
            this.validacaoDinamica(regrasFiltroEntrada, "filtro de entrada");
        }
        this._regra?.regrasParametroEntrada?.forEach(regra => regra.valor = regra.valor?.replace(/[\(\)\./\s-]+/g, ''));
    }

    validacaoDinamica(itens: any[], titulo: string, obrigatorio: boolean = false) {
        if (obrigatorio && !itens.length)
            this._messages.push({ type: ToastType.WARNING, message: `Pelo menos um ${titulo} é obrigatório!` });
        else if (!!itens.length) {
            const invalidos = itens.filter(({ ehValido }) => !ehValido);
            if (!!invalidos.length)
                for (const { item } of invalidos)
                    this._messages.push({ type: ToastType.WARNING, message: `[${titulo.toCaptalize()}] ${item.tipoDadoNome} inválido!` });
            else {
                const validos = itens.filter(({ ehValido }) => ehValido);
                const semOperadores = validos.filter(({ operador }) => !operador);
                const semAgrupadores = validos.filter(({ agrupador, ehUltimo }) => !ehUltimo && !agrupador);
                for (const { item } of semOperadores)
                    this._messages.push({ type: ToastType.WARNING, message: `[${titulo.toCaptalize()}] Operador para o campo ${item.tipoDadoNome} é obrigatório!` });
                for (const { item } of semAgrupadores)
                    this._messages.push({ type: ToastType.WARNING, message: `[${titulo.toCaptalize()}] Agrupador para o campo ${item.tipoDadoNome} é obrigatório!` });
            }
        }
    }

    getItens(form: FormArray): any[] {
        return form.controls.map(campo => {
            let { item, agrupador, operador, valor, ehUltimo, ehValido, entradaId } = campo.value;
            if (item.tipoDadoNome != 'DATAHORA')
                valor = valor?.replace(/[\(\)\./\s-]+/g, '');
            return {
                entradaId,
                item,
                operador,
                agrupador: ehUltimo ? null : agrupador,
                valor,
                ehValido: ehValido,
                ehUltimo
            };
        })
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }
}
