<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body ">
                        <div class="form-horizontal mx-3 ">
                            <div class="form-body " *ngIf="data ">
                                <div class="row ">
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Tipo Dado</label>
                                            <p>{{data.tipoDadoNome}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <div class="form-group ">
                                            <label class="control-label ">Nome</label>
                                            <p>{{data.nome}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 " *ngIf="data.caminhoMapeado ">
                                        <div class="form-group ">
                                            <label class="control-label ">Caminho mapeado</label>
                                            <p>{{data.caminhoMapeado}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Situação</label>
                                            <p>{{data.situacaoNome}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Data Inclusão</label>
                                            <p>{{data.dataHoraInclusao | date:'dd/MM/yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Usuário Inclusão</label>
                                            <p>{{data.usuarioNomeInclusao}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 " *ngIf="data.usuarioIDAlteracao ">
                                        <div class="form-group ">
                                            <label class="control-label ">Data Alteração</label>
                                            <p>{{data.dataHoraAlteracao | date:'dd/MM/yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 " *ngIf="data.usuarioIDAlteracao ">
                                        <div class="form-group ">
                                            <label class="control-label ">Usuário Alteração</label>
                                            <p>{{data.usuarioNomeAlteracao}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-sm default " (click)="voltar() ">Voltar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
