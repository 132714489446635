import { Component, Injectable, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface ButtonDialog {
    label: string;
    class: string | null;
    callback: Function | null;
}

export interface DialogModal {
    title: string | null;
    text: string | null;
    btns: ButtonDialog[];
}

@Component({
    selector: 'ngbd-modal-content',
    template: `
		<div>
			<div class="text-break modal-header py-1 px-3 d-flex align-items-center text-wrap" *ngIf="exibirTitulo">
				<h4 class="modal-title mt-0">{{config.title}}</h4>
				<span class="mx-3 btn-close pointer" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x</span>
			</div>
			<div class="modal-body py-1 px-3 text-center" *ngIf="exibirTexto">
				<span [innerHTML]="config.text"></span>
			</div>
			<div class="modal-footer py-1 px-3" *ngIf="exibirBotoes">
				<button *ngFor="let btn of config.btns" type="button" class="btn btn-sm" [ngClass]="btn.class" (click)="onClick(btn.callback)">{{btn.label}}</button>
			</div>
		</div>
		`
})
export class DialogContainer {
    @Input() config: DialogModal;
    @Input() exibirTitulo: boolean = false;
    @Input() exibirTexto: boolean;
    @Input() exibirBotoes: boolean;

    onClick(cb: Function | null) {
        cb?.();
        this.activeModal.close();
    }

    constructor(public activeModal: NgbActiveModal) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class DialogUtil {
    constructor(private modalService: NgbModal) { }

    open(config: DialogModal) {
        const modalRef = this.modalService.open(DialogContainer);
        modalRef.componentInstance.config = config;
        modalRef.componentInstance.exibirTitulo = config.title != null && config.title != '';
        modalRef.componentInstance.exibirTexto = config.text != null && config.text != '';
        modalRef.componentInstance.exibirBotoes = config.btns.length > 0;
    }
}
