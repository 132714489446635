<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary fixed-top p-0">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-toggle">
                <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                </button>
            </div>
            <a class="navbar-brand" href=""><img src="../../../assets/img/cotin-mini2-removebg-preview.png" alt=""
                    srcset="" style="width: 220px;"></a>
            <!-- <a class="navbar-brand" href="">{{getTitle()}}</a> -->
        </div>
        <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">

            <ul class="navbar-nav">
                <li class="nav-item pointer" ngbDropdown>
                    <a class="nav-link d-flex flex-row justify-content-center align-items-center" id="dropdownBasic1"
                        ngbDropdownToggle>
                        <div class="px-2">
                            <i class="now-ui-icons users_single-02"></i>
                        </div>
                        <p class="d-flex flex-column justify-content-center">
                            <span class="d-md-block usuario">{{usuario.login}}</span>
                            <span class="d-md-block perfil-nome">{{usuario.perfilNome}}</span>
                        </p>
                    </a>
                    <div class="dropdown-menu justify-content-center" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <a class="item-sair" (click)="logout()"> Sair </a>                        
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>