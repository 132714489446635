import { Component, ElementRef, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { RegraService } from 'src/app/services/app.regra.service';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">Parar Atualização da Regra #{{ regraId }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label class="col-form-label">Desaja realmente para a atualização para a regra {{ regraId }}?</label>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onConfirm()">Parar Atualização</button>
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cancel Click')">Voltar</button>
    </div>
  `,
})
export class PararAtualizacaoRegraComponent implements OnInit {
  @Input() regraId: string;
  @Input() regraDescricao: string;

  @ViewChild('bindingInput') bindingInput!: ElementRef;

  constructor (protected activeModal: NgbActiveModal, private toast: ToastUtil, private regraService: RegraService) {}

  ngOnInit (): void {
  }

  onConfirm () {
      this.regraService.pararAtualizacaoRegra(Number(this.regraId)).subscribe({
        next: result => {
          this.toast.showSuccess(result, 'Parar Atualização Regra');
          this.activeModal.close(result);
        },
        error: err => {
          this.toast.showDanger(err);
        },
      });   

    return false;
  }
}
