import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastUtil } from '../helper/app.toast.utils';
import { Regra } from '../model';
import { Passo1RegraValidator } from './passo1regra.validator';
import { Passo2RegraValidator } from './passo2regra.validator';
import { Passo3RegraValidator } from './passo3regra.validator';
import { Passo4RegraValidator } from './passo4regra.validator';
import { Passo5RegraValidator } from './passo5regra.validator';

@Injectable({
  providedIn: 'root',
})
export class RegraValidator {
  private _form: UntypedFormGroup;
  private _messages: any[];
  private _regra: Regra;

  constructor(
    private toast: ToastUtil,
    private passo1Validator: Passo1RegraValidator,
    private passo2Validator: Passo2RegraValidator,
    private passo3Validator: Passo3RegraValidator,
    private passo4Validator: Passo4RegraValidator,
    private passo5Validator: Passo5RegraValidator
  ) {
    this._messages = [];
  }

  get isValid() {
    return this._messages.length == 0;
  }
  get regra() {
    return this._regra;
  }

  set set(form: UntypedFormGroup) {
    this._form = form;
    this.passo1Validator.set = this._form;
    this.passo2Validator.set = this._form;
    this.passo3Validator.set = this._form;
    this.passo4Validator.set = this._form;
    this.passo5Validator.set = this._form;

    this._regra = {
      regraId: this._form.controls.regraId?.value || 0,
      ...this.passo1Validator.regra,
      ...this.passo2Validator.regra,
      ...this.passo3Validator.regra,
      ...this.passo4Validator.regra,
      ...this.passo5Validator.regra,
    };

    this._validate();
  }

  private _validate() {
    this._messages = [];
    if (!this.passo1Validator.isValid)
      this.passo1Validator.mensagens.forEach((message) => this._messages.push(message));

    if (!this.passo2Validator.isValid)
      this.passo2Validator.mensagens.forEach((message) => this._messages.push(message));

    if (!this.passo3Validator.isValid)
      this.passo3Validator.mensagens.forEach((message) => this._messages.push(message));

    if (!this.passo4Validator.isValid)
      this.passo4Validator.mensagens.forEach((message) => this._messages.push(message));

    if (!this.passo5Validator.isValid)
      this.passo5Validator.mensagens.forEach((message) => this._messages.push(message));
  }

  public showMessages() {
    this.toast.showMultiple(this._messages);
  }
}
