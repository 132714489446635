import { tap, switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AccountService } from 'src/app/services/app.login.service';
import { environment } from 'src/environments/environment';

const getParameterByName = (name: string, url: string = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

@Injectable({
    providedIn: 'root'
})
export class AutenticationGuard implements CanActivate {

    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const [caminho] = route.routeConfig.path.split("/");
        const chave = getParameterByName("id");

        if (String.isNullOrEmpty(chave) && !this.accountService.userLogged) {
            window.location.href = environment.efazendaUrl;
            return of(false);
        } else if (!this.accountService.userLogged) {

            return this.accountService.Login(chave)
                .pipe(
                    switchMap(usuario => {
                        console.log(usuario)
                        return this.verifyPermissionScreen(caminho, route.data.permissao);
                    }),
                    catchError(() => this.router.navigate(['/login']))
                );
        }

        return this.verifyPermissionScreen(caminho, route.data.permissao);
    }

    verifyPermissionScreen(caminho: string, permissao: number): Observable<boolean> {
        return this.accountService.CheckPermission(caminho, permissao)
            .pipe(
                tap(isValid => {
                    if (!isValid)
                        this.router.navigate(['/login']);
                })
            );
    }

}
