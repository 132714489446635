import { ESituacao } from '../../constantes/app.esituacao.const';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/app.sortable.directive';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { Regra } from 'src/app/model/app.regra.model';
import { RegraService } from 'src/app/services/app.regra.service';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DesativarRegraComponent } from './modal/cancelar-regra.component';
import * as XLSX from 'xlsx';
import { ExcelService } from 'src/app/services/app.excel.service';
import { map } from 'jquery';
import { PararAtualizacaoRegraComponent } from './modal/parar-atualizacao-regra.component';
import { EOrderBy } from 'src/app/constantes/app.eorderby.const';

@Component({
    selector: 'app-regra',
    templateUrl: './regra.component.html',
    styleUrls: ['./regra.component.scss'],
    providers: [RegraService, DialogUtil, BlockUtil, ExcelService],
})

export class RegraComponent extends ParmissoesPagina implements OnInit {
    public isMobile: boolean = false;
    public regras$: Observable<Regra[]>;
    public condicionanteEditar = new BehaviorSubject<Regra>(null);
    public total$: Observable<number>;
    public form: FormGroup;
    public eSituacao: ListItem[] = ESituacao.toList();
    public eSituacaoDesativada: ESituacao = ESituacao.DESATIVADO;
    public eSituacaoCancelada: ESituacao = ESituacao.CANCELADO;
    public pesquisaAvancadaAberta: boolean = false;


    public colsGrid: any = [
        {
            colKey: "regraID",
            colName: "Regra ID"
        },

        {
            colKey: "nomeModelo",
            colName: "Nome Modelo"
        },

        {
            colKey: "destinatario",
            colName: "Destinatário"
        },
        {
            colKey: "situacao",
            colName: "Situação",
            type: 'string',
            options: ESituacao.toListAll()
        },
        {
            colKey: "tipoRegraDescricao",
            colName: "Tipo Regra",
            type: 'string',
        },
        {
            colKey: "periodo",
            colName: "Período",
            type: 'dateTime'
        },
        {
            colKey: "justificativa",
            colName: "Justificativa",

        }

    ]

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<Regra>>;

    constructor(
        private router: Router,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private formBuilder: FormBuilder,
        public service: RegraService,
        private toast: ToastUtil,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService,
        private modalService: NgbModal,
        private excelService: ExcelService,
    ) {
        super(router.url, accountService);

        this.blockUtil.blockName = "regrasCadastradas";     
        this.service.blockUtil = this.blockUtil;
        this.regras$ = this.service.regras$;

        this.total$ = this.service.total$;

        this.accountService.PermissionOnModel(router.url).subscribe({
            next: permissoes => (this.permissoes = permissoes),
        });

        this.form = this.formBuilder.group({
            filtroGenerico: new FormControl('')

        });

        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });
    }

    ngOnInit(): void {
        this.search();
    }

    onSort({ column, direction }: SortEvent<Regra>) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.getOrderByDirection(column, direction);
    }

    search() {
        const filtroGenerico = this.form.controls.filtroGenerico.value?.trim();

        this.service.get(
            {
                page: 1,
                pageSize: 10,
                searchTerm: '',
                sortColumn: '',
                sortDirection: EOrderBy.ASC,
                nome: '',
                regraID: '',
                nomeModelo: '',
                destinatario: '',
                justificativa: '',
                periodo: {
                    dataInicial: '',
                    dataFinal: ''
                },
                situacao: '',
                tipoRegra: '',
                filtroGenerico: filtroGenerico ? filtroGenerico : ''
            }
        );
    }

    pesquisaAvancada() {
        this.pesquisaAvancadaAberta = true;
        this.form.reset();

    }

    onView({ regraId }: any) {
        this.router.navigate(['regra/visualizar'], { queryParams: { regraId } });
    }
    onEdit({ regraId }: any) {
        this.router.navigate(['regra/editar'], { queryParams: { regraId } });
    }
    onDelete(data: Regra) {
        this.dialogModal.open({
            title: data.modeloRegra.nome,
            text: `Você está prestes a remover a regra!<br/><strong>Deseja continuar?</strong>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        data.situacaoId = ESituacao.EXCLUIDO;
                        this.service.putSituacao(data).subscribe({
                            next: () => {
                                this.toast.showSuccess('Regra excluida com sucesso!');
                                this.blockUtil.desbloquear();
                            },
                            error: erro => {
                                this.toast.showDanger(erro);
                                this.blockUtil.desbloquear();
                            },
                        });
                    },
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null,
                },
            ],
        });
    }

    close() {
        this.pesquisaAvancadaAberta = false;
        this.search();
    }

    toInsert() {
        this.router.navigate(['regra/cadastro']);
    }

    onDesativar(data: Regra) {
        const regraId = data.regraId;
        const regraDescricao = `${data.modeloRegra?.nome} - ${data.comportamentoRegraNome}`;
        const modalRef: NgbModalRef = this.modalService.open(DesativarRegraComponent, {
            backdrop: true,
            centered: true,
            modalDialogClass: 'modal-lg',
        });

        modalRef.componentInstance.regraId = regraId;
        modalRef.componentInstance.regraDescricao = regraDescricao;

        modalRef.dismissed.subscribe(() => {
        });

        modalRef.closed.subscribe(result => {
            if (!!result) {
                this.search();
            }
        });
    }

    onPararAtualizacao(data: Regra) {
        const regraId = data.regraId;
        const regraDescricao = `${data.modeloRegra?.nome} - ${data.comportamentoRegraNome}`;
        const modalRef: NgbModalRef = this.modalService.open(PararAtualizacaoRegraComponent, {
            backdrop: true,
            centered: true,
            modalDialogClass: 'modal-lg',
        });

        modalRef.componentInstance.regraId = regraId;
        modalRef.componentInstance.regraDescricao = regraDescricao;

        modalRef.dismissed.subscribe(() => { });

        modalRef.closed.subscribe(result => {
            if (!!result) {
                this.search();
            }
        });
    }

    exportExcel(): void {
        const jsonData = this.service.regras.map(({
            regraId,
            modeloRegra,
            tipoDestinatarioNome,
            comportamentoRegraNome,
            justificativa,
            dataHoraInicioVigenciaFormatado,
            dataHoraFimVigenciaFormatado,
            situacaoNome,
            tipoRegraDescricao
        }) => {
            return ({
                "ID Regra": regraId,
                "Nome Modelo": modeloRegra.nome,
                "Destinatário": tipoDestinatarioNome,
                "Justificativa": justificativa,
                "Comportamento": comportamentoRegraNome,
                "Data Inicial": dataHoraInicioVigenciaFormatado,
                "Data Final": dataHoraFimVigenciaFormatado,
                "Situação": situacaoNome,
                "Tipo Regra": tipoRegraDescricao
            })
        });
        this.excelService.exportExcel(jsonData, "Regras");
    }

    exibirBotaoEditar(regra: any): boolean {        
        return this.PodeAlterar &&
            regra.situacaoId != ESituacao.DESATIVADO &&
            regra.regraDoTipoCustomizada == false;
    }

    exibirBotaoExcluir(regra: any): boolean {
        return this.PodeExcluir
            && regra.situacaoId != ESituacao.DESATIVADO &&
            regra.regraDoTipoCustomizada == false;
    }

    exibirBotaoDesativarRegra(regra: any): boolean {
        return this.PodeAlterar
            && regra.situacaoId != ESituacao.DESATIVADO &&
            regra.regraDoTipoCustomizada == false;
    }

    exibirBotaoPararAtualizacoes(regra: any): boolean {
        return this.PodeAlterar &&
            regra.exibirIconePararAtualizacao &&
            regra.regraDoTipoCustomizada == false;
    }

    cleanSearch() {
        this.form.controls.filtroGenerico.setValue('');

        this.search();

    }
}
