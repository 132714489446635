import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ComportamentoRegra } from './../../../../model/app.comportamentoregra.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { ComunService } from 'src/app/services/app.comum.service';

@Component({
    selector: 'app-passo3comportamento',
    templateUrl: './passo3comportamento.component.html',
    styleUrls: ['./passo3comportamento.component.scss']
})
export class Passo3ComportamentoComponent implements OnInit {

    public form: FormGroup;
    public comportamentosRegra$: Observable<ComportamentoRegra[]>;

    constructor(
        private rootFormGroup: FormGroupDirective,
        private comum: ComunService
    ) {
        this.comportamentosRegra$ = this.comum.ObterComportamentoRegraAtivos();
    }

    ngOnInit(): void {
        this.form = this.rootFormGroup.control as UntypedFormGroup;
        let valor = this.form.controls.comportamentoRegra?.value;
        if(valor){
            valor = typeof valor == "object" ? valor.comportamentoRegraId : valor;
            this.comportamentosRegra$.pipe(
                tap(comportamentos =>{
                    if(comportamentos){
                        const comportamentoRegra = comportamentos.find(({comportamentoRegraId})=> comportamentoRegraId == valor )
                        if(comportamentoRegra)
                            this.form.patchValue({comportamentoRegra}, {emitEvent: false});
                    }
                })
            ).subscribe();
        }
        // this.form.controls.comportamentoRegra.valueChanges.subscribe(valor =>{
        //     if(valor){
        //         this.comportamentosRegra$.pipe(
        //             tap(comportamento =>{
        //                 const comportamentoRegra = comportamento.find(({comportamentoRegraId})=> comportamentoRegraId == valor )
        //                 if(comportamentoRegra)
        //                     this.form.patchValue({comportamentoRegra}, {emitEvent: false});
        //             })
        //         ).subscribe();
        //     }
        // })
    }

}
