import { Regra } from 'src/app/model/app.regra.model';
import { UtilService } from './app.utils.service';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';

import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortColumn, SortDirection } from '../directives/app.sortable.directive';
import { SearchResult, State } from '../interfaces/app.utilservices.interface';
import { EOrderBy } from '../constantes/app.eorderby.const';
import { ComunService } from './app.comum.service';
import { AccountService } from './app.login.service';
import { BlockUtil } from '../helper/app.block.utils';

@Injectable({
    providedIn: 'root',
})
export class RegraService {
    private _busca$ = new Subject<void>();
    private _regra$ = new BehaviorSubject<Regra[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    private _situacao$ = new BehaviorSubject<boolean>(null);
    public blockUtil: BlockUtil = null;

    private readonly URL_CONTROLLER = 'Regra';

    private _state: State<Regra> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: EOrderBy.ASC,
        nome: '',
        filtroGenerico: '',
        regraID: '',
        nomeModelo: '',
        destinatario: '',
        justificativa:'',
        periodo: {
            dataInicial: '',
            dataFinal: ''
        },
        situacao: '',
        tipoRegra: ''



    };

    constructor(private util: UtilService, private comunService: ComunService, private accountService: AccountService) {
        this._busca$
            .pipe(
                tap(() => this.blockUtil.bloquear()),
                switchMap(() => this._pagination())
            )
            .subscribe(({ regra, total }) => {
                this._regra$.next(regra);
                this._total$.next(total);
                this.blockUtil.desbloquear();
            });
    }

    get regras$() {
        return this._regra$.asObservable();
    }

    get regras() {
        return this._regra$.value;
    }

    get total$() {
        return this._total$.asObservable();
    }
    get page() {
        return this._state.page;
    }
    get pages() {
        return Math.ceil(this._total$.value / this._state.pageSize);
    }
    get pageSize() {
        return this._state.pageSize;
    }
    get searchTerm() {
        return this._state.searchTerm;
    }
    get situacao$() {
        return this._situacao$.asObservable();
    }

    set page(page: number) {
        this._set({ page });
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize, page: 1 });
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }
    set sortColumn(sortColumn: SortColumn<Regra>) {
        this._set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    public new({
        modeloRegra = null,
        tipoDestinatarioId = null,
        comportamentoRegraId = null,
        dataHoraInicioVigencia = null,
        dataHoraFimVigencia = null,
        justificativa = null,
        regrasUnidadeFiscal = null,
        regrasParametroEntrada = null,
        regrasFiltroEntrada = null,
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/inserir`;
        return this.util.Post<Regra>(url, {
            ModeloRegraID: modeloRegra.modeloRegraId,
            TipoDestinatarioID: tipoDestinatarioId,
            ComportamentoRegraID: comportamentoRegraId,
            DataHoraInicioVigencia: dataHoraInicioVigencia,
            DataHoraFimVigencia: dataHoraFimVigencia,
            Justificativa: justificativa,
            RegrasUnidadeFiscal: regrasUnidadeFiscal?.map(({ unidadeFiscalId: UnidadeFiscalID, usuarioId: UsuarioID }) => ({
                UnidadeFiscalID,
                UsuarioID
            })),
            RegrasParametroEntrada: regrasParametroEntrada?.map(({ item, valor: Valor, operador, agrupador }) => ({
                ParametroEntradaID: item?.parametroEntradaId,
                Valor,
                OperadorID: operador?.operadorId,
                AgrupadorID: agrupador?.agrupadorId,
                TipoDadoID: item?.tipoDadoId
            })),
            RegrasFiltroEntrada: regrasFiltroEntrada?.map(({ item, valor: Valor, operador, agrupador }) => ({
                FiltroEntradaID: item?.filtroEntradaId,
                Valor,
                OperadorID: operador?.operadorId,
                AgrupadorID: agrupador?.agrupadorId,
                TipoDadoID: item?.tipoDadoId
            })),
            UsuarioID: this.accountService.userValue?.usuarioId,
        });
    }

    public get(data) {
        console.log(data);

        this._set(data);
    }

    public put(date): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizar`;
        const {
            regraId = null,
            modeloRegra = null,
            tipoDestinatarioId = null,
            comportamentoRegraId = null,
            dataHoraInicioVigencia = null,
            dataHoraFimVigencia = null,
            justificativa = null,
            regrasUnidadeFiscal = null,
            regrasParametroEntrada = null,
            regrasFiltroEntrada = null,
        } = date;
        return this.util
            .Put<Regra>(url, {
                RegraID: regraId,
                ModeloRegraID: modeloRegra.modeloRegraId,
                TipoDestinatarioID: tipoDestinatarioId,
                ComportamentoRegraID: comportamentoRegraId,
                DataHoraInicioVigencia: dataHoraInicioVigencia,
                DataHoraFimVigencia: dataHoraFimVigencia,
                Justificativa: justificativa,
                RegrasUnidadeFiscal: regrasUnidadeFiscal?.map(
                    ({ regraUnidadeFiscalId: RegraUnidadeFiscalID, unidadeFiscalId: UnidadeFiscalID, usuarioId: UsuarioID }) => ({
                        RegraUnidadeFiscalID,
                        UnidadeFiscalID,
                        UsuarioID,
                    })
                ),
                RegrasParametroEntrada: regrasParametroEntrada?.map(({ item, valor: Valor, entradaId: RegraParametroEntradaID, operador, agrupador }) => ({
                    ParametroEntradaID: item?.parametroEntradaId,
                    Valor,
                    RegraParametroEntradaID,
                    OperadorID: operador?.operadorId,
                    AgrupadorID: agrupador?.agrupadorId,
                    TipoDadoID: item?.tipoDadoId
                })),
                RegrasFiltroEntrada: regrasFiltroEntrada?.map(({ item, valor: Valor, entradaId: RegraFiltroEntradaID, operador, agrupador }) => ({
                    FiltroEntradaID: item?.filtroEntradaId,
                    Valor,
                    RegraFiltroEntradaID,
                    OperadorID: operador?.operadorId,
                    AgrupadorID: agrupador?.agrupadorId,
                    TipoDadoID: item?.tipoDadoId
                })),
                UsuarioID: this.accountService.userValue?.usuarioId,
            })
            .pipe(tap(() => this._busca$.next()));
    }

    public putSituacao({ regraId = null, situacaoId = null }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizarSituacao`;
        return this.util
            .Put<Regra>(url, {
                ID: regraId,
                UsuarioID: this.accountService.userValue?.usuarioId,
                SituacaoID: situacaoId,
            })
            .pipe(tap(() => this._busca$.next()));
    }

    public getById(regraId: number) {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPorID/${regraId}`;
        return this.util.Get<any>(url).pipe(
            filter(regra => !!regra),
            map(regra => new Regra(regra))
        );
    }

    public getOrderByDirection(sortColumn: SortColumn<Regra>, sortDirection: SortDirection) {
        this._set({ sortColumn, sortDirection });
    }

    private _set(patch: any) {
        Object.assign(this._state, patch);

        this._busca$.next();
    }

    private _pagination(): Observable<SearchResult<Regra>> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPaginado`;
        return this.util
            .Post(url, {
                Page: this._state.page,
                Size: this._state.pageSize,
                OrderBy: this._state.sortDirection || EOrderBy.ASC,
                FieldAtOrder: this._state.sortColumn?.toCaptalize(),
                ModeloRegraNome: this._state.nome,
                FiltroGenerico: this._state.filtroGenerico,
                RegraID: this._state.regraID,
                NomeModelo: this._state.nomeModelo,
                Justificativa: this._state.justificativa,
                Destinatario: this._state.destinatario,
                DataInicial: this._state.periodo.dataInicial,
                DataFinal: this._state.periodo.dataFinal,
                Situacao: this._state.situacao,
                TipoRegra: this._state.tipoRegra,
                UsuarioID: this.accountService.userValue?.usuarioId
            })
            .pipe(
                map(({ results, total }) => ({ regra: results.map(dto => new Regra(dto)), total })),
                catchError(errorRequest => {
                    const { error, message } = errorRequest;
                    console.log('ERROR', error?.message || message);
                    return of({ regra: [], total: 0 });
                })
            );
    }

    public cancelarRegra(regraId: Number, justificativa: string): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/cancelarRegra`;
        return this.util
            .Put<{ regraId: Number; justificativa: string }>(url, {
                ID: regraId,
                UsuarioID: this.accountService.userValue?.usuarioId,
                justificativa,
            })
            .pipe(catchError(this.errorHandler));
    }

    public pararAtualizacaoRegra(regraId: Number): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/PararAtualizacaoRegra`;
        return this.util
            .Put<{ regraId: Number; }>(url, {
                regraId: regraId,
                UsuarioID: this.accountService.userValue?.usuarioId
            })
            .pipe(catchError(this.errorHandler));
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(() => new Error(errorMessage));
    }
}
