import { Modulo } from "./app.modulo.model";

export class Grupo {
    grupoId: number;
    nome: string;
    modulos: Modulo[];

    ehUnico: boolean = false;

    estaAberto: boolean = true;

    constructor(grupoDto: any) {
        this.grupoId = grupoDto?.grupoID;
        this.nome = grupoDto?.nome;
        this.modulos = grupoDto?.modulos;
        this.ehUnico = grupoDto?.ehUnico;
        this.estaAberto = true;
    }

    public AddModulo(modulo: Modulo) {
        this.modulos.push(modulo)
    }
}
