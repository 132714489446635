import { Routes } from '@angular/router';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { MasterComponent } from '../../components/master/master.component';
import { PerfilCadastroComponent } from './cadastro/perfil.cadastro.component';
import { PerfilEditarComponent } from './editar/perfil.editar.component';
import { PerfilComponent } from './perfil.component';
import { PerfilVisualizarComponent } from './visualizar/perfil.visualizar.component';

export const PerfilComponentRoutes: Routes = [
    {
        path: 'perfil',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: PerfilComponent
            }
        ]
    },
    {
        path: 'perfil/cadastro',
        component: MasterComponent,
        data: { permissao: EAcao.CADASTRAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: PerfilCadastroComponent
            }
        ]
    },
    {
        path: 'perfil/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: PerfilEditarComponent
            }
        ]
    },
    {
        path: 'perfil/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: PerfilVisualizarComponent
            }
        ]
    },
];
