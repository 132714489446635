<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body ">
                        <div class="form-horizontal mx-3 ">
                            <div class="form-body " *ngIf="data ">
                                <div class="row ">
                                    <div class="col-md-12">
                                        <div class="form-group ">
                                            <label class="control-label ">Chave</label>
                                            <p>{{data.modeloRegraId}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-12">
                                        <div class="form-group "><label class="control-label ">Nome</label>
                                            <p>{{data.nome}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-12">
                                        <div class="form-group ">
                                            <label class="control-label ">Condicionante</label>
                                            <p>{{data.condicionanteDescricao}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row " *ngIf="!!parametrosEntrada.length">
                                    <div class="alert alert-danger w-100 py-1" role="alert">
                                        <span class="text-danger font-weight-bold">*</span> campo obrigatório.
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group ">
                                            <label class="control-label">Parâmetros de Entrada</label>
                                            <ul>
                                                <li *ngFor="let parametroEntrada of parametrosEntrada">{{
                                                    parametroEntrada.nome }}
                                                    <span *ngIf="parametroEntrada.obrigatorio"
                                                        class="text-danger font-weight-bold">*</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row " *ngIf="!!filtrosEntrada.length">
                                    <div class="col-md-12">
                                        <div class="form-group ">
                                            <label class="control-label">Filtros de Entrada</label>
                                            <ul>
                                                <li *ngFor="let filtroEntrada of filtrosEntrada">{{
                                                    filtroEntrada.nome }}
                                                    <span *ngIf="filtroEntrada.obrigatorio"
                                                        class="text-danger font-weight-bold">*</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row " *ngIf="!!parametrosSaida.length">
                                    <div class="alert alert-info w-100 py-1" role="alert">
                                        <span class="text-info font-weight-bold">*</span> liberado para a
                                        notificação.
                                    </div>
                                    <div class="alert alert-warning w-100 py-1" role="alert">
                                        <span class="text-warning font-weight-bold">*</span> liberado para a
                                        visualização.
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group ">
                                            <label class="control-label">Parâmetros de Saída</label>
                                            <ul>
                                                <li *ngFor="let parametroSaida of parametrosSaida">{{
                                                    parametroSaida.nome }}
                                                    <span *ngIf="parametroSaida.compoeNotificacao"
                                                        class="text-info font-weight-bold">*</span>
                                                    <span *ngIf="parametroSaida.compoeVisualizacao"
                                                        class="text-warning font-weight-bold">*</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Data Inclusão</label>
                                            <p>{{data.dataHoraInclusao | date:'dd/MM/yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Usuário Inclusão</label>
                                            <p>{{data.usuarioNomeInclusao}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3" *ngIf="data.dataHoraAlteracao">
                                        <div class="form-group ">
                                            <label class="control-label ">Data Alteração</label>
                                            <p *ngIf="data.usuarioIdAlteracao">{{data.dataHoraAlteracao |
                                                date:'dd/MM/yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 " *ngIf="data.dataHoraAlteracao">
                                        <div class="form-group ">
                                            <label class="control-label ">Usuário Alteração</label>
                                            <p *ngIf="data.usuarioIdAlteracao">{{data.usuarioNomeAlteracao}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-sm default " (click)="voltar() ">Voltar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>