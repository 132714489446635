import { ListItem } from '../../../../interfaces/app.utilservices.interface';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComunService } from 'src/app/services/app.comum.service';
import { ModeloRegraService } from 'src/app/services/app.modeloregra.service';
import { ETipoDestinatario } from 'src/app/constantes/app.etipodestinatario.const';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UnidadeFiscal } from 'src/app/model/app.unidadefiscal.model';
import { TipoDestinatario } from 'src/app/model';
import { BlockUtil } from 'src/app/helper/app.block.utils';


@Component({
    selector: 'app-passo2destinatario',
    templateUrl: './passo2destinatario.component.html',
    styleUrls: ['./passo2destinatario.component.scss'],
    providers: [
        ModeloRegraService
    ]
})
export class Passo2DestinatarioComponent implements OnInit {

    public form: FormGroup;
    public tiposDestinatario: Array<TipoDestinatario>;
    public unidadesFiscais: UnidadeFiscal[] = [];
    public modalIsOpen: boolean = false;

    public configuracoesPadrao: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: true,
        itemsShowLimit: 0,
        textField: 'descricao',
        idField: 'unidadeFiscalId'
    };

    public configuracoesUsuario: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: true,
        itemsShowLimit: 0,
        textField: 'nome',
        idField: 'usuarioId'
    };

    constructor(
        private rootFormGroup: FormGroupDirective,
        private comum: ComunService,
        private blockUtil: BlockUtil,

    ) {
        this.blockUtil.blockName = "destinatario";
        this.comum.ObterTipoDestinatarioAtivos().subscribe(tiposDestinatarios => this.tiposDestinatario = tiposDestinatarios);
    }

    get getItems() {
        return this.unidadesFiscais.reduce((acc, curr) => {
            acc[curr.unidadeFiscalId] = curr;
            return acc;
        }, {});
    }

    get unidadesFiscaisSelecionadas(): UnidadeFiscal[] {
        const selecionadas = (this.form?.controls.unidades.value || []).map(({ unidadeFiscalId }) => unidadeFiscalId);
        if (!selecionadas.length)
            this.removeTodasUnidades();
        return this.unidadesFiscais.filter(({ unidadeFiscalId }) => selecionadas.includes(unidadeFiscalId));
    }

    ngOnInit(): void {
        this.form = this.rootFormGroup.control as UntypedFormGroup;
        this.form.addControl('unidades', new UntypedFormControl([], Validators.required));

        this.form.controls.unidades.valueChanges
            .subscribe(unidades => this.autualizarFormControl(unidades));
    }

    tipoDestinatarioSelecionado() {
        let tipoDestino = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId;
        let regrasUnidadeFiscal = [];
        this.form.patchValue({ regrasUnidadeFiscal });
        let unidades = [];
        this.form.patchValue({ unidades });

        switch (tipoDestino) {
            case ETipoDestinatario.UNIDADE_FISCAL:
                this.obterUnidadesPorUsuario();
                break;
            case ETipoDestinatario.LOCALIZACAO:
                this.obterUnidadesMoveis();
                break;
            default:
                this.unidadesFiscais = [];
        }
    }

    obterUnidadesPorUsuario() {
        this.blockUtil.bloquear();

        this.comum.ObterTodasUnidadesFiscaisPorUsuario()
            .subscribe(unidadesFiscais => {
                this.unidadesFiscais = unidadesFiscais.map(unidadeFiscal => ({ ...unidadeFiscal }));
                this.blockUtil.desbloquear();
            });
    }

    obterUnidadesMoveis() {
        this.blockUtil.bloquear();

        this.comum.ObterUnidadesMoveis()
            .subscribe(unidadesFiscais => {
                this.unidadesFiscais = unidadesFiscais.map(unidadeFiscal => ({ ...unidadeFiscal }));
                this.blockUtil.desbloquear();
            });
    }

    removerUnidadeFiscal({ unidadeFiscalId, campoFiscais }: UnidadeFiscal) {
        const unidades = this.form.controls.unidades.value?.filter(unidade => unidade.unidadeFiscalId != unidadeFiscalId);
        this.form.patchValue({ unidades });
        this.form.removeControl(campoFiscais);
    }

    selecionarUnidade({ unidadeFiscalId }: UnidadeFiscal) {
        const { campoFiscais, subUnidades } = this.unidadesFiscais.find(unidade => unidade.unidadeFiscalId == unidadeFiscalId);
        this.form.addControl(campoFiscais, new UntypedFormControl(subUnidades, Validators.required));
        const unidades = this.form.controls.unidades.value || [];
        this.autualizarFormControl(unidades);
        this.form.controls[campoFiscais].valueChanges
            .subscribe(() => {
                const unidades = this.form.controls.unidades.value || [];
                this.autualizarFormControl(unidades);
            });
    }

    selecionarTodasUnidades(unidades: UnidadeFiscal[]) {
        for (const unidade of unidades)
            this.selecionarUnidade(unidade);
    }

    removerFiscalUnidade(_fiscal: UnidadeFiscal, campoFiscais: string) {
        const regrasFiscalUnidade = this.form.controls[campoFiscais].value?.filter(fiscal => _fiscal != fiscal);
        this.form.patchValue({ [campoFiscais]: regrasFiscalUnidade });
    }

    removeTodasUnidades() {
        this.unidadesFiscais.forEach(({ campoFiscais }) => this.form.removeControl(campoFiscais));
        const unidades = this.form.controls.unidades.value || [];
        this.autualizarFormControl(unidades);
    }

    onItemDeSelect({ unidadeFiscalId }: UnidadeFiscal) {
        this.form.removeControl(`fiscalUnidade${unidadeFiscalId}`);
    }

    autualizarFormControl(unidades: UnidadeFiscal[]) {
        let tipoDestinatario = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId;
        if (tipoDestinatario == ETipoDestinatario.UNIDADE_FISCAL) {
            const regrasUnidadeFiscal = unidades
                .reduce((final, { unidadeFiscalId }) => {
                    (this.form?.controls[`fiscalUnidade${unidadeFiscalId}`]?.value || [])
                        .forEach(usuario => final.push({ unidadeFiscalId, usuarioId: usuario.usuarioId }));
                    return final;
                }, []);

            this.form.patchValue({ regrasUnidadeFiscal });
        } else {
            const regrasUnidadeFiscal = unidades
                .reduce((final, { unidadeFiscalId }) => {
                    var usuarioId = undefined;
                    final.push({ unidadeFiscalId, usuarioId })
                    return final;
                }, []);

            this.form.patchValue({ regrasUnidadeFiscal });
        }
    }

    exibirUnidades(): boolean {
        let ehUnidadeFiscal = this.form.controls.tipoDestinatario?.value.tipoDestinatarioId == ETipoDestinatario.UNIDADE_FISCAL;
        let ehLocalizacao = this.form.controls.tipoDestinatario?.value.tipoDestinatarioId == ETipoDestinatario.LOCALIZACAO;

        return ehUnidadeFiscal || ehLocalizacao;
    }

    exibirUnidadesSelecionadas(): boolean {
        let ehUnidadeFiscal = this.form.controls.tipoDestinatario?.value.tipoDestinatarioId == ETipoDestinatario.UNIDADE_FISCAL;
        let ehLocalizacao = this.form.controls.tipoDestinatario?.value.tipoDestinatarioId == ETipoDestinatario.LOCALIZACAO;
        let foiSelecionadaUmaOuMaisUnidade = this.unidadesFiscaisSelecionadas.length > 0;
        return (ehUnidadeFiscal || ehLocalizacao) && foiSelecionadaUmaOuMaisUnidade;
    }

    exibirUsuarios(): boolean {
        let ehUnidadeFiscal = this.form.controls.tipoDestinatario?.value.tipoDestinatarioId == ETipoDestinatario.UNIDADE_FISCAL;
        return ehUnidadeFiscal;
    }
}
