<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h3>Modelo de regra</h3>
                        <div class="form-horizontal mx-3">
                            <div class="form-body" *ngIf="data">
                                <div class="row">
                                    <div class="col-md-6 col-lg-4">
                                        <div class="form-group">
                                            <label class="control-label">Chave</label>
                                            <p>{{ data.regraId }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="form-group">
                                            <label class="control-label">Nome</label>
                                            <p>{{ data.modeloRegra?.nome }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <div class="form-group">
                                            <label class="control-label">Condicionante</label>
                                            <p>{{ data.modeloRegra?.condicionanteDescricao }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <ng-container *ngIf="parametrosEntrada.length">
                                    <div class="col-md-6 col-lg-4">
                                        <div class="form-group">
                                            <label class="control-label">Parâmetros de Entrada</label>
                                            <ul>
                                                <ng-container *ngFor="let parametroEntrada of parametrosEntrada">
                                                <li *ngIf="parametroEntrada.valor">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            {{ parametroEntrada.parametroEntradaNome }}
                                                        </div>
                                                        <div class="col-12" [ngSwitch]="parametroEntrada.tipoDadoNome"
                                                            *ngIf="parametroEntrada.valor">
                                                            <div *ngSwitchCase="'CPF'">
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor | cpf)">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchCase="'CNPJ'">
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor | cnpj)">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchCase="'INSCRIÇÃO ESTADUAL'">
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor | inscricaoEstadual)">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchCase="'DATAHORA'">
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor | date: 'dd/MM/yyyy')">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchCase="'EQUIPAMENTOS'">
                                                                <ul>                                                                    
                                                                    <li>{{parametroEntrada?.operadorNome}}</li>
                                                                    <li><strong>Local:</strong>{{parametroEntrada.localNome}}</li>
                                                                    <li><strong>Sentido:</strong>{{parametroEntrada.sentidoDaLeitura}}</li>
                                                                    <li><strong>Equipamento:</strong>{{parametroEntrada.valor}}</li>
                                                                    <li *ngIf="parametroEntrada.agrupadorNome">{{parametroEntrada.agrupadorNome}}</li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchDefault>
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada, parametroEntrada.valor)">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                    </ng-container>
                                    <ng-container *ngIf="showFiltroEntrada">
                                    <div class="col-md-6 col-lg-4">
                                        <div class="form-group">
                                            <label class="control-label">Filtros de Entrada</label>
                                            <ul>
                                                <ng-container *ngFor="let filtroEntrada of filtrosEntrada">
                                                <li *ngIf="filtroEntrada.valor">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            {{ filtroEntrada.filtroEntradaNome }}
                                                        </div>
                                                        <div class="col-12" [ngSwitch]="filtroEntrada.tipoDadoNome"
                                                            *ngIf="filtroEntrada.valor">
                                                            <div *ngSwitchCase="'CPF'">
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor | cpf)">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchCase="'CNPJ'">
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor | cnpj)">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchCase="'INSCRIÇÃO ESTADUAL'">
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor | inscricaoEstadual)">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchCase="'DATAHORA'">
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor | date: 'dd/MM/yyyy')">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchCase="'EQUIPAMENTOS'">
                                                                <ul>
                                                                    <li>{{filtroEntrada?.operadorNome}}</li>
                                                                    <li><strong>Local:</strong>{{filtroEntrada.localNome}}</li>
                                                                    <li><strong>Sentido:</strong>{{filtroEntrada.sentidoDaLeitura}}</li>
                                                                    <li><strong>Equipamento:</strong>{{filtroEntrada.valor}}</li>
                                                                    <li *ngIf="filtroEntrada.agrupadorNome">{{filtroEntrada.agrupadorNome}}</li>
                                                                </ul>
                                                            </div>
                                                            <div *ngSwitchDefault>
                                                                <ul>
                                                                    <li *ngFor="let item of formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada, filtroEntrada.valor)">
                                                                        <span>{{item}}</span>                                                                        
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                    </ng-container>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label class="control-label">Data Inclusão</label>
                                            <p>{{ data.dataHoraInclusao | date: 'dd/MM/yyyy' }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label class="control-label">Usuário Inclusão</label>
                                            <p>{{ data.usuarioNomeInclusao }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-3" *ngIf="data.dataHoraAlteracao">
                                        <div class="form-group">
                                            <label class="control-label">Data Alteração</label>
                                            <p *ngIf="data.usuarioIDAlteracao">
                                                {{ data.dataHoraAlteracao | date: 'dd/MM/yyyy' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-3" *ngIf="data.dataHoraAlteracao">
                                        <div class="form-group">
                                            <label class="control-label">Usuário Alteração</label>
                                            <p *ngIf="data.usuarioIDAlteracao">{{ data.usuarioNomeAlteracao }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div class="row">
                            <div class="col-6">
                                <h3>Destinatário</h3>
                                <div class="form-horizontal mx-3">
                                    <div class="form-body" *ngIf="data">
                                        <div class="row">
                                            <div class="form-group">
                                                <label class="control-label">Tipo</label>
                                                <p>{{ data.tipoDestinatarioNome }}</p>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="possuiUnidadesFiscais">
                                            <label class="control-label">Unidades Fiscais</label>
                                            <ul class="font-14">
                                                <li *ngFor="let unidade of unidades" class="mb-1">
                                                    {{ unidade.nome }}
                                                    <ul *ngIf="!!unidade.usuarios.length">
                                                        <li *ngFor="let usuario of unidade.usuarios">
                                                            {{usuario.nome}}
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <h3>Comportamento</h3>
                                <div class="form-horizontal mx-3">
                                    <div class="form-body" *ngIf="data">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="control-label">Comportamento</label>
                                                    <p>{{ data.comportamentoRegraNome }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div class="row">
                            <div class="col-6">
                                <h3>Período</h3>
                                <div class="form-horizontal mx-3">
                                    <div class="form-body" *ngIf="data">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="control-label">Data inicial</label>
                                                    <p>{{ data.dataHoraInicioVigencia | date: 'dd/MM/yyyy' }}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="control-label">Data final</label>
                                                    <p>{{ data.dataHoraFimVigencia | date: 'dd/MM/yyyy' }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <h3>Justificativa</h3>
                                <div class="form-horizontal mx-3">
                                    <div class="form-body" *ngIf="data">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="control-label">Justificativa</label>
                                                    <p>{{ data.justificativa }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-sm default" (click)="voltar()">Voltar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>