import { ToastUtil } from '../../../helper/app.toast.utils';
import { ESituacao } from '../../../constantes/app.esituacao.const';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CondicionanteValidator } from "src/app/validators/condicionante.validator";
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CondicionanteService } from 'src/app/services/app.condicionante.service';

@Component({
    selector: 'app-condicionante-cadastro',
    templateUrl: './condicionante.cadastro.component.html',
    styleUrls: ['./condicionante.cadastro.component.scss'],
    providers:[
        BlockUtil,
        CondicionanteService
    ]
})
export class CondicionanteCadastroComponent implements OnInit {

    public form: FormGroup;
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private condicionanteValidator: CondicionanteValidator,
        private blockUtil: BlockUtil,
        public service: CondicionanteService,
        private toast: ToastUtil
    ) {
        this.form = this.formBuilder.group({
            descricao: new FormControl(null),
            situacaoId: ESituacao.ATIVO,
        });

    }

    ngOnInit(): void {
    }

    onSubmit() {

        this.blockUtil.bloquear();

        this.condicionanteValidator.set = this.form;

        if(!this.condicionanteValidator.isValid){
            this.blockUtil.desbloquear();
			this.condicionanteValidator.showMessages();
        }else{
            this.service.new(this.condicionanteValidator.condicionante)
            .subscribe({
                next: (data) => {
                    this._sucesso(data);

                    this.blockUtil.desbloquear();
                    this.clear();
                    this.router.navigate(['condicionante']);
                },
                error: (erro) =>{
                    this._erro(erro);

                    this.blockUtil.desbloquear();
                    return;
                }
            })
        }
    }

    cancel() {
        this.router.navigate(['condicionante']);
    }
    clear(){
        this.form.setValue({
            descricao: null,
            situacaoId: null,
        })
    }


    private _erro(message: string) {
		this.toast.showDanger(message);
	}

	private _sucesso(message: string) {
		this.toast.showSuccess(message);
	}
}
