import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComunService } from 'src/app/services/app.comum.service';
import { ModeloRegraService } from 'src/app/services/app.modeloregra.service';
import { ETipoDestinatario } from 'src/app/constantes/app.etipodestinatario.const';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Unidade, UnidadeFiscal } from 'src/app/model/app.unidadefiscal.model';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { TipoDestinatario } from 'src/app/model/app.tipodestinatario.model';

@Component({
    selector: 'app-passo2destinatario',
    templateUrl: './passo2destinatario.component.html',
    styleUrls: ['./passo2destinatario.component.scss'],
    providers: [
        ModeloRegraService,
        BlockUtil
    ]
})
export class Passo2DestinatarioComponent implements OnInit {

    public form: FormGroup;
    public tiposDestinatario: Array<TipoDestinatario>;
    private tipoDestinatarioCadastrado: number;
    public unidadesFiscais: UnidadeFiscal[] = [];
    public demaisUnidadesFiscaisSelecionadas: UnidadeFiscal[];
    public unidadesFiscaisMoveisSelecionadas: UnidadeFiscal[];

    public configuracoesPadrao: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: true,
        itemsShowLimit: 0,
        textField: 'descricao',
        idField: 'unidadeFiscalId'
    };

    public configuracoesUsuario: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        selectAllText: "Todos",
        unSelectAllText: "Remover Todos",
        enableCheckAll: true,
        itemsShowLimit: 0,
        textField: 'nome',
        idField: 'usuarioId'
    };

    constructor(
        private rootFormGroup: FormGroupDirective,
        private comum: ComunService,
        private blockUtil: BlockUtil,
    ) {
        this.blockUtil.blockName = "destinatario";
        this.comum.ObterTodasUnidadesFiscaisPorUsuario()
            .subscribe(unidadesFiscais => this.unidadesFiscais = unidadesFiscais);
    }

    get getItems() {
        return this.unidadesFiscais.reduce((acc, curr) => {
            acc[curr.unidadeFiscalId] = curr;
            return acc;
        }, {});
    }

    get unidadesFiscaisSelecionadas(): UnidadeFiscal[] {
        let selecionadas = (this.form?.controls.unidades.value || []).map(({ unidadeFiscalId }) => unidadeFiscalId);
        let tipoDestinatario = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId;

        switch (tipoDestinatario) {
            case ETipoDestinatario.UNIDADE_FISCAL:
                if (this.demaisUnidadesFiscaisSelecionadas)
                    selecionadas = this.demaisUnidadesFiscaisSelecionadas.map(({ unidadeFiscalId }) => unidadeFiscalId);
                break;
            case ETipoDestinatario.LOCALIZACAO:
                if (this.unidadesFiscaisMoveisSelecionadas)
                    selecionadas = this.unidadesFiscaisMoveisSelecionadas.map(({ unidadeFiscalId }) => unidadeFiscalId);
                break;
            default:
                selecionadas = [];
        }

        if (selecionadas.length)
            return this.unidadesFiscais.filter(({ unidadeFiscalId }) => selecionadas.includes(unidadeFiscalId));
        else
            return [];
    }

    ngOnInit(): void {
        this.form = this.rootFormGroup.control as UntypedFormGroup;
        this.form.addControl('unidades', new UntypedFormControl([], Validators.required));

        this.comum.ObterTipoDestinatarioAtivos().subscribe(tiposDestinatarios => {
            this.tiposDestinatario = tiposDestinatarios

            let tipoDestino = this.form.controls.tipoDestinatario?.value;
            this.tipoDestinatarioCadastrado = tipoDestino;
            tipoDestino = typeof tipoDestino == "object" ? tipoDestino.tipoDestinatarioId : tipoDestino;
            this.tipoDestinatarioCadastrado = tipoDestino;

            const tipoDestinatario = this.tiposDestinatario.find(tipo => tipo.tipoDestinatarioId == tipoDestino);
            if (tipoDestinatario)
                this.form.patchValue({ tipoDestinatario }, { emitEvent: false })

            switch (tipoDestino) {
                case ETipoDestinatario.UNIDADE_FISCAL:
                    this.obterUnidadesPorUsuario();
                    break;
                case ETipoDestinatario.LOCALIZACAO:
                    this.obterUnidadesMoveis();
                    break;
                default:
                    this.unidadesFiscais = [];
            }
        });
    }

    tipoDestinatarioSelecionado() {
        let tipoDestino = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId;
        let regrasUnidadeFiscal = [];
        this.form.patchValue({ regrasUnidadeFiscal });

        switch (tipoDestino) {
            case ETipoDestinatario.UNIDADE_FISCAL:
                this.obterUnidadesPorUsuario();
                break;
            case ETipoDestinatario.LOCALIZACAO:
                this.obterUnidadesMoveis();
                break;
            default:
                this.unidadesFiscais = [];
        }
    }

    obterUnidadesPorUsuario() {
        this.blockUtil.bloquear();
        this.comum.ObterTodasUnidadesFiscaisPorUsuario()
            .subscribe(unidadesFiscais => {
                this.unidadesFiscais = unidadesFiscais.map(unidadeFiscal => ({ ...unidadeFiscal }));
                let unidades = this.unidadesFiscais
                    .filter(({ unidadeFiscalId }) => regrasUnidades.some(regraUnidade => regraUnidade.unidadeFiscalId == unidadeFiscalId))
                    .map(unidade => {
                        const unidadeFiscal = this.unidadesFiscais.find(({ unidadeFiscalId }) => unidadeFiscalId == unidade.unidadeFiscalId);
                        unidade.usuarios.forEach(usuario => {
                            const { regraUnidadeFiscalId } = regrasUnidades.find(({ usuarioId }) => usuarioId == usuario.usuarioId) || {};
                            usuario.regraUnidadeFiscalId = regraUnidadeFiscalId;
                        });
                        const regrasFiscalUnidade = unidade.usuarios
                            .filter(({ usuarioId }) => regrasUnidades.some(regraUnidade => regraUnidade.usuarioId == usuarioId));
                        this.form.addControl(unidadeFiscal.campoFiscais, new UntypedFormControl(regrasFiscalUnidade, Validators.required));
                        this.form.controls[unidadeFiscal.campoFiscais].valueChanges
                            .subscribe(() => {
                                const unidades = this.form.controls.unidades.value || [];
                                this.autualizarFormControl(this.obterUnidadesComSubUnidades(unidades));
                            });
                        return unidadeFiscal;
                    })

                unidades = this.obterValorQueSeraAtribuidoNoFormDeUnidades(ETipoDestinatario.UNIDADE_FISCAL, unidades);
                this.form.patchValue({ unidades });
                this.blockUtil.desbloquear();
            });

        let regrasUnidades = this.form.controls.regrasUnidadeFiscal?.value.filter(ruf => ruf.situacaoId == ESituacao.ATIVO);
        if (!regrasUnidades.length)
            return;
    }

    obterUnidadesMoveis() {
        this.blockUtil.bloquear();
        this.comum.ObterUnidadesMoveis()
            .subscribe(unidadesFiscais => {
                this.unidadesFiscais = unidadesFiscais.map(unidadeFiscal => ({ ...unidadeFiscal }));
                let unidades = this.unidadesFiscais
                    .filter(({ unidadeFiscalId }) => regrasUnidades.some(regraUnidade => regraUnidade.unidadeFiscalId == unidadeFiscalId))
                    .map(unidade => {
                        const unidadeFiscal = this.unidadesFiscais.find(({ unidadeFiscalId }) => unidadeFiscalId == unidade.unidadeFiscalId);
                        unidade.usuarios.forEach(usuario => {
                            const { regraUnidadeFiscalId } = regrasUnidades.find(({ usuarioId }) => usuarioId == usuario.usuarioId) || {};
                            usuario.regraUnidadeFiscalId = regraUnidadeFiscalId;
                        });
                        return unidadeFiscal;
                    })

                unidades = this.obterValorQueSeraAtribuidoNoFormDeUnidades(ETipoDestinatario.LOCALIZACAO, unidades);
                this.form.patchValue({ unidades });
                this.autualizarFormControl(this.obterUnidadesComSubUnidades(unidades));
                this.blockUtil.desbloquear();
            });

        let regrasUnidades = this.form.controls.regrasUnidadeFiscal?.value.filter(ruf => ruf.situacaoId == ESituacao.ATIVO);
        if (!regrasUnidades.length)
            return;
    }

    obterValorQueSeraAtribuidoNoFormDeUnidades(tipoDestinatario: number, unidades: UnidadeFiscal[]): UnidadeFiscal[] {
        var unidadesEmMemoriaSelecionadas = tipoDestinatario == ETipoDestinatario.UNIDADE_FISCAL
            ? this.demaisUnidadesFiscaisSelecionadas
            : this.unidadesFiscaisMoveisSelecionadas;

        if (this.tipoDestinatarioCadastrado == tipoDestinatario) {
            if (unidadesEmMemoriaSelecionadas)
                unidades = unidadesEmMemoriaSelecionadas;
        }
        else if (unidadesEmMemoriaSelecionadas)
            unidades = unidadesEmMemoriaSelecionadas;
        else
            unidades = [];

        return unidades;
    }

    obterUnidadesComSubUnidades(unidades: UnidadeFiscal[]) {
        const selecionadas = unidades.map(({ unidadeFiscalId }) => unidadeFiscalId);
        return this.unidadesFiscais.filter(({ unidadeFiscalId }) => selecionadas.includes(unidadeFiscalId));
    }

    removerUnidadeFiscal({ unidadeFiscalId, campoFiscais }: UnidadeFiscal) {
        const unidades = this.form.controls.unidades.value?.filter(unidade => unidade.unidadeFiscalId != unidadeFiscalId);
        this.form.patchValue({ unidades });
        this.form.removeControl(campoFiscais);
    }

    selecionarUnidade({ unidadeFiscalId }: UnidadeFiscal) {
        const { campoFiscais, subUnidades } = this.unidadesFiscais.find(unidade => unidade.unidadeFiscalId == unidadeFiscalId);
        this.form.addControl(campoFiscais, new UntypedFormControl(subUnidades, Validators.required));
        const unidades = this.form.controls.unidades.value || [];
        let tipoDestino = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId;

        switch (tipoDestino) {
            case ETipoDestinatario.UNIDADE_FISCAL:
                this.demaisUnidadesFiscaisSelecionadas = unidades;
                break;
            case ETipoDestinatario.LOCALIZACAO:
                this.unidadesFiscaisMoveisSelecionadas = unidades;
                break;
        }

        this.autualizarFormControl(this.obterUnidadesComSubUnidades(unidades));
        this.form.controls[campoFiscais].valueChanges
            .subscribe(() => {
                const unidades = this.form.controls.unidades.value || [];
                this.autualizarFormControl(this.obterUnidadesComSubUnidades(unidades));
            });
    }

    selecionarTodasUnidades(unidades: UnidadeFiscal[]) {
        for (const unidade of unidades)
            this.selecionarUnidade(unidade);
    }

    removerFiscalUnidade(_fiscal: UnidadeFiscal, campoFiscais: string) {
        const regrasSubUnidadeFiscal = this.form.controls[campoFiscais].value?.filter(fiscal => _fiscal != fiscal);
        this.form.patchValue({ [campoFiscais]: regrasSubUnidadeFiscal });
    }

    removeTodasUnidades() {
        this.unidadesFiscais.forEach(({ campoFiscais }) => this.form.removeControl(campoFiscais));
        const unidades = this.form.controls.unidades.value || [];
        this.autualizarFormControl(this.obterUnidadesComSubUnidades(unidades));
    }

    onItemDeSelect({ unidadeFiscalId }: UnidadeFiscal) {
        this.form.removeControl(`fiscalUnidade${unidadeFiscalId}`);
    }

    autualizarFormControl(unidades: UnidadeFiscal[]) {
        let tipoDestinatario = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId;
        if (tipoDestinatario == ETipoDestinatario.UNIDADE_FISCAL) {
            const regrasUnidadeFiscal = unidades
                .reduce((final, { unidadeFiscalId }) => {
                    (this.form?.controls[`fiscalUnidade${unidadeFiscalId}`]?.value || [])
                        .forEach(({ usuarioId, regraUnidadeFiscalId }) => final.push({ regraUnidadeFiscalId, unidadeFiscalId, usuarioId, situacaoId: ESituacao.ATIVO }));
                    return final;
                }, []);

            this.form.patchValue({ regrasUnidadeFiscal });
        }
        else {
            const regrasUnidadeFiscal = unidades
                .reduce((final, { unidadeFiscalId }) => {
                    var regraUnidadeFiscalId = undefined;
                    var usuarioId = undefined;
                    var ativo = ESituacao.ATIVO;
                    final.push({ regraUnidadeFiscalId, unidadeFiscalId, usuarioId, situacaoId: ESituacao.ATIVO })
                    return final;
                }, []);

            this.form.patchValue({ regrasUnidadeFiscal });
        }
    };

    exibirUnidades(): boolean {
        let ehUnidadeFiscal = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId == ETipoDestinatario.UNIDADE_FISCAL;
        let ehLocalizacao = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId == ETipoDestinatario.LOCALIZACAO;

        return ehUnidadeFiscal || ehLocalizacao;
    }

    exibirUnidadesSelecionadas(): boolean {
        let ehUnidadeFiscal = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId == ETipoDestinatario.UNIDADE_FISCAL;
        let ehLocalizacao = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId == ETipoDestinatario.LOCALIZACAO;
        let foiSelecionadaUmaOuMaisUnidade = this.unidadesFiscaisSelecionadas.length > 0;
        return (ehUnidadeFiscal || ehLocalizacao) && foiSelecionadaUmaOuMaisUnidade;
    }

    exibirUsuarios(): boolean {
        let ehUnidadeFiscal = this.form.controls.tipoDestinatario?.value?.tipoDestinatarioId == ETipoDestinatario.UNIDADE_FISCAL;
        return ehUnidadeFiscal;
    }
}
