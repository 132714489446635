import {
    Component,
    OnInit,
    Input,
    forwardRef,
    ViewChild,
    AfterViewInit,
    Injector,
    ChangeDetectionStrategy
} from "@angular/core";
import {
    NgbTimeStruct,
    NgbDateStruct,
    NgbPopoverConfig,
    NgbPopover,
    NgbDatepicker
} from "@ng-bootstrap/ng-bootstrap";
import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor,
    NgControl
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import { noop } from "rxjs";
import { DateTimeModel } from "./app.component.datimepicker.model";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "app-date-time-picker",
    templateUrl: "./app.component.datimepicker.html",
    styleUrls: ["./app.component.datimepicker.scss"],
    providers: [
        DatePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true
        }
    ]
})
export class DateTimePickerComponent
    implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input()
    dateString: string;

    @Input()
    inputDatetimeFormat = "dd/MM/yyyy HH:mm:ss";
    @Input()
    hourStep = 1;
    @Input()
    minuteStep = 15;
    @Input()
    secondStep = 30;
    @Input()
    seconds = true;

    @Input()
    disabled = false;
    @Input()
    ehEdicao = false;

    @Input() set noSelectTime(_: any) {
        this.selectTime = false;
    }

    @Input() set internalClass(internalClass: string) {
        this.class = internalClass;
    }

    private showTimePickerToggle = false;

    public selectTime = true;
    public class: string = "input-group mr-2";
    private datetime: DateTimeModel = new DateTimeModel();
    private firstTimeAssign = false;
    public mask = this.inputDatetimeFormat.replace(/[a-zA-Z]/g, "0");

    @ViewChild(NgbPopover, { static: true })
    private popover: NgbPopover;

    private onTouched: () => void = noop;
    private onChange: (_: any) => void = noop;

    public ngControl: NgControl;

    constructor(private config: NgbPopoverConfig, private inj: Injector) {
        config.autoClose = "outside";
        config.placement = "auto";
    }

    ngOnInit(): void {
        this.ngControl = this.inj.get(NgControl);
        this.mask = this.inputDatetimeFormat.replace(/[a-zA-Z]/g, "0");
    }

    ngAfterViewInit(): void {
        this.popover.hidden.subscribe($event => this.showTimePickerToggle = false);
    }

    writeValue(newModel: string) {
        if (newModel) {
            this.datetime = Object.assign(
                this.datetime,
                DateTimeModel.fromLocalString(newModel)
            );
            this.dateString = newModel;
            this.setDateStringModel();
        } else
            this.datetime = new DateTimeModel();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    toggleDateTimeState($event) {
        this.showTimePickerToggle = !this.showTimePickerToggle;
        $event.stopPropagation();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onInputChange($event: any) {
        const value = $event.target.value;
        const dt = DateTimeModel.fromLocalString(value);

        if (dt) {
            this.datetime = dt;
            this.setDateStringModel();
        } else if (value.trim() === "") {
            this.datetime = new DateTimeModel();
            this.dateString = "";
            this.onChange(this.dateString);
        } else
            this.onChange(value);
    }

    onDateChange($event: string | NgbDateStruct, dp: NgbDatepicker) {
        const date = new DateTimeModel($event);

        if (!date) {
            this.dateString = this.dateString;
            return;
        }

        if (!this.datetime)
            this.datetime = date;

        this.datetime.year = date.year;
        this.datetime.month = date.month;
        this.datetime.day = date.day;

        const adjustedDate = new Date(this.datetime.toString());
        if (this.datetime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
            this.datetime.timeZoneOffset = adjustedDate.getTimezoneOffset();
        }

        this.setDateStringModel();
        this.popover.close();        
    }

    onTimeChange(event: NgbTimeStruct) {
        this.datetime.hour = event.hour;
        this.datetime.minute = event.minute;
        this.datetime.second = event.second;

        this.setDateStringModel();
    }

    setDateStringModel() {
        const dateString = this.datetime.toString();

        if (this.dateString == dateString)
            return;

        this.dateString = dateString;

        if (!this.firstTimeAssign)
            this.onChange(this.dateString);
        else if (this.dateString !== null)
            this.firstTimeAssign = false;
    }

    inputBlur($event) {
        this.onTouched();
    }
}
