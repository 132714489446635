<form [formGroup]="form">
    <h2 class="fs-title text-center">Selecione o comportamento do Alerta</h2>
    <div class="d-flex justify-content-center flex-column my-5">
        <div class="form-group d-flex justify-content-center">
            <div class="col-md-6 main-card d-flex justify-content-left flex-column">
                <label class="control-label text-left">Comportamento</label>
                <select class="form-control form-control-sm" formControlName="comportamentoRegra">
                    <option value>SELECIONE...</option>
                    <option *ngFor=" let comportamentoRegra of comportamentosRegra$ | async"
                        [ngValue]="comportamentoRegra">{{
                        comportamentoRegra.nome }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</form>
