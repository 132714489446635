export class ParametroSaida {
    parametroSaidaId: number;
    nome: string;
    usuarioIdInclusao: number;
    usuarioNomeInclusao: string;
    dataHoraInclusao: Date;
    situacaoId: number;
    situacaoNome: string;
    usuarioIdAlteracao: number;
    usuarioNomeAlteracao: string;
    dataHoraAlteracao: Date;
    tipoDadoId: number;
    tipoDadoNome: string;
    caminhoMapeado: string;
    compoeNotificacao?: boolean;
    compoeVisualizacao?: boolean;

    constructor(dtoParametroSaida: any) {
        this.parametroSaidaId = dtoParametroSaida.parametroSaidaID;
        this.nome = dtoParametroSaida.nome;
        this.usuarioIdInclusao = dtoParametroSaida.usuarioIdInclusao;
        this.usuarioNomeInclusao = dtoParametroSaida.usuarioNomeInclusao;
        this.dataHoraInclusao = dtoParametroSaida.dataHoraInclusao;
        this.situacaoId = dtoParametroSaida.situacaoID;
        this.situacaoNome = dtoParametroSaida.situacaoNome;
        this.usuarioIdAlteracao = dtoParametroSaida.usuarioIdAlteracao;
        this.usuarioNomeAlteracao = dtoParametroSaida.usuarioNomeAlteracao;
        this.dataHoraAlteracao = dtoParametroSaida.dataHoraAlteracao;
        this.tipoDadoId = dtoParametroSaida.tipoDadoID;
        this.tipoDadoNome = dtoParametroSaida.tipoDadoNome;
        this.caminhoMapeado = dtoParametroSaida.caminhoMapeado;
        this.compoeNotificacao = dtoParametroSaida.compoeNotificacao;
        this.compoeVisualizacao = dtoParametroSaida.compoeVisualizacao;
    }
}
