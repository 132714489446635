import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { EOrderBy } from '../constantes/app.eorderby.const';

export type SortColumn<T> = keyof T | '';
export type SortDirection = EOrderBy.ASC | EOrderBy.DESC | '';
const rotate: { [key: string]: SortDirection } = { [EOrderBy.ASC]: EOrderBy.DESC, [EOrderBy.DESC]: '', '': EOrderBy.ASC };

export interface SortEvent<T> {
    column: SortColumn<T>;
    direction: SortDirection;
}

@Directive({
    selector: 'th[sortable]',
    host: {
        '[class.asc]': `direction === ${EOrderBy.ASC}`,
        '[class.desc]': `direction === ${EOrderBy.DESC}`,
        '(click)': 'rotate()'
    }
})
export class NgbdSortableHeader<T> {

    @Input() sortable: SortColumn<T> = '';
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<SortEvent<T>>();

    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}
