import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ContentDescriptor } from './master.module';

@Injectable()
export class ContentService {
    private contentInit$ = new Subject<ContentDescriptor>();

    contentInit(): Observable<ContentDescriptor> {
        return this.contentInit$.asObservable();
    }

    registerContent(content: ContentDescriptor) {
        this.contentInit$.next(content);
    }
}
