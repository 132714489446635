import { Perfil } from 'src/app/model/app.perfil.model';
import { ChefeUnidadeFiscal } from './app.chefeunidadefiscal.model';
export class Usuario {
    usuarioId?: number;
    nome?: string;
    login?: string;
    perfil?: Perfil;
    perfilId?: number;
    perfilNome?: string;
    dataHoraInclusao?: string;
    situacaoId?: number;
    situacaoNome?: string;
    unidadeFiscalId?: number;
    unidadeFiscalNome?: string;
    dataHoraAlteracao?: string;
    usuarioIdAlteracao?: number;
    usuarioNomeAlteracao?: string;
    chefeUnidadesFiscais?: ChefeUnidadeFiscal[];
    regraUnidadeFiscalId?: number;

    constructor(usuarioDto: any) {
        this.usuarioId = usuarioDto?.usuarioID || usuarioDto?.usuarioId;
        this.nome = usuarioDto?.nome;
        this.login = usuarioDto?.login;
        this.perfil = usuarioDto?.perfil ? new Perfil(usuarioDto?.perfil) : null;
        this.perfilNome = this.perfil?.nome;
        this.dataHoraInclusao = usuarioDto?.dataHoraInclusao;
        this.situacaoId = usuarioDto?.situacaoID;
        this.situacaoNome = usuarioDto?.situacaoNome;
        this.unidadeFiscalId = usuarioDto?.unidadeFiscalID;
        this.unidadeFiscalNome = usuarioDto?.unidadeFiscalNome;
        this.dataHoraAlteracao = usuarioDto?.dataHoraAlteracao;
        this.usuarioIdAlteracao = usuarioDto?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = usuarioDto?.usuarioNomeAlteracao;
        this.chefeUnidadesFiscais = usuarioDto?.chefeUnidadesFiscais?.map(chefeUnidadeFiscal => new ChefeUnidadeFiscal(chefeUnidadeFiscal));
    }
}
