import { ETipoLiberacao } from './../../../constantes/app.etipoliberacao.const';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { AlertaService } from 'src/app/services/app.alerta.service';
import { Alerta } from 'src/app/model/app.alerta.model';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { AtenderAlertaValidator } from 'src/app/validators/atender.alerta.validator';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { Regra, RegraFiltroEntrada, RegraParametroEntrada } from 'src/app/model';
import { RegraService } from 'src/app/services/app.regra.service';
import { combineLatest, switchMap } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ComunService } from 'src/app/services/app.comum.service';
import { Equipamento } from 'src/app/model/app.equipamentos.model';

@Component({
    selector: 'app-alerta-atender',
    templateUrl: './alerta.atender.component.html',
    styleUrls: ['./alerta.atender.component.scss'],
    providers: [
        DialogUtil
    ]
})
export class AlertaAtenderComponent {

    public form: FormGroup;
    public data: Alerta;
    public equipamentos: Equipamento[] = [];
    public eTipoLiberacao: ListItem[] = ETipoLiberacao.toList();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: AlertaService,
        private serviceRegra: RegraService,
        private formBuilder: FormBuilder,
        private blockUtil: BlockUtil,
        private toast: ToastUtil,
        private validator: AtenderAlertaValidator,
        private dialogModal: DialogUtil,
        private comum: ComunService
    ) {

        this.form = this.formBuilder.group({
            alertaId: new FormControl(null),
            tipoLiberacao: new FormControl(''),
            motivo: new FormControl(null),
            serie: new FormControl(null),
            numeroDocumento: new FormControl(null),
            unidade: new FormControl(null),
        });

        const alertaId = +this.route.snapshot.queryParamMap.get("alertaId");
        combineLatest({
            alerta: this.service.getById(alertaId),
            equipamentos: this.comum.ObterEquipamentos()
                .pipe(filter(equipamentos => !!equipamentos)),
        })
            .pipe(
                take(1),
            )
            .subscribe({
                next: ({ alerta, equipamentos }) => {
                    const { motivo, serie, numeroDocumento, alertaTipoLiberacaoId, unidade } = alerta;
                    this.data = alerta;
                    this.equipamentos = equipamentos;
                    const tipoLiberacao = this.eTipoLiberacao.find(tipoLiberacao => tipoLiberacao.tipoLiberacaoId == alertaTipoLiberacaoId) ?? '';
                    this.onClear();
                    this.form.patchValue({ alertaId, motivo, serie, numeroDocumento, tipoLiberacao, unidade });
                },
                error: () => this.data = null
            });
    }

    get estaFinalizado() { return this.data?.situacaoId == ESituacao.FINALIZADO; }
    get estaEmAtendimento() { return this.data?.situacaoId == ESituacao.EM_ATENDIMENTO; }
    get estaDevolvido() { return this.data?.situacaoId == ESituacao.DEVOLVER_ATENDIMENTO; }
    get estaDesativado() { return this.data?.situacaoId == ESituacao.DESATIVADO; }
    get estaPendente() { return this.data?.situacaoId == ESituacao.PENDENTE; }
    get estaVinculado() { return this.data?.situacaoId == ESituacao.VINCULADO; }

    get EmAtendimento() { return this.data?.alertaHistoricos.find(atendimentoHistorico => atendimentoHistorico.situacaoId == ESituacao.EM_ATENDIMENTO) }
    get Finalizado() { return this.data?.alertaHistoricos.find(atendimentoHistorico => atendimentoHistorico.situacaoId == ESituacao.FINALIZADO) }
    get Devolvido() { return this.data?.alertaHistoricos.find(atendimentoHistorico => atendimentoHistorico.situacaoId == ESituacao.DEVOLVER_ATENDIMENTO) }
    get Desativado() { return this.data?.alertaHistoricos.find(atendimentoHistorico => atendimentoHistorico.situacaoId == ESituacao.DESATIVADO) }

    get ehTermoDeApreensao() { return this.form.controls.tipoLiberacao.value?.tipoLiberacaoId == ETipoLiberacao.TERMO_DE_APREENSAO; }
    get ehTermoDeVerificacaoFiscal() { return this.form.controls.tipoLiberacao.value?.tipoLiberacaoId == ETipoLiberacao.TERMO_DE_VERIFICACAO_FISCAL; }
    get ehALIM() { return this.form.controls.tipoLiberacao.value?.tipoLiberacaoId == ETipoLiberacao.ALIM; }
    get ehOutrasLiberacoes() { return this.form.controls.tipoLiberacao.value?.tipoLiberacaoId == ETipoLiberacao.OUTRAS_LIBERCOES; }
    get ehRegistroPassagemEstadual() { return this.form.controls.tipoLiberacao.value?.tipoLiberacaoId == ETipoLiberacao.REGISTRO_DE_PASSAGEM_ESTADUAL; }

    get mostrarNumeroDocumento() { return this.ehTermoDeApreensao || this.ehTermoDeVerificacaoFiscal || this.ehALIM || this.ehRegistroPassagemEstadual; }
    get mostrarMotivo() { return this.ehOutrasLiberacoes; }
    get mostrarSerie() { return this.ehALIM; }
    get mostrarUnidade() { return this.ehTermoDeApreensao || this.ehTermoDeVerificacaoFiscal || this.ehRegistroPassagemEstadual; }

    get placa(): string { return this.data.alertasParametroSaida.find(({ nome }) => nome == "Placa")?.valor; }

    get regra(): Regra {
        return this.data?.regra;
    }

    get filtrosEntrada(): any {
        return (this.data?.regra.regrasFiltroEntrada || [])
            .map(filtroEntrada => {
                const { valor } = this.data.regra
                    ?.regrasFiltroEntrada
                    ?.find(({ filtroEntradaNome }) => filtroEntradaNome == filtroEntrada.filtroEntradaNome) || {};
                filtroEntrada.valor = valor;
                const { tipoDadoNome } = filtroEntrada;
                if (tipoDadoNome == 'EQUIPAMENTOS') {
                    const { local, sentidoDaLeitura, nome } = this.equipamentos.find(({ identificadorDoEquipamento }) => identificadorDoEquipamento == filtroEntrada.valor) || {};
                    filtroEntrada.localNome = local?.nome;
                    filtroEntrada.sentidoDaLeitura = sentidoDaLeitura;
                }

                return filtroEntrada;
            });
    }

    get parametrosEntrada(): any {
        return (this.data?.regra.regrasParametroEntrada || [])
            .filter(parametroEntrada => {
                const isValid = this.data.regra
                    ?.modeloRegra?.parametrosEntrada
                    ?.some(({ nome }) => nome == parametroEntrada.parametroEntradaNome) || {};
                const { tipoDadoNome } = parametroEntrada;
                if (tipoDadoNome == 'EQUIPAMENTOS') {
                    const { local, sentidoDaLeitura, nome } = this.equipamentos.find(({ identificadorDoEquipamento }) => identificadorDoEquipamento == parametroEntrada.valor) || {};
                    parametroEntrada.localNome = local?.nome;
                    parametroEntrada.sentidoDaLeitura = sentidoDaLeitura;
                }

                return parametroEntrada && isValid;
            });
    }

    onSubmit() {
        this.dialogModal.open({
            title: this.data.modeloRegra.nome,
            text: `Deseja finalizar o Alerta?`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        this.validator.set = this.form;
                        if (!this.validator.isValid) {
                            this.blockUtil.desbloquear();
                            this.validator.showMessages();
                        } else {
                            this.service.onFinalizarAlerta(this.validator.atenderAlerta)
                                .subscribe({
                                    next: (data: string) => {
                                        this.toast.showSuccess(data);
                                        this.blockUtil.desbloquear();
                                        this.service.get({});
                                        this.router.navigate(['alerta']);
                                    },
                                    error: (erro: string) => {
                                        this.toast.showWarning(erro.toString().replace('Error:', ''));
                                        this.blockUtil.desbloquear();
                                    }
                                })
                        }
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    onFinalizeAndRules() {
        this.dialogModal.open({
            title: this.data.modeloRegra.nome,
            text: `Deseja finalizar o Alerta e Regra?`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        this.validator.set = this.form;
                        if (!this.validator.isValid) {
                            this.blockUtil.desbloquear();
                            this.validator.showMessages();
                        } else {
                            this.service.onFinalizarAlertaERegra(this.validator.atenderAlerta)
                                .pipe(
                                    switchMap(() => this.serviceRegra.putSituacao({
                                        regraId: this.data.regra.regraId,
                                        situacaoId: ESituacao.DESATIVADO
                                    })
                                        .pipe(
                                            map(() => "Alerta e Regra finalizados com sucesso!")
                                        )
                                    )
                                )
                                .subscribe({
                                    next: (data: string) => {
                                        this.toast.showSuccess(data);
                                        this.blockUtil.desbloquear();
                                        this.service.get({});
                                        this.router.navigate(['alerta']);
                                    },
                                    error: (erro: string) => {
                                        this.toast.showWarning(erro.toString().replace('Error:', ''));
                                        this.blockUtil.desbloquear();
                                    }
                                })
                        }
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    onClear() {
        this.form.patchValue({
            motivo: null,
            serie: null,
            numeroDocumento: null,
            unidade: null
        });
    }

    onConfirm() {
        this.dialogModal.open({
            title: this.data.modeloRegra.nome,
            text: `Deseja confirmar as informações do Alerta?`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        this.validator.set = this.form;
                        if (!this.validator.isValid) {
                            this.blockUtil.desbloquear();
                            this.validator.showMessages();
                        } else {
                            const data = this.validator.atenderAlerta;
                            data.situacaoId = this.data.situacaoId;
                            this.service.atender(data)
                                .subscribe({
                                    next: (data: string) => {
                                        this.toast.showSuccess("Alerta confirmada com sucesso!");
                                        this.blockUtil.desbloquear();
                                        this.service.get({});
                                        this.router.navigate(['alerta']);
                                    },
                                    error: (erro: string) => {
                                        this.toast.showWarning(erro.toString().replace('Error:', ''));
                                        this.blockUtil.desbloquear();
                                    }
                                })
                        }
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    onGiveBack() {
        this.dialogModal.open({
            title: this.data.modeloRegra.nome,
            text: `Você está prestes a devolver o Alerta<br/><strong>Deseja continuar?</strong>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        this.data.situacaoId = ESituacao.PENDENTE;
                        this.data.situacaoNome = ESituacao.PENDENTE.toString();
                        this.service.devolverParaFilaDePendentes(this.data)
                            .subscribe({
                                next: () => {
                                    this.toast.showSuccess("Alerta devolvido com sucesso!");
                                    this.blockUtil.desbloquear();
                                    this.service.get({});
                                    this.router.navigate(['alerta']);
                                },
                                error: (erro: string) => {
                                    this.toast.showDanger(erro);
                                    this.blockUtil.desbloquear();
                                }
                            });
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    voltar() {
        this.router.navigate(['alerta']);
    }

    formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada: RegraParametroEntrada, valorFormatado: string): string[] {
        let retorno = new Array();
        retorno.push(parametroEntrada?.operadorNome);
        retorno.push(valorFormatado);
        if (parametroEntrada.agrupadorNome)
            retorno.push(parametroEntrada.agrupadorNome);

        return retorno;
    }

    formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada: RegraFiltroEntrada, valorFormatado: string): string[] {
        let retorno = new Array();
        retorno.push(filtroEntrada?.operadorNome);
        retorno.push(valorFormatado);
        if (filtroEntrada.agrupadorNome)
            retorno.push(filtroEntrada.agrupadorNome);

        return retorno;
    }
}
