<my-content placeholder="main">
    <div class="main-content">
        <div class="card">
            <div class="d-flex flex-row px-2 my-3">
                <span class="form-label h4 mx-3 my-auto">Dashboard</span>
                <select [(ngModel)]="horas" class="form-control form-control-sm form-select col-md-1 col-sm-12"
                    (change)="onGetDashboard()">
                    <option value="24">24h</option>
                    <option value="168">7 dias</option>
                    <option value="720">30 dias</option>
                    <option value="2160">3 meses</option>
                    <option value="4320">6 meses</option>
                    <option value="8760">12 meses</option>
                </select>
            </div>
            <div class="d-flex justify-content-center px-2" [ngClass]="{ 'row row-cols-sm-1': isMobile }">
                <div *ngFor="let qtde of qtdesAlertas" class="px-2 w-100">
                    <div class="card rounded-5">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <i [class]="qtde.icone"></i>
                                <h6 class="card-title ml-1">{{qtde.situacaoNome}}</h6>
                            </div>
                            <div class="d-flex flex-row justify-content-between ml-3">
                                <h1 class="mb-0">{{qtde.qtde}}</h1>
                                <div class="justify-content-end">
                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="900.000000pt"
                                        height="520.000000pt" viewBox="0 0 900.000000 520.000000"
                                        preserveAspectRatio="xMidYMid meet" class="{{qtde.icone}}" width="100px"
                                        height="55px">
                                        <g transform="translate(0.000000,520.000000) scale(0.100000,-0.100000)"
                                            stroke="none">
                                            <path d="M2526 4758 c-14 -20 -16 -235 -16 -2096 0 -1410 3 -2080 10 -2093 10
                                    -18 25 -19 413 -19 300 0 406 3 415 12 9 9 12 489 12 2099 0 1884 -2 2089 -16
                                    2103 -13 14 -65 16 -409 16 l-394 0 -15 -22z" />
                                            <path d="M6766 4758 c-14 -20 -16 -235 -16 -2096 0 -1410 3 -2080 10 -2093 10
                                    -18 25 -19 413 -19 300 0 406 3 415 12 9 9 12 489 12 2099 0 1884 -2 2089 -16
                                    2103 -13 14 -65 16 -409 16 l-394 0 -15 -22z" />
                                            <path d="M3582 4338 c-17 -17 -17 -3759 0 -3776 9 -9 115 -12 415 -12 388 0
                                    403 1 413 19 7 13 10 617 10 1881 0 1264 -3 1868 -10 1881 -10 18 -25 19 -413
                                    19 -300 0 -406 -3 -415 -12z" />
                                            <path d="M7822 4338 c-17 -17 -17 -3759 0 -3776 9 -9 115 -12 415 -12 388 0
                                    403 1 413 19 14 27 14 3735 0 3762 -10 18 -25 19 -413 19 -300 0 -406 -3 -415
                                    -12z" />
                                            <path d="M1472 3918 c-9 -9 -12 -395 -12 -1677 0 -917 4 -1672 8 -1679 7 -10
                                    97 -12 418 -10 l409 3 3 1674 c1 1229 -1 1677 -9 1687 -16 19 -798 21 -817 2z" />
                                            <path d="M5712 3918 c-9 -9 -12 -395 -12 -1677 0 -917 4 -1672 8 -1679 7 -10
                                    97 -12 418 -10 l409 3 3 1674 c1 1229 -1 1677 -9 1687 -16 19 -798 21 -817 2z" />
                                            <path d="M450 3082 c-14 -2 -31 -9 -37 -14 -18 -15 -19 -2488 -1 -2506 17 -17
                                    809 -17 826 0 9 9 12 301 12 1254 0 1118 -2 1243 -16 1255 -12 10 -96 13 -387
                                    13 -205 1 -383 0 -397 -2z" />
                                            <path d="M4690 3082 c-14 -2 -31 -9 -37 -14 -18 -15 -19 -2488 -1 -2506 17
                                    -17 809 -17 826 0 9 9 12 301 12 1254 0 1118 -2 1243 -16 1255 -12 10 -96 13
                                    -387 13 -205 1 -383 0 -397 -2z" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row px-2" [ngClass]="{ 'row row-cols-sm-1': isMobile }">
                <div class="d-flex flex-fill">
                    <div class="card rounded-5 mx-2 p-2">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <span class="h6 card-title">Alertas Pendentes</span>
                            </div>
                            <div>
                                <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                    [xaxis]="chartOptions.xaxis" [dataLabels]="chartOptions.dataLabels"
                                    [grid]="chartOptions.grid" [stroke]="chartOptions.stroke"
                                    [title]="chartOptions.title"></apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-2 d-flex flex-column w-30">
                    <div class="card rounded-5">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <i class="fas fa-wifi"></i>
                                <span class="h6 card-title ml-3">Status Motor de Regras</span>
                            </div>
                            <div class="d-flex flex-row justify-content-center align-content-center"
                                *ngIf="motorOnline">
                                <span class="h4 mb-0 mt-0 d-flex"><i
                                        class="fas fa-check-circle text-success align-self-center"></i></span>
                                <span class="h3 card-title ml-3 text-success mb-0">Online</span>
                            </div>
                            <div class="d-flex flex-row justify-content-center align-content-center"
                                *ngIf="!motorOnline">
                                <span class="h4 mb-0 mt-0 d-flex"><i
                                        class="fas fa-times-circle text-danger align-self-center"></i></span>
                                <span class="h3 card-title ml-3 text-danger mb-0">Offline</span>
                            </div>
                            <div class="d-flex justify-content-center mt-3">
                                <span class="last-update">Última atualização: {{ultimaAtualizacao |
                                    date: 'dd/MM/yyyy'}} às
                                    {{ultimaAtualizacao | date: 'hh:mm:ss'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card rounded-5">
                        <div class="card-body">
                            <div class="d-flex flex-row">
                                <i class="fas fa-pencil-ruler text-info"></i>
                                <span class="h6 card-title ml-3">Regras</span>
                            </div>
                            <div class="d-flex flex-column resume-rules">
                                <span>Regras com alertas pendentes hoje: 0</span>
                                <span>Alertas pendentes hoje: {{regras?.qtdeAlertasPendentes??0}}</span>
                                <span>Total de regras cadastradas: {{regras?.totalQtdeRegras??0}}</span>
                                <span>Regras sem alertas pendentes hoje: {{regras?.regrasSemAlertas??0}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
