import { Injectable } from "@angular/core";
import { BlockUIService } from "ng-block-ui";

@Injectable()
export class BlockUtil {
    blockName: string;

    constructor(private blockUIService: BlockUIService) { }

    bloquear(instanceName: string = null): void {
        this.blockUIService.start(instanceName || this.blockName);
    }

    desbloquear(instanceName: string = null): void {
        this.blockUIService.stop(instanceName || this.blockName);
    }
}
