<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body ">
                        <div class="form-horizontal mx-3 " *ngIf="data ">
                            <div class="form-body ">
                                <div class="row ">
                                    <div class="col-md-12 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Nome</label>
                                            <h5 class="p-2">{{data.nome}}</h5>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row justify-content-center">
                                    <div class="col-md-3 d-flex  flex-row border rounded p-2 m-1"
                                        *ngFor="let moduloPerfil of data.modulosPerfil">
                                        <div class="col-md-7">
                                            <label class="control-label ">Menu</label>
                                            <h5 class="p-2">{{moduloPerfil.moduloTitulo}}</h5>
                                        </div>
                                        <div class="col-md-5 ">
                                            <ul class="list-group">
                                                <label class="control-label mb-3">Permissões</label>
                                                <li *ngFor="let acao of moduloPerfil.acoes">
                                                    <p class="m-0"> {{ acao.nome }}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-sm default " (click)="voltar() ">Voltar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
