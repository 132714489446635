import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from "src/app/directives/app.directives.module";
import { LocalizacaoEventoComponent } from './localizacaoevento.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ComponentsModule } from "src/app/components/components.module";
import { BlockUIModule } from "ng-block-ui";
import { LocalizacaoEventoVisualizarComponent } from "./visualizar/localizacaoevento.visualizar.component";


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        DirectivesModule,
        NgMultiSelectDropDownModule.forRoot(),
        ComponentsModule,
        BlockUIModule.forRoot(),
    ],
    declarations: [
        LocalizacaoEventoComponent,
        LocalizacaoEventoVisualizarComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LocalizacaoEventoModule { }
