import { Component, Input } from '@angular/core';
import { Modulo } from 'src/app/model';
import { Grupo } from 'src/app/model/app.grupo.model';

@Component({
    selector: 'app-sidebar-grupo',
    template: `
    <ng-container *ngIf="!grupo.ehUnico">
        <li class="nav-item" (click)="collapse.toggle()" [attr.aria-expanded]="estaAberto"
            aria-controls="collapseExample">
            <a class="d-flex p-1 m-0">
                <i *ngIf="!estaAberto" class="fa-solid fa-caret-down"></i>
                <i *ngIf="estaAberto" class="fa-solid fa-caret-right"></i>
                <p class="w-100">{{grupo.nome}}</p>
            </a>
        </li>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="estaAberto">
            <li routerLinkActive="active" *ngFor="let modulo of grupo.modulos" class="nav-item">
                <a [routerLink]="[modulo.caminho]" class="d-flex p-1 m-0">
                    <i class="fa-solid {{modulo.icone}}"></i>
                    <p class="w-100">{{modulo.titulo}}</p>
                    <i class="fa-solid fa-caret-right"></i>
                </a>
            </li>
        </div>
    </ng-container>
    <ng-container *ngIf="grupo.ehUnico">
        <li routerLinkActive="active" class="nav-item">
            <a [routerLink]="[grupo.modulos[0].caminho]" class="d-flex p-1 m-0">
                <i *ngIf="grupo.grupoId == 2" class="fa-solid fa-scale-balanced"></i>
                <i *ngIf="grupo.grupoId == 4" class="fa-solid fa-circle-exclamation"></i>
                <i *ngIf="grupo.grupoId == 5" class="fa-solid fa-chart-line"></i>
                <p class="w-100">{{grupo.modulos[0].titulo}}</p>
                <i class="fa-solid fa-caret-right"></i>
            </a>
        </li>
    </ng-container>
    `
})

export class GrupoComponent {
    @Input() grupo: Grupo;
    private _estaAberto: boolean = false;

    get estaAberto() {
        return this._estaAberto;
    }

    set estaAberto(estaAberto: boolean) {
        this._estaAberto = estaAberto;
    }
}
