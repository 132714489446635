import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ETipoDestinatario } from "../constantes/app.etipodestinatario.const";
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { Regra } from "../model";

@Injectable({
    providedIn: 'root'
})
export class Passo3RegraValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _regra: Regra;

    constructor(private toast: ToastUtil) {
        this._messages = [];
    }

    get isValid() { return this._messages.length == 0; }
    get regra() { return this._regra; }
    get mensagens() { return this._messages; }

    set set(form: UntypedFormGroup) {
        this._form = form;
        this._regra = {
            comportamentoRegraId: this._form.controls.comportamentoRegra?.value?.comportamentoRegraId
        };
    }

    private _validate() {
        const {
            comportamentoRegraId
        } = this._regra;

        this._messages = [];
        if (!comportamentoRegraId)
            this._messages.push({ type: ToastType.WARNING, message: "Comportamento Regra é obrigatório!" });
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }
}
