import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { ETipoDado } from 'src/app/constantes/app.etipodado.const';
import { CpfPipe } from 'src/app/pipes/app.cpf.pipe';
import { CnpjPipe } from 'src/app/pipes/app.cnpj.pipe';
import { PlacaPipe } from 'src/app/pipes/app.placa.pipe';
import { InscricaoEstadualPipe } from 'src/app/pipes/app.inscricaoestadual.pipe';

@Injectable()
export class FormControlUtil {

    private _tipoCampo: any;

    constructor(
        private cpfPipe: CpfPipe,
        private cnpjPipe: CnpjPipe,
        private placaPipe: PlacaPipe,
        private inscricaoEstadualPipe: InscricaoEstadualPipe
    ) {

        this._tipoCampo = {
            [ETipoDado.CPF]: {
                formControl: (value = {}) => new UntypedFormControl(value, [Validators.required, Validators.minLength(11), Validators.maxLength(14)]),
                transform: this.cpfPipe.transform
            },
            [ETipoDado.CNPJ]: {
                formControl: (value = {}) => new UntypedFormControl(value, [Validators.required, Validators.minLength(14), Validators.maxLength(18)]),
                transform: this.cnpjPipe.transform
            },
            [ETipoDado.INSCRICAO_ESTADUAL]: {
                formControl: (value = {}) => new UntypedFormControl(value, Validators.required),
                transform: this.inscricaoEstadualPipe.transform
            },
            [ETipoDado.PLACA]: {
                formControl: (value = {}) => new UntypedFormControl(value, [Validators.required, Validators.minLength(6)]),
                transform: this.placaPipe.transform
            },
            [ETipoDado.TEXTO]: { formControl: (value = '') => new UntypedFormControl(value, Validators.required) },
            [ETipoDado.INTEIRO]: { formControl: (value = '') => new UntypedFormControl(value, Validators.required) },
            [ETipoDado.DECIMAL]: { formControl: (value = '') => new UntypedFormControl(value, Validators.required) },
            [ETipoDado.DATAHORA]: { formControl: (value = '') => new UntypedFormControl(value, { validators: [DateTimeValidator, Validators.required] }) },
            [ETipoDado.UF]: { formControl: (value = '') => new UntypedFormControl(value, Validators.required) },
            [ETipoDado.EQUIPAMENTOS]: {
                formControl: (value = []) => new UntypedFormControl(value, Validators.required),
                transform: value => value ? ([value]) : ([])
            },
            [ETipoDado.OUTROS]: { formControl: (value = '') => new UntypedFormControl(value, Validators.required) }
        }
    }

    get tipo(): any { return this._tipoCampo; }
}

export const DateTimeValidator = (fc: FormControl) => {
    const date = new Date(fc.value);
    const isValid = !isNaN(date.valueOf());

    return isValid ? null : {
        isValid: {
            valid: false
        }
    };
};
