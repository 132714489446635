export class ParametroEntrada {
    parametroEntradaId: number;
    nome: string;
    usuarioIdInclusao: number;
    usuarioNomeInclusao: string;
    dataHoraInclusao: Date;
    situacaoId: number;
    situacaoNome: string;
    usuarioIdAlteracao: number;
    usuarioNomeAlteracao: string;
    dataHoraAlteracao: Date;
    tipoDadoId: number;
    tipoDadoNome: string;
    caminhoMapeado: string;
    tratadoNoBigData: boolean;
    dataDeTratamento: Date = new Date();
    obrigatorio: boolean;
    valor?: string;
    localNome?: string;
    sentidoDaLeitura?: string;

    constructor(dtoParametroEntrada: any) {
        this.parametroEntradaId = dtoParametroEntrada.parametroEntradaID;
        this.nome = dtoParametroEntrada.nome;
        this.usuarioIdInclusao = dtoParametroEntrada.usuarioIdInclusao;
        this.usuarioNomeInclusao = dtoParametroEntrada.usuarioNomeInclusao;
        this.dataHoraInclusao = dtoParametroEntrada.dataHoraInclusao;
        this.situacaoId = dtoParametroEntrada.situacaoID;
        this.situacaoNome = dtoParametroEntrada.situacaoNome;
        this.usuarioIdAlteracao = dtoParametroEntrada.usuarioIdAlteracao;
        this.usuarioNomeAlteracao = dtoParametroEntrada.usuarioNomeAlteracao;
        this.dataHoraAlteracao = dtoParametroEntrada.dataHoraAlteracao;
        this.tipoDadoId = dtoParametroEntrada.tipoDadoID;
        this.tipoDadoNome = dtoParametroEntrada.tipoDadoNome;
        this.caminhoMapeado = dtoParametroEntrada.caminhoMapeado;
        this.tratadoNoBigData = dtoParametroEntrada.tratadoNoBigData;
        this.dataDeTratamento = dtoParametroEntrada.dataDeTratamento;
        this.obrigatorio = dtoParametroEntrada.obrigatorio;
    }
}
