import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { Regra } from '../model';

@Injectable({
  providedIn: 'root',
})
export class Passo5RegraValidator {
  private _form: UntypedFormGroup;
  private _messages: any[];
  private _regra: Regra;

  constructor (private toast: ToastUtil) {
    this._messages = [];
  }

  get isValid () {
    return this._messages.length == 0;
  }
  get regra () {
    return this._regra;
  }
  get mensagens () {
    return this._messages;
  }

  set set (form: UntypedFormGroup) {
    this._form = form;
    this._regra = {
      justificativa: this._form.controls.justificativa?.value,
    };
    this._validate();
  }

  private _validate () {
    const { justificativa } = this._regra;

    this._messages = [];
    if (String.isNullOrEmpty(justificativa))
      this._messages.push({
        type: ToastType.WARNING,
        message: 'Deve ser informado no mínimo 20 carateres de justificativa para criação de uma regra',
      });
    else if (justificativa.length < 20)
      this._messages.push({
        type: ToastType.WARNING,
        message: 'Deve ser informado no mínimo 20 carateres de justificativa para criação de uma regra',
      });
    else if (justificativa.length > 300)
      this._messages.push({
        type: ToastType.WARNING,
        message: 'Justificativa não pode ser superior a 300 caracteres!',
      });
  }

  public showMessages () {
    this.toast.showMultiple(this._messages);
  }
}
