export class ModuloPerfil {
    moduloPerfilId: number;
    perfilId: string | number;
    perfilNome: string;
    moduloId: number;
    moduloTitulo: string;
    acoes: any[];

    constructor(moduloPerfilDto: any) {
        this.moduloPerfilId = moduloPerfilDto?.moduloPerfilID;
        this.perfilId = moduloPerfilDto?.perfilID;
        this.perfilNome = moduloPerfilDto?.perfilNome;
        this.moduloId = moduloPerfilDto?.modulo.moduloID;
        this.moduloTitulo = moduloPerfilDto?.modulo.titulo;
        this.acoes = moduloPerfilDto?.acoes.map(({ acaoID, nome }) => ({ nome, acaoId: acaoID }));
    }
}
