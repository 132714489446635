import { Component, Input, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})
export class MapaComponent implements OnInit {
  
  constructor(public activeModal: NgbActiveModal) {}

  @Input() nomeUsuario: string;
  @Input() login: string;
  @Input() unidadeDeLotacao: string;
  @Input() dataHoraInclusao: string;
  @Input() coordenadas: string;

  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v12';
  longitude = 0;
  latitude = 0;

  async ngOnInit() {
    this.obterLongitude().then((responseLongitude) => {
      this.longitude = responseLongitude;
    }).finally(() => {
      this.obterLatitude().then((responseLatitude) => {
        this.latitude = responseLatitude;
      })
    }).finally(() => {
      this.abrirMapa(this.longitude, this.latitude);
    })
  }

  async obterLongitude(): Promise<number> {
    return this.prepadarDadosParaRetorno(1);
  }

  async obterLatitude(): Promise<number> {
    return this.prepadarDadosParaRetorno(0);
  }

  async prepadarDadosParaRetorno(posicaoDoArray: number): Promise<number> {
    return +this.coordenadas.replace('POINT', '').replace('(', '').replace(')', '').split(',')[posicaoDoArray] ?? null;
  }

  async abrirMapa(longitude: number, latitude: number) {
    if ((!latitude || latitude === 0) || (!longitude || longitude === 0))
      return;

    (mapboxgl as typeof mapboxgl).accessToken = 'pk.eyJ1IjoiZGFuaWVsdHJpYm9uaSIsImEiOiJjbGZzc2w0MGQwOHo1M3NvMTV1c2dza21vIn0.o5plCFUF0qJmFRDTsZi5XA';
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 16,
      center: [longitude, latitude],
      scrollZoom: true,
      touchZoomRotate: false
    });

    const popup = new mapboxgl.Popup({
      offset: 25
    }).setHTML(this.gerarTextoPopup());

    new mapboxgl.Marker({
      color: "#ff0000",
      draggable: true,
    }).setLngLat([longitude, latitude])
      .setPopup(popup)
      .addTo(this.map);

    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
  }

  private gerarTextoPopup(): string {
    let coordenas = `${this.longitude}, ${this.latitude}`;

    return `${this.gerarUmaLinhaComTitutloEConteudo('Usuário', this.nomeUsuario)}
    ${this.gerarUmaLinhaComTitutloEConteudo('Login', this.login)}
    ${this.gerarUmaLinhaComTitutloEConteudo('Unidade', this.unidadeDeLotacao)}
    ${this.gerarUmaLinhaComTitutloEConteudo('Data', this.dataHoraInclusao)}
    ${this.gerarUmaLinhaComTitutloEConteudo('Coordenadas', coordenas)}`;
  }

  private gerarUmaLinhaComTitutloEConteudo(titulo: string, conteudo: string, comQuebraDeLinha: boolean = true): string {
    let novaLinha = comQuebraDeLinha ? this.quebraDeLinha() : '';
    return `${this.textoEmNegrito(titulo)} ${conteudo} ${novaLinha}`;
  }

  private textoEmNegrito(texto: string): string {
    return `<strong>${texto}:</strong>`;
  }

  private quebraDeLinha(): string {
    return '<br />';
  }

  closeModal(): void {
    this.activeModal.close();    
  }

}
