import { CondicionanteVisualizarComponent } from './visualizar/condicionante.visualizar.component';
import { Routes } from '@angular/router';
import { MasterComponent } from '../../components/master/master.component';
import { CondicionanteCadastroComponent } from './cadastro/condicionante.cadastro.component';
import { CondicionantesComponent } from './condicionante.component';
import { CondicionanteEditarComponent } from './editar/condicionante.editar.component';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { EAcao } from 'src/app/constantes/app.eacao.const';

export const CondicionantesRoutes: Routes = [
    {
        path: 'condicionante',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: CondicionantesComponent
            }
        ]
    },
    {
        path: 'condicionante/cadastro',
        component: MasterComponent,
        data: { permissao: EAcao.CADASTRAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: CondicionanteCadastroComponent
            }
        ]
    },
    {
        path: 'condicionante/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: CondicionanteVisualizarComponent
            }
        ]
    },
    {
        path: 'condicionante/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: CondicionanteEditarComponent
            }
        ]
    }
];
