import { ListItem } from './../../../../interfaces/app.utilservices.interface';
import { InscricaoEstadualPipe } from './../../../../pipes/app.inscricaoestadual.pipe';
import { CpfPipe } from './../../../../pipes/app.cpf.pipe';
import { combineLatest, filter, take } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComunService } from 'src/app/services/app.comum.service';
import { ModeloRegraService } from 'src/app/services/app.modeloregra.service';
import { Condicionante, FiltroEntrada, ParametroEntrada, Agrupador, Operador, ModeloRegra } from 'src/app/model';
import { CnpjPipe } from 'src/app/pipes/app.cnpj.pipe';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { EUF } from 'src/app/constantes/app.euf.const';
import { PlacaPipe } from 'src/app/pipes/app.placa.pipe';
import { FormControlUtil } from 'src/app/helper/app.tipocampo.utils';
import { Equipamento } from 'src/app/model/app.equipamentos.model';
import { BlockUtil } from 'src/app/helper/app.block.utils';

@Component({
    selector: 'app-passo1modeloregra',
    templateUrl: './passo1modeloregra.component.html',
    styleUrls: ['./passo1modeloregra.component.scss'],
    providers: [
        ModeloRegraService,
        CpfPipe,
        CnpjPipe,
        PlacaPipe,
        InscricaoEstadualPipe,
        FormControlUtil
    ]
})
export class Passo1ModeloRegraComponent implements OnInit {

    public form: FormGroup;
    public formParametro: FormArray;
    public formFiltro: FormArray;
    public condicionantes: Condicionante[] = [];
    public parametrosEntrada: ParametroEntrada[];
    public operadores: Operador[] = [];
    public agrupadores: Agrupador[] = [];
    public equipamentos: Equipamento[] = [];
    public filtrosEntrada: FiltroEntrada[];
    public modelosRegra: ModeloRegra[] = [];

    public ufs: ListItem[] = EUF.toList();

    constructor(
        private rootFormGroup: FormGroupDirective,
        private toast: ToastUtil,
        private comum: ComunService,
        private util: FormControlUtil,
        private blockUtil: BlockUtil
    ) {
        this.blockUtil.bloquear("modeloRegra");

        combineLatest({
            operadores: this.comum.ObterOperadoresAtivos()
                .pipe(filter(operadores => !!operadores)),
            agrupadores: this.comum.ObterAgrupadoresAtivos()
                .pipe(filter(agrupadores => !!agrupadores)),
            modelosRegra: this.comum.ObterModelosConfiguradosParaSerExibidosNoCadatroDaRegra()
                .pipe(filter(modelosRegra => !!modelosRegra)),
            equipamentos: this.comum.ObterEquipamentos()
                .pipe(filter(equipamentos => !!equipamentos)),
            condicionantes: this.comum.ObterCondicionantesByExibeRegra()
                .pipe(filter(condicionantes => !!condicionantes))
        }).pipe(
            take(1),
        ).subscribe(({ operadores, agrupadores, modelosRegra, equipamentos, condicionantes }) => {
            operadores.forEach(operador => this.operadores.push(operador));
            agrupadores.forEach(agrupador => this.agrupadores.push(agrupador));
            equipamentos.forEach(equipamento => this.equipamentos.push(equipamento));
            modelosRegra.forEach(modeloRegra => this.modelosRegra.push(modeloRegra));
            condicionantes.forEach(condicionante => this.condicionantes.push(condicionante));
            this.blockUtil.desbloquear("modeloRegra");
        });
    }

    get modeloDeRegraSelecionado(): boolean { return !!this.form.controls.modeloRegra?.value; }
    get possuiParametrosEntrada(): boolean { return !!this.formParametro.length; }
    get possuiFiltrosEntrada(): boolean { return !!this.formFiltro.length; }
    get modeloRegra(): ModeloRegra { return this.modelosRegra.filter(m => m.modeloRegraId == this.form.controls.modeloRegra?.value.modeloRegraId)[0]; }
    get condicionanteDomodeloDeRegraSelecionado(): string { return this.modelosRegra.filter(m => m.modeloRegraId == this.form.controls.modeloRegra?.value?.modeloRegraId)[0]?.condicionanteDescricao; }

    onSelectModeloRegra() {
        this.parametrosEntrada = this.modeloRegra?.parametrosEntrada;
        this.filtrosEntrada = this.modeloRegra?.filtrosEntrada;
        this.form.controls.modeloRegra.setValue(this.modeloRegra);
        this.formParametro.clear();
        this.formFiltro.clear();

        this.parametrosEntrada
            ?.filter(({ obrigatorio }) => obrigatorio)
            .map(parametro => Object.assign({}, parametro, { naoRemove: true }))
            .forEach(parametroSelecionado => {
                this.form.patchValue({ parametroSelecionado });
                this.onAddParametroEntrada();
            });

        this.filtrosEntrada
            ?.filter(({ obrigatorio }) => obrigatorio)
            .map(filtro => Object.assign({}, filtro, { naoRemove: true }))
            .forEach(filtroSelecionado => {
                this.form.patchValue({ filtroSelecionado });
                this.onAddFiltroEntrada();
            });
    }

    ngOnInit(): void {
        this.form = this.rootFormGroup.control as UntypedFormGroup;
        this.formParametro = this.rootFormGroup.control.get('formParametro') as UntypedFormArray;
        this.formFiltro = this.rootFormGroup.control.get('formFiltro') as UntypedFormArray;
    }

    onAddParametroEntrada() {
        const parametro = this.form.controls.parametroSelecionado.value;
        if (!parametro)
            return;
        this.onAddFieldInForm(this.formParametro, parametro);
        this.form.patchValue({ parametroSelecionado: '' }, { emitEvent: false });
    }

    onAddFiltroEntrada() {
        const filtro = this.form.controls.filtroSelecionado.value;
        if (!filtro)
            return;
        this.onAddFieldInForm(this.formFiltro, filtro);
        this.form.patchValue({ filtroSelecionado: '' }, { emitEvent: false });
    }

    onAddFieldInForm(form: FormArray, item: any) {
        const { formControl, transform } = this.util.tipo[item.tipoDadoId];
        const campo = formControl({ item: { ...item }, transform, valor: '' });
        form.push(campo);
    }

    onRemoveFieldInFormParametro({ value }) {
        this.formParametro.removeAt(value);
    }

    onRemoveFieldInFormFiltro({ value }) {
        this.formFiltro.removeAt(value);
    }

    modeloeEtaConfiguradoComAQuintaCondicionante(): boolean {
        if (!this.form?.value?.condicionante?.modeloRegra?.estaConfiguradoComAQuintaCondicionante)
            return false;

        return this.form.value.condicionante.modeloRegra.estaConfiguradoComAQuintaCondicionante;
    }
}
