import { ListItem } from '../interfaces/app.utilservices.interface';

export enum ETipoLiberacao {
    SEM_IRREGULARIDADES = 1,
    TERMO_DE_APREENSAO = 2,
    TERMO_DE_VERIFICACAO_FISCAL = 3,
    ALIM = 4,
    OUTRAS_LIBERCOES = 5,
    REGISTRO_DE_PASSAGEM_ESTADUAL = 6
}

export namespace ETipoLiberacao {
    export function toString(situacao: ETipoLiberacao | number): string {
        switch (situacao) {
            case ETipoLiberacao.SEM_IRREGULARIDADES: return 'SEM IRREGULARIDADES';
            case ETipoLiberacao.TERMO_DE_APREENSAO: return 'TERMO DE APREENSÃO';
            case ETipoLiberacao.TERMO_DE_VERIFICACAO_FISCAL: return 'TERMO DE VERIFICAÇÃO FISCAL';
            case ETipoLiberacao.ALIM: return 'ALIM';
            case ETipoLiberacao.OUTRAS_LIBERCOES: return 'OUTRAS LIBERAÇÕES';
            case ETipoLiberacao.REGISTRO_DE_PASSAGEM_ESTADUAL: return 'REGISTRO DE PASSAGEM ESTADUAL';
        }
    }

    export function toList(): ListItem[] {
        return Object.keys(ETipoLiberacao)
            .filter((value) => isNaN(Number(value)) === false)
            .map<ListItem>((key) => ({ tipoLiberacaoId: +key, nome: ETipoLiberacao.toString(+key) }));
    }
}
