<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="form-body ">
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Grupo</label>
                                    <div class="col-md-12 main-card">
                                        <select class="form-control form-control-sm " formControlName="grupo">
                                            <option value>Selecione...</option>
                                            <option *ngFor="let grupo of grupos | async " [ngValue]="grupo">
                                                {{grupo.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Título</label>
                                    <div class="col-md-12 main-card">
                                        <input type="text " class="form-control input-lg " name="titulo"
                                            placeholder="Digite aqui o Título do Modulo... " formControlName="titulo">
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Caminho</label>
                                    <div class="col-md-12 main-card">
                                        <input type="text " class="form-control input-lg " name="caminho"
                                            placeholder="Digite aqui a Rota do Modulo... " formControlName="caminho">
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Ícone</label>
                                    <div class="icone d-flex justify-content-center align-items-center"
                                        *ngIf="iconeSelecionado">
                                        <article style="flex: .25; max-width: 240px !important;">
                                            <div class="card m-0">
                                                <div
                                                    class="d-flex flex-column justify-content-center align-items-center icone-visualizar">
                                                    <i class="{{ iconeSelecionado.classe }} ml-1 mr-2"></i>
                                                    <span class="icon-name">{{ iconeSelecionado.nome }}</span>
                                                </div>
                                                <div *ngIf="!selecionar && iconeSelecionado"
                                                    class="d-flex m-0 icone-btn">
                                                    <div class="text-center w-100 m-0" (click)="removeIcon()">
                                                        <span class="mr-2">Remover</span>
                                                        <i class="fas fa-times"></i>
                                                    </div>
                                                    <div *ngIf="icone" class="text-center w-100 m-0 icone-original"
                                                        (click)="selectOrigin()">
                                                        <span class="mr-2">Original</span>
                                                        <i class="fas fa-crosshairs"></i>
                                                    </div>
                                                </div>
                                                <div *ngIf="selecionar && iconeSelecionado"
                                                    class="d-flex m-0 icone-btn">
                                                    <div class="text-center w-100 m-0" (click)="confirmIcon()">
                                                        <span class="mr-2">Confirmar</span>
                                                        <i class="fas fa-check"></i>
                                                    </div>
                                                    <div *ngIf="icone" class="text-center w-100 m-0 icone-original"
                                                        (click)="selectOrigin()">
                                                        <span class="mr-2">Original</span>
                                                        <i class="fas fa-crosshairs"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div class="icone d-flex justify-content-center align-items-center"
                                        *ngIf="!iconeSelecionado">
                                        <article style="flex: .25; max-width: 240px !important;">
                                            <div class="card m-0">
                                                <div
                                                    class="d-flex flex-column justify-content-center align-items-center icone-vazio">
                                                    <i class="fas fa-ban ml-1 mr-2"></i>
                                                    <span class="">Nenhum</span>
                                                </div>
                                                <div *ngIf="!selecionar || !iconeSelecionado"
                                                    class="d-flex m-0 icone-btn">
                                                    <div class="text-center w-100 m-0" (click)="toogleSelectIcon()">
                                                        <span *ngIf="!selecionar" class="mr-2">Selecionar</span>
                                                        <i *ngIf="!selecionar" class="fas fa-search"></i>
                                                        <span *ngIf="selecionar" class="mr-2">Selecione</span>
                                                        <i *ngIf="selecionar" class="fas fa-angle-down"></i>
                                                    </div>
                                                    <div *ngIf="icone" class="text-center w-100 m-0 icone-original"
                                                        (click)="selectOrigin()">
                                                        <span class="mr-2">Original</span>
                                                        <i class="fas fa-crosshairs"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div class="area-icone {{ selecionar ? 'show' : ''}}">
                                        <div class="col-md-12 main-card input-group mt-3">
                                            <input type="text " class="form-control form-control-sm"
                                                placeholder="Filtar icone por nome" id="filtro"
                                                formControlName="filtro">
                                            <button class="btn btn-secondary m-0" type="button"
                                                (click)="clearFilter()">Limpar</button>
                                        </div>
                                        <div class="lista-icones row">
                                            <article class="col-sm-6 col-md-6 col-lg-4 col-xl-2"
                                                *ngFor="let icone of icones">
                                                <div class="card d-flex flex-row justify-content-start align-items-center {{form.controls.icone.value?.classe == icone.classe ? 'icone-selecionado' : ''}}"
                                                    (click)="selectIcon(icone)">
                                                    <i class="fas fa-check-circle icone-selecao"></i>
                                                    <i class="{{ icone.classe }} ml-1 mr-2"></i>
                                                    <span class="icon-name">{{ icone.nome }}</span>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label class="col-md-5 control-label ">Ordem</label>
                                    <div class="col-md-12 main-card">
                                        <input type="number" class="form-control input-lg " name="ordem"
                                            placeholder="Digite aqui a Ordem do Modulo... " formControlName="ordem">
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-sm default" (click)="cancel()">Cancelar</button>
                                <button type="submit" class="btn btn-sm btn-primary">Atualizar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
