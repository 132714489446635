import { environment as environmentHom } from './../../environments/environment.hom';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, pluck, retry, switchMap, tap } from 'rxjs/operators';
import { Params } from '../interfaces/app.request.interface';
import { Token } from '../model/app.token.model';
import { Injectable } from '@angular/core';

@Injectable()
export class UtilService {
    private token: Token;

    constructor(private http: HttpClient) { }

    public Get<T>(url: string, params: Params = null): Observable<T> {
        const dados: Subject<T> = new BehaviorSubject<T>(null);
        const tokenHom = url.includes('hom.');
        this._token(tokenHom)
            .pipe(
                switchMap((token: Token) => {
                    const { token_type, access_token } = token;
                    const autorization = `${token_type} ${access_token}`;
                    const headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: autorization,
                    });

                    return this.http.get(url, { headers, params, responseType: 'json' }).pipe(
                        map((retorno: any) => {
                            const { statusCode, errors, data } = retorno;
                            if (!!statusCode && statusCode != HttpStatusCode.Ok) throw new Error(errors);
                            if (data)
                                return data as T;
                            return retorno as T;
                        })
                    );
                })
            )
            .subscribe((data: T) => dados.next(data));
        return dados.asObservable();
    }

    public Post<T>(url: string, params: Params): Observable<any> {
        const tokenHom = url.includes('hom.');
        return this._token(tokenHom).pipe(
            switchMap((token: Token) => {
                const { token_type, access_token } = token;
                const autorization = `${token_type} ${access_token}`;
                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: autorization,
                });
                return this.http.post(url, params, { headers, responseType: 'json' }).pipe(
                    map((retorno: any) => {
                        const { statusCode, errors, data } = retorno;
                        if (statusCode != HttpStatusCode.Ok) throw new Error(errors);
                        return data as T;
                    })
                );
            })
        );
    }

    public Put<T>(url: string, params: Params): Observable<any> {
        const tokenHom = url.includes('hom.');
        return this._token(tokenHom).pipe(
            switchMap((token: Token) => {
                const { token_type, access_token } = token;
                const autorization = `${token_type} ${access_token}`;
                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: autorization,
                });
                return this.http.put(url, params, { headers, responseType: 'json' }).pipe(
                    map(({ statusCode, errors, data }: any) => {
                        if (statusCode != HttpStatusCode.Ok) throw new Error(errors);
                        return data as T;
                    })
                );
            })
        );
    }

    private _token(tokenHom: boolean): Observable<Token> {
        // if (this.token && !tokenHom)
        //     return of(this.token);

        let eClientsecret = environment.clientSecret;
        let tokenUrl = environment.tokenUrl;
        if (tokenHom && !environment.production) {
            tokenUrl = environmentHom.tokenUrl;
            eClientsecret = environmentHom.clientSecret;
        }
        const clientSecret = `client_secret=${eClientsecret}`;
        const clientId = `client_id=${environment.clientId}`;
        const grantType = 'grant_type=client_credentials';
        const data = `${grantType}&${clientId}&${clientSecret}`;
        const headers = {
            'content-type': 'application/x-www-form-urlencoded',
            Accept: 'application/json, text/plain, */*',
        };
        
        return this.http.post<Token>(tokenUrl, data, { headers, responseType: 'json' }).pipe(
            retry(2),
            map((token: Token) => token),
            tap((token: Token) => {
                this.token = token;
                setTimeout(() => {
                    this.token = null;
                }, this.token.expires_in * 1000);
            }),
            catchError((erro, souce) => {
                console.log(erro);
                return souce;
            })
        );
    }

    public GetJson<T>(url: string): Observable<T | []> {
        const dados: Subject<T | []> = new BehaviorSubject<T | []>([]);
        this.http
            .get(url)
            .pipe(pluck('data'))
            .subscribe((data: T) => dados.next(data));
        return dados.asObservable();
    }
}
