export class AlertaHistorico {
    alertaHistoricoId: number;
    situacaoId: number;
    situacaoNome: string;
    dataHoraInclusao: string;
    usuarioIdInclusao: number;
    usuarioNomeInclusao: string;

    constructor(alertaHistoricoDto: any) {
        this.alertaHistoricoId = alertaHistoricoDto?.alertaHistoricoId ?? alertaHistoricoDto?.alertaHistoricoID;
        this.situacaoId = alertaHistoricoDto?.situacaoID;
        this.situacaoNome = alertaHistoricoDto?.situacaoNome;
        this.dataHoraInclusao = alertaHistoricoDto?.dataHoraInclusao;
        this.usuarioIdInclusao = alertaHistoricoDto?.usuarioIDInclusao;
        this.usuarioNomeInclusao = alertaHistoricoDto?.usuarioNomeInclusao;
    }
}
