<footer class="footer">
    <div class="container-fluid">
        <nav>
            <ul>
                <li>
                    <a href="" style="color: #c2c2c2;">
            Miltec
          </a>
                </li>
                <li>
                    <a href="" style="color: #c2c2c2;">
            SOBRE NÓS
          </a>
                </li>
            </ul>
        </nav>
        <div class="copyright" style="color: #c2c2c2;">
            &copy; {{test | date: 'yyyy'}}, Layout feito por
            <a href="" target="_blank" style="color: #c2c2c2;">Miltec</a>. Codificado por
            <a href="" target="_blank" style="color: #c2c2c2;">Miltec</a>.
        </div>
    </div>
</footer>
