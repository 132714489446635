import { FiltroEntradaService } from 'src/app/services/app.filtroentrada.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component } from '@angular/core';
import { FiltroEntrada } from 'src/app/model/app.filtroentrada.model';

@Component({
    selector: 'app-visualizar-filtro-cadastro',
    templateUrl: './filtroentrada.visualizar.component.html',
    styleUrls: ['./filtroentrada.visualizar.component.scss'],
    providers: [
        FiltroEntradaService
    ]
})
export class VisualizarFiltroComponent {

    public data: FiltroEntrada;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: FiltroEntradaService
    ) {
        const filtroEntradaId = +this.route.snapshot.queryParamMap.get("filtroEntradaId");
        this.service.getById(filtroEntradaId)
            .subscribe({
                next: (data: any) => this.data = data,
                error: () => this.data = null
            });
    }

    voltar() {
        this.router.navigate(['filtroentrada']);
    }
}
