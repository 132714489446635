<div class="panel-header panel-header-sm">

</div>

<div class="main-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-chart">
        <div class="card-header">
          <article class="card-body text-center">
            <img src="../../assets/img/logo_governo.jpeg" alt="" srcset="">
            <h4>Sistema ALERTA</h4>
            <hr>
            <form>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
                  </div>
                  <input name="" class="form-control" placeholder="Digite sua Matrícula" type="email">
                </div> <!-- input-group.// -->
              </div> <!-- form-group// -->
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                  </div>
                  <input class="form-control" placeholder="Digite sua Senha" type="password">
                </div> <!-- input-group.// -->
              </div> <!-- form-group// -->
              <div class="form-group">
                <button type="submit" class="btn btn-primary btn-block"> Entrar </button>
              </div> <!-- form-group// -->
              <p class="text-center"><a href="#" class="btn">Esqueceu a senha?</a></p>
            </form>
          </article>
        </div>
      </div>
    </div>

  </div>
