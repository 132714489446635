import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateWithLineBreaks'
})
export class TruncateWithLineBreaksPipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (!value) return ''; // Handle null, undefined, or empty string

    if (value.length <= limit) {
      return value;
    }

    const truncatedText = value.substr(0, limit);
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');

    if (lastSpaceIndex !== -1) {
      return truncatedText.substr(0, lastSpaceIndex) + '...';
    } else {
      return truncatedText + '...';
    }
  }
}