import { AccountService } from 'src/app/services/app.login.service';
import { EAcao } from '../constantes/app.eacao.const';

export class ParmissoesPagina {
    public permissoes: any = {};

    constructor(url: string, accountService: AccountService) {
        accountService.PermissionOnModel(url).subscribe({
            next: (permissoes) => (this.permissoes = permissoes),
        });
    }

    public get PodeCadastrar(): boolean {
        return !!this.permissoes[EAcao.toString(EAcao.CADASTRAR)];
    }
    public get PodeExcluir(): boolean {
        return !!this.permissoes[EAcao.toString(EAcao.EXCLUIR)];
    }
    public get PodeVisualizar(): boolean {
        return !!this.permissoes[EAcao.toString(EAcao.VISUALIZAR)];
    }
    public get PodeAlterar(): boolean {
        return !!this.permissoes[EAcao.toString(EAcao.ALTERAR)];
    }
    public get PodeCancelar(): boolean {
        return !!this.permissoes[EAcao.toString(EAcao.EXCLUIR)];
    }
}
