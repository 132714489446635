import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'datetime' })
export class DateTimePipe implements PipeTransform {

	private readonly monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
	private readonly dayOfWeekNames = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"];

	transform(date: string | Date, formato: string = "dd/MM/yyyy HH:mm:ss"): string {
		if (date == null || date == "")
			return "";
		if (typeof date == "string")
			date = new Date(date);

		let yearFull = date.getUTCFullYear();
		let yearMin = yearFull.toString().substring(2);
		let month = date.getUTCMonth() + 1;
		let monthName = this.monthNames[month - 1];
		let day = date.getUTCDate();
		let dayOfWeekName = this.dayOfWeekNames[date.getUTCDay()];
		let hours = date.getHours();
		let minutes = date.getUTCMinutes();
		let seconds = date.getUTCSeconds();

		return formato.replace("yyyy", yearFull.toString()).replace("yy", yearMin)
			.replace("MMMM", monthName).replace("MM", month.toString().padStart(2, '0'))
			.replace("dd", day.toString().padStart(2, '0')).replace("EEEE", dayOfWeekName)
			.replace("HH", hours.toString().padStart(2, '0')).replace("mm", minutes.toString().padStart(2, '0'))
			.replace("ss", seconds.toString().padStart(2, '0'));
	}
}
