import { ParametrosSaidaService } from 'src/app/services/app.parametrossaida.service';
import { ParametroSaida } from '../../../model/app.parametrosaida.model';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-saida-visualizar',
    templateUrl: './parametrosaida.visualizar.component.html',
    styleUrls: ['./parametrosaida.visualizar.component.scss']
})
export class ParametroSaidaVisualizarComponent {

    public data: ParametroSaida;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: ParametrosSaidaService,
    ) {
        const parametroSaidaId = +this.route.snapshot.queryParamMap.get("parametroSaidaId");
        this.service.getById(parametroSaidaId)
            .subscribe({
                next: (data: any) => this.data = data,
                error: () => this.data = null
            });
    }

    voltar() {
        this.router.navigate(['parametrosaida']);
    }
}
