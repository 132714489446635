<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card" *ngIf="!isMobile" >
                    <div class="card-body">
                        <form [formGroup]="form" (ngSubmit)="search()">
                            <h5 class="mb-0">Consultar Usuário</h5>
                            <hr class="mt-2" />
                            <div class="form-group">
                                <div class="row d-flex">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="col-md-12 main-card">
                                                <label>Unidade Fiscal</label>
                                                <ng-multiselect-dropdown placeholder="Selecione Unidade Fiscal"
                                                    [settings]="configuracoesPadrao" [data]="unidadesFiscais"
                                                    formControlName="unidadeDoUsuario" (onSelect)="selecionarUnidade()"
                                                    (onDeSelect)="selecionarUnidade()"
                                                    (clear)="limpar()"
                                                    (onSelectAll)="selecionarUnidade($event)">
                                                    <ng-template #optionsTemplate let-item let-option="option"
                                                        let-id="id" let-isSelected="isSelected">
                                                        <div style="
                                                                padding: 1px 10px;
                                                                margin-right: 10px;
                                                                right: 2px;
                                                                top: -2px;
                                                            ">
                                                            <label
                                                                style="color: #333; min-width: 130px; font-weight: normal;"
                                                                [ngStyle]="{ 'font-weight': isSelected? 'bold':' normal'}">{{getItems[id].nome}}</label>
                                                        </div>
                                                    </ng-template>
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="col-md-12 main-card">
                                                <label>Nome Servidores</label>
                                                <ng-multiselect-dropdown
                                                    [placeholder]="'Selecione um ou mais servidor(es)'"
                                                    [settings]="configuracoesNomeUsuario" [data]="usuarios"
                                                    formControlName="nomeUsuario">
                                                    <ng-template #optionsTemplate let-item let-option="option"
                                                        let-id="id" let-isSelected="isSelected">
                                                        <div style="
                                                            padding: 1px 10px;
                                                            margin-right: 10px;
                                                            right: 2px;
                                                            top: -2px;
                                                        ">
                                                            <label
                                                                style="color: #333; min-width: 130px; font-weight: normal;"
                                                                [ngStyle]="{ 'font-weight': isSelected? 'bold':' normal'}">{{getItems[id]}}</label>
                                                        </div>
                                                    </ng-template>
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex flex-row">
                                    <div class="datePicker">
                                        <div class="col-sm-6">

                                            <label class="control-label text-left">Data Inicial</label>
                                            <div class="input-group">
                                                <input class="form-control" placeholder="dd/mm/yyyy"
                                                    formControlName="dataInicio" />
                                                <input placeholder="dd/mm/yyyy" formControlName="dataInicioAux"
                                                    name="dI" ngbDatepicker [footerTemplate]="footerTemplateInit"
                                                    #dI="ngbDatepicker"
                                                    [ngStyle]="{ 'visibility': 'hidden', 'width':'0px'}"
                                                    mask="00/00/0000" />
                                                <button
                                                    class="btn btn-outline-secondary fa-solid fa-calendar-days m-0 px-3"
                                                    (click)="dI.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">

                                            <label class="control-label text-left">Hora Inicial</label>

                                            <div class="input-group">
                                                <ngb-timepicker [spinners]="false" formControlName="horaInicio"
                                                    name="hI">
                                                </ngb-timepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="datePicker">
                                        <div class="col-sm-6">
                                            <label class="control-label text-left">Data Final</label>
                                            <div class="input-group">
                                                <input class="form-control" placeholder="dd/mm/yyyy"
                                                    formControlName="dataFim" />
                                                <input ngbDatepicker [footerTemplate]="footerTemplateFinish"
                                                    [ngStyle]="{ 'visibility': 'hidden', 'width':'0px'}" name="dF"
                                                    #dF="ngbDatepicker" formControlName="dataFimAux" />
                                                <button
                                                    class="btn btn-outline-secondary fa-solid fa-calendar-days m-0 px-3"
                                                    (click)="dF.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <label class="control-label text-left">Hora Final</label>
                                            <div class="input-group">

                                                <ngb-timepicker [spinners]="false" formControlName="horaFim" name="hF"
                                                    [disabled]="true">
                                                </ngb-timepicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row btn-pesquisar">
                                <div class="input-group mb-3 justify-content-start">
                                    <button type="submit" class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                        style="gap:0.5rem">Pesquisar<i
                                            class="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                    <button type="reset" class="btn btn-default btn-sm my-0 d-flex align-items-center"
                                        style="gap:0.5rem" (click)="limpar()">Limpar<i class="fa-solid fa-times"></i>
                                    </button>
                                </div>
                                <div class="input-group mb-3 justify-content-end btn-excel">
                                    <button type="button" class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                        style="gap: 0.5rem" (click)="exportExcel()">
                                        Excel <i class="fas fa-file-csv"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive px-3">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="nomeDoUsuario" (sort)="onSort($event)">Nome do Usuário
                                    </th>
                                    <th scope="col" sortable="loginUsuario" (sort)="onSort($event)">Login Usuário
                                    </th>
                                    <th scope="col" sortable="unidadeDoUsuario" (sort)="onSort($event)">Unidade do
                                        Usuário
                                    </th>
                                    <th scope="col" class="text-center">Data e Hora</th>
                                    <th scope="col" class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of usuariosConectados$ | async">
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.NomeDoUsuario" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.LoginUsuario" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.UnidadeDoUsuario" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1" style="text-align: center;">
                                        <ngb-highlight [result]="data.dataHoraInclusao" [term]="service.searchTerm">
                                        </ngb-highlight>
                                    </td>
                                    <td class="py-1" style="width: 30%;">
                                        <div class="d-flex justify-content-center" style="gap: 1rem;">
                                            <button
                                                class="btn btn-sm btn-outline d-flex justify-content-center align-items-center"
                                                (click)="abrirMapa(data)" title="Ver Mapa" style="gap:0.5rem;">
                                                <i class="fa-solid fa-map-location-dot"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize" [maxSize]="10">
                            </ngb-pagination>

                            <select class="form-select form-control-sm" style="width: auto" name="pageSize"
                                [(ngModel)]="service.pageSize">
                                <option [ngValue]="10">10 Itens</option>
                                <option [ngValue]="15">15 Itens</option>
                                <option [ngValue]="20">20 Itens</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"><app-map></app-map></div>
    </div>
</my-content>