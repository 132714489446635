import { Unidade } from 'src/app/model/app.unidadefiscal.model';
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ETipoDestinatario } from "../constantes/app.etipodestinatario.const";
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { Regra, RegraUnidadeFiscal, Usuario } from "../model";
import { AccountService } from '../services/app.login.service';


@Injectable({
    providedIn: 'root'
})
export class Passo2RegraValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _regra: Regra;
    private _result: any = [];

    constructor(private toast: ToastUtil, public accountService: AccountService) {
        this._messages = [];
    }

    get isValid() { return this._messages.length == 0; }
    get regra() { return this._regra; }
    get mensagens() { return this._messages; }

    set set(form: UntypedFormGroup) {
        this._form = form;
        this._regra = {
            tipoDestinatarioId: this._form.controls.tipoDestinatario?.value?.tipoDestinatarioId,
            unidades: this._form.controls.unidades?.value,
            regrasUnidadeFiscal: this._form.controls.regrasUnidadeFiscal?.value || []
        };
        this.formatValue();
        this._validate();

    }

    private _validate() {
        const {
            tipoDestinatarioId,
            unidades
        } = this._regra;
       
        this._messages = [];
        if (!tipoDestinatarioId)
            this._messages.push({ type: ToastType.WARNING, message: "Tipo Destinatário é obrigatório!" });
        else if (tipoDestinatarioId) {
            if(unidades.length == 0){
                this._messages.push({ type: ToastType.WARNING, message: "A seleção de unidade é obrigatória!" });
            }else if(tipoDestinatarioId === ETipoDestinatario.UNIDADE_FISCAL){
                for (const unidade of unidades) {
                    const usuarios = this._form.controls[`fiscalUnidade${unidade.unidadeFiscalId}`]?.value || [];
                    if (!usuarios.length)
                        this._messages.push({ type: ToastType.WARNING, message: `Fiscal da Unidade ${unidade.descricao} é obrigatório!` });
                }
            }
            
        }
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }

    public formatValue() {

        const vetoresUsuarios = Object.keys(this._form.value);

        const subUnidades = vetoresUsuarios.filter(vu => {
            const [prefix, id] = vu.split("_");
            return prefix === "subUnidades";
        });

        subUnidades.forEach(sub => {
            const unidadeid = sub.split("_")[1];
            const matchingUsers = this._form.controls[`usuarios_${unidadeid}`].value;
            // console.log(matchingUsers)

            if (matchingUsers.length > 0) {
                matchingUsers.forEach(user => {
                    this._result.push({
                        unidadeFiscalId: user.unidadeFiscalID,
                        usuarioId: user.usuarioID
                    });
                });
            } else {
                this._result.push({
                    unidadeFiscalId: unidadeid,
                    usuarioId: this.accountService.userValue?.usuarioId
                });
            }
        });

        // console.log(this._result);
    }
}
