import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PerfilService } from 'src/app/services/app.perfil.service';
import { ComunService } from 'src/app/services/app.comum.service';
import { tap, map, filter } from 'rxjs/operators';
import { Modulo } from './../../../model/app.modulo.model';
import { EAcao } from './../../../constantes/app.eacao.const';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { PerfilValidator } from 'src/app/validators/perfil.validator';
import { ModuloPerfil } from 'src/app/model/app.moduloperfil.model';

@Component({
  selector: 'app-perfil-cadastro',
  templateUrl: './perfil.cadastro.component.html',
  styleUrls: ['./perfil.cadastro.component.scss'],
  providers: [
    BlockUtil,
    PerfilService
]
})
export class PerfilCadastroComponent implements OnInit {

    public form: FormGroup;
    public acoes: ListItem[] = EAcao.toList();
    public modulos: Modulo[];
    public modulosPerfilAdicionados: ModuloPerfil[] = [];

    public configuracoesPadrao: IDropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      selectAllText: "Todos",
      unSelectAllText: "Remover Todos",
      enableCheckAll: true
    };

    public moduloConfiguracao: IDropdownSettings = {
      ...this.configuracoesPadrao,
      textField: 'titulo',
      idField: 'moduloId'
    }

    public acaoConfiguracao: IDropdownSettings = {
      ...this.configuracoesPadrao,
      textField: 'nome',
      idField: 'acaoId'
    }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private blockUtil: BlockUtil,
    private toast: ToastUtil,
    private comum: ComunService,
    private validator: PerfilValidator,
    public service: PerfilService,
  ) {
    this.blockUtil.blockName = "cadastroPerfil";
    this.comum.ObterModulosAtivos()
            .pipe(
              tap(modulos => this.modulos = modulos)
            ).subscribe();

      this.form = this.formBuilder.group({
        perfilId: new FormControl(null),
        nome: new FormControl(''),
        modulosSelecionados: new FormControl([]),
        acoesSelecionadas: new FormControl([]),
        modulosPerfil: new FormControl([])
      });
  }
  get possuiModulosSelecionados(): boolean { return !!this.form.controls.modulosSelecionados?.value.length; }
    get possuiModulosAdicionados(): boolean { return !!this.modulosPerfilAdicionados.length; }
    get possuiModulosEAcoesSelecionadas(): boolean { return this.possuiModulosSelecionados && !!this.form.controls.acoesSelecionadas?.value.length;}

  ngOnInit(): void {

  }

  adicionarModulos(){
    if(this.possuiModulosEAcoesSelecionadas){

      let modulosSelecionados = this.form.controls.modulosSelecionados?.value as Modulo[];
      let acoesSelecionadas = this.form.controls.acoesSelecionadas?.value;

      if(!this.modulosPerfilAdicionados.length){
          modulosSelecionados.forEach(mod => {
              const {moduloId, titulo } = mod;
              this.modulosPerfilAdicionados.push({moduloId, moduloTitulo:titulo, acoes: acoesSelecionadas} as ModuloPerfil);
          });
      }
      else{
        modulosSelecionados.forEach(modulo =>{
          const possuiModuloAdicionado = this.modulosPerfilAdicionados.some((moduloPerfil)=> moduloPerfil.moduloId === modulo.moduloId);
          if(!possuiModuloAdicionado){
              const {moduloId, titulo } = modulo;
              this.modulosPerfilAdicionados.push({moduloId, moduloTitulo:titulo, acoes: acoesSelecionadas} as ModuloPerfil);
          }
          else
            this.modulosPerfilAdicionados.forEach((item) => {
              if(item.moduloId === modulo.moduloId)
                item.acoes = acoesSelecionadas
            });
        });
      }
      this.form.patchValue({ modulosSelecionados: [], acoesSelecionadas: [] });
    }
  }

  retornaNomeAcao(acoes:any[]){
    return acoes.map(({nome})=> `${nome} `);
  }
  onEdit(moduloPerfil:any){
    const { moduloId, acoes} = moduloPerfil;
    const moduloSelecionado = this.modulos.find((modulo)=> modulo.moduloId === moduloId);
    this.form.patchValue({
      modulosSelecionados: [moduloSelecionado],
      acoesSelecionadas: acoes
    })
  }
  onDelete(item:any){
    var filter = this.modulosPerfilAdicionados.filter((moduloPerfil)=> item.moduloId !== moduloPerfil.moduloId);
    this.modulosPerfilAdicionados = filter;
  }
  onSubmit(){
    this.blockUtil.bloquear();

    this.form.patchValue({
      modulosPerfil: this.modulosPerfilAdicionados
    })

    this.validator.set = this.form;
    if (!this.validator.isValid) {
      this.blockUtil.desbloquear();
      this.validator.showMessages();
    }else {
      this.service.new(this.validator.perfil)
          .subscribe({
              next: (data) => {
                  this.toast.showSuccess(data);

                  this.blockUtil.desbloquear();
                  this.clear();
                  this.router.navigate(['perfil']);
              },
              error: (erro) => {
                  this.toast.showDanger(erro);
                  this.blockUtil.desbloquear();
              }
          })
    }
  }

  cancel(){
    this.router.navigate(['perfil']);
  }

  clear() {
    this.form.patchValue({
      perfilId: null,
      nome: '',
      modulosSelecionados: [],
      acoesSelecionadas: [],
      modulosPerfil: []
    })
  }

}
