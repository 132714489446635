import { ListItem } from '../interfaces/app.utilservices.interface';

export enum ETipoDestinatario {
  LOCALIZACAO = 1,
  //TODOS = 2,
  UNIDADE_FISCAL = 3,
}

export namespace ETipoDestinatario {
  export function toString (situacao: ETipoDestinatario | number): string {
    switch (situacao) {
      case ETipoDestinatario.LOCALIZACAO:
        return 'LOCALIZAÇÃO';
      //case ETipoDestinatario.TODOS: return 'TODAS AS UNIDADES';
      case ETipoDestinatario.UNIDADE_FISCAL:
        return 'UNIDADE FISCAL';
    }
  }

  export function toList (): ListItem[] {
    return Object.keys(ETipoDestinatario)
      .filter(value => isNaN(Number(value)) === false)
      .map<ListItem>(key => ({ tipoDestinatarioId: +key, nome: ETipoDestinatario.toString(+key) }));
  }
}
