import { ESituacao } from './../constantes/app.esituacao.const';
import { Agrupador } from './../model/app.agrupador.model';
import { tap } from 'rxjs/operators';
import { ListItem } from './../interfaces/app.utilservices.interface';
import { UtilService } from './app.utils.service';
import { Injectable } from "@angular/core";
import { TipoDado } from '../model/app.tipodado.model';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
    Condicionante,
    FiltroEntrada,
    Grupo,
    Icone,
    ModeloRegra,
    Operador,
    ParametroEntrada,
    ParametroSaida,
    Usuario
} from '../model';
import { ComportamentoRegra } from '../model/app.comportamentoregra.model';
import { TipoDestinatario } from '../model/app.tipodestinatario.model';
import { Unidade, UnidadeFiscal } from '../model/app.unidadefiscal.model';
import { Perfil } from '../model/app.perfil.model';
import { Modulo } from '../model/app.modulo.model';
import { Equipamento } from '../model/app.equipamentos.model';
import { Dashboard } from '../model/app.dashboard.model';
import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { UtilAcessoProducaoService } from './app.utilsacessoproducao.service';
import { Servidor } from '../model/app.servidor.model';
import { Acao } from '../model/app.acao.model';
import { LocalizacaoUsuarioDto } from '../pages/usuarioconectado/dto/localizacaousuario.dto';
import { AccountService } from './app.login.service';
import { AlertaImagem, AlertaImagemAtendimento } from '../model/app.alertaimagematendimento.model';

@Injectable()
export class ComunService {

    constructor(private util: UtilService,
        private utilAcessoProducao: UtilAcessoProducaoService,
        private accountService: AccountService) {
    }

    public ObterUsuarioLogado() {
        return 1;
    }

    public ObterTipoDado(): Observable<ListItem[]> {
        const url = `${environment.apiUrl}/TipoDado/obterTodos`;
        return this.util.Get<TipoDado[]>(url)
            .pipe(
                map((tiposDado: any[]) => tiposDado?.map(({ tipoDadoID, nome }) => ({ tipoDadoID, nome } as ListItem)) || [])
            );
    }

    public ObterCondicionantesAtivos(): Observable<Condicionante[]> {
        const url = `${environment.apiUrl}/Condicionante/obterAtivos`;
        return this.util.Get<Condicionante[]>(url)
            .pipe(
                filter(condicionantes => !!condicionantes),
                map((condicionantes: any[]) => condicionantes.map(condicionante => new Condicionante(condicionante)))
            );
    }

    public ObterCondicionantesByExibeRegra(): Observable<Condicionante[]> {
        const url = `${environment.apiUrl}/Condicionante/obterByExibeRegra`;
        return this.util.Get<Condicionante[]>(url)
            .pipe(
                filter(condicionantes => !!condicionantes),
                map((condicionantes: any[]) => condicionantes.map(condicionante => new Condicionante(condicionante)))
            );
    }

    public ObterCondicionantesAtivosEAtribuido(condicionanteId: number): Observable<Condicionante[]> {
        const url = `${environment.apiUrl}/Condicionante/obterTodos`;
        return this.util.Get<Condicionante[]>(url)
            .pipe(
                filter(condicionantes => !!condicionantes),
                map((condicionantes: any[]) => condicionantes.map(condicionante => new Condicionante(condicionante))),
                map((condicionantes: any[]) => condicionantes.filter(condicionante => condicionante.condicionanteId == condicionanteId || condicionante.situacaoId == ESituacao.ATIVO)),
            );
    }

    public ObterFiltrosEntradaAtivos(): Observable<FiltroEntrada[]> {
        const url = `${environment.apiUrl}/FiltroEntrada/obterAtivos`;
        return this.util.Get<FiltroEntrada[]>(url)
            .pipe(
                filter(filtrosEntrada => !!filtrosEntrada),
                map((filtrosEntrada: any[]) => filtrosEntrada.map(filtroEntrada => new FiltroEntrada(filtroEntrada)))
            );
    }

    public ObterModelosRegraAtivos(): Observable<ModeloRegra[]> {
        const url = `${environment.apiUrl}/ModeloRegra/obterAtivos`;
        const dados: Subject<ModeloRegra[]> = new BehaviorSubject<ModeloRegra[]>(null);
        this.util.Get<ModeloRegra[]>(url)
            .pipe(
                filter(modelosRega => !!modelosRega)
            )
            .subscribe((modelosRega: ModeloRegra[]) => dados.next(modelosRega.map(modeloRegra => new ModeloRegra(modeloRegra))));
        return dados.asObservable();
    }

    public ObterModelosConfiguradosParaSerExibidosNoCadatroDaRegra(): Observable<ModeloRegra[]> {
        const url = `${environment.apiUrl}/ModeloRegra/ObterModelosConfiguradosParaSerExibidosNoCadatroDaRegra`;
        const dados: Subject<ModeloRegra[]> = new BehaviorSubject<ModeloRegra[]>(null);
        this.util.Get<ModeloRegra[]>(url)
            .pipe(
                filter(modelosRega => !!modelosRega)
            )
            .subscribe((modelosRega: ModeloRegra[]) => dados.next(modelosRega.map(modeloRegra => new ModeloRegra(modeloRegra))));
        return dados.asObservable();
    }

    public ObterParametrosEntradaAtivos(): Observable<ParametroEntrada[]> {
        const url = `${environment.apiUrl}/ParametroEntrada/obterAtivos`;
        return this.util.Get<ParametroEntrada[]>(url)
            .pipe(
                filter(parametrosEntrada => !!parametrosEntrada),
                map((parametrosEntrada: any[]) => parametrosEntrada.map(parametroEntrada => new ParametroEntrada(parametroEntrada)))
            );
    }

    public ObterParametrosSaidaAtivos(): Observable<ParametroSaida[]> {
        const url = `${environment.apiUrl}/ParametroSaida/obterAtivos`;
        return this.util.Get<ParametroSaida[]>(url)
            .pipe(
                filter(parametrosSaida => !!parametrosSaida),
                map((parametrosSaida: any[]) => parametrosSaida.map(parametroSaida => new ParametroSaida(parametroSaida)))
            );
    }

    public ObterTipoDestinatarioAtivos(): Observable<TipoDestinatario[]> {
        const url = `${environment.apiUrl}/TipoDestinatario/obterAtivos`;
        return this.util.Get<TipoDestinatario[]>(url)
            .pipe(
                filter(tiposDestinatario => !!tiposDestinatario),
                map((tiposDestinatario: any[]) => tiposDestinatario.map(tipoDestinatario => new TipoDestinatario(tipoDestinatario)))
            );
    }

    public ObterComportamentoRegraAtivos(): Observable<ComportamentoRegra[]> {
        const url = `${environment.apiUrl}/ComportamentoRegra/obterTodos`;
        const dados: Subject<ComportamentoRegra[]> = new BehaviorSubject<ComportamentoRegra[]>(null);
        this.util.Get<ComportamentoRegra[]>(url)
            .pipe(
                filter(comportamentosRegra => !!comportamentosRegra)
            )
            .subscribe((comportamentosRegra: ComportamentoRegra[]) => dados.next(comportamentosRegra.map(comportamentoRegra => new ComportamentoRegra(comportamentoRegra))));
        return dados.asObservable();
    }

    public ObterUsuariosAtivos(): Observable<Usuario[]> {
        const url = `${environment.apiUrl}/Usuario/obterAtivos`;
        return this.util.Get<Usuario[]>(url)
            .pipe(
                filter(usuarios => !!usuarios),
                map(usuarios => usuarios.map(usuario => new Usuario(usuario)))
            )
    }

    public ObterPerfilsAtivos(): Observable<Perfil[]> {
        const url = `${environment.apiUrl}/Perfil/obterAtivos`;
        const dados: Subject<Perfil[]> = new BehaviorSubject<Perfil[]>(null);
        this.util.Get<Perfil[]>(url)
            .pipe(
                filter(perfils => !!perfils)
            )
            .subscribe((perfils: Perfil[]) => dados.next(perfils.map(perfil => new Perfil(perfil))));
        return dados.asObservable();
    }

    public ObterGrupos(): Observable<ListItem[]> {
        const url = `${environment.apiUrl}/Grupo/obterTodos`;
        return this.util.Get<Grupo[]>(url)
            .pipe(
                map((grupos: any[]) => grupos?.map(({ grupoID, nome }) => ({ grupoID, nome } as ListItem)) || [])
            );
    }

    public ObterModulosAtivos(): Observable<Modulo[]> {
        const url = `${environment.apiUrl}/Modulo/obterAtivos`;
        return this.util.Get<Modulo[]>(url)
            .pipe(
                filter(modulos => !!modulos),
                map((modulos: any[]) => modulos.map(modulo => new Modulo(modulo)))
            );
    }

    public ObterModulosPorUsuario(usuarioId: number): Observable<Modulo[]> {
        const url = `${environment.apiUrl}/Modulo/obterPorUsuario/${usuarioId}`;
        return this.util.Get<Modulo[]>(url)
            .pipe(
                filter(modulos => !!modulos),
                map((modulos: any[]) => modulos.map(modulo => new Modulo(modulo)))
            );
    }

    public ObterIcones(): Observable<Icone[]> {
        const url = `assets/json/icones.json`;
        const dados: Subject<Icone[]> = new BehaviorSubject<Icone[]>(null);
        this.util.GetJson<Icone[]>(url)
            .pipe(
                filter(icones => !!icones),
                tap((icones: any[]) => icones.orderBy("Nome"))
            )
            .subscribe((icones: Icone[]) => dados.next(icones.map(icone => new Icone(icone))));
        return dados.asObservable();
    }

    public ObterOperadoresAtivos(): Observable<Operador[]> {
        const url = `${environment.apiUrl}/Operador/obterAtivos`;
        return this.util.Get<Modulo[]>(url)
            .pipe(
                filter(operadores => !!operadores),
                map((operadores: any[]) => operadores.map(operador => new Operador(operador)))
            );
    }

    public ObterAgrupadoresAtivos(): Observable<Agrupador[]> {
        const url = `${environment.apiUrl}/Agrupador/obterAtivos`;
        return this.util.Get<Modulo[]>(url)
            .pipe(
                filter(agrupadores => !!agrupadores),
                map((agrupadores: any[]) => agrupadores.map(agrupador => new Agrupador(agrupador)))
            );
    }

    public ObterEquipamentos(): Observable<Equipamento[]> {
        const url = `${environment.apiUrlFiscalizacaoVirutalTransito}/Equipamento/consultar-equipamentos`;
        return this.util.Get<any>(url)
            .pipe(
                filter(equipamentos => !!equipamentos),
                map(resposta => {
                    if (Array.isArray(resposta))
                        return resposta;
                    const { equipamentos: { $values } } = resposta;
                    return $values;
                }),
                map((equipamentos: any[]) => equipamentos.map(equipamento => new Equipamento(equipamento)))
            );
    }

    public ObterTodasUnidades(): Observable<Unidade[]> {
        const url = `${environment.apiUrlUnidadesProducao}/Unidade/v1/obter-unidades`;
        return this.utilAcessoProducao.Get<Unidade[]>(url)
            .pipe(
                filter(unidades => !!unidades),
                map(({ unidades }: any) => unidades.map(unidade => new Unidade(unidade)))
            );
    }

    public ObterTodasUnidadesFiscais(): Observable<UnidadeFiscal[]> {
        const url = `${environment.apiUrl}/UnidadeFiscal/obterTodos`;
        return this.utilAcessoProducao.Get<UnidadeFiscal[]>(url)
            .pipe(
                filter(unidadesFiscais => !!unidadesFiscais),
                map(unidadesFiscais => unidadesFiscais.map(unidadeFiscal => new UnidadeFiscal(unidadeFiscal)))
            );
    }

    public ObterTodasUnidadesFiscaisPorUsuario(): Observable<UnidadeFiscal[]> {
        const url = `${environment.apiUrl}/ChefeUnidadeFiscal/obterUnidadesPorID/${this.accountService.userValue?.usuarioId}`;
        return this.utilAcessoProducao.Get<UnidadeFiscal[]>(url)
            .pipe(
                filter(unidadesFiscais => !!unidadesFiscais),
                map(unidadesFiscais => unidadesFiscais.map(unidadeFiscal => new UnidadeFiscal(unidadeFiscal)))
            );
    }

    public ObterUnidadesMoveis(): Observable<UnidadeFiscal[]> {
        const url = `${environment.apiUrl}/ChefeUnidadeFiscal/ObterUnidadesMoveis`;
        return this.utilAcessoProducao.Get<UnidadeFiscal[]>(url)
            .pipe(
                filter(unidadesFiscais => !!unidadesFiscais),
                map(unidadesFiscais => unidadesFiscais.map(unidadeFiscal => new UnidadeFiscal(unidadeFiscal, false)))
            );
    }

    public ObterUnidadesFiscais(): Observable<UnidadeFiscal[]> {
        const url = `${environment.apiUrl}/UnidadeFiscal/obterTodos`;
        return this.utilAcessoProducao.Get<UnidadeFiscal[]>(url)
            .pipe(
                filter(unidadesFiscais => !!unidadesFiscais),
                map(unidadesFiscais => unidadesFiscais.map(unidadeFiscal => new UnidadeFiscal(unidadeFiscal))),
                map(unidadesFiscais => unidadesFiscais.filter(({ ehUnidade }) => ehUnidade)
                    .reduce((final, unidade) => ([
                        ...final,
                        {
                            ...unidade,
                            subUnidades: unidadesFiscais.filter(({ unidadeFiscalIdPaiEfazenda }) => unidadeFiscalIdPaiEfazenda == unidade.unidadeFiscalIdEfazenda)
                        }
                    ]), []))
            );
    }

    public ObterUnidadesESubunidadesFiscaisAtivas(desabilitarUnidadesQueNaoPossuiUsuario: boolean = true): Observable<UnidadeFiscal[]> {
        const url = `${environment.apiUrl}/UnidadeFiscal/ObterUnidadesESubunidadesFiscaisAtivas`;
        return this.utilAcessoProducao.Get<UnidadeFiscal[]>(url)
            .pipe(
                filter(unidadesFiscais => !!unidadesFiscais),
                map(unidadesFiscais => unidadesFiscais.map(unidadeFiscal => new UnidadeFiscal(unidadeFiscal, desabilitarUnidadesQueNaoPossuiUsuario)))
            );
    }

    public ObterSubUnidades(unidadeID: number): Observable<UnidadeFiscal[]> {
        const url = `${environment.apiUrl}/UnidadeFiscal/obterSubUnidades?unidadeFiscalIDPaiEFazenda=${unidadeID}`;
        return this.utilAcessoProducao.Get<UnidadeFiscal[]>(url)
            .pipe(
                filter(unidadesFiscais => !!unidadesFiscais),
                map(unidadesFiscais => unidadesFiscais.map(unidadeFiscal => new UnidadeFiscal(unidadeFiscal))),
            );
    }

    public ObterNomeDosServidores(codigoUnidade: number): Observable<string[]> {
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear();
        const mesAtual = dataAtual.getMonth() + 1;
        let params = {
            Unidade: codigoUnidade,
            Ano: anoAtual,
            Mes: mesAtual
        }
        console.log('params', params)
        const url = `${environment.apiUrlUnidadesProducao}/Servidor/v1/obter-servidores`;
        return this.utilAcessoProducao.Get<string[]>(url, params)
            .pipe(
                filter(servidores => !!servidores),
                map(({ servidores }: any) => servidores.map(servidor => servidor.nome))
            );
    }

    public ObterDashboard(horas: number): Observable<Dashboard> {
        const url = `${environment.apiUrl}/Alerta/Dashboard/${horas}`;
        return this.util.Get<Dashboard>(url)
            .pipe(
                filter(dashboard => !!dashboard),
                map(dashboard => new Dashboard(dashboard))
            );
    }

    public ObterAcoes(): Observable<Acao[]> {
        const url = `${environment.apiUrl}/Acao/obterTodosAuditoria`;
        return this.util.Get<Acao[]>(url)
            .pipe(
                filter(dashboard => !!dashboard),
                map((acoes: any[]) => acoes?.map(acao => new Acao(acao)))
            );
    }

    public ObterUsuariosConectados(): Observable<LocalizacaoUsuarioDto[]> {
        const url = `${environment.apiUrl}/LocalizacaoUsuario/obterTodosRegistrosDaDataAtual`;
        return this.util.Get<LocalizacaoUsuarioDto[]>(url)
            .pipe(
                filter(usuariosConectados => !!usuariosConectados),
                map((usuariosConectados: any[]) => usuariosConectados.map(usuarioConectado => new LocalizacaoUsuarioDto(usuarioConectado)))
            );
    }

    public ObterUsuarioPorUnidade(unidadeID: Number) {
        const url = `${environment.apiUrl}/UsuarioUnidade/obterPorUnidadeID?unidadeID=${unidadeID}`;
        return this.util.Get<LocalizacaoUsuarioDto[]>(url)
            .pipe(
                filter(usuariosConectados => !!usuariosConectados)
            );
    }

    public ObterAlertaImagemAtendimento(alertaAImagemAtendimentoId: Number) {
        const url = `${environment.apiUrl}/AlertaImagemAtendimento/obterPorID/${alertaAImagemAtendimentoId}`;
        return this.util.Get<AlertaImagemAtendimento[]>(url)
            .pipe(
                filter(alertaImagemAtendimentos => !!alertaImagemAtendimentos)
            );
    }

    public ObterAlertaImagemPorAlertaImagemAtendimento(alertaAImagemAtendimentoId: Number) {
        const url = `${environment.apiUrl}/AlertaImagemAtendimento/obterImagemID/${alertaAImagemAtendimentoId}`;
        return this.util.Get<AlertaImagem>(url)
            .pipe(
                filter(alertaImagem => !!alertaImagem)
            );
    }
}
