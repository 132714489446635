import { AlertaComponent } from './alerta.component';
import { Routes } from '@angular/router';
import { MasterComponent } from '../../components/master/master.component';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { AlertaVisualizarComponent } from './visualizar/alerta.visualizar.component';
import { AlertaAtenderComponent } from './atender/alerta.atender.component';

export const AlertasRoutes: Routes = [
    {
        path: 'alerta',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: AlertaComponent,
            }
        ],
    },
    {
        path: 'alerta/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: AlertaVisualizarComponent,
            },
        ],
    },
    {
        path: 'alerta/atender',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: AlertaAtenderComponent,
            },
        ],
    },
];
