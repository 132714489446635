import { Routes } from '@angular/router';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { MasterComponent } from '../../components/master/master.component';
import { ParametroSaidaCadastroComponent } from './cadastro/parametrosaida.cadastro.component';
import { ParametroSaidaEditarComponent } from './editar/parametrosaida.editar.component';
import { ParametroSaidaConsultaComponent } from './parametrosaida.component';
import { ParametroSaidaVisualizarComponent } from './visualizar/parametrosaida.visualizar.component';

export const SaidaRoutes: Routes = [
    {
        path: 'parametrosaida',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ParametroSaidaConsultaComponent
            }
        ]
    },
    {
        path: 'parametrosaida/cadastro',
        component: MasterComponent,
        data: { permissao: EAcao.CADASTRAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ParametroSaidaCadastroComponent
            }
        ]
    },
    {
        path: 'parametrosaida/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ParametroSaidaVisualizarComponent
            }
        ]
    },
    {
        path: 'parametrosaida/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ParametroSaidaEditarComponent
            }
        ]
    }
];
