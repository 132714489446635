import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ToastUtil, ToastType } from '../helper/app.toast.utils';
import { ModeloRegra } from "../model";

@Injectable({
    providedIn: 'root'
})
export class ModeloRegraValidator {
    private _form: UntypedFormGroup;
    private _messages: any[];
    private _modeloRegra: ModeloRegra;

    constructor(private toast: ToastUtil) {
        this._messages = [];
    }

    get isValid() { return this._messages.length == 0; }
    get modeloRegra() { return this._modeloRegra; }

    set set(form: UntypedFormGroup) {
        this._form = form;
        this._modeloRegra = {
            modeloRegraId: this._form.controls.modeloRegraId?.value || 0,
            condicionanteId: this._form.controls.condicionante?.value?.condicionanteId || 0,
            nome: this._form.controls.nome.value,
            parametrosEntrada: this._form.controls.parametrosEntrada.value || [],
            filtrosEntrada: this._form.controls.filtrosEntrada.value || [],
            parametrosSaida: this._form.controls.parametrosSaida.value || [],
            dataHoraInclusao: new Date()
        };
        this._validate();
    }

    private _validate() {
        const {
            modeloRegraId,
            condicionanteId,
            nome,
            parametrosEntrada,
            filtrosEntrada,
            parametrosSaida,
            dataHoraInclusao,
        } = this._modeloRegra;

        this._messages = [];
        if (!condicionanteId || condicionanteId === 0)
            this._messages.push({ type: ToastType.WARNING, message: "Condicionante é obrigatório!" });

        if (String.isNullOrEmpty(nome))
            this._messages.push({ type: ToastType.WARNING, message: "Nome do Model de Regra é obrigatório!" });

        // if (!parametrosEntrada.length)
        //     this._messages.push({ type: ToastType.WARNING, message: "Parâmetro de Entrada é obrigatório!" });

        // if (!filtrosEntrada.length)
        //     this._messages.push({ type: ToastType.WARNING, message: "Filtro de Entrada é obrigatório!" });

        // if (!parametrosSaida.length)
        //     this._messages.push({ type: ToastType.WARNING, message: "Parâmetro de Saída é obrigatório!" });
    }

    public showMessages() {
        this.toast.showMultiple(this._messages);
    }
}
