import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from "src/app/directives/app.directives.module";
import { ConsultarplacasComponent } from './consultarplacas.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ComponentsModule } from "src/app/components/components.module";
import { BlockUIModule } from "ng-block-ui";


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgbModule,
        DirectivesModule,
        NgMultiSelectDropDownModule.forRoot(),
        ComponentsModule,
        BlockUIModule.forRoot(),
    ],
    declarations: [
        ConsultarplacasComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConsultarplacasModule { }
