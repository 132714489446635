import { ComunService } from 'src/app/services/app.comum.service';
import { Router } from '@angular/router';
import { Observable, from, tap } from 'rxjs';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/app.sortable.directive';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { Usuario } from 'src/app/model/app.usuario.model';
import { UsuarioService } from 'src/app/services/app.usuario.service';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { EOrderBy } from 'src/app/constantes/app.eorderby.const';

@Component({
    selector: 'app-usuario',
    templateUrl: './usuario.component.html',
    styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent extends ParmissoesPagina implements OnInit {
    public isMobile: boolean = false;
    public usuario$: Observable<Usuario[]>;
    public total$: Observable<number>;
    public form: FormGroup;
    public eSituacao: ListItem[] = ESituacao.toList();
    public pesquisaAvancadaAberta: boolean = false;

    public colsGrid: any = [
        {
            colKey: "nome",
            colName: "Nome"
        },

        {
            colKey: "perfil",
            colName: "Perfil"
        },

       
    ]


    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<Usuario>>;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public service: UsuarioService,
        private toast: ToastUtil,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private comum: ComunService,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService
    ) {
        super(router.url, accountService);
        this.blockUtil.blockName = "usuario";
        this.blockUtil.bloquear();
        this.usuario$ = service.usuario$
            .pipe(tap(usuarios => {
                if (usuarios.length)
                    this.blockUtil.desbloquear();
            }));
        this.total$ = service.total$;

        this.accountService.PermissionOnModel(router.url)
            .subscribe({
                next: permissoes => this.permissoes = permissoes
            });

        this.form = this.formBuilder.group({
            filtroGenerico: new FormControl(null),
           
        });

        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        });
    }

    ngOnInit(): void {
        this.service.get("");
    }

    onSort({ column, direction }: SortEvent<Usuario>) {
        this.headers.forEach(header => {
            if (header.sortable !== column)
                header.direction = '';
        });

        this.service.getOrderByDirection(column, direction);
    }

    search() {
        const filtroGenerico = this.form.controls.filtroGenerico.value?.trim();

        this.service.get( {page: 1,
            pageSize: 10,
            searchTerm: '',
            sortColumn: '',
            sortDirection: EOrderBy.ASC,
            nome:'',
            perfil:'',
            filtroGenerico:filtroGenerico?filtroGenerico:''
        });
    }

    onView({ usuarioId }: any) {
        this.router.navigate(['usuario/visualizar'], { queryParams: { usuarioId } });
    }

    onEdit({ usuarioId }: any) {
        this.router.navigate(['usuario/editar'], { queryParams: { usuarioId } });
    }

    onDelete(data: Usuario) {
        this.dialogModal.open({
            title: data.nome,
            text: `Você está prestes a remover este Usuario !<br/><strong>Deseja continuar?</strong>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        data.situacaoId = ESituacao.EXCLUIDO;
                        this.service.putSituacao(data)
                            .subscribe({
                                next: () => {
                                    this.toast.showSuccess("Usuario Excluido com sucesso!");
                                    this.blockUtil.desbloquear();
                                },
                                error: (erro: string) => {
                                    this.toast.showDanger(erro);
                                    this.blockUtil.desbloquear();
                                }
                            });
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    toInsert() {
        this.toast.showDanger('Não implementado');
    }

    pesquisaAvancada() {
        this.form.reset();

        this.pesquisaAvancadaAberta = true;
    }

    close() {
        this.pesquisaAvancadaAberta = false;
        this.search();


    }
    cleanSearch() {
        this.form.controls.filtroGenerico.setValue('');

        this.search();

    }
}
