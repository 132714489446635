import { ModuloPerfil } from 'src/app/model/app.moduloperfil.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfilService } from 'src/app/services/app.perfil.service';

@Component({
  selector: 'app-perfil-visualizar',
  templateUrl: './perfil.visualizar.component.html',
  styleUrls: ['./perfil.visualizar.component.scss'],
  providers: [
    PerfilService
  ]
})
export class PerfilVisualizarComponent implements OnInit {

  public data: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: PerfilService
  ) {
    const perfilId = +this.route.snapshot.queryParamMap.get("perfilId");
        this.service.getById(perfilId)
            .subscribe((data: any) => {
                if (!data)
                    return;

                const {nome, modulosPerfil} = data;
                this.data = {nome, modulosPerfil};
            });
  }

  ngOnInit(): void {
  }

  voltar(){
    this.router.navigate(['perfil']);
  }
}
