import { Routes } from '@angular/router';
import { EAcao } from 'src/app/constantes/app.eacao.const';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { MasterComponent } from '../../components/master/master.component';
import { ParametroEntradaCadastroComponent } from './cadastro/parametroentrada.cadastro.component';
import { ParametroEntradaEditarComponent } from './editar/parametroentrada.editar.component';
import { ParametroEntradaConsultaComponent } from './parametroentrada.component';
import { ParametroEntradaVisualizarComponent } from './visualizar/parametroentrada.visualizar.component';

export const EntradaRoutes: Routes = [
    {
        path: 'parametroentrada',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ParametroEntradaConsultaComponent
            }
        ]
    },
    {
        path: 'parametroentrada/cadastro',
        component: MasterComponent,
        data: { permissao: EAcao.CADASTRAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ParametroEntradaCadastroComponent
            }
        ]
    },
    {
        path: 'parametroentrada/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ParametroEntradaVisualizarComponent
            }
        ]
    },
    {
        path: 'parametroentrada/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: ParametroEntradaEditarComponent
            }
        ]
    }
];
