import { ParmissoesPagina } from '../permissoes.pages';
import { Alerta } from '../../model/app.alerta.model';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/app.sortable.directive';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { AccountService } from 'src/app/services/app.login.service';
import { AlertaService } from 'src/app/services/app.alerta.service';
import { ExcelService } from 'src/app/services/app.excel.service';
import { EOrderBy } from 'src/app/constantes/app.eorderby.const';

@Component({
    selector: 'app-alertas',
    templateUrl: './alerta.component.html',
    styleUrls: ['./alerta.component.scss'],
    providers: [DialogUtil, BlockUtil, ExcelService],
})
export class AlertaComponent extends ParmissoesPagina implements OnInit {
    public isMobile: boolean = false;
    public alertas$: Observable<Alerta[]>;
    public total$: Observable<number>;
    public form: FormGroup;
    public eSituacao: ListItem[] = ESituacao.toList();
    public eSitucaoPendente: ESituacao = ESituacao.PENDENTE;
    public eSitucaoEmAtendimento: ESituacao = ESituacao.EM_ATENDIMENTO;
    public eSitucaoFinalizado: ESituacao = ESituacao.FINALIZADO;
    public pesquisaAvancadaAberta: boolean = false;
    public colsGrid: any = [
        {
            colKey: "alertaID",
            colName: "Alerta"
        },
        {
            colKey: "regraID",
            colName: "Regra"
        },
        {
            colKey: "situacao",
            colName: "Situação",
            type: 'string',
            options: ESituacao.toListAll()
        },
        {
            colKey: "condicionante",
            colName: "Condicionante"
        },
        {
            colKey: "usuarioAtendimento",
            colName: "Usuário Atendimento"
        },
        {
            colKey: "tipoAlerta",
            colName: "Tipo Alerta",
            type: 'bool',
            options: ['Customizada', 'Normal']

        },
        {
            colKey: "periodo",
            colName: "Data de Geração",
            type: 'dateTime'
        }
    ]

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<Alerta>>;

    constructor(
        private router: Router,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private formBuilder: FormBuilder,
        public service: AlertaService,
        private toast: ToastUtil,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService,
        private excelService: ExcelService
    ) {
        super(router.url, accountService);

        this.blockUtil.blockName = "alertasCadastrados";
        this.service.blockUtil = this.blockUtil;
        this.alertas$ = this.service.alertas$;

        this.total$ = this.service.total$;

        this.form = this.formBuilder.group({
            filtroGenerico: new FormControl('')
        });

        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });
    }

    ngOnInit(): void {
        this.service.get({});
    }

    onSort({ column, direction }: SortEvent<Alerta>) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.getOrderByDirection(column, direction);
    }

    search() {
        const filtroGenerico = this.form.controls.filtroGenerico.value?.trim();

        this.service.get({
            page: 1,
            pageSize: 10,
            searchTerm: '',
            sortColumn: '',
            sortDirection: EOrderBy.ASC,
            alerta: '',
            modeloRegraId: '',
            dataGeracao: '',
            dataHoraInclusao: '',
            situacaoId: '',
            unidadeFiscalNome: '',
            usuarioAtendimentoNome: '',
            alertaTipoLiberacaoId: '',
            alertaID: '',
            regraID: '',
            periodo: '',
            situacao: '',
            motivo: '',
            serie: '',
            condicionante: '',
            usuarioAtendimento: '',
            tipoAlerta: '',
            filtroGenerico: filtroGenerico ? filtroGenerico : ''
        });

    }

    onLink(data: Alerta) {
        this.dialogModal.open({
            title: data.modeloRegra.nome,
            text: `Deseja vincular esse Alerta a você.<br/><strong>Deseja continuar?</strong>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        data.situacaoNome = "VINCULADO";
                        data.situacaoId = ESituacao.VINCULADO;
                        this.service.putSituacao(data)
                            .subscribe({
                                next: () => {
                                    this.toast.showSuccess("Alerta vinculado!");
                                    this.blockUtil.desbloquear();
                                },
                                error: (erro: string) => {
                                    this.toast.showDanger(erro);
                                    this.blockUtil.desbloquear();
                                }
                            });
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    onView({ alertaId }: any) {
        this.router.navigate(['alerta/visualizar'], { queryParams: { alertaId } });
    }

    onFinalize({ alertaId }: any) {
        this.router.navigate(['alerta/atender'], { queryParams: { alertaId } });
    }

    onFiscalizar({ id }: any) {
        //this.router.navigate(['alertas/visualizar'], { queryParams: { regraId: id } });
        this.dialogModal.open({
            title: 'Fiscalizar',
            text: 'Ação não implementada',
            btns: [],
        });
    }

    onLiberar({ id }: any) {
        //this.router.navigate(['alertas/visualizar'], { queryParams: { regraId: id } });
        this.dialogModal.open({
            title: 'Liberar',
            text: 'Ação não implementada',
            btns: [],
        });
    }

    exportExcel(): void {
        this.blockUtil.bloquear();
        const jsonData = this.service.alertas.map(({
            alertaId,
            regra,
            dataHoraInclusaoFormatado,
            situacaoNome,
            descricaoTipoAlerta,
            usuarioNomeAlteracao,
        }) => {
            return {
                "Alerta": alertaId,
                "Regra": regra.regraId,
                "Data de Geração": dataHoraInclusaoFormatado,
                "Situação": situacaoNome,
                "Condicionante": regra?.modeloRegra?.condicionanteDescricao,
                "Usuário Atendimento": usuarioNomeAlteracao,
                "Tipo Alerta": descricaoTipoAlerta
            };
        });
        this.excelService.exportExcel(jsonData, "Alertas");
        this.blockUtil.desbloquear();
    }

    pesquisaAvancada() {
        this.pesquisaAvancadaAberta = true;
        this.form.reset();
    }

    closeSearch() {
        this.search();
        this.pesquisaAvancadaAberta = false;
    }

    cleanSearch() {
        this.form.controls.filtroGenerico.setValue('');
        this.search();

    }
}
