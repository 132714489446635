import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component } from '@angular/core';
import { UsuarioService } from 'src/app/services/app.usuario.service';
import { Usuario } from 'src/app/model';
import { BlockUtil } from 'src/app/helper/app.block.utils';

@Component({
    selector: 'app-usuario-visualizar',
    templateUrl: './usuario.visualizar.component.html',
    styleUrls: ['./usuario.visualizar.component.scss'],
    providers: [
        UsuarioService
    ]
})
export class UsuarioVisualizarComponent {

    public data: Usuario;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private blockUtil: BlockUtil,
        private service: UsuarioService
    ) {
        this.blockUtil.blockName = "usuario";
        this.blockUtil.bloquear();
        const usuarioId = +this.route.snapshot.queryParamMap.get("usuarioId");
        this.service.getById(usuarioId)
            .subscribe({
                next: (data: any) => {
                    this.data = data;
                    this.blockUtil.desbloquear();
                },
                error: () => this.data = null
            });
    }

    voltar() {
        this.router.navigate(['usuario']);
    }
}
