import { Routes } from '@angular/router';
import { MasterComponent } from '../../components/master/master.component';
import { FiltroEntradaCadastroComponent } from './cadastro/filtroentrada.cadastro.component';
import { FiltroEntradaEditarComponent } from './editar/filtroentrada.editar.component';
import { VisualizarFiltroComponent } from './visualização/filtroentrada.visualizar.component';
import { FiltroEntradaComponent } from './filtroentrada.component';
import { AutenticationGuard } from 'src/app/guard/autentication.guard';
import { EAcao } from 'src/app/constantes/app.eacao.const';

export const FiltroEntradaRoutes: Routes = [
    {
        path: 'filtroentrada',
        component: MasterComponent,
        data: { permissao: EAcao.PESQUISAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: FiltroEntradaComponent
            }
        ]
    },
    {
        path: 'filtroentrada/cadastro',
        component: MasterComponent,
        data: { permissao: EAcao.CADASTRAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: FiltroEntradaCadastroComponent
            }
        ]
    },
    {
        path: 'filtroentrada/editar',
        component: MasterComponent,
        data: { permissao: EAcao.ALTERAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: FiltroEntradaEditarComponent
            }
        ]
    },
    {
        path: 'filtroentrada/visualizar',
        component: MasterComponent,
        data: { permissao: EAcao.VISUALIZAR },
        canActivate: [AutenticationGuard],
        children: [
            {
                outlet: 'app-master',
                path: '',
                component: VisualizarFiltroComponent
            }
        ]
    }
];
