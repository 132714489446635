<h2 class="fs-title text-center">Selecione o Perído de Vigência</h2>
<div class="d-flex justify-content-center flex-column my-5">
    <div class="form-group d-flex flex-row justify-content-around date-card">
        <div class="col-3 text-left px-0">
            <label class="control-label text-left">Data Inicial</label>
            <div class="input-group">
                <input class="form-control" placeholder="dd/mm/yyyy" name="dI" ngbDatepicker disabled="true"
                    [footerTemplate]="footerTemplateInit" #dI="ngbDatepicker" [(ngModel)]="dataInicial"
                    mask="00/00/0000" />
                <button class="btn btn-outline-secondary fa-solid fa-calendar-days m-0 px-3" (click)="dI.toggle()" disabled="true"
                    type="button"></button>
            </div>
        </div>
        <div class="col-3 text-left not-format">
            <label class="control-label text-left">Hora Inicial</label>
            <ngb-timepicker [spinners]="false" [(ngModel)]="horaInicial" name="hI" disabled="true">
            </ngb-timepicker>
        </div>
        <div class="col-3 text-left px-0">
            <label class="control-label text-left">Data Final</label>
            <div class="input-group">
                <input class="form-control" placeholder="dd/mm/yyyy" name="dF" ngbDatepicker
                    [footerTemplate]="footerTemplateFinish" #dF="ngbDatepicker" [(ngModel)]="dataFinal"
                    mask="00/00/0000" />
                <button class="btn btn-outline-secondary fa-solid fa-calendar-days m-0 px-3" (click)="dF.toggle()"
                    type="button"></button>
            </div>
        </div>
        <div class="col-3 text-left not-format">
            <label class="control-label text-left">Hora Final</label>
            <ngb-timepicker [spinners]="false" [(ngModel)]="horaFinal" name="hF">
            </ngb-timepicker>
        </div>
    </div>

    <ng-template #footerTemplateInit>
        <hr class="my-0" />
        <div class="d-flex justify-content-between">
            <button class="btn btn-primary btn-sm m-2 float-start" (click)="dataInicial = hoje; dI.close()">
                Hoje
            </button>
            <button class="btn btn-secondary btn-sm m-2 float-end" (click)="dI.close()">
                Fechar
            </button>
        </div>
    </ng-template>

    <ng-template #footerTemplateFinish>
        <hr class="my-0" />
        <div class="d-flex justify-content-between">
            <button class="btn btn-primary btn-sm m-2 float-start" (click)="dataFinal = hoje; dF.close()">
                Hoje
            </button>
            <button class="btn btn-secondary btn-sm m-2 float-end" (click)="dF.close()">
                Fechar
            </button>
        </div>
    </ng-template>
    <input type="hidden" [ngModel]="I" />
    <input type="hidden" [ngModel]="F" />
</div>
