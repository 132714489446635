<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body ">
                        <div class="form-horizontal mx-3 ">
                            <div class="form-body " *ngIf="data ">
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Título</label>
                                            <p>{{data.titulo}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Caminho</label>
                                            <p>{{data.caminho}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label class="control-label">Grupo</label>
                                            <p>{{data.grupoNome}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label">Ordem</label>
                                            <p>{{data.ordem}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group icone">
                                            <label class="control-label">Icone</label>
                                            <div class="icone d-flex justify-content-center align-items-center"
                                                *ngIf="!data.icone">
                                                <article style="flex: .25; max-width: 240px !important;">
                                                    <div class="card m-0">
                                                        <div
                                                            class="d-flex flex-column justify-content-center align-items-center icone-vazio">
                                                            <i class="fas fa-ban ml-1 mr-2"></i>
                                                            <span class="">Nenhum</span>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div class="icone d-flex justify-content-center align-items-center"
                                                *ngIf="data.icone">
                                                <article style="flex: .25; max-width: 240px !important;">
                                                    <div class="card m-0">
                                                        <div
                                                            class="d-flex flex-column justify-content-center align-items-center icone-visualizar">
                                                            <i class="{{ data.icone.classe }} ml-1 mr-2"></i>
                                                            <span class="icon-name">{{ data.icone.nome }}</span>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label">Situação</label>
                                            <p>{{data.situacaoNome}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3 ">
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Data Inclusão</label>
                                            <p>{{data.dataHoraInclusao | date:'dd/MM/yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Usuário Inclusão</label>
                                            <p *ngIf="data.usuarioNomeInclusao">{{data.usuarioNomeInclusao}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Data Alteração</label>
                                            <p *ngIf="data.usuarioIDAlteracao ">{{data.dataHoraAlteracao |
                                                date:'dd/MM/yyyy'}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 ">
                                        <div class="form-group ">
                                            <label class="control-label ">Usuário Alteração</label>
                                            <p *ngIf="data.usuarioNomeAlteracao">{{data.usuarioNomeAlteracao}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer ">
                                <button type="button" class="btn btn-sm default " (click)="voltar() ">Voltar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</my-content>
