import { AlertaEventoAtualizacaoDfe } from './app.alertaeventoatualizacaodfe.model';
import { AlertaHistorico } from './app.alertahistorico.model';
import { AlertaImagemAtendimento } from './app.alertaimagematendimento.model';
import { AlertaNotificacao } from './app.alertanotificacao.model';
import { AlertaParametroSaida } from './app.alertaparametrosaida.model';
import { ModeloRegra } from "./app.modeloregra.model";
import { Regra } from "./app.regra.model";
import { RegraUnidadeFiscal } from "./app.regraunidadefiscal.model";

export class Alerta {
    alertaId: number;
    situacaoId: number;
    situacaoNome: string;
    modeloRegra: ModeloRegra;
    regra: Regra;
    motivo: string;
    serie: string;
    numeroDocumento: string;
    regraUnidadeFiscal: RegraUnidadeFiscal;
    alertaTipoLiberacaoId: number;
    alertaTipoLiberacaoNome: string;
    dataHoraInclusao: string;
    dataHoraAlteracao: string;
    usuarioIdInclusao: number;
    usuarioNomeInclusao: string;
    usuarioIdAlteracao: number;
    usuarioNomeAlteracao: string;
    alertaHistoricos: AlertaHistorico[];
    alertasParametroSaida: AlertaParametroSaida[];
    alertasNotificacao: AlertaNotificacao[];
    alertasEventoAtualizacaoDfe: AlertaEventoAtualizacaoDfe[];
    alertaImagemAtendimentoIds: number[];
    unidade: string;
    hashNIFI: string;
    alertaCustomizado: boolean;
    descricaoTipoAlerta: string;
    dataHoraInclusaoFormatado?: string;

    constructor(alertaDto: any) {
        this.alertaId = alertaDto?.alertaId ?? alertaDto?.alertaID;
        this.situacaoId = alertaDto?.situacaoID;
        this.situacaoNome = alertaDto?.situacaoNome;
        this.modeloRegra = new ModeloRegra(alertaDto?.modeloRegra);
        this.regra = new Regra(alertaDto?.regra);
        this.motivo = alertaDto?.motivo;
        this.serie = alertaDto?.serie;
        this.numeroDocumento = alertaDto?.numeroDocumento;
        this.regraUnidadeFiscal = new RegraUnidadeFiscal(alertaDto?.regraUnidadeFiscal);
        this.alertaTipoLiberacaoId = alertaDto?.alertaTipoLiberacaoID;
        this.alertaTipoLiberacaoNome = alertaDto?.alertaTipoLiberacaoNome;
        this.dataHoraInclusao = alertaDto?.dataHoraInclusao;
        this.dataHoraAlteracao = alertaDto?.dataHoraAlteracao;
        this.usuarioIdInclusao = alertaDto?.usuarioIDInclusao;
        this.usuarioNomeInclusao = alertaDto?.usuarioNomeInclusao;
        this.usuarioIdAlteracao = alertaDto?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = alertaDto?.usuarioNomeAlteracao;
        this.alertaHistoricos = alertaDto?.alertaHistoricos?.map(alertaHistorico => new AlertaHistorico(alertaHistorico)) ?? [];
        this.alertasParametroSaida = alertaDto?.alertasParametroSaida?.map(alertaParametroSaida => new AlertaParametroSaida(alertaParametroSaida)) ?? [];
        this.alertasNotificacao = alertaDto?.alertasNotificacao?.map(alertaNotificacao => new AlertaNotificacao(alertaNotificacao)) ?? [];
        this.alertasEventoAtualizacaoDfe = alertaDto?.alertasEventoAtualizacaoDfe?.map(alertaEventoAtualizacao => new AlertaEventoAtualizacaoDfe(alertaEventoAtualizacao)) ?? [];
        this.alertaImagemAtendimentoIds = alertaDto?.alertaImagemAtendimentoIDs ?? [];
        this.unidade = alertaDto?.unidade;
        this.hashNIFI = alertaDto.hashNIFI;
        this.alertaCustomizado = alertaDto?.alertaCustomizado;
        this.descricaoTipoAlerta = alertaDto.descricaoTipoAlerta;
        this.dataHoraInclusaoFormatado = alertaDto?.dataHoraInclusaoFormatado;
    }
}
