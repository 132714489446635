
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, filter, Observable } from 'rxjs';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

declare global {
    interface String {
        toFloat: (this: String) => Number;
        getMask: (this: String) => String;
        toCaptalize: (this: String) => String;
        coordiantesInDecimal: () => number
    }
    interface StringConstructor {
        isNullOrEmpty: (value: any) => boolean;
    }

    interface Array<T> {
        orderBy: (ordenador: string, desc?: boolean) => void;
    }
    interface Number {
        toStartFixed: (casas: number, caracter: string, comma?: boolean) => string;
        toFixedView: (casas: number) => string;
    }
}

String.prototype.toFloat = function (this: String): Number {
    return +this?.replace(/,/g, '.');
}

String.isNullOrEmpty = function (value: any): boolean {
    return value === null || value === undefined || value === '';
}

String.prototype.getMask = function (): String {
    const number = this.replace(/\D/g, '');
    return number.length == 9 ? "00000-0000" : "0000-0000";
}

String.prototype.toCaptalize = function (): String {
    return this.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

Number.prototype.toStartFixed = function (casas: number, caracter: string, comma: boolean = false): string {
    const number = parseInt(this) + '';
    let numberReverse = this.toString().split('').reverse().join('');
    for (let i = number.length; i < casas; i++)
        numberReverse += caracter;
    if (comma)
        numberReverse = numberReverse.replace('.', ',');
    return numberReverse.split('').reverse().join('');
}

Number.prototype.toFixedView = function (casas: number): string {
    return this.toFixed(casas)
        .split('').reverse().join('')
        .replace(/\./, ',')
        .split('').reverse().join('');
}

Array.prototype.orderBy = function (ordenador: string, desc: boolean = false): void {
    //bubbleSort
    let trocado: boolean;
    do {
        trocado = false;
        for (let i = 0; i < this.length - 1; i++) {
            let valorAtual = typeof this[i][ordenador] === "function" ? this[i][ordenador]() : this[i][ordenador];
            let valorSeguinte = typeof this[i + 1][ordenador] === "function" ? this[i + 1][ordenador]() : this[i + 1][ordenador];
            if ((valorAtual < valorSeguinte && desc) ||
                (valorAtual > valorSeguinte && !desc)) {
                let temp = this[i];
                this[i] = this[i + 1];
                this[i + 1] = temp;
                trocado = true;
            }
        }
    } while (trocado);
};


String.prototype.coordiantesInDecimal = function (): number {
    const tipoCoordenda = this.replace(/[^A-Za-z]/g, "");
    const [, grau, minuto, segundo] = this.split(" ");
    const minutos = parseInt(grau.replace(/\D/g, ""));
    const segundos1 = parseInt(minuto.replace(/[\'\"]/g, "")) * 60;
    const segundos2 = parseFloat(segundo.replace(/[\'\"]/g, "").replace(",", "."));
    const ehNegativo = tipoCoordenda == "S" || tipoCoordenda == "W";
    let numero = minutos + (segundos1 + segundos2) / 3600;

    if (ehNegativo)
        numero *= -1;

    return numero;
}
declare module '@angular/forms' {
    interface FormGroup {
        values: () => Observable<any>;
    }
}

FormGroup.prototype.values = function (): Observable<any> {
    const _subject = new BehaviorSubject(null);
    this.valueChanges.subscribe((values: any) => _subject.next(values));
    return _subject.asObservable()
        .pipe(
            filter(values => !!values)
        );
};
