import { NgxMaskModule } from 'ngx-mask';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { CondicionanteModule } from './pages/condicionante/condicionante.module';
import { UtilService } from './services/app.utils.service';
import { ComunService } from './services/app.comum.service';
import { AuthenticationProvider } from './auth/app.auth.token';
import { ParametroEntradaModule } from './pages/parametroentrada/parametroentrada.module';
import { FiltroEntradaModule } from './pages/filtroentrada/filtroentrada.module';
import { ParametroSaidaModule } from './pages/parametrosaida/parametrosaida.module';
import { DialogContainer } from './helper/app.dialog.util';
import { PipesModule } from './pipes/app.pipes.module';
import { ModeloRegraModule } from './pages/modeloregra/modeloregra.module';
import { RegraModule } from './pages/regra/regra.module';
import { PerfilModule } from './pages/perfil/perfil.module';
import { LoginComponent } from './login/login.component';
import { UsuarioModule } from './pages/usuario/usuario.module';
import { ModuloModule } from './pages/menu/modulo.module';
import { AlertaModule } from './pages/alerta/alerta.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { UsuarioConectadoModule } from './pages/usuarioconectado/usuarioconectado.module';
import { UtilAcessoProducaoService } from './services/app.utilsacessoproducao.service';
import { LocalizacaoEventoModule } from './pages/localizacaoevento/localizacaoevento.module';
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import localePt from '@angular/common/locales/pt';
import { LocalizacaoMapaModule } from './pages/localizacaomapa/localizacaomapa.module';
import { ConsultarplacasModule } from './pages/consultarplacas/consultarplacas.module';

registerLocaleData(localePt);
@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        NgbModule,
        CommonModule,
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot(),
        BlockUIModule.forRoot(),
        NgbDatepickerModule,
        CondicionanteModule,
        ParametroEntradaModule,
        FiltroEntradaModule,
        ParametroSaidaModule,
        ModeloRegraModule,
        PipesModule,
        FontAwesomeModule,
        RegraModule,
        PerfilModule,
        UsuarioModule,
        ModuloModule,
        PipesModule,
        AlertaModule,
        DashboardModule,
        UsuarioConectadoModule,
        LocalizacaoEventoModule,
        LocalizacaoMapaModule,
        ConsultarplacasModule
    ],
    declarations: [AppComponent, DialogContainer, LoginComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AuthenticationProvider,
        UtilService,
        ComunService,
        BlockUtil,
        UtilAcessoProducaoService,
        ComponentsModule,
        { provide: LOCALE_ID, useValue: 'pt' }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faCalendar, faClock);
    }
}
