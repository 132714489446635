import { ESituacao } from '../../constantes/app.esituacao.const';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { NgbdSortableHeader, SortEvent } from 'src/app/directives/app.sortable.directive';
import { DialogUtil } from 'src/app/helper/app.dialog.util';
import { BlockUtil } from 'src/app/helper/app.block.utils';
import { ToastUtil } from 'src/app/helper/app.toast.utils';
import { ListItem } from 'src/app/interfaces/app.utilservices.interface';
import { ModeloRegra } from 'src/app/model/app.modeloregra.model';
import { ModeloRegraService } from 'src/app/services/app.modeloregra.service';
import { AccountService } from 'src/app/services/app.login.service';
import { ParmissoesPagina } from '../permissoes.pages';
import { EOrderBy } from 'src/app/constantes/app.eorderby.const';


@Component({
    selector: 'app-condicionantes',
    templateUrl: './modeloregra.component.html',
    styleUrls: ['./modeloregra.component.scss']

})
export class ModeloRegraComponent extends ParmissoesPagina implements OnInit {

    public isMobile: boolean = false;
    public modelosregra$: Observable<ModeloRegra[]>;
    public condicionanteEditar = new BehaviorSubject<ModeloRegra>(null);
    public total$: Observable<number>;
    public form: FormGroup;
    public eSituacao: ListItem[] = ESituacao.toList();
    public permissoes: any = {};
    public pesquisaAvancadaAberta: boolean = false;
    
    public colsGrid: any = [
       {
            colKey: "nome",
            colName: "Nome"
        },
        {
            colKey: "condicionante",
            colName: "Condicionante",

        },
        {
            colKey: "situacaoID",
            colName: "Situação",
            type:'string',
            options:ESituacao.toListAll()
        },

    ]


    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader<ModeloRegra>>;

    constructor(
        private router: Router,
        private dialogModal: DialogUtil,
        private blockUtil: BlockUtil,
        private formBuilder: FormBuilder,
        public service: ModeloRegraService,
        private toast: ToastUtil,
        private breakpointObserver: BreakpointObserver,
        private accountService: AccountService
    ) {
        super(router.url, accountService);
        this.modelosregra$ = service.modelosregra$;
        this.total$ = service.total$;

        this.accountService.PermissionOnModel(router.url)
            .subscribe({
                next: permissoes => this.permissoes = permissoes
            });

        this.form = this.formBuilder.group({
            filtroGenerico: new FormControl('')
        });

        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            if (result.matches) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        });
    }

    ngOnInit(): void {
        this.service.get({});
    }

    onSort({ column, direction }: SortEvent<ModeloRegra>) {
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.getOrderByDirection(column, direction);
    }

    search() {
        const filtroGenerico = this.form.controls.filtroGenerico.value?.trim();
        this.service.get({
            page: 1,
            pageSize: 10,
            searchTerm: '',
            sortColumn: '',
            sortDirection: EOrderBy.ASC,
            nome: '',
            situacaoID:'',
            condicionante:'',
            filtroGenerico: filtroGenerico?filtroGenerico:''
        });
    }

   

    onView({ modeloRegraId }: any) {
        this.router.navigate(['modeloregra/visualizar'], { queryParams: { modeloRegraId } });
    }
    onEdit({ modeloRegraId }: any) {
        this.router.navigate(['modeloregra/editar'], { queryParams: { modeloRegraId } });
    }
    onDelete(data: ModeloRegra) {
        this.dialogModal.open({
            title: data.nome,
            text: `Você está prestes a remover o modelo de regra!<br/><strong>Deseja continuar?</strong>`,
            btns: [
                {
                    label: 'Sim',
                    class: 'btn-primary',
                    callback: () => {
                        this.blockUtil.bloquear();
                        data.situacaoId = ESituacao.EXCLUIDO;
                        this.service.putSituacao(data)
                            .subscribe({
                                next: () => {
                                    this.toast.showSuccess("Modelo de Regra Excluido com sucesso!");
                                    this.blockUtil.desbloquear();
                                },
                                error: (erro) => {
                                    let exibirMensagemDeAtencao = erro.toString().includes('Atenção!');
                                    if (exibirMensagemDeAtencao)
                                        this.toast.showWarning(erro.toString().replace('Error:', ''), '');
                                    else
                                        this.toast.showDanger(erro);

                                    this.blockUtil.desbloquear();
                                }
                            });
                    }
                },
                {
                    label: 'Não',
                    class: '',
                    callback: null
                }
            ]
        })
    }

    toInsert() {
        this.router.navigate(['modeloregra/cadastro']);
    }

    pesquisaAvancada() {
        this.form.reset();

        this.pesquisaAvancadaAberta = true;
    }

    close() {
        this.pesquisaAvancadaAberta = false;
        this.search();


    }
    cleanSearch() {
        this.form.controls.filtroGenerico.setValue('');

        this.search();

    }
}
