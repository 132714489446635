import { Component, Input, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { Equipamento } from 'src/app/model/app.equipamentos.model';
import { ComunService } from 'src/app/services/app.comum.service';
import { LocalizacaoMapaService } from './localizacaomapa.service';

@Component({
  selector: 'app-localizacaomapa',
  templateUrl: './localizacaomapa.component.html',
  styleUrls: ['./localizacaomapa.component.scss']
})
export class LocalizacaomapaComponent implements OnInit {
  map: mapboxgl.Map;

  public equipamentos: Equipamento[];

  exibirViasFerrea = true;
  exibirRodoviasEstaduais = true;
  exibirRodoviasFederais = true;

  constructor(private comunService: ComunService,
    private localizacaoMapaService: LocalizacaoMapaService) {
  }

  async controleExibirViaFerrea(exibir: boolean) {
    this.exibirViasFerrea = exibir;
    await this.abrirMapa();
  }

  async controleExibirRodoviasEstaduais(exibir: boolean) {
    this.exibirRodoviasEstaduais = exibir;
    await this.abrirMapa();
  }

  async controleExibirRodoviasFederais(exibir: boolean) {
    this.exibirRodoviasFederais = exibir;
    await this.abrirMapa();
  }

  async ngOnInit() {
    await this.abrirMapa();
  }

  async abrirMapa() {
    await this.criarMapa();
    await this.adicionarMalhaViaria();
  }

  async criarMapa() {
    (mapboxgl as typeof mapboxgl).accessToken = 'pk.eyJ1IjoiZGFuaWVsdHJpYm9uaSIsImEiOiJjbGZzc2w0MGQwOHo1M3NvMTV1c2dza21vIn0.o5plCFUF0qJmFRDTsZi5XA';
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      zoom: 8,
      scrollZoom: false,
      center: [-54.64931380254392, -20.42205329420588]
    });

    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    this.adicionarLocalizacaoDeEquipamentos();
    this.adicionarUsuariosConectados();
    this.AdicionarUnidadesFiscais();
  }

  private adicionarLocalizacaoDeEquipamentos() {
    this.comunService.ObterEquipamentos()
      .pipe()
      .subscribe(response => {
        response.forEach(equipamento => {
          if (!equipamento.equipamentoDeMS(equipamento?.local))
            return;

          let longitude = +equipamento?.local?.longitude;
          let latitude = +equipamento?.local?.latitude;
          let nomeEquipamento = equipamento?.local?.nome;

          const popup = new mapboxgl.Popup({
            offset: 25
          }).setHTML(this.gerarTextoPopup(longitude, latitude, nomeEquipamento));

          const el = document.createElement('div');
          el.className = 'marker';
          el.style.backgroundImage = `url(../../../assets/img/equipamento-ms.png)`;
          el.style.backgroundColor = 'transparent'
          el.style.width = '25px';
          el.style.height = '30px';
          el.style.backgroundSize = '100%';

          new mapboxgl.Marker(el)
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);
        });
      });
  }

  private adicionarUsuariosConectados() {
    this.comunService.ObterUsuariosConectados()
      .pipe()
      .subscribe(response => {
        response.forEach(usuarioConectado => {
          let longitude = this.formatarDadosCoordenadas(usuarioConectado.Coordenadas, 1);
          let latitude = this.formatarDadosCoordenadas(usuarioConectado.Coordenadas, 0);
          const popup = new mapboxgl.Popup({
            offset: 25
          }).setHTML(this.gerarTextoPopup(longitude, latitude, usuarioConectado.NomeDoUsuario, usuarioConectado.UnidadeDoUsuario));

          const el = document.createElement('div');
          el.className = 'marker';
          el.style.backgroundImage = `url(../../../assets/img/usuario-conectado.png)`;
          el.style.backgroundColor = 'transparent'
          el.style.width = '60px';
          el.style.height = '70px';
          el.style.backgroundSize = '100%';

          new mapboxgl.Marker(el).setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);
        });
      });
  }

  private AdicionarUnidadesFiscais() {
    this.comunService.ObterUnidadesESubunidadesFiscaisAtivas()
      .pipe()
      .subscribe(response => {
        response.forEach(unidadeFiscal => {
          let longitude = this.formatarDadosCoordenadas(unidadeFiscal.coordenadas, 1);
          let latitude = this.formatarDadosCoordenadas(unidadeFiscal.coordenadas, 0);
          
          const popup = new mapboxgl.Popup({
            offset: 25
          }).setHTML(this.gerarTextoPopup(longitude, latitude, unidadeFiscal.nome));

          const el = document.createElement('div');
          el.className = 'marker';
          el.style.backgroundImage = `url(../../../assets/img/unidade-fiscal.png)`;
          el.style.backgroundColor = 'transparent'
          el.style.width = '50px';
          el.style.height = '60px';
          el.style.backgroundSize = '100%';

          new mapboxgl.Marker(el).setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);
        });
      });
  }

  private formatarDadosCoordenadas(coordenadas: string, posicaoDoArray: number): number {
    if (String.isNullOrEmpty(coordenadas))
      return null;

    return +coordenadas.replace('POINT', '').replace('(', '').replace(')', '').split(',')[posicaoDoArray] ?? null;
  }

  private gerarTextoPopup(longitude: number, latitude: number, nome: string, nomeUnidadeFiscal?: string): string {
    let coordenas = `${longitude}, ${latitude}`;

    return `${this.gerarUmaLinhaComTitutloEConteudo('Nome', nome)}
    ${this.gerarUmaLinhaComTitutloEConteudo('Coordenadas', coordenas)}
    ${!String.isNullOrEmpty(nomeUnidadeFiscal) ? this.gerarUmaLinhaComTitutloEConteudo('Unidade', nomeUnidadeFiscal) : ''}`;
  }

  private gerarUmaLinhaComTitutloEConteudo(titulo: string, conteudo: string, comQuebraDeLinha: boolean = true): string {
    let novaLinha = comQuebraDeLinha ? this.quebraDeLinha() : '';
    return `${this.textoEmNegrito(titulo)} ${conteudo} ${novaLinha}`;
  }

  private textoEmNegrito(texto: string): string {
    return `<strong>${texto}:</strong>`;
  }

  private quebraDeLinha(): string {
    return '<br />';
  }

  async adicionarMalhaViaria() {
     if (this.exibirViasFerrea)
       await this.adicionarMalhaViariaViasFerrea();

    if (this.exibirRodoviasEstaduais)
      await this.adicionarMalhaViariaRodoviasEstaduais();

     if (this.exibirRodoviasFederais)
       await this.adicionarMalhaViariaRodoviasFederais();
  }

  async adicionarMalhaViariaViasFerrea() {
    let idSource = 'viasFerrea';
    let cor = '#000000';

    this.map.on('load', () => {
      this.criarSource(idSource, this.malhaViariaMSViaFerrea());
      this.criarLayer(idSource, cor);
    });
  }

  async adicionarMalhaViariaRodoviasEstaduais() {
    let idSource = 'rodoviasEstaduais';
    let cor = '#0000ff';

    this.map.on('load', () => {
      this.criarSource(idSource, this.malhaViariaMSRodoviasEstaduais());
      this.criarLayer(idSource, cor);
    });
  }

  async adicionarMalhaViariaRodoviasFederais() {
    let idSource = 'rodoviasFederais';
    let cor = '#ffa500';

    this.map.on('load', () => {
      this.criarSource(idSource, this.malhaViariaMSRodoviasFederais());
      this.criarLayer(idSource, cor);
    });
  }

  private criarSource(idSource: string, malhaViaria: string) {
    this.map.addSource(idSource, {
      type: 'geojson',
      data: `${malhaViaria}`      
    });
  }

  private criarLayer(idSource: string, cor: string) {
    this.map.addLayer({
      'id': `layer_${idSource}`,
      'type': 'line',
      'source': idSource,
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': cor,
        'line-width': 2
      }
    });
  }

  private malhaViariaMSViaFerrea(): string {
    return this.localizacaoMapaService.obterLinkMalhaViariaMSViaFerrea()
  }

  private malhaViariaMSRodoviasEstaduais(): string {
    return this.localizacaoMapaService.obterLinkMalhaViariaMSRodoviasEstaduais();
  }

  private malhaViariaMSRodoviasFederais(): string {
    return this.localizacaoMapaService.obterLinkMalhaViariaMSRodoviasFederais();    
  }
}
