<form [formGroup]="form" *blockUI="'modeloRegra'">
    <h2 class="fs-title">Selecione o Modelo da Regra</h2>
    <div class="d-flex justify-content-center flex-column my-3">
        <div class="form-group d-flex justify-content-center">
            <div class="col-md-6 main-card d-flex justify-content-left flex-column">
                <label class="control-label text-left">Modelo de Regra</label>
                <select class="form-control form-control-sm" formControlName="modeloRegra" disabled="true">
                    <option value>SELECIONE...</option>
                    <option *ngFor=" let modeloRegra of modelosRegra" [ngValue]="modeloRegra">{{
                        modeloRegra.nome }}
                    </option>
                </select>
            </div>
        </div>
        <div class="my-3" *ngIf="modeloDeRegraSelecionado">
            <h2 class="fs-title">Condicionante</h2>
            <label class="control-label text-center font-weight-bold">{{ condicionanteDomodeloDeRegraSelecionado }}</label>
        </div>
        <div class="form-group d-flex justify-content-center flex-column" *ngIf="modeloDeRegraSelecionado">
            <h2 class="fs-title">Parâmetros de Entrada</h2>
            <label class="control-label text-left">Escolha o Parâmetro de Entrada</label>
            <div class="input-group main-card d-flex justify-content-left ml-0">
                <select class="form-control form-control-sm" formControlName="parametroSelecionado">
                    <option value>SELECIONE...</option>
                    <option *ngFor="let parametroEntrada of parametrosEntrada" [ngValue]="parametroEntrada">{{
                        parametroEntrada.nome }}
                    </option>
                </select>
                <button class="btn btn-primary my-0" type="button" (click)="onAddParametroEntrada()"><i
                        class="fa-solid fa-plus"></i> Adicionar</button>
            </div>
        </div>
        <div class="my-3" *ngIf="possuiParametrosEntrada" formArrayName="formParametro">            
            <div class="d-flex justify-content-center" *ngFor="let campo of formParametro.controls; let i = index">
                <app-campo class="ml-1 w-100" [campo]="campo" [index]="i" [operadores]="operadores"
                    [agrupadores]="agrupadores" [equipamentos]="equipamentos" [ultimo]="formParametro.controls.length-1"
                    (deleteItem)="onRemoveFieldInFormParametro($event)" ehEdicao></app-campo>
            </div>
        </div>
        <div class="form-group d-flex justify-content-center flex-column" *ngIf="modeloDeRegraSelecionado">
            <h2 class="fs-title">Filtros de Entrada</h2>
            <label class="control-label text-left">Escolha o Filtro de Entrada</label>
            <div class="input-group main-card d-flex justify-content-left ml-0">
                <select class="form-control form-control-sm" formControlName="filtroSelecionado">
                    <option value>SELECIONE...</option>
                    <option *ngFor="let filtroEntrada of filtrosEntrada" [ngValue]="filtroEntrada">{{
                        filtroEntrada.nome }}
                    </option>
                </select>
                <button class="btn btn-primary my-0" type="button" (click)="onAddFiltroEntrada()"><i
                        class="fa-solid fa-plus"></i> Adicionar</button>
            </div>
        </div>
        <div class="my-3" *ngIf="possuiFiltrosEntrada" formArrayName="formFiltro">            
            <div class="d-flex justify-content-center" *ngFor="let campo of formFiltro.controls; let i = index">
                <app-campo class="ml-1 w-100" [campo]="campo" [index]="i" [operadores]="operadores"
                    [agrupadores]="agrupadores" [equipamentos]="equipamentos" [ultimo]="formFiltro.controls.length-1"
                    (deleteItem)="onRemoveFieldInFormFiltro($event)" ehEdicao></app-campo>
            </div>
        </div>
    </div>
</form>