import { ParametroSaida } from './app.parametrosaida.model';
import { FiltroEntrada } from './app.filtroentrada.model';
import { ParametroEntrada } from './app.parametroentrada.model';
export class ModeloRegra {
    modeloRegraId: number;
    nome: string;
    condicionanteId: number;
    condicionanteDescricao?: string;
    situacaoId?: number;
    situacaoNome?: string;
    usuarioIdInclusao?: number;
    usuarioNomeInclusao?: string;
    usuarioIdAlteracao?: number;
    usuarioNomeAlteracao?: string;
    dataHoraInclusao: Date;
    dataHoraAlteracao?: Date;
    parametrosEntrada: ParametroEntrada[];
    filtrosEntrada: FiltroEntrada[];
    parametrosSaida: ParametroSaida[];
    estaConfiguradoComAQuintaCondicionante?: boolean;

    constructor(dtoModeloregra: any) {
        this.modeloRegraId = dtoModeloregra?.modeloRegraID;
        this.condicionanteId = dtoModeloregra?.condicionanteID;
        this.condicionanteDescricao = dtoModeloregra?.condicionanteDescricao;
        this.nome = dtoModeloregra?.nome;
        this.situacaoId = dtoModeloregra?.situacaoID;
        this.situacaoNome = dtoModeloregra?.situacaoNome;
        this.usuarioIdInclusao = dtoModeloregra?.usuarioIDInclusao;
        this.usuarioNomeInclusao = dtoModeloregra?.usuarioNomeInclusao;
        this.usuarioIdAlteracao = dtoModeloregra?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = dtoModeloregra?.usuarioNomeAlteracao;
        this.dataHoraInclusao = dtoModeloregra?.dataHoraInclusao;
        this.dataHoraAlteracao = dtoModeloregra?.dataHoraAlteracao;
        this.parametrosEntrada = dtoModeloregra?.parametrosEntrada?.map((modeloRegra: any) => new ParametroEntrada(modeloRegra)) as ParametroEntrada[];
        this.filtrosEntrada = dtoModeloregra?.filtrosEntrada?.map((modeloRegra: any) => new FiltroEntrada(modeloRegra)) as FiltroEntrada[];
        this.parametrosSaida = dtoModeloregra?.parametrosSaida?.map((modeloRegra: any) => new ParametroSaida(modeloRegra)) as ParametroSaida[];
        this.estaConfiguradoComAQuintaCondicionante = dtoModeloregra?.estaConfiguradoComAQuintaCondicionante;
    }
}
