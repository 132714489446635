import { ChangeDetectorRef, Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { Alerta, Perfil } from '../../model/index';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { ConfigurableFocusTrap } from "@angular/cdk/a11y";
import { AlertaService } from "src/app/services/app.alerta.service";
import { RegraService } from "src/app/services/app.regra.service";


@Component({
  selector: 'app-inputs',
  template: `
    <ng-container *ngIf='!ehData && !ehBoleano && !ehSelect'>
        <div class="input-group mb-3">
        <input
          type="search"
          class="form-control form-control-sm"
          style="width: 200px; height:40px;"    
          [placeholder]="inputProp.placeHolder" 
          (blur)="onBlur($event)"
          maxlength="75"
          [id]="control"
        /> 
        <button type="button"
          (click)="excluir($event)"
          class="btn btn-default btn-sm my-0 d-flex align-items-center"
          style="gap: 0.5rem; height: 40px;">
          <i class="fa-solid fa-x"></i>
        </button>
        </div>
      </ng-container>
      <ng-container *ngIf='ehBoleano'>
      <div class="input-group mb-3">
      <select class="form-control form-control-sm" style="width: 200px" [id]="control"
        (change)="selectChange($event)"  selected="selecione">
        <option value='selecione'>Selecione tipo</option>
        <option value='true'>{{ options[0]}}</option>
        <option value='false'>{{ options[1]}}</option>
      </select>
      <button type="button"
        (click)="excluir($event)"
        class="btn btn-default btn-sm my-0 d-flex align-items-center"
        style="gap: 0.5rem; height: 40px;">
        <i class="fa-solid fa-x"></i>
      </button>
      </div>
    </ng-container>
    <ng-container *ngIf='ehSelect'>
    <div class="input-group mb-3">
    <select class="form-control form-control-sm" style="width: 200px" [id]="control"
      (change)="selectChange($event)">
      <option value='selecione'>Selecione</option>
      <option *ngFor="let c of options" [value]="c.id">{{c.nome}}</option>
    </select>
    <button type="button"
      (click)="excluir($event)"
      class="btn btn-default btn-sm my-0 d-flex align-items-center"
      style="gap: 0.5rem; height: 40px;">
      <i class="fa-solid fa-x"></i>
    </button>
    </div>
  </ng-container>
      <ng-container *ngIf='ehData'>
        <div class="input-group mb-6">
        <p style="margin: 8px">De</p>
          <input
            type="search"
            class="form-control form-control-sm"
            style="width: 200px; height:40px;"    
            placeholder="__/__/____" 
            mask="00/00/0000"
            (blur)="onBlurData($event, 1)"
            maxlength="75"
            id="dataInicial"
          /> 
          <p style="margin: 8px">até</p>
          <input
            type="search"
            class="form-control form-control-sm"
            style="width: 200px; height:40px;"    
            placeholder="__/__/____" 
            mask="00/00/0000"
            (blur)="onBlurData($event, 2)"
            maxlength="75"
            id="dataInicial"

          /> 
          <button type="button"
            (click)="excluir($event)"
            class="btn btn-default btn-sm my-0 d-flex align-items-center"
            style="gap: 0.5rem; height: 40px;">
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
      </ng-container>
    
  
    `,
})
export class InputsComponent implements OnInit {
  @Input() inputProp: any;
  @Output() delete = new EventEmitter();
  @Input() body: any;
  @Input() limpar: Function;

  ehData: boolean = false;
  ehBoleano: boolean = false;
  ehSelect: boolean = false;

  periodo: any = {
    dataInicial: '',
    dataFinal: ''
  }


  constructor() {

  }

  get control(): string {
    return this.inputProp.control;
  }

  get options(): Array<string> {
    return this.inputProp.options;
  }

  ngOnInit(): void {
    if (this.inputProp?.type?.includes('dateTime')) {
      this.ehData = true;
    }

    if (this.inputProp?.type?.includes('bool')) {
      this.ehBoleano = true;

    }

    if (this.inputProp?.type?.includes('string') && this.inputProp?.options.length > 0) {
      this.ehSelect = true;

    }


  }

  onBlur(event) {
    this.body[this.inputProp.control] = event.target.value;
  }


  onBlurData(event, tipo) {

    if (tipo == 1) {

      this.periodo.dataInicial = event.target.value;
    } else if (this.periodo.dataInicial && tipo == 2) {

      this.periodo.dataFinal = event.target.value;

      this.body[this.inputProp.control] = this.periodo;

    }
  }

  selectChange(event){
    
    this.body[this.inputProp.control] = event.target.value;
  }

  excluir(event) {
    this.body[this.inputProp.control] = "";


    this.delete.emit(event);
  }

}


@Component({
  selector: 'app-inputsearch',
  templateUrl: './customsearch.component.html',
  styleUrls: ['./customsearch.component.scss']
})


export class CustomSearchComponent {
  @Input() colsGrid: Array<any>;
  @Input() module?: string;
  @Input() service: any;
  @Output() closeSearch = new EventEmitter();
  @Output() cleanSearch = new EventEmitter();


  public form: FormGroup;
  public inputs: any = [];
  public body: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    public alertaService: AlertaService,
    public regraService: RegraService,

  ) { }

  selectChange(event) {

    let col = this.colsGrid.filter(c => c.colKey == event.target.value);

    if (!this.inputs.some(i => i.control == col[0].colKey)) {
      this.inputs.push(
        {
          control: col[0]?.colKey,
          placeHolder: `Digite ${col[0]?.colName}`,
          type: col[0].type,
          options: col[0].options
        });
    }
  }

  closer() {
    this.closeSearch.emit(false);
  }

  delete(event) {

    this.inputs.splice(event, 1);

  }

  search() {
    this.body['filtroGenerico'] =''
    
    this.service.get(this.body);
    this.body = {};
  }

  limpar() {
    const el: HTMLFormElement = document.getElementById('pesquisaAvancadaForm') as any;


    el.reset();

    this.cleanSearch.emit();

  }

}

