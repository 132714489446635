import { ListItem } from "../interfaces/app.utilservices.interface";


export enum EUF {
    AC = 1,
    AL,
    AP,
    AM,
    BA,
    CE,
    DF,
    ES,
    GO,
    MA,
    MT,
    MS,
    MG,
    PA,
    PB,
    PR,
    PE,
    PI,
    RJ,
    RN,
    RS,
    RO,
    RR,
    SC,
    SP,
    SE,
    TO,
    EX
}

export namespace EUF {

    export function toString(uf: EUF | number): string {
        switch (uf) {
            case EUF.AC: return 'Acre';
            case EUF.RO: return 'Rondônia';
            case EUF.AM: return 'Amazonas';
            case EUF.RR: return 'Roraima';
            case EUF.AP: return 'Amapá';
            case EUF.TO: return 'Tocantins';
            case EUF.MA: return 'Maranhão';
            case EUF.CE: return 'Ceará';
            case EUF.RN: return 'Rio Grande do Norte';
            case EUF.PB: return 'Paraíba';
            case EUF.PE: return 'Pernambuco';
            case EUF.PI: return 'Piauí';
            case EUF.PA: return 'Pará';
            case EUF.AL: return 'Alagoas';
            case EUF.SE: return 'Sergipe';
            case EUF.BA: return 'Bahia';
            case EUF.MG: return 'Minas Gerais';
            case EUF.ES: return 'Espírito Santo';
            case EUF.RJ: return 'Rio de Janeiro';
            case EUF.SP: return 'São Paulo';
            case EUF.PR: return 'Paraná';
            case EUF.SC: return 'Santa Catarina';
            case EUF.RS: return 'Rio Grande do Sul';
            case EUF.MS: return 'Mato Grosso do Sul';
            case EUF.MT: return 'Mato Grosso';
            case EUF.GO: return 'Goiás';
            case EUF.DF: return 'Distrito Federal';
            case EUF.EX: return 'Exterior';
        }
    }

    export function toList(): ListItem[] {
        return Object.keys(EUF)
            .filter(value => isNaN(Number(value)) === false)
            .map<ListItem>(key => ({ siglaUf: EUF[+key], nome: EUF.toString(+key) }));
    }
}
