import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component } from '@angular/core';
import { ModuloService } from 'src/app/services/app.modulo.service';
import { Icone, Modulo } from 'src/app/model';
import { ComunService } from 'src/app/services/app.comum.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LocalizacaoEvento } from 'src/app/model/app.localizacaoevento.model';
import { LocalizacaoEventoService } from 'src/app/services/app.localizacaoevento.service';

@Component({
    selector: 'app-localizacaoevento-visualizar',
    templateUrl: './localizacaoevento.visualizar.component.html',
    styleUrls: ['./localizacaoevento.visualizar.component.scss'],
    providers: [
        ModuloService
    ]
})
export class LocalizacaoEventoVisualizarComponent {

    public data: LocalizacaoEvento;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: LocalizacaoEventoService,
        private comum: ComunService
    ) {
        const id = +this.route.snapshot.queryParamMap.get("id");
        this.service.getById(id)
            .subscribe({
                next: (data: LocalizacaoEvento) => {
                    this.data = data;
                },
                error: () => this.data = null
            });
    }

    voltar() {
        this.router.navigate(['localizacaoevento']);
    }
}
