import { filter, tap } from 'rxjs/operators';
import { Component, OnInit, Pipe } from '@angular/core';
import { Grupo } from 'src/app/model/app.grupo.model';
import { ComunService } from 'src/app/services/app.comum.service';
import { AccountService } from 'src/app/services/app.login.service';
import { EGrupo } from 'src/app/constantes/app.egrupo.const';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    public grupos: Grupo[] = []

    constructor(
        private service: ComunService,
        private accountService: AccountService) {

        this.accountService.$user
            .pipe(
                filter(usuario => !!usuario)
            ).subscribe({
                next: usuario =>
                    this.service.ObterModulosPorUsuario(usuario.usuarioId)
                        .subscribe(modulos =>
                            this.grupos = modulos.reduce((final, { grupoId: grupoID, grupoNome: nome }, i, modulos) => {
                                if (!final.some(({ grupoId }) => grupoId == grupoID)) {
                                    const modulosGrupo = modulos.filter(({ grupoId }) => grupoId == grupoID);
                                    const ehUnico = modulosGrupo.length == 1;

                                    if (grupoID == EGrupo.DASHBOARD)
                                        final.unshift(new Grupo({ grupoID, nome, modulos: modulosGrupo, ehUnico }));
                                    else if (grupoID == EGrupo.ALERTA) {
                                        const regraIndex = final.findIndex(({ grupoId }) => grupoId == EGrupo.REGRAS);
                                        if (regraIndex != -1)
                                            final.splice(regraIndex + 1, 0, new Grupo({ grupoID, nome, modulos: modulosGrupo, ehUnico }))
                                    }
                                    else
                                        final.push(new Grupo({ grupoID, nome, modulos: modulosGrupo, ehUnico }));
                                }
                                return final;
                            }, []))
            });

    }

    get modulosCarregados() { return !!this.grupos.length; }

    ngOnInit() {
    }

    isMobileMenu() {
        return window.innerWidth < 991;
    };
}
