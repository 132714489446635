import { ModeloRegra } from 'src/app/model/app.modeloregra.model';
import { UtilService } from './app.utils.service';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

import { map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SortColumn, SortDirection } from '../directives/app.sortable.directive';
import { SearchResult, State } from '../interfaces/app.utilservices.interface';
import { EOrderBy } from '../constantes/app.eorderby.const';
import { ComunService } from './app.comum.service';
import { AccountService } from './app.login.service';

@Injectable({
    providedIn: 'root'
})
export class ModeloRegraService {
    private _search$ = new Subject<void>();
    private _modelosregra$ = new BehaviorSubject<ModeloRegra[]>([]);
    private _total$ = new BehaviorSubject<number>(0);
    private _situacao$ = new BehaviorSubject<boolean>(null);

    private readonly URL_CONTROLLER = 'ModeloRegra';

    private _state: State<ModeloRegra> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: EOrderBy.ASC,
        nome: '',
        situacaoID:'',
        condicionante:'',
        fitroGenerico:''
    };

    constructor(private util: UtilService, private comunService: ComunService, private accountService: AccountService) {
        this._search$.pipe(
            switchMap(() => this._pagination())
        )
            .subscribe(({ modelosregra, total }) => {
                this._modelosregra$.next(modelosregra);
                this._total$.next(total);
            });
    }

    get modelosregra$() { return this._modelosregra$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get page() { return this._state.page; }
    get pages() { return Math.ceil(this._total$.value / this._state.pageSize); }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get situacao$() { return this._situacao$.asObservable(); }


    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize, page: 1 }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn<ModeloRegra>) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    public new({
        modeloRegraId = null,
        condicionanteId = null,
        nome = null,
        parametrosEntrada = null,
        filtrosEntrada = null,
        parametrosSaida = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/inserir`;
        return this.util.Post<ModeloRegra>(url, {
            ModeloRegraID: modeloRegraId,
            CondicionanteID: condicionanteId,
            Nome: nome,
            ParametrosEntrada: parametrosEntrada.map(({ parametroEntradaId, obrigatorio }) => ({ ParametroEntradaID: parametroEntradaId, Obrigatorio: obrigatorio })),
            FiltrosEntrada: filtrosEntrada.map(({ filtroEntradaId, obrigatorio }) => ({ FiltroEntradaID: filtroEntradaId, Obrigatorio: obrigatorio })),
            ParametrosSaida: parametrosSaida.map(({ parametroSaidaId, compoeNotificacao, compoeVisualizacao }) => ({ ParametroSaidaID: parametroSaidaId, CompoeNotificacao: compoeNotificacao, CompoeVisualizacao: compoeVisualizacao })),
            UsuarioID: this.accountService.userValue?.usuarioId
        });
    }

    public get(data) {
        this._set(data);
    }

    public put({
        modeloRegraId = null,
        condicionanteId = null,
        nome = null,
        parametrosEntrada = null,
        filtrosEntrada = null,
        parametrosSaida = null,
        situacaoId = null,
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizar`;
        return this.util.Put<ModeloRegra>(url, {
            ModeloRegraID: modeloRegraId,
            CondicionanteID: condicionanteId,
            Nome: nome,
            ParametrosEntrada: parametrosEntrada.map(({ parametroEntradaId, obrigatorio }) => ({ ParametroEntradaID: parametroEntradaId, Obrigatorio: obrigatorio })),
            FiltrosEntrada: filtrosEntrada.map(({ filtroEntradaId, obrigatorio }) => ({ FiltroEntradaID: filtroEntradaId, Obrigatorio: obrigatorio })),
            ParametrosSaida: parametrosSaida.map(({ parametroSaidaId, compoeNotificacao, compoeVisualizacao }) => ({ ParametroSaidaID: parametroSaidaId, CompoeNotificacao: compoeNotificacao, CompoeVisualizacao: compoeVisualizacao })),
            UsuarioID: this.accountService.userValue?.usuarioId,
            SituacaoID: situacaoId
        })
            .pipe(
                tap(() => this._search$.next())
            )
    }

    public putSituacao({
        modeloRegraId = null,
        situacaoId = null
    }): Observable<string> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/atualizarSituacao`;
        return this.util.Put<ModeloRegra>(url, {
            ID: modeloRegraId,
            UsuarioID: this.accountService.userValue?.usuarioId,
            SituacaoID: situacaoId
        })
            .pipe(
                tap(() => this._search$.next())
            )
    }

    public getById(modeloRegraId: number) {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPorID/${modeloRegraId}`;
        return this.util.Get<any>(url)
            .pipe(
                filter(modeloRegra => !!modeloRegra),
                map(modeloRegra => new ModeloRegra(modeloRegra))
            );
    }

    public getOrderByDirection(sortColumn: SortColumn<ModeloRegra>, sortDirection: SortDirection) {
        this._set({ sortColumn, sortDirection });
    }

    private _set(patch: any) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _pagination(): Observable<SearchResult<ModeloRegra>> {
        const url = `${environment.apiUrl}/${this.URL_CONTROLLER}/obterPaginado`;
        return this.util.Post(url, {
            Page: this._state.page,
            Size: this._state.pageSize,
            OrderBy: this._state.sortDirection || EOrderBy.ASC,
            FieldAtOrder: this._state.sortColumn?.toCaptalize(),
            Nome: this._state.nome,
            SituacaoID:this._state.situacaoID,
            Condicionante:this._state.condicionante,
            FiltroGenerico: this._state.filtroGenerico
        })
            .pipe(
                map(({ results, total }) => ({ modelosregra: results.map(dto => new ModeloRegra(dto)), total })),
                catchError(errorRequest => {
                    const { error, message } = errorRequest;
                    console.log("ERROR", error?.message || message);
                    return of({ condicionantes: [], total: 0 });
                })
            );
    }
}
