import { Regra } from 'src/app/model/app.regra.model';
import { RegraService } from './../../../services/app.regra.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { ComunService } from 'src/app/services/app.comum.service';
import { filter, take, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Unidade, UnidadeFiscal } from 'src/app/model/app.unidadefiscal.model';
import { Equipamento } from 'src/app/model/app.equipamentos.model';
import { ESituacao } from 'src/app/constantes/app.esituacao.const';
import { ParametroEntrada, RegraFiltroEntrada, RegraParametroEntrada } from 'src/app/model';

@Component({
    selector: 'app-regra-visualizar',
    templateUrl: './regra.visualizar.component.html',
    styleUrls: ['./regra.visualizar.component.scss'],
    providers: [
        RegraService
    ]
})
export class RegraVisualizarComponent {

    public data: Regra;
    public unidadesFiscais: UnidadeFiscal[] = [];
    public equipamentos: Equipamento[] = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: RegraService,
        private comum: ComunService
    ) {
        const regraId = +this.route.snapshot.queryParamMap.get("regraId");
        this.comum.ObterUnidadesESubunidadesFiscaisAtivas().subscribe((retorno) => {
            this.unidadesFiscais = retorno;
        })
        combineLatest({
            regra: this.service.getById(regraId),
            equipamentos: this.comum.ObterEquipamentos()
                .pipe(filter(equipamentos => !!equipamentos)),            
        }).pipe(
                take(1),
            )
            .subscribe(({ regra, equipamentos }) => {
                this.data = regra;
                this.equipamentos = equipamentos;
            });
    }
    get possuiUnidadesFiscais() { return !!this.data.regrasUnidadeFiscal.length; }

    get unidades(): UnidadeFiscal[] {
        const selecionadas = this.data?.regrasUnidadeFiscal.filter(({ unidadeFiscalId, usuarioId, situacaoId }) => ({ unidadeFiscalId, usuarioId }) && situacaoId == ESituacao.ATIVO);

        return this.unidadesFiscais
            .filter(({ unidadeFiscalId }) => selecionadas.some(unidadeFiscal => unidadeFiscal.unidadeFiscalId == unidadeFiscalId))
            .map(unidade => {
                unidade.usuarios = unidade.usuarios.filter(({ usuarioId }) => selecionadas.some(unidadeFiscal => unidadeFiscal.usuarioId == usuarioId && unidadeFiscal.unidadeFiscalId == unidade.unidadeFiscalId));
                return unidade;
            });
    }

    get filtrosEntrada(): any {
        return (this.data?.regrasFiltroEntrada || [])
            .map(filtroEntrada => {
                const { valor } = this.data
                    ?.regrasFiltroEntrada
                    ?.find(({ filtroEntradaNome }) => filtroEntradaNome == filtroEntrada.filtroEntradaNome) || {};
                filtroEntrada.valor = valor;
                const { tipoDadoNome } = filtroEntrada;
                if (tipoDadoNome == 'EQUIPAMENTOS') {
                    const { local, sentidoDaLeitura, nome } = this.equipamentos.find(({ identificadorDoEquipamento }) => identificadorDoEquipamento == filtroEntrada.valor) || {};
                    filtroEntrada.localNome = local?.nome;
                    filtroEntrada.sentidoDaLeitura = sentidoDaLeitura;
                }
                return filtroEntrada;
            });
    }

    get parametrosEntrada(): any {
        return (this.data?.regrasParametroEntrada || [])
            .filter(parametroEntrada => {
                const isValid = this.data
                    ?.modeloRegra?.parametrosEntrada
                    ?.some(({ nome }) => nome == parametroEntrada.parametroEntradaNome) || {};
                const { tipoDadoNome } = parametroEntrada;
                if (tipoDadoNome == 'EQUIPAMENTOS') {
                    const { local, sentidoDaLeitura, nome } = this.equipamentos.find(({ identificadorDoEquipamento }) => identificadorDoEquipamento == parametroEntrada.valor) || {};
                    parametroEntrada.localNome = local?.nome;
                    parametroEntrada.sentidoDaLeitura = sentidoDaLeitura;
                }
                return parametroEntrada && isValid;
            });
    }

    get parametrosSaida(): any {
        return (this.data?.modeloRegra.parametrosSaida || [])

    }

    get showFiltroEntrada(): boolean {
        return this.filtrosEntrada.some(f => !String.isNullOrEmpty(f.valor));
    }

    voltar() {
        this.router.navigate(['regra']);
    }

    formatarValorOperadorAgrupadorParametroEntrada(parametroEntrada: RegraParametroEntrada, valorFormatado: string): string[] {
        let retorno = new Array();
        retorno.push(parametroEntrada?.operadorNome);
        retorno.push(valorFormatado);
        if (parametroEntrada.agrupadorNome)
            retorno.push(parametroEntrada.agrupadorNome);

        return retorno;
    }

    formatarValorOperadorAgrupadorFiltroEntrada(filtroEntrada: RegraFiltroEntrada, valorFormatado: string): string[] {
        let retorno = new Array();
        retorno.push(filtroEntrada?.operadorNome);
        retorno.push(valorFormatado);
        if (filtroEntrada.agrupadorNome)
            retorno.push(filtroEntrada.agrupadorNome);

        return retorno;
    }
}
