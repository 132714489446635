export class Operador {

    operadorId?: number;
    nome?: string;
    dataHoraInclusao?: Date;
    dataHoraAlteracao?: Date;
    usuarioIDInclusao?: number;
    usuarioNomeInclusao?: string;
    usuarioIDAlteracao?: number;
    usuarioNomeAlteracao?: string;
    situacaoId?: number;
    situacaoNome?: string;

    constructor(moduloDto: any) {
        this.operadorId = moduloDto?.operadorID ?? moduloDto?.operadorId;
        this.nome = moduloDto?.nome;
        this.dataHoraInclusao = moduloDto?.dataHoraInclusao;
        this.dataHoraAlteracao = moduloDto?.dataHoraAlteracao;
        this.usuarioIDInclusao = moduloDto?.usuarioIDInclusao;
        this.usuarioNomeInclusao = moduloDto?.usuarioNomeInclusao;
        this.usuarioIDAlteracao = moduloDto?.usuarioIDAlteracao;
        this.usuarioNomeAlteracao = moduloDto?.usuarioNomeAlteracao;
        this.situacaoId = moduloDto?.situacaoID;
        this.situacaoNome = moduloDto?.situacaoNome;
    }
}
