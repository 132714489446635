import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export enum ToastType {
	DEFAULT = 0,
	SUCCESS = 1,
	INFO = 2,	
	WARNING = 3,
	DANGER = 4,
}

interface ToastMultiple {
	type: ToastType;
	message: string;
	title?: string;
}

@Injectable({
	providedIn: 'root'
})
export class ToastUtil {

	constructor(public toastr: ToastrService) { }

	showMultiple(messages: ToastMultiple[]) {
		for (const { type, message, title } of messages) {
			switch (type) {
				case ToastType.DEFAULT:
					this.showStandard(message, title);
					break;
				case ToastType.SUCCESS:
					this.showSuccess(message, title);
					break;
				case ToastType.INFO:
					this.showInfo(message, title);
					break;
				case ToastType.WARNING:
					this.showWarning(message, title);
					break;
				case ToastType.DANGER:
					this.showDanger(message, title);
					break;
			}
		}
	}

	showStandard(message: string, titulo: string = null) {
		this.toastr.show(message, titulo);
	}

	showSuccess(message: string, titulo: string = 'Sucesso') {
		this.toastr.success(message, titulo);
	}

	showWarning(message: string, titulo: string = 'Atenção') {
		this.toastr.warning(message, titulo);
	}

	showInfo(message: string, titulo: string = 'Fique Atento') {
		this.toastr.info(message, titulo);
	}

	showDanger(message: string, titulo: string = 'Erro') {
		this.toastr.error(message, titulo);
	}

	remove(toastId: number) {
		this.toastr.remove(toastId);
	}

	clear() {
		this.toastr.clear();
	}
}
