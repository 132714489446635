<my-content placeholder="main">
    <div class="main-content">
        <div class="row">
            <div class="col-md-12">
                <div class="card" *blockUI="'regra'">
                    <div class="card-body">
                        <h5 class="mb-0">Complete as etapas para atualizar a Regra</h5>
                        <hr class="mt-2" />
                        <div class="">
                            <div class="row">
                                <div class="col-md-12">
                                    <form [formGroup]="regraForm" id="msform">
                                        <!-- progressbar -->
                                        <ul class="d-flex justify-content-center p-0" id="progressbar">
                                            <li [ngClass]="jaPassouModeloRegra">Modelo de Regra</li>
                                            <li [ngClass]="jaPassouDestinatario">Destinatários</li>
                                            <li [ngClass]="jaPassouComportamento">Comportamento do Alerta</li>
                                            <li [ngClass]="jaPassouComportamento">Comportamento do Evento</li>
                                            <li [ngClass]="jaPassouPeriodoVigencia">Período de Vigência
                                            </li>
                                            <li [ngClass]="jaPassouJustificativa">Justificativa</li>
                                        </ul>
                                        <p class="col-md-9 m-auto mb-4">
                                            <ngb-progressbar type="success" [value]="progressValue" [striped]="true">
                                            </ngb-progressbar>
                                        </p>
                                        <!-- fieldsets -->
                                        <div class="fieldset form-fieldset" hidden [class.show]="ehModeloRegra"
                                            style="margin-top: 30px;">
                                            <app-passo1modeloregra *ngIf="ehModeloRegra"></app-passo1modeloregra>
                                            <div class="modal-footer d-flex justify-content-center">
                                                <input (click)="onNext()" type="button" name="next"
                                                    class="next btn btn-primary action-button px-3"
                                                    value="Prosseguir" />
                                            </div>
                                        </div>
                                        <div class="fieldset form-fieldset" hidden [class.show]="ehDestinatario"
                                            style="margin-top: 30px;">
                                            <app-passo2destinatario *ngIf="ehDestinatario"></app-passo2destinatario>
                                            <div class="modal-footer d-flex justify-content-center">
                                                <input (click)="onPrevious()" type="button" name="previous"
                                                    class="previous btn action-button-previous" value="Voltar" />
                                                <input (click)="onNext()" type="button" name="next"
                                                    class="next btn btn-primary action-button px-3"
                                                    value="Prosseguir" />
                                            </div>
                                        </div>
                                        <div class="fieldset form-fieldset" hidden [class.show]="ehComportamento"
                                            style="margin-top: 30px;">
                                            <app-passo3comportamento *ngIf="ehComportamento"></app-passo3comportamento>
                                            <div class="modal-footer d-flex justify-content-center">
                                                <input (click)="onPrevious()" type="button" name="previous"
                                                    class="previous btn action-button-previous" value="Voltar" />
                                                <input (click)="onNext()" type="button" name="next"
                                                    class="next btn btn-primary action-button px-3"
                                                    value="Prosseguir" />
                                            </div>
                                        </div>
                                        <div class="fieldset form-fieldset" hidden [class.show]="ehPeriodoVigencia"
                                            style="margin-top: 30px;">
                                            <app-passo4periodo></app-passo4periodo>
                                            <div class="modal-footer d-flex justify-content-center">
                                                <input (click)="onPrevious()" type="button" name="previous"
                                                    class="previous btn action-button-previous" value="Voltar" />
                                                <input (click)="onNext()" type="button" name="next"
                                                    class="next btn btn-primary action-button px-3"
                                                    value="Prosseguir" />
                                            </div>
                                        </div>
                                        <div class="fieldset form-fieldset" hidden [class.show]="ehJustificativa"
                                            style="margin-top: 30px;">
                                            <app-passo5justificativa></app-passo5justificativa>
                                            <div class="modal-footer d-flex justify-content-center">
                                                <input (click)="onPrevious()" type="button" name="previous"
                                                    class="previous btn action-button-previous" value="Voltar" />
                                                <input (click)="onSubmit()" type="submit" name="submit"
                                                    class="next btn btn-primary action-button px-3"
                                                    value="Atualizar Regra" />
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn default"
                                                (click)="onCancel()">Cancelar</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <hr /> -->

                </div>
            </div>
        </div>
    </div>



</my-content>