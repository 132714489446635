import { Alerta } from "./app.alerta.model";
import { Regra } from "./app.regra.model";
import { Usuario } from "./app.usuario.model";

export class LocalizacaoEvento {
    id: number;
    acaoID: number;
    acaoNome: string;
    modulo: string;
    menu: string;
    dataHora: string;
    usuario: Usuario;
    regra: Regra;
    alerta: Alerta;
    record: string;
    uuId?: string;
    latitude?: number;
    longitude?: number;
    localizacao?: string;

    constructor(localizacaoEventosDto: any) {
        this.id = localizacaoEventosDto?.id;
        this.acaoID = localizacaoEventosDto?.acaoID;
        this.acaoNome = localizacaoEventosDto?.acaoNome;
        this.modulo = localizacaoEventosDto?.modulo;
        this.menu = localizacaoEventosDto?.menu;
        this.dataHora = localizacaoEventosDto?.dataHoraFormatada;
        this.usuario = new Usuario(localizacaoEventosDto?.usuario);
        this.regra = localizacaoEventosDto?.regra ? new Regra(localizacaoEventosDto?.regra) : null;
        this.alerta = localizacaoEventosDto?.alerta ? new Alerta(localizacaoEventosDto?.alerta) : null;
        this.record = localizacaoEventosDto?.record;
        this.uuId = localizacaoEventosDto?.uUID;
        this.latitude = localizacaoEventosDto?.latitude;
        this.longitude = localizacaoEventosDto?.longitude;
        this.localizacao = this.latitude ? `${this.latitude}, ${this.longitude}` : '';
    }
}

export interface LocalizacaoEventoSearch {
    id: number;
    acaoID: number;
    modulo: string;
    menu: string;
    dataHora: string;
    usuario: Usuario;
    record: string;
    uuId?: string;
    latitude?: number;
    longitude?: number;
}
