import { CondicionanteService } from '../../../services/app.condicionante.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component } from '@angular/core';
import { Condicionante } from 'src/app/model/app.condicionante.model';

@Component({
    selector: 'app-condicionante-visualizar',
    templateUrl: './condicionante.visualizar.component.html',
    styleUrls: ['./condicionante.visualizar.component.scss'],
    providers: [
        CondicionanteService
    ]
})
export class CondicionanteVisualizarComponent{

    public data: Condicionante;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: CondicionanteService
    ) {
        const condicionanteId = +this.route.snapshot.queryParamMap.get("condicionanteId");
        this.service.getById(condicionanteId)
            .subscribe({
                next: (data: any) => this.data = data,
                error: () => this.data = null
            });
    }

    voltar() {
        this.router.navigate(['condicionante']);
    }
}
