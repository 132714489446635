import { ParametrosEntradaService } from 'src/app/services/app.parametrosentrada.service';
import { ParametroEntrada } from '../../../model/app.parametroentrada.model';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-entrada-visualizar',
    templateUrl: './parametroentrada.visualizar.component.html',
    styleUrls: ['./parametroentrada.visualizar.component.scss']
})
export class ParametroEntradaVisualizarComponent {

    public data: ParametroEntrada;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private service: ParametrosEntradaService,
    ) {
        const parametroEntradaId = +this.route.snapshot.queryParamMap.get("parametroEntradaId");
        this.service.getById(parametroEntradaId)
            .subscribe({
                next: (data: any) => {
                    this.data = data
                },
                error: () => this.data = null
            });
    }

    voltar() {
        this.router.navigate(['parametroentrada']);
    }
}
