export class AlertaParametroSaida {
    alertaParametroSaidaId: number;
    alertaId: number;
    modeloRegraParametroSaidaId: number;
    valor: string;
    nome: string;
    hashNIFI: string;
    compoeVisualizacao: boolean;
    dataHoraInclusao: string;
    edadosDoNovoHash: boolean

    constructor(dto: any) {
        this.alertaParametroSaidaId = dto?.alertaParametroSaidaID;
        this.alertaId = dto?.alertaID;
        this.modeloRegraParametroSaidaId = dto?.modeloRegraParametroSaidaID;
        this.valor = dto?.valor;
        this.nome = dto?.nome;
        this.hashNIFI = dto?.hashNIFI;
        this.compoeVisualizacao = dto?.compoeVisualizacao;
        this.dataHoraInclusao = dto?.dataHoraInclusao;
        this.edadosDoNovoHash = dto?.edadosDoNovoHash;
    }
}
